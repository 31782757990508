import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, vModelText as _vModelText, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "modal fade show",
  style: {"display":"block"},
  "aria-modal": "true",
  role: "dialog"
}
const _hoisted_3 = { class: "modal-dialog modal-lg modal-fullscreen-md-down" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "modal-header" }
const _hoisted_6 = { class: "modal-title" }
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = { class: "mb-4" }
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "mb-4" }
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "mb-2" }
const _hoisted_15 = { class: "mb-5" }
const _hoisted_16 = { class: "d-flex align-items-center" }
const _hoisted_17 = ["disabled"]

import { ref, computed, watch, onMounted} from 'vue'
import { useStore } from 'vuex'
import { TranslatedText } from "@/store/types"
import { ContactsNetworking } from "@/store/i/types"
import { AppNetworkErrorOther, networkErrorText } from "@/crud"
import { DateTime } from 'luxon'
import _ from 'lodash'


export default /*@__PURE__*/_defineComponent({
  __name: 'MeetingModal',
  props: {
    connection: {},
    toggle_modal: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const store = useStore()

// Reactive state
const show = ref(false)
const meeting_title = ref("")
const long_summary = ref("")
const date = ref("")
const duration = ref("")
const time = ref("")
const meetings = ref<any[]>([])
const meetings_loaded = ref(false)
const errorstring = ref<TranslatedText>({ de: '', en: '' })
const successtring = ref<TranslatedText>({ de: '', en: '' })

// Computed
const contactEntry = computed(() => {
  return _.find(
      store.state.i.contacts_networking,
      (e: ContactsNetworking) => `${e.objvalueid}` === `${props.connection}`
  )
})

const days = computed(() => [
  { key: "17.06.2024", name: 'June 17' },
  { key: "18.06.2024", name: 'June 18' },
])

const durations = computed(() => [
  { key: '1', name: '15 min' },
  { key: '2', name: '30 min' },
])

const times = computed(() => {
  switch (duration.value) {
    case "1":
      return [
        {key:"09:00", name:'9:00 AM'},
        {key:"09:15", name:'9:15 AM'},
        {key:"09:30", name:'9:30 AM'},
        {key:"09:45", name:'9:45 AM'},
        {key:"10:00", name:'10:00 AM'},
        {key:"10:15", name:'10:15 AM'},
        {key:"10:30", name:'10:30 AM'},
        {key:"10:45", name:'10:45 AM'},
        {key:"11:00", name:'11:00 AM'},
        {key:"11:15", name:'11:15 AM'},
        {key:"11:30", name:'11:30 AM'},
        {key:"11:45", name:'11:45 AM'},
        {key:"12:00", name:'12:00 PM'},
        {key:"12:15", name:'12:15 PM'},
        {key:"12:30", name:'12:30 PM'},
        {key:"12:45", name:'12:45 PM'},
        {key:"13:00", name:'13:00 PM'},
        {key:"13:15", name:'13:15 PM'},
        {key:"13:30", name:'13:30 PM'},
        {key:"13:45", name:'13:45 PM'},
        {key:"14:00", name:'14:00 PM'},
        {key:"14:15", name:'14:15 PM'},
        {key:"14:30", name:'14:30 PM'},
        {key:"14:45", name:'14:45 PM'},
        {key:"15:00", name:'15:00 PM'},
        {key:"15:15", name:'15:15 PM'},
        {key:"15:30", name:'15:30 PM'},
        {key:"15:45", name:'15:45 PM'},
        {key:"16:00", name:'16:00 PM'},
        {key:"16:15", name:'16:15 PM'},
        {key:"16:30", name:'16:30 PM'},
        {key:"16:45", name:'16:45 PM'},
        {key:"17:00", name:'17:00 PM'},
        {key:"17:15", name:'17:15 PM'},
        {key:"17:30", name:'17:30 PM'},
        {key:"17:45", name:'17:45 PM'},
      ]
    case "2":
      return [
        {key:"09:00", name:'9:00 AM'},
        {key:"09:30", name:'9:30 AM'},
        {key:"10:00", name:'10:00 AM'},
        {key:"10:30", name:'10:30 AM'},
        {key:"11:00", name:'11:00 AM'},
        {key:"11:30", name:'11:30 AM'},
        {key:"12:00", name:'12:00 PM'},
        {key:"12:30", name:'12:30 PM'},
        {key:"13:00", name:'13:00 PM'},
        {key:"13:30", name:'13:30 PM'},
        {key:"14:00", name:'14:00 PM'},
        {key:"14:30", name:'14:30 PM'},
        {key:"15:00", name:'15:00 PM'},
        {key:"15:30", name:'15:30 PM'},
        {key:"16:00", name:'16:00 PM'},
        {key:"16:30", name:'16:30 PM'},
        {key:"17:00", name:'17:00 PM'},
        {key:"17:30", name:'17:30 PM'},
      ]
    default:
      return null
  }
})

// Methods
const close = () => {
  date.value = ''
  duration.value = ''
  time.value = ''
  meeting_title.value = ''
  long_summary.value = ''
  show.value = false
  meetings_loaded.value = false
}

const reset_message = () => {
  errorstring.value = { de: '', en: '' }
  successtring.value = { de: '', en: '' }
}

const change = () => {
  time.value = ''
}

const getNameAvailable = (key: string) => {
  const meetingDate = new Date(DateTime.fromFormat(`${date.value} ${key}`, "dd.MM.yyyy hh:mm").toISO()).getTime()
  let show_text = false

  Object.entries(meetings.value).forEach(([key, val]) => {
    const startdate = new Date(val["startdate"]).getTime()
    const enddate = new Date(val["enddate"]).getTime()
    if (meetingDate >= startdate && meetingDate < enddate) {
      show_text = true
      return true
    }
  })

  return show_text ? "possibly not available" : ""
}

const createMeeting = async (ContactEntry: any) => {
  try {
    const meetingData = {
      objvalueid: ContactEntry.objvalueid,
      startdate: `${date.value} ${time.value}`,
      duration: duration.value,
      meeting_title: meeting_title.value,
      long_summary: long_summary.value,
    }

    errorstring.value = { de: '', en: '' }
    successtring.value = { de: '', en: '' }

    const dpr = await store.dispatch("i/createMeeting", meetingData)
    setTimeout(reset_message, 2000)

    if (dpr) {
      successtring.value = {
        de: "Appointment request sent",
        en: "Appointment request sent"
      }

      const contactData = {
        fbuid: ContactEntry.fbuid,
        objvalueid: ContactEntry.objvalueid,
        gender: ContactEntry.gender,
        title: ContactEntry.title,
        firstname: ContactEntry.firstname,
        lastname: ContactEntry.lastname,
        orgname: ContactEntry.orgname
      }

      await store.dispatch('i/checkIfContactNeedsToBeAdded', { ...contactData })
      await store.dispatch('i/sendmydm', {
        body: "I sent you a request for an appointment",
        ts: Date.now() / 1000,
        sender: store.state.config.fbuid,
        receiver: contactData.fbuid
      })
      close()
    } else {
      errorstring.value = {
        de: "Appointment request could not be created",
        en: "Appointment request could not be created"
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

// Watchers
watch(() => props.toggle_modal, () => {
  show.value = !!props.connection
})

watch([() => date.value, () => duration.value], async () => {
  if (contactEntry.value && date.value && duration.value && !meetings_loaded.value) {
    try {
      errorstring.value = { de: '', en: '' }
      meetings.value = await store.dispatch("i/getSlotsNotAvailable", contactEntry.value.objvalueid)
      meetings_loaded.value = true
    } catch (e) {
      errorstring.value = networkErrorText(e as AppNetworkErrorOther)
    }
  }
})

// Lifecycle
onMounted(() => {
  show.value = !!props.connection
})

return (_ctx: any,_cache: any) => {
  return (show.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h2", _hoisted_6, "Appointment request to " + _toDisplayString(contactEntry.value?.firstname) + " " + _toDisplayString(contactEntry.value?.lastname), 1),
                _createElementVNode("button", {
                  type: "button",
                  "data-mdb-dismiss": "modal",
                  "aria-label": "Close",
                  class: "btn-close",
                  onClick: _withModifiers(close, ["prevent"])
                })
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("form", {
                  onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (createMeeting(contactEntry.value)), ["prevent"]))
                }, [
                  _withDirectives(_createElementVNode("div", { class: "alert alert-success col-lg-12" }, _toDisplayString(_unref(store).getters.i18n(successtring.value)), 513), [
                    [_vShow, successtring.value.de !== '']
                  ]),
                  _withDirectives(_createElementVNode("div", { class: "alert alert-danger mb-5" }, _toDisplayString(_unref(store).getters.i18n(errorstring.value)), 513), [
                    [_vShow, errorstring.value.de !== '']
                  ]),
                  _cache[11] || (_cache[11] = _createElementVNode("p", null, " Timeslots marked as \"possibly not available\" show the timeslots when the person may already be in another appointment. ", -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label" }, "Date", -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((date).value = $event))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(days.value, (e, ei) => {
                        return (_openBlock(), _createElementBlock("option", {
                          value: e.key
                        }, _toDisplayString(e.name), 9, _hoisted_9))
                      }), 256))
                    ], 512), [
                      [_vModelSelect, date.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _cache[7] || (_cache[7] = _createElementVNode("label", { class: "form-label" }, "Duration", -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((duration).value = $event)),
                      onChange: change
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(durations.value, (e, ei) => {
                        return (_openBlock(), _createElementBlock("option", {
                          value: e.key
                        }, _toDisplayString(e.name), 9, _hoisted_11))
                      }), 256))
                    ], 544), [
                      [_vModelSelect, duration.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _cache[8] || (_cache[8] = _createElementVNode("label", { class: "form-label" }, "Time", -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((time).value = $event))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(times.value, (e, ei) => {
                        return (_openBlock(), _createElementBlock("option", {
                          value: e.key
                        }, _toDisplayString(e.name) + " " + _toDisplayString(getNameAvailable(e.key)), 9, _hoisted_13))
                      }), 256))
                    ], 512), [
                      [_vModelSelect, time.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _cache[9] || (_cache[9] = _createElementVNode("label", { class: "form-label" }, "Subject", -1)),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((meeting_title).value = $event)),
                      class: "form-control"
                    }, null, 512), [
                      [_vModelText, meeting_title.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _cache[10] || (_cache[10] = _createElementVNode("label", { class: "form-label" }, "Message", -1)),
                    _withDirectives(_createElementVNode("textarea", {
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((long_summary).value = $event)),
                      class: "form-control"
                    }, null, 512), [
                      [_vModelText, long_summary.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("button", {
                      class: "btn btn-primary m-1",
                      onClick: _withModifiers(close, ["prevent"])
                    }, "Cancel"),
                    _createElementVNode("button", {
                      class: "btn btn-primary m-1",
                      type: "submit",
                      disabled: !date.value || !duration.value || !time.value || !meeting_title.value || !long_summary.value
                    }, " Send request ", 8, _hoisted_17)
                  ])
                ], 32)
              ])
            ])
          ])
        ]),
        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "modal-backdrop fade show" }, null, -1))
      ]))
    : _createCommentVNode("", true)
}
}

})