import { ActionTree } from "vuex"
import {
  ref,
  push,
  remove,
  onDisconnect,
  get,
  query,
  onValue,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  orderByChild,
  startAfter,
  startAt,
  limitToLast,
  endBefore,
  off,
  set
} from "firebase/database"

import _ from "lodash"
import randomstop from "@/store/randomstop"
import crud from "@/crud"
import OfflineDB from "@/store/i/offlinedb"
import rankingsMaxPoints from '@/ranking'
import { getFireDB, logoutFirebase } from "@/firebaseConnections"

import {
  InteractionState,
  Message,
  DMMessage,
  MultiUserRoom,
  SupportMessage,
  VotingBallot,
  VotingInteraction,
  ChatContact,
  ConnectionNetworking,
  AgendaPoint,
  ImagePoint,
  InteractionAlert,
  MemberEntry,
  ContactsNetworking,
  Meetings,
  Partner
} from "@/store/i/types"
import { RootState } from "@/store/types"

declare var __version__: number

export const roomlist = [
  "muc-livestream",
  "muc-workshop",
  "muc-cafe"
]

interface RoomToJoinTraceID {
  [key: string]: string
}
export const roomjointraceids: RoomToJoinTraceID = {}

let mycafeJoinref!: any // DatabaseReference
let mypresenceref!: any // DatabaseReference

let listenaddedres!: any
export const fblistenersattached = new Promise(resolve => {
  listenaddedres = resolve
})

const actions: ActionTree<InteractionState, RootState> = {

  async populate({ commit }): Promise<void> {
    try {
      await OfflineDB.populateStore(commit)
    } catch (e) {
      //
    }
  },

  async empty({ commit }) {
    commit("clean")
    await OfflineDB.empty()
    commit("clean")
  },

  async logoutFirebase({ state }) {
    if (state.connected) {
      try {
        if (mypresenceref) {
          await remove(mypresenceref)
        }
        await logoutFirebase()
      } catch (e) {
        console.debug(e)
      }
    }
  },

  async addFirebaseListeners({ state, commit, dispatch, rootState }) {
    const firedb = await getFireDB()

    const connectedRef = ref(firedb, ".info/connected")
    onValue(connectedRef, (snapshot) => {
      if (snapshot.val() === true) {
        const myconnectionref = ref(firedb, `/presence/event-general/${rootState.config.fbuid}`)
        mypresenceref = push(myconnectionref, { ts: Date.now() / 1000 })
        onDisconnect(mypresenceref).remove()
        commit("setConnectedState", true)
      } else {
        commit("setConnectedState", false)
      }
    })

    // Die Listener für alle Räume setzen
    for (let ri = 0; ri < roomlist.length; ri++) {
      dispatch("roomListeners", roomlist[ri])
    }

    await dispatch("getMyFormData")
    await dispatch("chatListeners")
    await dispatch("notificationsListeners")
    await dispatch("changeListenersAgenda")
    await dispatch("changeListenersPartner")
    await dispatch("changeListenerImage")
    await dispatch("fetchSpeakers")

    if (state.me.accept_networking) {
      await dispatch("changeListenersContactsNetworking", true)
      await dispatch("changeListenersConnectionsNetworking", true)
      await dispatch("changeListenersMeetings", true)
    }

    listenaddedres()
  },

  async roomListeners({ state, commit, dispatch }, pl: string) {
    if (pl === state.mucname) {
      dispatch("addInRoomlisteners", pl)
    }

    const firedb = await getFireDB()

    // "value"-Listener auf /rooms/<pl>
    const rRef = ref(firedb, `/rooms/${pl}`)
    onValue(rRef, (snapshot) => {
      const roomconf = snapshot.val()
      if (!roomconf) return
      roomconf.roomname = pl
      commit("roomUpdate", roomconf)
    })

    // Abstimmungen (child_added, child_changed)
    const votings = _.filter(state.votings, (v: VotingInteraction) => v.roomname === pl)
    const lastchange_voting = _.max([
      0,
      ..._.map(
        votings,
        (v: VotingInteraction) => v.ts
      )
    ]) as number

    const vPath = ref(firedb, `/votings/${pl}`)
    const vQuery = query(
      vPath,
      orderByChild("ts"),
      startAfter(lastchange_voting)
    )

    const handleVotingUpdate = (snapshotVal: any, snapKey: string) => {
      const voting_config = snapshotVal
      voting_config.uid = snapKey
      voting_config.roomname = pl
      commit("setVoting", voting_config)
    }

    onChildAdded(vQuery, (snapshot) => {
      if (!snapshot.exists()) return
      handleVotingUpdate(snapshot.val(), snapshot.key as string)
    })
    onChildChanged(vPath, (snapshot) => {
      if (!snapshot.exists()) return
      handleVotingUpdate(snapshot.val(), snapshot.key as string)
    })
  },

  async chatListeners({ state, rootState, commit, dispatch }) {
    const firedb = await getFireDB()

    // DM chat
    const lastDMTS = _.max([0, ..._.map(state.dmmessages, dm => dm.ts)]) as number
    const chatsRef = ref(firedb, `/chats/${rootState.config.fbuid}`)
    const chatsQuery = query(chatsRef, orderByChild("ts"), startAfter(lastDMTS))

    const firebaseProcessChat = async (snapshot: any) => {
      try {
        const chatuid = snapshot.key as string
        const data_chatindex = snapshot.val()
        const data_fullchat = (await get(ref(firedb, `/chatmsg/${chatuid}`))).val()

        const takeseenflag = data_chatindex.sender === rootState.config.fbuid
          ? data_chatindex.receiver
          : rootState.config.fbuid

        const seenflag = !!(
          data_fullchat.flags &&
          data_fullchat.flags[takeseenflag] &&
          !!(data_fullchat.flags[takeseenflag].seen)
        )

        const ndm: DMMessage = {
          uuid: chatuid,
          sender: data_chatindex.sender,
          receiver: data_chatindex.receiver,
          ts: data_fullchat.ts,
          body: data_fullchat.msg,
          seen: seenflag
        }

        const otheruserfbuid = ndm.sender !== rootState.config.fbuid ? ndm.sender : ndm.receiver
        const userfromdb = await OfflineDB.contactschats.where({ fbuid: otheruserfbuid }).last()
        if (!userfromdb) {
          try {
            const userfetch: ChatContact | false = await dispatch("getUserinfoForFirebaseuid", otheruserfbuid)
            if (userfetch !== false) {
              userfetch.fbuid = otheruserfbuid
              await dispatch("checkIfContactNeedsToBeAdded", userfetch)
            }
          } catch (e) {
            console.error(`Unable to fetch ${otheruserfbuid}`, e)
          }
        }

        commit("addDM", ndm)
      } catch (e) {
        console.error(e)
      }
    }

    onChildAdded(chatsQuery, firebaseProcessChat)

    // Support Chat
    const lastSMTS = _.max([0, ..._.map(state.supportmessages, sm => sm.ts)]) as number
    const supportRef = ref(firedb, `/supportchat/${rootState.config.fbuid}`)
    const supportQuery = query(supportRef, orderByChild("ts"), startAfter(lastSMTS))

    const firebaseProcessSupportChat = (snapshot: any) => {
      const data: any = snapshot.val()
      if (!data) return
      const stateNe: SupportMessage = {
        uuid: snapshot.key as string,
        ts: data.ts,
        body: data.msg,
        sender: data.sender === rootState.config.fbuid ? "me" : "support",
        receiver: data.receiver === rootState.config.fbuid ? "me" : "support",
        seen: data.seen
      }
      commit("addSupportMessage", stateNe)
    }

    onChildAdded(supportQuery, firebaseProcessSupportChat)
  },

  async notificationsListeners({ state, rootState, dispatch }) {
    const firedb = await getFireDB()
    const nrooms = [...rootState.config.rooms]

    nrooms.forEach((rn: string) => {
      const timecuttoff = _.max([
        0,
        ..._.map(
          _.filter(state.toasts, (ia: InteractionAlert) => ia.validforroom === rn),
          (ia: InteractionAlert) => ia.ts ? ia.ts : 0
        )
      ]) as number

      const modAnnRef = ref(firedb, `/modannounce/msg/${rn}`)
      const modAnnQuery = query(modAnnRef, orderByChild("ts"), startAt(timecuttoff))

      onChildAdded(modAnnQuery, (snapshot) => {
        dispatch("gotNotification", { roomname: rn, snapshot })
      })
    })
  },

  async gotNotification({ commit }, pl: any) {
    const roomname = pl.roomname as string
    const snapshot = pl.snapshot
    const nuid = snapshot.key as string

    const hasnotification = await OfflineDB.toasts.where({ uid: nuid }).first()
    const data = snapshot.val()
    if (!data) return

    const nalert: InteractionAlert = {
      uid: nuid,
      header: { de: data.header ? data.header : '' },
      msg: { de: data.text ? data.text : '' },
      ts: data.ts,
      validforroom: roomname,
      validfrom: new Date(),
      removed: false,
      queued: true,
      got: new Date(),
      link: data.link ? { de: data.link } : undefined,
      hidden: false,
      showfor: data.showfor
    }

    if (nalert.validfrom && data.validstart) {
      nalert.validfrom.setTime(data.validstart * 1000)
      if (nalert.got && nalert.got.getTime() < nalert.validfrom.getTime()) {
        nalert.got.setTime(nalert.validfrom.getTime())
        nalert.queued = true
      }
    }

    if (typeof data.validend === "number") {
      if (data.validend > 0) {
        if (data.validend * 1000 < Date.now()) {
          return
        } else {
          nalert.validto = new Date()
          nalert.validto.setTime(data.validend * 1000)
        }
      }
    }

    if (hasnotification) {
      nalert.id = hasnotification.id
      nalert.removed = hasnotification.removed
      nalert.hidden = hasnotification.hidden
      nalert.got = hasnotification.got
    }

    commit("addToast", nalert)
  },

  async testQueuedNotifications({ state, rootState, commit }) {
    const needed_rooms = [...rootState.config.rooms]
    if (state.mucname && state.mucname !== "") {
      needed_rooms.push(state.mucname)
    }

    const qn = _.filter(
      state.toasts,
      (t: InteractionAlert) => (
        t.queued &&
        (
          t.validfrom && t.validfrom.getTime() < Date.now() &&
          (!t.validto || t.validto.getTime() > Date.now())
        ) &&
        needed_rooms.some((r: string) => r === t.validforroom)
      )
    ) as InteractionAlert[]

    let nid!: number

    for (let ti = 0; ti < qn.length; ti++) {
      qn[ti].queued = false
      qn[ti].got = new Date()

      try {
        nid = await OfflineDB.toasts.put({ ...qn[ti] })
        qn[ti].id = nid
      } catch (e) {
        //
      }

      commit("addToast", qn[ti])
    }
  },

  async addInRoomlisteners({ state, commit, dispatch }, pl: string) {
    await fblistenersattached

    const firedb = await getFireDB()

    // Letzte 20 Nachrichten
    const maxpubchatts = _.max([
      0,
      ..._.map(
        _.filter(state.publicchats, (pc: Message) => pc.roomname === pl),
        (pc: Message) => pc.ts
      )
    ]) as number

    const pcRef = ref(firedb, `/pubchats/${pl}`)
    const pcQuery = query(
      pcRef,
      orderByChild("ts"),
      startAfter(maxpubchatts),
      limitToLast(20)
    )

    const handlePubChat = async (snapshot: any) => {
      const msguuid = snapshot.key as string
      const data = snapshot.val()
      if (!data) return
      const nmsg: Message = {
        uuid: msguuid,
        body: data.msg,
        msgid: data.msgid,
        roomname: pl,
        ts: data.ts,
        sender: data.sender
      }
      commit("addPublicMessage", nmsg)
    }

    onChildAdded(pcQuery, handlePubChat)
    onChildChanged(pcRef, (snapshot) => {
      const data = snapshot.val()
      if (!data) return
      // Man kann hier die Nachricht aktualisieren, falls nötig.
      handlePubChat(snapshot)
    })
    onChildRemoved(pcRef, (snapshot) => {
      commit("removeAPublicMessage", snapshot.key as string)
    })

    // Notifications
    const timecuttoff_notify = _.max([
      0,
      ..._.map(
        _.filter(state.toasts, (ia: InteractionAlert) => ia.validforroom === pl),
        (ia: InteractionAlert) => ia.ts ? ia.ts : 0
      )
    ]) as number

    const modRef = ref(firedb, `/modannounce/msg/${pl}`)
    const modQuery = query(modRef, orderByChild("ts"), startAt(timecuttoff_notify))
    onChildAdded(modQuery, (snapshot) => {
      dispatch("gotNotification", { roomname: pl, snapshot })
    })
  },

  async removeInRoomlisteners({}, pl: string) {
    const firedb = await getFireDB()

    // Man muss die Listener explizit abbestellen
    const pcRef = ref(firedb, `/pubchats/${pl}`)
    off(pcRef, 'child_added')
    off(pcRef, 'child_changed')
    off(pcRef, 'child_removed')

    const modRef = ref(firedb, `/modannounce/msg/${pl}`)
    off(modRef, 'child_added')
  },

  async loadRoomconfViaFallback({ state, commit }) {
    // Man wartet hier kurz (3 Sek), um ggf. noch Firebase-Daten zu erhalten
    await new Promise(resolve => { window.setTimeout(resolve, 3000) })

    const maxts = _.max([
      0,
      ..._.map(
        state.rooms,
        (r: MultiUserRoom) => r.ts * 1000
      )
    ]) as number

    // Wenn die vorhandenen Daten nicht neuer als 3 Minuten sind
    if (Date.now() - maxts < 1000 * 60 * 3) {
      return
    }

    try {
      const roomconfs = await crud("/i/roomConfFallback")
      if (state.rooms.length === 0 || !state.connected) {
        roomconfs.forEach(
          (r: MultiUserRoom) => {
            commit("roomUpdate", r)
          }
        )
      }
    } catch (e) {
      console.warn("Could not get room conf fallback", e)
    }
  },

  async changeListenersAgenda({ state, commit, dispatch }) {
    await dispatch("fetchAgenda")

    const newest_change = _.max([
      0,
      ..._.map(state.agenda, (a: AgendaPoint) => a.defaulttimestamp)
    ]) as number

    const firedb = await getFireDB()
    const agendaRef = ref(firedb, `/eveobj/agenda_items`)
    const agendaQuery = query(agendaRef, orderByChild("defaulttimestamp"), startAfter(newest_change))

    const handleChangeAgenda = async (snapshot: any) => {
      const data = snapshot.val()
      if (!data) return
      data.objvalueid = parseInt(snapshot.key as string, 10)

      let newdata = data
      const olddata = _.find(state.agenda, (a: AgendaPoint) => a.objvalueid === data.objvalueid)
      if (olddata) {
        newdata = {
          ...olddata,
          ...data
        }
        commit("setAgendaPoint", newdata)
      } else {
        await dispatch("fetchAgenda")
      }
    }

    onChildAdded(agendaQuery, handleChangeAgenda)
    onChildChanged(agendaRef, handleChangeAgenda)
  },

  async changeListenersPartner({ state, commit, dispatch }) {
    await dispatch("fetchPartner")

    const newest_change = _.max([
      0,
      ..._.map(state.partners, (a: Partner) => a.defaulttimestamp)
    ]) as number

    const firedb = await getFireDB()
    const partnerRef = ref(firedb, `/eveobj/exhibitor`)
    const partnerQuery = query(partnerRef, orderByChild("defaulttimestamp"), startAfter(newest_change))

    const handleChangePartner = async (snapshot: any) => {
      const data = snapshot.val()
      if (!data) return
      data.objvalueid = parseInt(snapshot.key as string, 10)

      let newdata = data
      const olddata = _.find(state.partners, (a: Partner) => a.objvalueid === data.objvalueid)
      if (olddata) {
        newdata = {
          ...olddata,
          ...data
        }
        commit("setPartner", newdata)
      } else {
        await dispatch("fetchPartner")
      }
    }

    onChildAdded(partnerQuery, handleChangePartner)
    onChildChanged(partnerRef, handleChangePartner)
  },

  async changeListenerImage({ state, commit, dispatch }) {
    await dispatch("fetchImages")

    const newest_change = _.max([
      0,
      ..._.map(state.image, (a: ImagePoint) => a.defaulttimestamp)
    ]) as number

    const firedb = await getFireDB()
    const imageRef = ref(firedb, `/eveobj/images`)
    const imageQuery = query(imageRef, orderByChild("defaulttimestamp"), startAfter(newest_change))

    const handleChangeImage = async (snapshot: any) => {
      const data = snapshot.val()
      if (!data) return
      data.objvalueid = parseInt(snapshot.key as string, 10)

      let newdata = data
      const olddata = _.find(state.image, (a: ImagePoint) => a.objvalueid === data.objvalueid)
      if (olddata) {
        newdata = {
          ...olddata,
          ...data
        }
        commit("setImagePoint", newdata)
      } else {
        await dispatch("fetchImages")
      }
    }

    onChildAdded(imageQuery, handleChangeImage)
    onChildChanged(imageRef, handleChangeImage)
  },

  async changeListenersContactsNetworking({ state, commit, dispatch }, pl: boolean) {
    const firedb = await getFireDB()
    if (pl) {
      await dispatch("fetchContactsNetworking")

      const newest_change = _.max([
        0,
        ..._.map(state.contacts_networking, (a: ContactsNetworking) => a.defaulttimestamp)
      ]) as number

      const membersRef = ref(firedb, `/eveobj/members`)
      const mQuery = query(membersRef, orderByChild("defaulttimestamp"), startAfter(newest_change))

      const handleChangeMember = async (snapshot: any) => {
        const data = snapshot.val()
        if (!data) return
        data.objvalueid = parseInt(snapshot.key as string, 10)

        let newdata = data
        const olddata = _.find(state.contacts_networking, (a: ContactsNetworking) => a.objvalueid === data.objvalueid)
        if (olddata) {
          newdata = {
            ...olddata,
            ...data
          }
          commit("setContactsNetworking", newdata)
        } else {
          await dispatch("fetchContactsNetworking")
        }
      }

      onChildAdded(mQuery, handleChangeMember)
      onChildChanged(membersRef, handleChangeMember)
    } else {
      const membersRef = ref(firedb, `/eveobj/members`)
      off(membersRef, 'child_added')
      off(membersRef, 'child_changed')
    }
  },

  async changeListenersConnectionsNetworking({ state, commit, dispatch, rootState }, pl: boolean) {
    const firedb = await getFireDB()
    if (pl) {
      await dispatch("fetchConnectionsNetworking")

      const newest_change = _.max([
        0,
        ..._.map(state.connections_networking, (a: ConnectionNetworking) => a.defaulttimestamp)
      ]) as number

      const netRef = ref(firedb, `/eveobj/networking`)
      const netQuery = query(netRef, orderByChild("defaulttimestamp"), startAfter(newest_change))

      const handleChangeNetworking = async (snapshot: any) => {
        const data = snapshot.val()
        if (!data) return
        data.objvalueid = parseInt(snapshot.key as string, 10)

        let newdata = data
        const olddata = _.find(state.connections_networking, (a: ConnectionNetworking) => a.objvalueid === data.objvalueid)
        if (olddata) {
          newdata = {
            ...olddata,
            ...data
          }
          commit("setConnectionsNetworking", newdata)
        } else {
          if (newdata.status && newdata.objvalueid && newdata.receiver && newdata.sender
            && (newdata.receiver == rootState.config.me.objvalueid || newdata.sender == rootState.config.me.objvalueid)) {
            await dispatch("fetchConnectionsNetworking")
          }
        }
      }

      onChildAdded(netQuery, handleChangeNetworking)
      onChildChanged(netRef, handleChangeNetworking)
    } else {
      const netRef = ref(firedb, `/eveobj/networking`)
      off(netRef, 'child_added')
      off(netRef, 'child_changed')
    }
  },

  async changeListenersMeetings({ state, commit, dispatch, rootState }, pl: boolean) {
    const firedb = await getFireDB()
    if (pl) {
      await dispatch("fetchMeetings")

      const newest_change = _.max([
        0,
        ..._.map(state.meetings, (a: Meetings) => a.defaulttimestamp)
      ]) as number

      const meetRef = ref(firedb, `/eveobj/meetings`)
      const meetQuery = query(meetRef, orderByChild("defaulttimestamp"), startAfter(newest_change))

      const handleChangeMeetings = async (snapshot: any) => {
        const data = snapshot.val()
        if (!data) return
        data.objvalueid = parseInt(snapshot.key as string, 10)

        let newdata = data
        const olddata = _.find(state.meetings, (a: Meetings) => a.objvalueid === data.objvalueid)
        if (olddata) {
          newdata = {
            ...olddata,
            ...data
          }
          commit("setMeetings", newdata)
        } else {
          if (
            newdata.status && newdata.objvalueid && newdata.receiver && newdata.sender &&
            (newdata.receiver == rootState.config.me.objvalueid || newdata.sender == rootState.config.me.objvalueid)
          ) {
            await dispatch("fetchMeetings")
          }
        }
      }

      onChildAdded(meetQuery, handleChangeMeetings)
      onChildChanged(meetRef, handleChangeMeetings)
    } else {
      const meetRef = ref(firedb, `/eveobj/meetings`)
      off(meetRef, 'child_added')
      off(meetRef, 'child_changed')
    }
  },

  async announce({ state, rootState }, pl: any) {
    const firedb = await getFireDB()

    const pushobj: any = {
      ts: Date.now() / 1000,
      user: rootState.config.fbuid
    }

    const forRoom = pl.ev === 'ijoined' || pl.ev === 'ileft'
    const forJoin = pl.ev === 'ijoined'

    if (forRoom) {
      if (forJoin && roomlist.some((r: string) => r === pl.nsp)) {
        pushobj.type = "playback"
      } else if (!forJoin) {
        if (!!roomjointraceids[pl.nsp as string] && roomjointraceids[pl.nsp as string] !== '') {
          const leaveset = 
            set(ref(firedb, `/trace/${pl.nsp}/${roomjointraceids[pl.nsp as string]}/end`), { ".sv": "timestamp" })

          leaveset.catch(e => console.error(e))
          delete roomjointraceids[pl.nsp as string]

          return Promise.race([
            leaveset,
            new Promise(resolve => { window.setTimeout(resolve, 1000) })
          ])
        }
      }
    } else {
      pushobj.ev = pl.ev
    }

    const pushpromise = push(ref(firedb, `/trace/${pl.nsp}`), pushobj)

    pushpromise.then((newref: any) => {
      if (forJoin) {
        onDisconnect(ref(firedb, `/trace/${pl.nsp}/${newref.key as string}/end`))
          .set({ ".sv": "timestamp" })
        roomjointraceids[pl.nsp as string] = newref.key as string
      }
    })

    return Promise.race([
      pushpromise,
      new Promise(resolve => { window.setTimeout(resolve, 1000) })
    ])
  },

  async endAllAnnounce({ dispatch }) {
    const useroomjointrace: string[] = Object.keys(roomjointraceids)
    for (let ri = 0; ri < useroomjointrace.length; ri++) {
      await dispatch("announce", {
        ev: "ileft",
        nsp: useroomjointrace[ri]
      })
    }
  },

  async getUserinfoForFirebaseuid({}, pl: string) {
    return crud('/i/getUserObjForFBUID', { fbuid: pl })
  },

  async forceLogout() {
    // Noch nichts implementiert
  },

  async openContactChat({ commit }, pl: any) {
    try {
      const nc: ChatContact = {
        fbuid: pl.fbuid,
        defaulttimestamp: pl.defaulttimestamp,
        objvalueid: pl.objvalueid,
        gender: pl.gender,
        title: pl.title,
        firstname: pl.firstname,
        lastname: pl.lastname,
        orgname: pl.orgname,
        country: pl.country
      }

      commit('OpenSideNav', 'chat', { root: true })
      commit('setChatboxContact', nc)
    } catch (e) {
      throw e
    }
  },

  async checkIfContactNeedsToBeAdded({ state, commit }, pl: ChatContact) {
    if (!state.contactschats.some((c: ChatContact) => c.fbuid === pl.fbuid)) {
      commit("addContactChat", pl)
    }
  },

  async sendmydm({ commit, rootState }, pl: DMMessage) {
    const firedb = await getFireDB()
    const nchatres = await push(ref(firedb, `/chatmsg`), {
      msg: pl.body,
      ts: pl.ts,
      receiver: pl.receiver,
      sender: rootState.config.fbuid
    })

    const chatobj = {
      ts: pl.ts,
      receiver: pl.receiver,
      sender: rootState.config.fbuid
    }

    await Promise.all([
      set(ref(firedb, `/chats/${chatobj.receiver}/${nchatres.key}`), chatobj),
      set(ref(firedb, `/chats/${chatobj.sender}/${nchatres.key}`), chatobj)
    ])
  },

  async sendmysupportmsg({ rootState }, pl: SupportMessage) {
    const firedb = await getFireDB()
    await set(ref(firedb, `/supportstatus/${rootState.config.fbuid}`),{
      change: pl.ts,
      changeby: rootState.config.fbuid,
      status: "waiting for answer"
    })

    const nuid = await push(ref(firedb, `/supportchat/${rootState.config.fbuid}`), {
      msg: pl.body,
      ts: pl.ts,
      sender: rootState.config.fbuid,
      receiver: "support",
      seen: false
    })
    pl.uuid = nuid.key as string
  },

  async setSupportFlag({ rootState, commit }, pl: any) {
    if (pl.msgid && pl.msgid !== "") {
      const firedb = await getFireDB()
      await set(ref(firedb, `/supportchat/${rootState.config.fbuid}/${pl.msgid}/seen`), true)
      commit("setSupportMessageFlag", pl)
    }
  },

  async setDMFlag({ rootState, commit }, pl: any) {
    if (pl.msgid && pl.msgid !== "") {
      const firedb = await getFireDB()
      await set(ref(firedb, `/chatmsg/${pl.msgid}/flags/${rootState.config.fbuid}`),{
        seen: true,
        ts: Date.now() / 1000
      })
      commit("setDMFlag", pl)
    }
  },

  async pushPublicChatMessage({ state, commit, rootState }, pl: string) {
    if (!state.mucname) {
      return false
    }

    //ranking activity a - für die Nachrichten im Raumchat je 3 Punkte
    if (state.me.tn_type != 26 && state.me.tn_type != 33) {
      if (state.me.ranking_a < rankingsMaxPoints.ranking_a && new Date().toDateString() === 'Mon Jun 17 2024') {
        let value = state.me.ranking_a + 3
        await crud('/setDataRanking', { fieldname: 'ranking_a', fieldvalue: value })
        commit("add_ranking_a", value)
        commit("add_me_offline", state.me)
      } else if (state.me.ranking_c < rankingsMaxPoints.ranking_c && new Date().toDateString() === 'Tue Jun 18 2024') {
        let value = state.me.ranking_c + 3
        await crud('/setDataRanking', { fieldname: 'ranking_c', fieldvalue: value })
        commit("add_ranking_c", value)
        commit("add_me_offline", state.me)
      } else {
        let value = state.me.ranking_a + 3
        await crud('/setDataRanking', { fieldname: 'ranking_a', fieldvalue: value })
        commit("add_ranking_a", value)
        commit("add_me_offline", state.me)
      }
    }

    const firedb = await getFireDB()
    try {
      const npc = await push(ref(firedb, `/pubchats/${state.mucname}`), {
        msg: pl,
        sender: rootState.config.fbuid,
        ts: Date.now() / 1000
      })

      await crud("/i/enrichuser", {
        type: "pubchats",
        uid: npc.key,
        roomname: state.mucname
      })
    } catch (e) {
      console.error(e)
      return false
    }
  },

  async pushInteractionMessage({ state, commit, rootState }, pl: string) {
    if (!state.mucname) {
      return false
    }

    const firedb = await getFireDB()
    try {
      const npc = await push(ref(firedb, `/questions/${state.mucname}`), {
        msg: pl,
        sender: rootState.config.fbuid,
        ts: Date.now() / 1000
      })

      await crud("/i/enrichuser", {
        type: "questions",
        uid: npc.key,
        roomname: state.mucname
      })

      const q = {
        uuid: npc.key,
        roomname: state.mucname,
        body: pl,
        sender: {
          obj: {
            ...rootState.config.me
          }
        },
        ts: Date.now() / 1000
      }
      commit("addQuestion", q)
    } catch (e) {
      console.error(e)
      return false
    }
  },

  async castBallot({ state, commit, rootState }, pl: VotingBallot): Promise<false | VotingBallot> {
    if (!state.mucname) {
      return false
    }

    const firedb = await getFireDB()
    pl.ts = Date.now() / 1000

    const nballot: VotingBallot = {
      ...pl,
      forfbuid: rootState.config.fbuid
    }

    try {
      const ref_res = await push(ref(firedb, `/voteballot/${pl.voteuid}/${pl.answer}/${rootState.config.fbuid}`), {
        ts: pl.ts
      })

      if (ref_res.key) {
        nballot.fbuid = ref_res.key
      }
    } catch (e) {
      console.error(e)
      return false
    }

    OfflineDB.ballots.put(nballot)
    commit("setBallot", nballot)

    return nballot
  },

  async setPresence({ rootState }, pl: string) {
    const firedb = await getFireDB()
    const myPresenceRef = push(ref(firedb, `/presence/${pl}/${rootState.config.fbuid}`), {
      ts: Date.now() / 1000
    })
    onDisconnect(myPresenceRef).remove()
    return myPresenceRef
  },

  async getMorePubchats({ state, commit }, pl: number) {
    const firedb = await getFireDB()

    const handlePubChat = async (snapshot: any) => {
      const msguuid = snapshot.key as string
      const data = snapshot.val()
      if (!data) return
      const nmsg: Message = {
        uuid: msguuid,
        body: data.msg,
        roomname: state.mucname,
        msgid: msguuid,
        ts: data.ts,
        sender: data.sender
      }
      commit("addPublicMessage", nmsg)
    }

    // Hier "endBefore(pl).limitToLast(5)"
    const q = query(
      ref(firedb, `/pubchats/${state.mucname}`),
      orderByChild("ts"),
      endBefore(pl),
      limitToLast(5)
    )
    onChildAdded(q, handlePubChat)
  },

  async checkSupportAvailable() {
    // Noch nichts implementiert
  },

  async checkUserOnlineState() {
    // Noch nichts implementiert
  },

  async getHistory() {
    // Noch nichts implementiert
  },

  async createMeeting({ state, commit }, pl: any) {
    try {
      //ranking activity e - Punktevergabe
      const value = state.me.ranking_f + 5
      await crud('/setDataRanking', { fieldname: 'ranking_f', fieldvalue: value })
      commit("add_ranking_f", value)
      commit("add_me_offline", state.me)

      const res = await crud('/acceptMeeting', pl)
      return res
    } catch (e) {
      throw e
    }
  },

  async declineMeeting({ commit }, pl: any) {
    try {
      const res = await crud('/declineMeeting', pl)
      return res
    } catch (e) {
      throw e
    }
  },

  async createConnection({ state }, pl: any) {
    try {
      //ranking activity c - hier gekürzt
      const res = await crud('/createConnection', pl)
      return res
    } catch (e) {
      throw e
    }
  },

  async removeConnection({}, pl: any) {
    try {
      const res = await crud('/removeConnection', pl)
      return res
    } catch (e) {
      throw e
    }
  },

  async acceptConnection({ state, commit }, pl: any) {
    try {
      if (state.me.ranking_d < rankingsMaxPoints.ranking_d && state.me.tn_type != 26 && state.me.tn_type != 33) {
        const value = state.me.ranking_d + 5
        await crud('/setDataRanking', { fieldname: 'ranking_d', fieldvalue: value })
        commit("add_ranking_d", value)
        commit("add_me_offline", state.me)
      }
      const res = await crud('/acceptConnection', pl)
      return res
    } catch (e) {
      throw e
    }
  },

  async declineConnection({}, pl: any) {
    try {
      const res = await crud('/declineConnection', pl)
      return res
    } catch (e) {
      throw e
    }
  },

  async blockConnection({}, pl: any) {
    try {
      const res = await crud('/blockConnection', pl)
      return res
    } catch (e) {
      throw e
    }
  },

  async fetchSpeakers({ state, commit, rootState }) {
    let newest_change = _.max([
      0,
      ..._.map(state.speakers, (a: MemberEntry) => a.defaulttimestamp || 0)
    ]) as number

    try {
      await randomstop()

      // Verwende den öffentlichen API-Endpunkt, wenn kein JWT vorhanden ist
      const endpoint = rootState.jwt === "" ? '/public/getSpeakers' : '/getSpeakers';
      const lae = await crud(endpoint, { changeoffset: newest_change })
      if (lae.entries) {
        lae.entries.forEach(
          (ae: MemberEntry) => {
            commit("setSpeaker", ae)
          }
        )
      }
    } catch (e) {
      console.error("Problem fetching speakers", e)
    }
  },

  async fetchAgenda({ state, commit, rootState }) {
    let newest_change = _.max([
      0,
      ..._.map(state.agenda, (a: AgendaPoint) => a.defaulttimestamp)
    ]) as number

    try {
      await randomstop()

      // Verwende den öffentlichen API-Endpunkt, wenn kein JWT vorhanden ist
      const endpoint = rootState.jwt === "" ? '/public/getAgendaEntries' : '/getAgendaEntries';
      const lae = await crud(endpoint, { changeoffset: newest_change })
      if (lae.entries) {
        lae.entries.forEach(
          (ae: AgendaPoint) => {
            commit("setAgendaPoint", ae)
          }
        )
      }
    } catch (e) {
      console.error("Problem fetching agenda", e)
    }
  },

  async fetchPartner({ state, commit }) {
    let newest_change = _.max([
      0,
      ..._.map(state.partners, (a: Partner) => a.defaulttimestamp)
    ]) as number

    try {
      await randomstop()

      const lae = await crud('/getPartners', { changeoffset: newest_change })
      if (lae.entries) {
        lae.entries.forEach(
          (ae: Partner) => {
            commit("setPartner", ae)
          }
        )
      }
    } catch (e) {
      console.error("Problem fetching agenda", e)
    }
  },

  async fetchImages({ state, commit }) {
    let newest_change = _.max([
      0,
      ..._.map(state.image, (a: ImagePoint) => a.defaulttimestamp)
    ]) as number

    try {
      await randomstop()

      const lae = await crud('/getImageEntries', { changeoffset: newest_change })
      if (lae.entries) {
        lae.entries.forEach(
          (ae: ImagePoint) => {
            commit("setImagePoint", ae)
          }
        )
      }
    } catch (e) {
      console.error("Problem fetching agenda", e)
    }
  },

  async fetchContactsNetworking({ state, commit }) {
    let newest_change = _.max([
      0,
      ..._.map(state.contacts_networking, (a: ChatContact) => a.defaulttimestamp)
    ]) as number

    try {
      await randomstop()
      const lae = await crud('/getContactsNetworkingEntries', { changeoffset: newest_change })
      if (lae.entries) {
        lae.entries.forEach(
          (ae: ChatContact) => {
            commit("setContactsNetworking", ae)
          }
        )
      }
    } catch (e) {
      console.error("Problem fetching contacts_networking", e)
    }
  },

  async fetchAllContactsNetworking({ commit }) {
    try {
      await randomstop()
      const lae = await crud('/getContactsNetworkingEntries', { changeoffset: 0 })
      if (lae.entries) {
        lae.entries.forEach(
          (ae: ChatContact) => {
            commit("setContactsNetworking", ae)
          }
        )
      }
    } catch (e) {
      console.error("Problem fetching contacts_networking", e)
    }
  },

  async fetchConnectionsNetworking({ state, commit }) {
    let newest_change = _.max([
      0,
      ..._.map(state.connections_networking, (a: ConnectionNetworking) => a.defaulttimestamp)
    ]) as number

    try {
      await randomstop()
      const lae = await crud('/getConnectionsNetworkingEntries', { changeoffset: newest_change })
      if (lae.entries) {
        lae.entries.forEach(
          (ae: ConnectionNetworking) => {
            commit("setConnectionsNetworking", ae)
          }
        )
      }
    } catch (e) {
      console.error("Problem fetching connections_networking", e)
    }
  },

  async fetchMeetings({ state, commit }) {
    let newest_change = _.max([
      0,
      ..._.map(state.meetings, (a: Meetings) => a.defaulttimestamp)
    ]) as number

    try {
      await randomstop()
      const lae = await crud('/getMeetingsEntries', { changeoffset: newest_change })
      if (lae.entries) {
        lae.entries.forEach(
          (ae: Meetings) => {
            commit("setMeetings", ae)
          }
        )
      }
    } catch (e) {
      console.error("Problem fetching meetings", e)
    }
  },

  async getSlotsNotAvailable({}, pl: number) {
    try {
      const lae = await crud('/getSlotsNotAvailable', { objvalueid: pl })
      return lae.entries
    } catch (e) {
      console.error("Problem get meetings", e)
    }
  },

  async SetFormData({ state, commit }, pl: any) {
    commit("add_me", pl)
    commit("add_me_offline", state.me)
  },

  async setDataOnBoarding1({ state, commit }, pl: any) {
    try {
      const res = await crud('/setDataOnBoarding1', pl)
      commit("add_onboarding1", pl)
      commit("add_me_offline", state.me)
      return res
    } catch (e) {
      throw e
    }
  },

  async setDataOnBoarding2({ state, commit }, pl: any) {
    try {
      const res = await crud('/setDataOnBoarding2', pl)
      commit("add_onboarding2", pl)
      commit("add_me_offline", state.me)
      return res
    } catch (e) {
      throw e
    }
  },

  async setDataOnBoarding3({}, pl: any) {
    try {
      const res = await crud('/setDataOnBoarding3', pl)
      return res
    } catch (e) {
      throw e
    }
  },

  async setDataCompetition({ commit }, pl: any) {
    try {
      const res = await crud('/setDataCompetition', pl)
      commit("add_score_reaction", pl)
      return res
    } catch (e) {
      throw e
    }
  },

  async setDataFav({ state, commit }, pl: any) {
    try {
      const res = await crud('/setDataFav', pl)
      if (res.sv) {
        commit("add_fav_agenda_items", res.data)
        if (state.me.ranking_b < rankingsMaxPoints.ranking_b && state.me.tn_type != 26 && state.me.tn_type != 33) {
          const value = state.me.ranking_b + 5
          await crud('/setDataRanking', { fieldname: 'ranking_b', fieldvalue: value })
          commit("add_ranking_b", value)
        }
        commit("add_me_offline", state.me)
      }
      return res
    } catch (e) {
      throw e
    }
  },

  async setDataUnFav({ state, commit }, pl: any) {
    try {
      const res = await crud('/setDataUnFav', pl)
      if (res.sv) {
        commit("add_fav_agenda_items", res.data)
        commit("add_me_offline", state.me)
      }
      return res
    } catch (e) {
      throw e
    }
  },

  async getMyFormData({ state, dispatch }) {
    try {
      let changeoffset = 0
      if (state.me.defaulttimestamp > 0) {
        changeoffset = (new Date(state.me.defaulttimestamp)).getTime()
      }
      const fe = await crud('/getMyFormData', { changeoffset })
      if (fe.formdata) {
        fe.formdata.forEach(
          (pl: any) => dispatch("SetFormData", pl)
        )
      }
    } catch (e) {
      //
    }
  },

  async setDataRankingE({ state, commit }, value: number) {
    try {
      await crud('/setDataRanking', { fieldname: 'ranking_e', fieldvalue: value })
      commit("add_ranking_e", value)
      commit("add_me_offline", state.me)
    } catch (e) {
      throw e
    }
  },

  async setDataRankingG({ state, commit }, value: number) {
    try {
      await crud('/setDataRanking', { fieldname: 'ranking_g', fieldvalue: value })
      commit("add_ranking_g", value)
      commit("add_me_offline", state.me)
    } catch (e) {
      throw e
    }
  },

  async setDataRankingH({ state, commit }, value: number) {
    try {
      await crud('/setDataRanking', { fieldname: 'ranking_h', fieldvalue: value })
      commit("add_ranking_h", value)
      commit("add_me_offline", state.me)
    } catch (e) {
      throw e
    }
  },

  async setDataRankingI({ commit }, value: number) {
    try {
      await crud('/setDataRanking', { fieldname: 'ranking_i', fieldvalue: value })
      commit("add_ranking_i", value)
    } catch (e) {
      throw e
    }
  },

  async setDataRankingJ({ commit }, value: number) {
    try {
      await crud('/setDataRanking', { fieldname: 'ranking_j', fieldvalue: value })
      commit("add_ranking_j", value)
    } catch (e) {
      throw e
    }
  },

  async setDataRankingM({ commit }, value: number) {
    try {
      await crud('/setDataRanking', { fieldname: 'ranking_m', fieldvalue: value })
      commit("add_ranking_m", value)
    } catch (e) {
      throw e
    }
  },

  async fetchSpeakerDetails({ rootState }, speakerId) {
    try {
      // Verwende den öffentlichen API-Endpunkt, wenn kein JWT vorhanden ist
      const endpoint = rootState.jwt === "" ? '/public/getSpeakerDetails' : '/getSpeakerDetails';
      return await crud(endpoint, { speakerid: speakerId });
    } catch (e) {
      console.error("Problem fetching speaker details", e);
      return null;
    }
  },

  async fetchAgendaDetails({ rootState }, agendaId) {
    try {
      // Verwende den öffentlichen API-Endpunkt, wenn kein JWT vorhanden ist
      const endpoint = rootState.jwt === "" ? '/public/getAgendaDetails' : '/getAgendaDetails';
      return await crud(endpoint, { agendaid: agendaId });
    } catch (e) {
      console.error("Problem fetching agenda details", e);
      return null;
    }
  },
}

export default actions
