import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

import { ref, watch, onMounted, onBeforeUnmount, nextTick} from 'vue';
import VideoChatEntry from "@/components/i/VideoChatEntry.vue";
import type { Message } from "@/store/i/types";

interface Props {
  msgs: Message[];
  chattype: "chat" | "questions";
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoChatList',
  props: {
    msgs: {},
    chattype: {}
  },
  emits: ["reachedtop"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const chatListRef = ref<HTMLElement | null>(null);
const correctScroll = ref(true);

const lookIfToHaltAutoscroll = () => {
  if (!chatListRef.value) return;

  const pos = chatListRef.value.scrollTop;
  if (pos < 1) {
    emit('reachedtop');
  }
  const desiredPos = chatListRef.value.scrollHeight - chatListRef.value.clientHeight;
  correctScroll.value = pos >= desiredPos - 20;
};

const scrollBottom = async () => {
  if (!chatListRef.value || !correctScroll.value) return;

  chatListRef.value.removeEventListener('scroll', lookIfToHaltAutoscroll);
  await nextTick();
  chatListRef.value.scrollTop = chatListRef.value.scrollHeight - chatListRef.value.clientHeight;
  await nextTick();
  chatListRef.value.addEventListener('scroll', lookIfToHaltAutoscroll, { passive: true });
};

// Watch msgs prop
watch(() => props.msgs, async () => {
  await scrollBottom();
});

onMounted(() => {
  scrollBottom();
  window.addEventListener('resize', scrollBottom, { passive: true });
  chatListRef.value?.addEventListener('scroll', lookIfToHaltAutoscroll, { passive: true });
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', scrollBottom);
  chatListRef.value?.removeEventListener('scroll', lookIfToHaltAutoscroll);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'chat-list':true}),
    ref_key: "chatListRef",
    ref: chatListRef
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.msgs, (msg, index) => {
      return (_openBlock(), _createBlock(VideoChatEntry, {
        chattype: _ctx.chattype,
        cE: msg,
        key: 'chat_entry' + index + '_' + msg.msgid
      }, null, 8, ["chattype", "cE"]))
    }), 128))
  ], 512))
}
}

})