import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "private-question-ia",
  class: "mt-4 mb-4 mb-lg-0"
}
const _hoisted_2 = { class: "ms-2 pb-2" }
const _hoisted_3 = { class: "row mb-2 mt-4" }
const _hoisted_4 = { class: "col" }

import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'
import VideoChatArea from "@/components/i/VideoChatArea.vue"
import FontAwesomeIcon from "@/components/FontAwesomeIcon.vue"
import { MultiUserRoom } from "@/store/i/types"

// Store

export default /*@__PURE__*/_defineComponent({
  __name: 'StreamQAWrapper',
  setup(__props) {

const store = useStore()

// Reactive state
const show = ref(true)
const originalParent = ref<HTMLElement | null>(null)

// Methods
const toogle = () => {
  show.value = !show.value
}

// Computed
const isSelected = computed(() => {
  if (store.state.i.rooms.length > 0) {
    const uroom: MultiUserRoom | undefined = _.find(
        store.state.i.rooms,
        (r: MultiUserRoom) => r.roomname === store.state.i.mucname
    )
    return !!(
        uroom !== null &&
        typeof uroom === "object" &&
        uroom.interaction &&
        uroom.interaction.type === "questions"
    )
  }
  return false
})

// Fullscreen handling
const fullscreenhandle = async (nv: boolean) => {
  const playertoattach = document.querySelector(".video-js")
  if (!playertoattach) return

  const el = document.getElementById('private-question-ia')
  if (!el) return

  if (nv) {
    originalParent.value = el.parentElement
    originalParent.value?.removeChild(el)
    playertoattach.append(el)
  } else {
    playertoattach.removeChild(el)
    originalParent.value?.append(el)
  }
}

// Watchers
watch(
    () => store.state.i.videoFullscreen,
    (newValue) => fullscreenhandle(newValue)
)

// Lifecycle hooks
onMounted(() => {
  if (store.state.i.videoFullscreen) {
    fullscreenhandle(true)
  }
})

return (_ctx: any,_cache: any) => {
  return (isSelected.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h5", {
          onClick: toogle,
          class: "mt-2"
        }, [
          _createElementVNode("span", _hoisted_2, [
            _withDirectives(_createVNode(FontAwesomeIcon, { icon: "arrow-down" }, null, 512), [
              [_vShow, !show.value]
            ]),
            _withDirectives(_createVNode(FontAwesomeIcon, { icon: "arrow-up" }, null, 512), [
              [_vShow, show.value]
            ])
          ]),
          _createTextVNode(" " + _toDisplayString(_unref(store).getters.i18n({
      de: 'Stellen Sie hier Ihre Frage',
      en: 'Ask your question here'
    })), 1)
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, _toDisplayString(_unref(store).getters.i18n({
          de: 'Ihre Fragen sind nur für Sie, die Regie und den Speaker sichtbar. Bitte haben Sie Verständnis, dass nicht alle Fragen beantwortet werden können.',
          en: 'Your questions are only visible to you, the editorial team and the speaker. Please be aware that not all questions can be answered.'
        })), 1)
          ]),
          _createVNode(VideoChatArea, { chattype: "questions" })
        ], 512), [
          [_vShow, show.value]
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})