import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = {
  key: 0,
  class: "headline cb-headlinenormalxl g-3"
}
const _hoisted_3 = {
  key: 1,
  class: "row g-3"
}
const _hoisted_4 = { class: "col-md-5 col-lg-3 mb-3 sm-center" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "col-md-7 col-lg-9 mb-3 text-left ipadfx" }
const _hoisted_7 = { class: "pb-3 pt-0" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["href"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 2,
  class: "row"
}
const _hoisted_13 = { class: "col-12" }
const _hoisted_14 = { class: "Role copydetailxs" }
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "sr-only" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgendaEntry = _resolveComponent("AgendaEntry")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.partnerEntry)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " Not found "))
      : _createCommentVNode("", true),
    (_ctx.partnerEntry)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: _ctx.partnerLogoUrl,
                width: "250",
                alt: _ctx.partnerEntry.title,
                onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleImageError && _ctx.handleImageError(...args)))
              }, null, 40, _hoisted_5)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.partnerEntry.title), 1),
            (_ctx.partnerEntry.email)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _cache[1] || (_cache[1] = _createTextVNode(" E-Mail: ")),
                  _createElementVNode("a", {
                    href: `mailto:${_ctx.partnerEntry.email}`
                  }, _toDisplayString(_ctx.partnerEntry.email), 9, _hoisted_9)
                ]))
              : _createCommentVNode("", true),
            (_ctx.partnerEntry.website)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _cache[2] || (_cache[2] = _createTextVNode(" Website: ")),
                  _createElementVNode("a", {
                    href: _ctx.partnerEntry.website
                  }, _toDisplayString(_ctx.partnerEntry.website), 9, _hoisted_11)
                ]))
              : _createCommentVNode("", true),
            (_ctx.hasSocialMedia)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", _hoisted_14, [
                      _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "headline_light text-center" }, "FIND US ON", -1)),
                      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialMediaLinks, (link, platform) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: platform }, [
                          link
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: link,
                                class: "buttonshare",
                                target: "_blank"
                              }, [
                                _createElementVNode("i", {
                                  class: _normalizeClass(_ctx.socialMediaIcons[platform])
                                }, null, 2),
                                _createElementVNode("span", _hoisted_16, _toDisplayString(platform), 1)
                              ], 8, _hoisted_15))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ]),
                    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[11] || (_cache[11] = _createElementVNode("hr", null, null, -1)),
          (_ctx.partnerEntry.description)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "bold mb-3" }, "About us", -1)),
                _createElementVNode("div", {
                  class: "cv",
                  innerHTML: _ctx.partnerEntry.description
                }, null, 8, _hoisted_18),
                _cache[8] || (_cache[8] = _createElementVNode("hr", null, null, -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.program.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "bold mt-4 mb-4" }, "Program", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.program, (ae, index) => {
                  return (_openBlock(), _createBlock(_component_AgendaEntry, {
                    key: `agp${index}`,
                    ae: ae,
                    agendatype: "entry"
                  }, null, 8, ["ae"]))
                }), 128)),
                _cache[10] || (_cache[10] = _createElementVNode("hr", null, null, -1))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}