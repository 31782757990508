import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "row g-3 mb-5 photos mt-2 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GalleryV2Loader = _resolveComponent("GalleryV2Loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "pb-1" }, "DW Freedom of Speech Award", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "cv" }, [
        _createElementVNode("p", null, [
          _createTextVNode(" Since 2015, DW has awarded the Freedom of Speech Award to spotlight restricted press freedom of the world and honor the exceptional work of journalists and human rights defenders."),
          _createElementVNode("br"),
          _createTextVNode(" Past honorees include Óscar Martínez, editor-in-chief of online outlet El Faro in El Salvador (2023), and Ukrainian war reporters Evgeniy Maloletka and Mstyslav Chernov (2022), who won the \"Best Documentary\" award at the 2025 Oscars for \"20 Days in Mariupol\". ")
        ])
      ], -1)),
      _createVNode(_component_GalleryV2Loader, {
        url: '/webapp/imageslider/',
        class: "light-gray"
      })
    ])
  ]))
}