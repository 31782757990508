import { createStore } from 'vuex';
import mutations from "@/store/mutations";
import actions from "@/store/actions";
import getters from "@/store/getters";
import i from "@/store/i";
import members from "@/store/member";
import landingpage from "@/store/landingpage";


const store = createStore({
  state: {
    isonline: (navigator.onLine),
    config: {
      firebase: {
        apiKey: '',
        authDomain: '',
        projectId: '',
        storageBucket: '',
        databaseURL: '',
        messagingSenderId: '',
        appId: ''
      },
      project:'',
      group:'',
      fbuid:'',
      lang: 'en',
      /*
      lang:(
          navigator.language.indexOf('de') > -1 &&
          (navigator.language.indexOf('en') === -1 || navigator.language.indexOf('de') < navigator.language.indexOf('en'))
              ? 'de':'en'
      ),*/
      me: {
        fbuid: '',
        objvalueid: -1,
        groupid: 1,
        gsuffix: "members",
        gender: 0,
        title: 0,
        tn_type: 0,
        is_test: false,
        firstname: "",
        lastname: "",
        position: "",
        orgname: "",
        email: "",
        no_force_plenum: false,
      },
      withnetworking: true,
      withsupportchat: true,
      supportmail: '',
      supportphone: '',
      rooms: []
    },
    tick: new Date(),
    jwt: typeof sessionStorage.getItem("jwt") === "string" ? sessionStorage.getItem("jwt") as string : "",
    firebaseconnecttoken: "",
    sidenav: "",
    zweifapassed: false,
    loginpassed: false,
    dppassed: false,
    visible: true,
    cdndomain: "https://d2vm4gllgd6k5k.cloudfront.net"
  },
  mutations,
  actions,
  getters,
  modules: {
    i,
    members,
    landingpage
  }
})

export const getToken = async () => {
  return store.state.jwt
}

const ticknow = async () => {
  store.commit("tick");
  if (store.state.jwt !== "") {
    await store.dispatch("i/testQueuedNotifications");
  }
}

let ticktimer: number|null = window.setInterval(ticknow, 250)

window.addEventListener('online', async () => {
  store.commit('changeOnlineState', navigator.onLine);
  if (store.state.jwt !== "" && navigator.onLine) {
    const tcres = await store.dispatch("testToken");
    if (!tcres) {
      await store.dispatch("logout");
    }
  }
});

window.addEventListener('offline', () => {
  store.commit('changeOnlineState', navigator.onLine)
})
document.addEventListener('visibilitychange', () => {
  store.commit('changeVisibilityState')
  if (document.visibilityState === 'visible') {
    if (ticktimer === null) {
      ticktimer = window.setInterval(ticknow, 250)
    }
  } else if (typeof ticktimer === "number") {
    window.clearInterval(ticktimer)
    ticktimer = null
  }
})

export const populateInital = store.dispatch("populateInital")

export default store
