<template>
  <div class="call-wrapper" v-show="connected">
    <div
        id="interlocutor"
        v-show="converence_joined"
        :class="{'card': true, 'card-big' : expanded}"
        ref="interlocutorElement"
        :style="`--amplitude:${interlocutor ? interlocutor.audioLevel : 0};`"
    >
      <div :class="{'ratio': true, 'ratio-16x9': true, 'novideo': !interlocutor || !interlocutor.hasVideo}">
        <JitsiFontAwesomeIcon icon="user" v-if="!interlocutor || !interlocutor.hasVideo" />
      </div>
      <JitsiFontAwesomeIcon icon="microphonemuted" v-if="interlocutor && interlocutor.isMuted" />
      <div class="card-body">
        <h5 v-if="!interlocutor && inContact">
          {{ i18n({
          de: `Warte auf ${inContact ? `${inContact.firstname} ${inContact.lastname}` : 'Teilnehmer'}`,
          en: `Waiting for ${inContact ? `${inContact.firstname} ${inContact.lastname}` : 'connection'}`
        }) }}
        </h5>
        <h5 v-if="interlocutor && inContact">{{inContact.firstname}} {{inContact.lastname}}</h5>
      </div>
    </div>

    <div id="me" class="card" ref="myElement" :style="`--amplitude:${me ? me.audioLevel : 0};`">
      <div :class="{'ratio': true, 'ratio-16x9': true, 'novideo': !me || !me.hasVideo}">
        <JitsiFontAwesomeIcon icon="user" v-if="!me || !me.hasVideo" />
      </div>
      <JitsiFontAwesomeIcon icon="microphonemuted" v-if="me && me.isMuted" />
      <div class="card-body">
        <h5>{{ i18n({de: "Ich", en: "Me"}) }}</h5>
      </div>
    </div>

    <div v-if="connected" id="calltoolbox">
      <div v-show="myQuality.connectionQuality < 50">
        <p>
          {{ i18n({de: "Verbindingsqualität", en: "Connection quality"}) }}:
          {{ myQuality.connectionQuality === 100
            ? i18n({de: "sehr gut", en: "very good"})
            : (myQuality.connectionQuality > 80
                ? i18n({de: "gut", en: "good"})
                : (myQuality.connectionQuality > 30
                    ? i18n({de: "ausreichend", en: "ok"})
                    : i18n({de: "schlecht", en: "poor"})))
          }}
        </p>
      </div>
      <div>
        <button
            type="button"
            @click="toggleCamera"
            class="btn btn-dark"
            :title="i18n({
            de: `Kamera ${me && me.hasVideo ? 'deaktivieren':'aktivieren'}`,
            en: `Camera ${me && me.hasVideo ? 'disable':'enable'}`
          })"
        >
          <JitsiFontAwesomeIcon :icon="`video${me && !me.hasVideo ? 'off':''}`" />
        </button>
        <button type="button" @click="endConnection" class="btn btn-primary" :title="i18n({de: 'Gespräch beenden', en: 'End Call'})">
          <JitsiFontAwesomeIcon icon="phone" />
        </button>

        <button type="button" @click="toggleMute" class="btn btn-dark" :title="i18n({de: `Audio ${me && me.isMuted ? 'entmuten':'muten'}`, en: `Audio ${me && me.isMuted ? 'unmute':'mute'}`})">
          <JitsiFontAwesomeIcon :icon="`microphone${me && me.isMuted ? 'muted':''}`" />
        </button>

        <button type="button" @click="toggleExpanded" class="btn btn-dark" :title="i18n({de: `Window ${expanded ? 'Minimize':'Maximize'}`, en: `Window ${expanded ? 'Minimize':'Maximize'}` })">
          <JitsiFontAwesomeIcon :icon="`${expanded ? 'minimize':'maximize'}`" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import JitsiMeetJS from '@/vendor/jitsi/lib-jitsi-meet.min';
import { TranslatedText } from '@/store/types';
import {
  CallerPerson,
  MemberContactPerson,
  JitsiCallRequest,
  JitsiVideoSettings
} from '@/store/member/types';
import { useI18n } from '@/composables/i18n';
import JitsiFontAwesomeIcon from "@/components/jitsi/JitsiFontAwesomeIcon.vue";
import { objToFirebaseUID } from '@/firebaseConnections';
import rankingsMaxPoints from '@/ranking';

interface JitsiConnectionQualityPL {
  download: number;
  upload: number;
  total: number;
}

interface JitsiConnectionQualityTP {
  ip: string;
  localCandidateType: string;
  localip: string;
  networkType: string;
  p2p: boolean;
  remoteCandidateType: string;
  rtt: number;
  type: string;
}

interface JitsiConnectionQuality {
  connectionQuality: number;
  packetLoss: JitsiConnectionQualityPL;
  transport: JitsiConnectionQualityTP[];
}

interface JitsiConfParticipant {
  juid: string | null;
  name: string;
  userid?: number;
  avatar?: string;
  hasMic: boolean;
  hasVideo: boolean;
  audioLevel: number;
  isMuted: boolean;
  volume: number;
  tracks: any[];
  iceConnection?: 'active' | 'inactive' | 'interrupted' | 'restoring';
}

export default defineComponent({
  name: 'JitsiCall',
  components: {JitsiFontAwesomeIcon},

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      callstate: {} as JitsiCallRequest
    };
  },


  computed: {
    jitsijwt(): string {
      return this.callstate.jwt as string;
    },

    roomname(): string {
      return this.callstate.roomname as string;
    },

    callid(): number {
      return this.callstate.objvalueid as number;
    }
  },



  setup(props) {
    const store = useStore();
    const { i18n } = useI18n();

    const errors = ref<TranslatedText>({ de: '', en: '' });
    const me = ref<JitsiConfParticipant | null>(null);
    const interlocutor = ref<JitsiConfParticipant | null>(null);
    const connected = ref(false);
    const converence_joined = ref(false);
    const expanded = ref(true);
    const cansharedesktop = ref(false);
    const connection = ref<any>(null);
    const room = ref<any>(null);
    const callstate = ref<JitsiCallRequest>();


    const handleRanking = async (): Promise<void> => {
      if (store.state.i.me.tn_type != 26 &&
          store.state.i.me.tn_type != 33 &&
          store.state.i.me.ranking_g < rankingsMaxPoints.ranking_g) {

        if (store.state.members.currentCall && callstate.value?.connection_start) {
          const start = new Date(callstate.value.connection_start).getTime();
          const end = new Date().getTime();
          const diff = Math.round((end - start) / 1000);

          if (diff > 300) {
            const value = store.state.i.me.ranking_g + 3;
            await store.dispatch('i/setDataRankingG', value);
          }
        }
      }
    }
    
    const toggleCamera = async () => {
      if (!me.value) {
        return;
      }

      const videoTrack = _.find(me.value.tracks, t => t.getType() === "video");
      if (videoTrack) {
        if (videoTrack.isMuted()) {
          await videoTrack.unmute();
        } else {
          await videoTrack.mute();
        }

        me.value.hasVideo = !videoTrack.isMuted();
      }
    };

    const toggleExpanded = () => {
      expanded.value = !expanded.value;
    };

    const toggleMute = async () => {
      if (!me.value) {
        return;
      }

      const audioTrack = _.find(me.value.tracks, t => t.getType() === "audio");
      if (audioTrack) {
        if (audioTrack.isMuted()) {
          await audioTrack.unmute();
        } else {
          await audioTrack.mute();
        }

        me.value.isMuted = audioTrack.isMuted();
      }
    };


    const settings = {
      serviceUrl: 'https://telko.eventit.ag/http-bind',
      hosts: {
        domain: 'telko.eventit.ag',
        muc: 'conference.telko.eventit.ag',
        anonymousdomain: 'telko.eventit.ag'
      },
      useStunTurn: true
    };

    const myQuality = ref<JitsiConnectionQuality>({
      connectionQuality: 100,
      packetLoss: {
        download: 0,
        upload: 0,
        total: 0
      },
      transport: []
    });

    const videoOptions = {
      resolution: 720,
      constraints: {
        video: {
          aspectRatio: 16 / 9,
          height: {
            ideal: 720,
            max: 1080,
            min: 240
          },
        },
      },
      minFps: 15,
      maxFps: 30
    };

    const jinitOptions = {
      useIPv6: false,
      desktopSharingChromeExtId: 'fwefwwww',
      desktopSharingChromeDisabled: false,
      desktopSharingChromeSources: ['window', 'screen'],
      desktopSharingFirefoxDisabled: false,
      disableAudioLevels: false,
      disableSimulcast: false,
      enableWindowOnErrorHandler: true,
      disableThirdPartyRequests: true,
      enableAnalyticsLogging: false,
      disableRtx: true,
      disableH264: false,
      preferH264: true,
      desktopSharingFrameRate: {
        min: 5,
        max: 15
      },
      p2p: {
        enabled: true,
        useStunTurn: true,
        stunServers: [
          { urls: 'stun:stun.t-online.de:3478' },
          { urls: 'stun:stun.1und1.de:3478' },
        ]
      }
    };

    const devicesPicked = computed((): JitsiVideoSettings => {
      return store.getters['members/devicesPicked'];
    });

    const jitsijwt = computed((): string => {
      return callstate.value?.jwt as string;
    });

    const roomname = computed((): string => {
      return callstate.value?.roomname as string;
    });

    const callid = computed((): number => {
      return callstate.value?.objvalueid as number;
    });

    const inContact = computed((): MemberContactPerson | CallerPerson | undefined => {
      if (!store.state.members.currentCall) {
        return undefined;
      }
    });

    const trackAdded = (track: any) => {
      const juid = track.getParticipantId();
      let targetUser: JitsiConfParticipant;
      let targetHTMLParent: HTMLElement;
      let targetHTMLElement: HTMLAudioElement | HTMLVideoElement;

      if (juid === room.value.myUserId()) {
        targetUser = me.value!;
        targetHTMLParent = document.getElementById('localVideo')!;
      } else {
        targetUser = interlocutor.value!;
        targetHTMLParent = document.getElementById('remoteVideo')!;
      }

      if (track.getType() === 'video') {
        targetHTMLElement = document.createElement('video');
        targetHTMLElement.autoplay = true;
        targetUser.hasVideo = true;
      } else {
        targetHTMLElement = document.createElement('audio');
        targetHTMLElement.autoplay = true;
        targetUser.hasMic = true;
      }

      targetHTMLParent.appendChild(targetHTMLElement);
      track.attach(targetHTMLElement);
      targetUser.tracks.push(track);
    };

    const trackRemoved = (track: any) => {
      const juid = track.getParticipantId();
      let targetUser: JitsiConfParticipant = juid === room.value.myUserId()
          ? me.value!
          : interlocutor.value!;

      if (track.getType() === 'video') {
        targetUser.hasVideo = false;
      } else {
        targetUser.hasMic = false;
      }

      track.detach().forEach((element: HTMLElement) => element.remove());
      targetUser.tracks = targetUser.tracks.filter(t => t.getId() !== track.getId());
    };

    const createLocalTracks = async () => {
      try {
        const tracks = await JitsiMeetJS.createLocalTracks({
          devices: [
            devicesPicked.value.mic ? 'audio' : '',
            devicesPicked.value.cam ? 'video' : ''
          ].filter(Boolean),
          micDeviceId: devicesPicked.value.mic,
          cameraDeviceId: devicesPicked.value.cam,
          resolution: videoOptions.resolution,
          constraints: videoOptions.constraints,
          minFps: videoOptions.minFps,
          maxFps: videoOptions.maxFps
        });

        if (me.value?.tracks.length) {
          me.value.tracks.forEach(track => {
            track.dispose();
            room.value?.removeTrack(track);
          });
          me.value.tracks = [];
        }

        tracks.forEach(track => {
          room.value?.addTrack(track);
          trackAdded(track);
        });

      } catch (error) {
        console.error('Fehler beim Erstellen der lokalen Tracks:', error);
        errors.value = {
          de: 'Fehler beim Zugriff auf Kamera/Mikrofon',
          en: 'Error accessing camera/microphone'
        };
      }
    };

    const init = async () => {
      if (!JitsiMeetJS.mediaDevices.isDeviceListAvailable()) {
        store.commit('i/addToast', {
          header: { de: 'Fehler', en: 'Error' },
          msg: {
            de: 'Keine Audio Geräte gefunden.',
            en: 'No audio devices found.'
          },
          validforroom: 'event-general',
          got: new Date(),
          hidden: false,
          alert_type: 'error'
        });
        return;
      }

      JitsiMeetJS.init(jinitOptions);
      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

      connection.value = new JitsiMeetJS.JitsiConnection(null, jitsijwt.value, settings);

      connection.value.addEventListener(
          JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
          async () => {
            connected.value = true;
            room.value = connection.value.initJitsiConference(roomname.value, {
              openBridgeChannel: true
            });

            room.value.on(JitsiMeetJS.events.conference.TRACK_ADDED, trackAdded);
            room.value.on(JitsiMeetJS.events.conference.TRACK_REMOVED, trackRemoved);

            room.value.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, () => {
              converence_joined.value = true;
              me.value = {
                juid: room.value.myUserId(),
                name: store.state.members.user.firstname,
                userid: store.state.members.user.objvalueid,
                hasMic: false,
                hasVideo: false,
                audioLevel: 0,
                isMuted: false,
                volume: 100,
                tracks: []
              };
              createLocalTracks();
            });

            room.value.on(JitsiMeetJS.events.conference.USER_JOINED, (id: string, user: any) => {
              interlocutor.value = {
                juid: id,
                name: user.getDisplayName(),
                hasMic: false,
                hasVideo: false,
                audioLevel: 0,
                isMuted: false,
                volume: 100,
                tracks: []
              };
            });

            room.value.on(JitsiMeetJS.events.conference.USER_LEFT, () => {
              interlocutor.value = null;
            });

            room.value.join();
          }
      );

      connection.value.addEventListener(
          JitsiMeetJS.events.connection.CONNECTION_FAILED,
          () => {
            console.error('Verbindung fehlgeschlagen');
            connected.value = false;
          }
      );

      connection.value.connect();
    };

    const endConnection = async () => {
      if (room.value) {
        room.value.leave();
      }
      if (connection.value) {
        connection.value.disconnect();
      }
      if (me.value?.tracks) {
        me.value.tracks.forEach(track => track.dispose());
      }
      if (interlocutor.value?.tracks) {
        interlocutor.value.tracks.forEach(track => track.dispose());
      }

      switch (props.type) {
        case "member":
          if (store.state.members.currentCall && !callstate.value?.connection_start) {
            await store.dispatch('i/sendmydm', {
              body: "I have been trying to reach you via video call.",
              ts: Date.now() / 1000,
              receiver: objToFirebaseUID(store.state.members.currentCall.interlocutorid)
            });
          }
          await store.dispatch("members/endCall", callid.value);
          break;
        case "employee":
          await store.dispatch("members/endCallReceiver", callid.value);
          break;
        default: break;
      }
      
      me.value = null;
      interlocutor.value = null;
      room.value = null;
      connection.value = null;
      connected.value = false;
      converence_joined.value = false;
    };

    // Watchers
    watch(() => devicesPicked.value.output, (newVal) => {
      if (newVal !== '') {
        try {
          JitsiMeetJS.mediaDevices.setAudioOutputDevice(newVal);
        } catch (e) {
          console.error(e);
        }
      }
    });

    watch([() => devicesPicked.value.mic, () => devicesPicked.value.cam],
        async () => {
          await createLocalTracks();
        }
    );

    // Lifecycle hooks
    onMounted(() => {
      init();
    });

    onBeforeUnmount(async () => {
      await endConnection();
    });

    return {
      errors,
      me,
      toggleCamera,
      interlocutor,
      connected,
      converence_joined,
      expanded,
      cansharedesktop,
      connection,
      room,
      callstate,
      myQuality,
      devicesPicked,
      jitsijwt,
      roomname,
      callid,
      i18n,
      inContact,
      trackAdded,
      trackRemoved,
      createLocalTracks,
      init,
      toggleExpanded,
      toggleMute,
      handleRanking,
      endConnection
    };
  }
});
</script>
