import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "clearfix" }
const _hoisted_2 = { class: "chat-message" }
const _hoisted_3 = {
  key: 0,
  class: "card bg-other rounded w-75 z-depth-0 mb-0"
}
const _hoisted_4 = { class: "card-body p-2" }
const _hoisted_5 = { class: "card-text text-black" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 2,
  class: "d-flex justify-content-end"
}
const _hoisted_8 = { class: "card bg-primary rounded w-75 z-depth-0 mb-0" }
const _hoisted_9 = { class: "card-body p-2" }
const _hoisted_10 = { class: "card-text" }
const _hoisted_11 = {
  key: 3,
  class: "d-flex justify-content-end"
}

import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { DateTime } from 'luxon'
import _ from 'lodash'
import type { ChatContact, DMMessage, SupportMessage } from "@/store/i/types"
import type { EveUser } from "@/store/types"


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatContactMessageSmall',
  props: {
    m: {}
  },
  setup(__props: any) {

const props = __props

const store = useStore()

const user = computed((): ChatContact | EveUser | null => {
  if (props.m && props.m.sender && props.m.sender !== "me" && props.m.sender !== "support") {
    const fuser = _.find(store.state.i.contactschats, (c) => c.fbuid === (props.m as DMMessage).sender)
    return fuser || null
  }
  return null
})

const ismine = computed((): boolean => {
  if (props.m) {
    switch (props.m.sender) {
      case "support":
        return false
      case "me":
      case store.state.config.fbuid:
        return true
      default:
        return !!(user.value && user.value.fbuid === store.state.config.fbuid)
    }
  }
  return false
})

const issupport = computed((): boolean => {
  if (props.m) {
    return props.m.sender === "support"
  }
  return false
})

const getDate = (date: number): string => {
  const setM = DateTime.fromSeconds(date)
  const ndate = DateTime.fromJSDate(store.state.tick)

  return setM
      .setLocale(store.getters.i18n({ de: 'de', en: 'en' }))
      .toLocaleString(DateTime.DATETIME_SHORT) + store.getters.i18n({ de: ' Uhr', en: ' ' })
}

onMounted(() => {
  if (props.m && !props.m.seen && !ismine.value) {
    const updatePL = {
      flag: 'SEEN',
      msgid: props.m.uuid
    }
    window.setTimeout(() => {
      store.dispatch(issupport.value ? 'i/setSupportFlag' : 'i/setDMFlag', updatePL)
    }, 2000)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!ismine.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(props.m.body), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (!ismine.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(getDate(props.m.ts)), 1))
        : _createCommentVNode("", true),
      (ismine.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(props.m.body), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (ismine.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(getDate(props.m.ts)), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})