<template>
  <div class="box">
    <div class="row g-3 mb-5 partners">
      <h3>Partners</h3>
      <div class="row">
        <p>
          <input
              placeholder="Search..."
              type="search"
              v-model.trim="searchtext"
              class="form-control"
          >
        </p>

        <template v-for="section in partnerSections" :key="section.id">
          <h4 v-if="section.partners.length > 0" :class="{'mt-4': section.id !== 1}">
            {{ section.title }}
          </h4>
          <PartnerEntry
              v-for="(partner, index) in section.partners"
              :key="`partner${section.id}-${index}`"
              :partner="partner"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import PartnerEntry from "@/components/PartnerEntry.vue"
import _ from "lodash"
import { Partner } from "@/store/i/types"
import { useGtm } from "@/composables/useGtm";

export default defineComponent({
  name: 'PartnerOverview',

  components: {
    PartnerEntry
  },

  setup() {
    const { trackPage } = useGtm()

    const store = useStore()
    const searchtext = ref('')

    onBeforeMount(() => {
      trackPage({
        pageCategory: 'Partner',
        PageName: 'Overview',
        PageType: 'Webapp',
      })
    })

    const filteredPartners = computed(() => {
      let partners = _.filter(
          store.state.i.partners,
          (e: Partner) => e.dbstate > -1
      )

      if (partners.length > 0 && searchtext.value.length > 0) {
        const searchWords = _.map(
            _.words(searchtext.value),
            s => new RegExp(_.escapeRegExp(s), 'iu')
        )

        return _.filter(partners, partner =>
            searchWords.every(word => word.test(partner.title))
        )
      }

      return partners
    })

    const getPartnersByCategory = (category: number) => {
      const filtered = _.filter(
          filteredPartners.value,
          (p: Partner) => p.dbstate > -1 && p.category === category
      )

      return _.orderBy(
          filtered,
          [p => category === 5 ? p.title.toLowerCase() : p.objvalueid],
          ['asc']
      )
    }

    const partnerSections = computed(() => [
      { id: 1, title: 'MAIN PARTNERS', partners: getPartnersByCategory(1) },
      { id: 2, title: 'CO-HOSTED BY', partners: getPartnersByCategory(2) },
      { id: 3, title: 'PUBLIC PARTNERS', partners: getPartnersByCategory(3) },
      { id: 5, title: 'MEDIA PARTNER', partners: getPartnersByCategory(5) },
      { id: 4, title: 'PARTNERS', partners: getPartnersByCategory(4) }
    ])

    return {
      searchtext,
      partnerSections
    }
  }
})
</script>