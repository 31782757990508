<template>
  <div class="row" :class="{'col-lg-4 col-md-6 col-12': route.name === 'Welcome', 'col-lg-6': route.name != 'Welcome'}">
    <div class="col-5 mb-5" v-if="route.name != 'Welcome'">
      <a @click="navigateMember" class="pointer">
        <div class="circular_image">
          <img :src="`/files/members/${props.member.objvalueid}/foto.jpg`"
               onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';"
               alt="Contact Image">
        </div>
      </a>
    </div>
    <div class="mb-5 ipadfx2" :class="{'col': route.name === 'Welcome', 'col-7': route.name != 'Welcome'}">
      <a @click="navigateMember" class="pointer">
        <div class="Name-Nachname mb-1">
          <span v-if="props.type === 'ranking'">{{ position_ranking(props.numberkey) }}.</span>
          {{ props.member.firstname }} {{ props.member.lastname }}
        </div>
        <div class="Role copydetailxs mb-1 bold">{{ title(props.member.position) }}</div>
        <div class="Role copydetailxs">{{ title(props.member.orgname) }}</div>
        <div class="Role copydetailxs badge badge-pill" v-if="props.type != 'ranking'">{{ props.member.match }} %</div>
        <div class="Role copydetailxs badge badge-pill" v-if="props.type === 'ranking'">{{ props.member.ranking }}</div>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router'
import type { ContactsNetworking } from "@/store/i/types"
import _ from "lodash"

interface Props {
  member: ContactsNetworking
  type?: string
  numberkey?: number
}

const props = defineProps<Props>()
const router = useRouter()
const route = useRoute()

const navigateMember = () => {
  router.push({
    name: 'ContactDetail',
    params: { contactid: `${props.member.objvalueid}` }
  })
}

const position_ranking = (keyvalue?: number): number => {
  return keyvalue ? keyvalue + 1 : 1
}

const title = (title: string): string => {
  let processedTitle = _.replace(title, /&#34;/g, '"')
  processedTitle = _.replace(processedTitle, /&#39;/g, "'")
  return processedTitle
}
</script>