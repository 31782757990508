<template>
  <div class="row">
    <div class="col">
      <form method="post" action="#" v-if="store.state.i.connected" @submit.prevent="sendmyanswers">
        {{ answerhelper }}
        <ul class="voting-list">
          <li v-for="(a, index) in voting.answers"
              :key="'voting_' + a.uid + '_answer_' + index"
              :class="{'active': myselection.some(s => s === a.uid)}">
            <label>
              <input type="checkbox" :value="a.uid" name="myselection" v-model="myselection">
              {{ a.caption }}
              <FontAwesomeIcon v-show="!myselection.some(s => s === a.uid)" icon="circlecheckhollow" />
              <FontAwesomeIcon v-show="myselection.some(s => s === a.uid)" icon="circlecheck" />
            </label>
          </li>
        </ul>
        <div class="d-flex justify-content-center">
          <button type="submit" :disabled="restanswers < 0 || myselection.length < 1" class="btn btn-primary">
            {{ store.getters.i18n({de:'Abstimmen',en:'Vote'}) }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import type { VotingInteraction } from "@/store/i/types"
import _ from "lodash"

const props = defineProps<{
  voting: VotingInteraction
}>()

const store = useStore()
const myselection = ref<string[]>([])

const restanswers = computed((): number => {
  return props.voting.maxselections - myselection.value.length
})

const answerhelper = computed((): string => {
  if (restanswers.value < 0 && props.voting.maxselections === 1) {
    return store.getters.i18n({
      de: `Sie haben zu viele Antworten gewählt. Sie dürfen nur eine wählen.`,
      en: `You chose too many answers. Only one is allowed.`
    })
  }
  if (restanswers.value < 0) {
    return store.getters.i18n({
      de: `Sie haben zu viele Antworten gewählt. Sie dürfen nur ${props.voting.maxselections} wählen.`,
      en: `You chose too many answers. Only ${props.voting.maxselections} are allowed.`
    })
  }
  if (restanswers.value === 1 && myselection.value.length > 0) {
    return store.getters.i18n({
      de: `Sie können noch eine weitere Antwort auswählen.`,
      en: `You can choose one more answer.`
    })
  }
  if (restanswers.value === 0) {
    return store.getters.i18n({
      de: `Sie können keine weiteren Antworten auswählen.`,
      en: `You cannot choose any more answers.`
    })
  }
  if (restanswers.value === 1) {
    return store.getters.i18n({
      de: `Sie können eine Antwort auswählen.`,
      en: `You can choose one answer.`
    })
  }
  if (myselection.value.length > 0) {
    return store.getters.i18n({
      de: `Sie können ${props.voting.maxselections} Antworten auswählen. ${restanswers.value} Antworten noch übrig.`,
      en: `You can choose ${props.voting.maxselections} answers, ${restanswers.value} remain.`
    })
  }
  return store.getters.i18n({
    de: `Sie können ${props.voting.maxselections} Antworten wählen.`,
    en: `You can choose ${props.voting.maxselections} answers.`
  })
})

const sendmyanswers = async () => {
  if (restanswers.value >= 0 && myselection.value.length > 0) {
    await Promise.all(
        _.map(
            myselection.value,
            s => store.dispatch("i/castBallot", {
              roomname: props.voting.roomname,
              voteuid: props.voting.uid,
              answer: s
            })
        )
    )
  }
}
</script>