import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vertical-nav" }
const _hoisted_2 = { class: "row m-0" }
const _hoisted_3 = { class: "col vertical-nav-content" }

import { onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'


export default /*@__PURE__*/_defineComponent({
  __name: 'VerticalNav',
  setup(__props) {

const store = useStore()

onMounted(() => {
  document.body.classList.add("noscroll")
})

onBeforeUnmount(() => {
  document.body.classList.remove("noscroll")
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: "btn-close btn-close",
      "aria-label": "Close",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(store).commit('setSideNav', '')), ["prevent"]))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}
}

})