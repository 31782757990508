<template>
  <video
      :id="playerid"
      :class="`video-js vjs-default-skin vjs-${pprefix}-skin vjs-big-play-centered`"
      controls
      playsinline
      :loop="sloop"
      :autoplay="sautostart"
      :muted="sautostart"
      preload="auto"
      :poster="splaceholder"
      data-setup="{}"
  >
    <source :src="ssrc" :type="videofiletype">
    <p class="vjs-no-js">
      {{ store.getters.i18n({de:'Ihr Browser unterstützt kein HTML-Video.',en:'Your browser does not support HTML-Video.'}) }}
    </p>
  </video>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, computed, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import videojs from 'video.js'

export interface VideoPlayerPosition {
  position: number
  duration: number
  positionPercentage: number
}

const props = defineProps<{
  playerid: string
  ssrc: string
  splaceholder: string
  sautostart: boolean
  sautopip: boolean
  sloop: boolean
  watchtime?: number
  videofiletype?: string
}>()

const emit = defineEmits<{
  (e: 'player-ready', player: any): void
  (e: 'playerPositionChanged', position: VideoPlayerPosition): void
  (e: 'fullscreenchange', isFullscreen: boolean): void
  (e: 'canPlay', player: any): void
  (e: 'playerError', error: any): void
  (e: 'playbackPlayed', vm: any): void
  (e: 'playbackStarted', vm: any): void
  (e: 'playbackSeeking', vm: any): void
  (e: 'playbackPaused', vm: any): void
  (e: 'playbackEnded', vm: any): void
}>()

const store = useStore()
const notplayed = ref(true)
const isplaying = ref(false)
const isfullscreen = ref(false)
const duration = ref(0)
const position = ref(0)
const positionPercentage = ref(0)
const playerposelement = ref<HTMLElement | null>(null)
const playerinst = ref<any>(undefined)
const autopipd = ref(false)

const pprefix = computed(() => {
  if (!store.state.config.project) {
    return ''
  }

  switch (typeof store.state.config.project) {
    case "object":
      return store.state.config.project.pprefix
    case "string":
      const projectparts: string[] = store.state.config.project.split('.')
      if (!projectparts || projectparts.length < 2) {
        return ''
      }
      return projectparts[1]
    default:
      return ''
  }
})

watch(() => props.ssrc, async () => {
  await nextTick()
  if (!playerinst.value) return

  playerinst.value.src({
    src: props.ssrc,
    type: props.videofiletype
  })

  await nextTick()
  playerinst.value.play()
})

watch(() => props.splaceholder, async () => {
  await nextTick()
  if (!playerinst.value) return
  playerinst.value.poster(props.splaceholder)
})

watch(() => props.sloop, async () => {
  await nextTick()
  if (!playerinst.value) return
  playerinst.value.loop(props.sloop)
})

watch(() => store.state.visible, async () => {
  if (playerinst.value) {
    if (store.state.visible && autopipd.value) {
      if (playerinst.value.isInPictureInPicture()) {
        try {
          await playerinst.value.exitPictureInPicture()
        } catch (e) {
          // Handle error
        }
      }
      autopipd.value = false
      return
    }

    if (
        !store.state.visible &&
        props.sautopip &&
        isplaying.value &&
        !isfullscreen.value &&
        !playerinst.value.isInPictureInPicture()
    ) {
      try {
        await playerinst.value.requestPictureInPicture()
        autopipd.value = playerinst.value.isInPictureInPicture()
      } catch (e) {
        autopipd.value = false
      }
    }
  }
})

const positionRecalc = () => {
  if (playerinst.value && duration.value > 0) {
    position.value = playerinst.value.currentTime()
    positionPercentage.value = Math.round(position.value / duration.value * 100) / 100
    emit("playerPositionChanged", {
      duration: duration.value,
      position: position.value,
      positionPercentage: positionPercentage.value
    })
  }
}

const handlePlayerPosElement = () => {
  if (playerinst.value) {
    const playerdom = playerinst.value.contentEl() as HTMLElement
    if (!playerposelement.value && notplayed.value && props.watchtime && props.watchtime > 0 && duration.value > 0) {
      playerposelement.value = document.createElement("DIV")
      const percentage = props.watchtime / duration.value * 100
      playerposelement.value.setAttribute("class", `maxplaypos${percentage >= 95 ? ' completed':''}`)
      playerposelement.value.setAttribute("style", `width:${percentage}%;`)
      playerdom.append(playerposelement.value)
    } else if (playerposelement.value !== null) {
      playerdom.removeChild(playerposelement.value)
      playerposelement.value = null
    }
  }
}

const playerReady = async () => {
  if (!playerinst.value) return

  emit('player-ready', playerinst.value)

  playerinst.value.on("fullscreenchange", () => {
    if (playerinst.value) {
      isfullscreen.value = playerinst.value.isFullscreen()
      emit('fullscreenchange', isfullscreen.value)
    }
  })

  playerinst.value.on("durationchange", () => {
    if (playerinst.value) {
      duration.value = playerinst.value.duration()
      positionRecalc()
      handlePlayerPosElement()
    }
  })

  playerinst.value.on("timeupdate", () => {
    if (playerinst.value) {
      positionRecalc()
    }
  })

  playerinst.value.on("loadedmetadata", () => {
    if (playerinst.value) {
      duration.value = playerinst.value.duration()
      if (props.watchtime && props.watchtime > 0 && position.value === 0) {
        const percentage = props.watchtime / duration.value * 100
        if (percentage < 95) {
          playerinst.value.currentTime(props.watchtime)
        }
      }
    }
  })

  playerinst.value.on("canplay", () => {
    if (playerinst.value) {
      emit('canPlay', playerinst.value)
    }
  })

  playerinst.value.on("error", (e: any) => {
    if (playerinst.value) {
      emit('playerError', e)
    }
  })

  playerinst.value.on("play", () => {
    if (playerinst.value) {
      isplaying.value = true
      emit('playbackPlayed', playerinst.value)
    }
  })

  playerinst.value.one("play", () => {
    if (playerinst.value) {
      notplayed.value = false
      handlePlayerPosElement()
      emit('playbackStarted', playerinst.value)
    }
  })

  playerinst.value.on("seeking", () => {
    if (playerinst.value) {
      emit('playbackSeeking', playerinst.value)
      positionRecalc()
    }
  })

  playerinst.value.on("pause", () => {
    if (playerinst.value) {
      isplaying.value = false
      emit('playbackPaused', playerinst.value)
    }
  })

  playerinst.value.on("abort", () => {
    if (playerinst.value) {
      isplaying.value = false
    }
  })

  playerinst.value.on("ended", () => {
    if (playerinst.value) {
      isplaying.value = false
      emit('playbackEnded', playerinst.value)
    }
  })

  playerinst.value.fluid(true)
}

onMounted(() => {
  const element = document.getElementById(props.playerid)
  if (!element) {
    throw new Error(`Element with id ${props.playerid} not found`)
  }
  playerinst.value = videojs(element, {
    fluid: true,
    autoplay: props.sautostart,
    muted: props.sautostart,
    loop: props.sloop
  }, playerReady)
})

onBeforeUnmount(() => {
  emit('player-ready', undefined)
  if (playerinst.value) {
    playerinst.value.dispose()
  }
})
</script>

<style lang="scss">
@import "@/styles/videojs-theme";
</style>