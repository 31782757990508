<template>
  <div class="box">
    <div class="mainform g-3 pb-4">
      <h3>{{ $store.getters.i18n({de: 'Seite nicht gefunden', en: 'Page not found'}) }}</h3>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onBeforeMount, onMounted} from 'vue'
import {useGtm} from "@/composables/useGtm";

export default defineComponent({
  name: 'Error404',

  setup() {
    const { trackPage } = useGtm()

    onBeforeMount(() => {
      trackPage({
        pageCategory: 'Error',
        PageName: 'Error404',
        PageType: 'Webapp',
      })
    })
  }
})
</script>