<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import ToastAlert from "@/components/i/ToastAlert.vue"
import { InteractionAlert } from "@/store/i/types"


// Store
const store = useStore()

// Reactive state
const originalParent = ref<HTMLElement | null>(null)
const ias = ref<InteractionAlert[]>([])

// Computed
const unhiddenToasts = computed(() => store.getters['i/unhiddenToasts'])

// Methods
const dismissed = (ia: InteractionAlert) => {
  store.commit('i/removeToast', ia)
}

// Watchers
watch(unhiddenToasts, (newValue) => {
  if (newValue.length === 0) {
    ias.value = []
  } else {
    newValue.forEach((uht: InteractionAlert) => {
      if (!ias.value.some((ia: InteractionAlert) => ia === uht)) {
        ias.value.push(uht)
      }
    })
  }
})

watch(() => store.state.i.videoFullscreen, async (newValue) => {
  const playertoattach = document.querySelector(".video-js")
  if (!playertoattach) return

  const el = document.getElementById('toastswrapper')
  if (!el) return

  if (newValue) {
    originalParent.value = el.parentElement
    originalParent.value?.removeChild(el)
    playertoattach.append(el)
  } else {
    playertoattach.removeChild(el)
    originalParent.value?.append(el)
  }
})
</script>

<template>
  <div id="toastswrapper"
       v-show="ias.length > 0"
       :class="{ 'opened': ias.length > 0 }">
    <ToastAlert v-for="(t, index) in ias"
                :t="t"
                :inline="false"
                :key="'tw' + index"
                @dismissed="dismissed">
      {{ store.getters.i18n(t.msg) }}
    </ToastAlert>
  </div>
</template>
