<template>
  <VerticalNav>
    <div class="row scroll-header">
      <div class="col mt-4">
        <p style="font-size: 28px">
          {{ store.getters.i18n({de: 'Chat', en: 'Chat'}) }}
        </p>
      </div>
    </div>
    <BigChat/>
  </VerticalNav>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import BigChat from "@/components/i/BigChat.vue"
import VerticalNav from "@/components/VerticalNav.vue"

const store = useStore()
</script>