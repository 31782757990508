import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row mb-4"
}
const _hoisted_2 = { class: "col text-center" }
const _hoisted_3 = { class: "ms-2 pb-2" }
const _hoisted_4 = {
  key: 1,
  class: "big-question"
}
const _hoisted_5 = {
  key: 0,
  class: "row"
}
const _hoisted_6 = {
  key: 0,
  class: "col"
}
const _hoisted_7 = {
  key: 1,
  class: "col"
}
const _hoisted_8 = {
  key: 2,
  class: "row"
}
const _hoisted_9 = { class: "col" }

import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import VotingForm from "@/components/i/VotingForm.vue";
import FontAwesomeIcon from "@/components/FontAwesomeIcon.vue";
import _ from "lodash";
import { DateTime } from "luxon";
import type { MultiUserRoom, RoomInteraction, VotingBallot, VotingInteraction } from "@/store/i/types";


export default /*@__PURE__*/_defineComponent({
  __name: 'VotingArea',
  setup(__props) {

const store = useStore();
const show = ref(true);
const onlyvotingresult = ref(false);
const hidetitle = ref(false);
const votemode = ref<"vote"|"results">("vote");
const originalParent = ref<HTMLElement | null>(null);
const labelscut = ref(false);

const toogle = () => {
  show.value = !show.value;
};

const room = computed((): MultiUserRoom | null | undefined => {
  if (store.state.i.rooms.length > 0) {
    return _.find(
        store.state.i.rooms,
        r => r.roomname === store.state.i.mucname
    );
  }
  return null;
});

const ivoted = computed(() => {
  return !onlyvotingresult.value && votemode.value === "results";
});

const voting = computed(() => {
  if (
      room.value?.interaction &&
      'type' in room.value.interaction &&
      room.value.interaction.type === "voting" &&
      'voting' in room.value.interaction &&
      typeof room.value.interaction.voting === 'string' &&
      room.value.interaction.voting !== ""
  ) {
    const cvote = _.find(
        store.state.i.votings,
        v => v.uid === (room.value?.interaction as RoomInteraction & { voting: string }).voting
    );

    if (cvote) {
      return cvote;
    }
  }

  votemode.value = "vote";
  return false;
});

const voteuid = computed(() => voting.value ? voting.value.uid : '');

const myanswers = computed((): VotingBallot[] => {
  if (voting.value) {
    return store.getters["i/myBallotsForVoting"](voting.value.roomname, voting.value.uid);
  }
  return [];
});

const hasDeadline = computed(() => {
  const interaction = room.value?.interaction;

  if (!interaction || typeof interaction !== 'object') {
    return false;
  }

  return !!(
      'type' in interaction &&
      interaction.type === "voting" &&
      'votingduedate' in interaction &&
      interaction.votingduedate &&
      interaction.votingduedate !== ""
  );
});

const deadlineReached = computed(() => {
  const interaction = room.value?.interaction;

  if (
      !hasDeadline.value ||
      !interaction ||
      !('votingduedate' in interaction) ||
      typeof interaction.votingduedate !== 'string'
  ) {
    return false;
  }

  return DateTime.fromISO(interaction.votingduedate) < DateTime.fromJSDate(store.state.tick);
});

const toDeadline = computed(() => {
  const interaction = room.value?.interaction;

  if (
      !hasDeadline.value ||
      !interaction ||
      !('votingduedate' in interaction) ||
      typeof interaction.votingduedate !== 'string'
  ) {
    return '';
  }

  const vdd = DateTime.fromISO(interaction.votingduedate);
  const vsecs = Math.round(vdd.diff(DateTime.fromJSDate(store.state.tick), "seconds").seconds);

  if (vsecs <= 30) {
    return store.getters.i18n({de:`in ${vsecs} Sekunden`,en:`in ${vsecs} seconds`});
  }

  return vdd.setLocale(store.getters.i18n({de:'de',en:'en'}))
      .toRelative({ base: DateTime.fromJSDate(store.state.tick) });
});

const voteSum = computed(() => {
  if (!voting.value) {
    return 0;
  }

  return voting.value.answers.reduce((sum: number, answer: { uid: string }) => {
    const voteCount = store.getters["i/ballotsForVotingAnswer"](
        voting.value.roomname,
        voting.value.uid,
        answer.uid
    ).length;
    return sum + voteCount;
  }, 0);
});

const percentageForAnswer = (auid: string): string => {
  if (!voting.value || voteSum.value < 1) {
    return "";
  }

  const votecountA = store.getters["i/ballotsForVotingAnswer"](
      voting.value.roomname,
      voting.value.uid,
      auid
  ).length;

  return Math.round(votecountA * 100 / voteSum.value) + "%";
};

watch(voteuid, () => {
  votemode.value = "vote";
});

watch(myanswers, (nv: VotingBallot[]) => {
  votemode.value = nv.length > 0 ? "results" : "vote";
});

const togglegreenkey = (e: KeyboardEvent) => {
  switch (e.key) {
    case "g":
      document.body.style.background = !document.body.style.background ? '#0F0' : '';
      break;
    case "t":
      hidetitle.value = !hidetitle.value;
      redrawChart();
    default:
      break;
  }
};

const fullscreenhandle = async (nv: boolean) => {
  const playertoattach = document.querySelector(".video-js");
  if (!playertoattach) return;

  if (nv) {
    originalParent.value = document.getElementById('stream-voting-area')?.parentElement ?? null;
    originalParent.value?.removeChild(document.getElementById('stream-voting-area')!);
    playertoattach.append(document.getElementById('stream-voting-area')!);
  } else {
    playertoattach.removeChild(document.getElementById('stream-voting-area')!);
    originalParent.value?.append(document.getElementById('stream-voting-area')!);
  }
};

const redrawChart = () => {
  // Implementation hier
};

onMounted(() => {
  if (onlyvotingresult.value) {
    document.body.addEventListener("keyup", togglegreenkey, {passive:true});
  }

  if (store.state.i.videoFullscreen) {
    fullscreenhandle(true);
  }

  votemode.value = myanswers.value.length > 0 ? "results" : "vote";
});

onBeforeUnmount(() => {
  if (onlyvotingresult.value) {
    document.body.removeEventListener("keyup", togglegreenkey);
  }
});

// Watch für Fullscreen
watch(() => store.state.i.videoFullscreen, fullscreenhandle);

return (_ctx: any,_cache: any) => {
  return (voting.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: "stream-voting-area",
        class: _normalizeClass({
          'text-center': true,
          'inline-voting-area': !onlyvotingresult.value,
          'w-100': onlyvotingresult.value,
          'd-flex': onlyvotingresult.value,
          'flex-column': onlyvotingresult.value,
          'justify-content-center': onlyvotingresult.value,
          'align-items-center': onlyvotingresult.value,
          'ivoted': ivoted.value,
          'mb-4': true,
          'mb-lg-0': true
    })
      }, [
        (!hidetitle.value && !ivoted.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (!onlyvotingresult.value)
                  ? (_openBlock(), _createElementBlock("h5", {
                      key: 0,
                      onClick: toogle,
                      class: "mt-2"
                    }, [
                      _createElementVNode("span", _hoisted_3, [
                        _withDirectives(_createVNode(FontAwesomeIcon, { icon: "arrow-down" }, null, 512), [
                          [_vShow, !show.value]
                        ]),
                        _withDirectives(_createVNode(FontAwesomeIcon, { icon: "arrow-up" }, null, 512), [
                          [_vShow, show.value]
                        ])
                      ]),
                      _createTextVNode(" " + _toDisplayString(voting.value.question), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(voting.value.question), 1))
              ])
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", null, [
          (hasDeadline.value && !ivoted.value)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
                (!deadlineReached.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_unref(store).getters.i18n({de:'Das Voting endet ' + toDeadline.value,en:'Voting ends ' + toDeadline.value})) + ". ", 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(store).getters.i18n({de:'Das Voting ist beendet.',en:'The voting has ended.'})), 1))
              ], 512)), [
                [_vShow, !hidetitle.value]
              ])
            : _createCommentVNode("", true),
          (!onlyvotingresult.value && votemode.value === 'vote' && !deadlineReached.value)
            ? (_openBlock(), _createBlock(VotingForm, {
                key: 1,
                voting: voting.value
              }, null, 8, ["voting"]))
            : _createCommentVNode("", true),
          (ivoted.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h3", null, _toDisplayString(_unref(store).getters.i18n({de:'Danke für Ihre Abstimmung.',en:'Thank you for voting.'})), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 512), [
          [_vShow, show.value]
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})