<template>
  <Loading v-if="loading" />
  <span v-else-if="error"></span>
  <GalleryV2 :images="images" v-else />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import Loading from "@/components/Loading.vue"
import axios from "axios"
import GalleryV2 from "@/components/GalleryV2.vue"

interface Image {
  content: string
  image: string
  key: string
  sortorder: string
  title: string
}

const props = defineProps<{
  url: string
}>()

const loading = ref(true)
const error = ref(false)
const images = ref<Image[]>([])

onMounted(async () => {
  try {
    const response = await axios.get(props.url)

    if (response.status !== 200) {
      error.value = true
      console.error("Couldn't get image slide: Request not successful", response)
      return
    }

    if (response.data.length < 1) {
      error.value = true
      console.error("Couldn't get image slide: empty response")
      return
    }

    images.value = response.data
  } catch (e) {
    error.value = true
    console.error("Couldn't get image slide:", e)
  } finally {
    loading.value = false
  }
})
</script>