<script lang="ts">
import {defineComponent, computed, ref, onBeforeMount} from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import JitsiCall from "@/components/jitsi/JitsiCall.vue"
import ChatContactUser from "@/components/i/ChatContactUser.vue"
import _ from "lodash"
import { objToFirebaseUID } from "@/firebaseConnections"
import { useGtm } from '@/composables/useGtm'

export default defineComponent({
  name: 'VideoCallModal',
  components: { JitsiCall, ChatContactUser },

  setup() {
    const store = useStore()
    const router = useRouter()
    const searchtext = ref('')

    const currentCall = computed(() => store.state.members.currentCall)

    const incomingCalls = computed(() =>
        store.getters["members/incomingCalls"](false)
    )

    const stringForCalls = computed(() => {
      const callsnotended = store.getters["members/incomingCalls"](false)

      if (callsnotended.length < 1) return ''
      if (callsnotended.length === 1) {
        return store.getters.i18n({ de: 'Sie haben einen Anruf' })
      }
      return store.getters.i18n({ de: `Sie haben ${callsnotended.length} Anrufe` })
    })

    const stringForChats = computed(() => {
      const unreadCount = store.getters['i/unreadcontactschats']
      if (unreadCount < 1) return ''
      if (unreadCount === 1) {
        return store.getters.i18n({ de: 'Sie haben eine ungelesene Chat-Nachricht' })
      }
      return store.getters.i18n({
        de: `Sie haben ${unreadCount} ungelesene Chat-Nachrichten`
      })
    })

    const sortedContacts = computed(() => {
      const sC = store.getters['i/sortedContacts']
      if (sC.length > 0 && searchtext.value.length > 0) {
        const swords = _.map(
            _.words(searchtext.value),
            s => new RegExp(_.escapeRegExp(s),'iu')
        )
        return _.filter(sC, c => {
          for (let i = swords.length -1; i >= 0; i--) {
            if (!swords[i].test(c.firstname + ' ' + c.lastname)) {
              return false
            }
          }
          return true
        })
      }
      return sC
    })

    const joincall = async (cp: any) => {
      if (currentCall.value) {
        store.commit("i/addToast", {
          header: {de: "Fehler", en: "Error"},
          msg: {
            de: 'Sie sind bereits im Gespräch.',
            en: 'You are already in a call.'
          },
          validforroom: "event-general",
          got: new Date(),
          hidden: false,
          alert_type: 'error'
        })
      } else {
        await store.dispatch("members/startCallReceiver", cp.objvalueid)
      }
    }

    const pickContactChat = async (cp: any) => {
      store.dispatch("i/openContactChat", {
        fbuid: objToFirebaseUID(cp.callerid),
        objvalueid: cp.callerid,
        firstname: cp.firstname,
        lastname: cp.lastname
      })
    }

    const pickuser = (fbuid: string) => {
      const uc = _.find(
          store.state.i.contactschats,
          (c) => c.fbuid === fbuid
      )
      if (uc) {
        store.commit('OpenSideNav', 'chat')
        store.commit('i/setChatboxContact', uc)
      }
    }

    const { trackPage } = useGtm()
    onBeforeMount(() => {
      trackPage({
        pageCategory: 'Networking',
        PageType: 'Webapp',
        PageName: 'Videocalls',
      })
    })
    
    return {
      currentCall,
      incomingCalls,
      stringForCalls,
      stringForChats,
      sortedContacts,
      searchtext,
      joincall,
      pickContactChat,
      pickuser
    }
  }
})
</script>