<template>
  <div class="box">
    <div class="row g-3 mb-5">
      <h3>All speakers</h3>
      <div class="row">
        <SpeakerEntry
            v-for="(e, ei) in orderedSpeakers"
            :key="`omember${ei}`"
            :member="e"
            type="overview"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import SpeakerEntry from "@/components/SpeakerEntry.vue"
import _ from "lodash"
import { MemberEntry } from "@/store/i/types"
import { useGtm } from '@/composables/useGtm'

export default defineComponent({
  name: 'SpeakerOverview',

  components: {
    SpeakerEntry
  },

  setup() {
    const store = useStore()

    onBeforeMount(async () => {
      await store.dispatch("i/fetchSpeakers")
      
      const { trackPage } = useGtm()
      onBeforeMount(() => 
        trackPage({
          pageCategory: 'Speaker',
          PageType: 'Webapp',
          PageName: 'Overview',
        })
      )
    })

    const orderedSpeakers = computed(() => {
      const filter = _.filter(
          store.state.i.speakers,
          (e: MemberEntry) => e.dbstate > -1 && e.tn_type === 8 && e.is_visible
      )

      return _.orderBy(
          filter,
          [user => user.lastname.toLowerCase()],
          ["asc"]
      )
    })

    return {
      orderedSpeakers
    }
  }
})
</script>