import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "box_header"
}
const _hoisted_2 = { class: "row g-3 pt-0" }
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "box2 pb-0" }
const _hoisted_6 = {
  key: 0,
  class: "row g-3 pb-3"
}
const _hoisted_7 = { class: "col-lg-3 calendar g-0" }
const _hoisted_8 = { class: "col-lg-9 g-0" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "Text float-end mb-4" }
const _hoisted_11 = {
  key: 1,
  class: "row g-3"
}
const _hoisted_12 = { class: "Rectangle" }
const _hoisted_13 = { class: "row g-3" }
const _hoisted_14 = { class: "col-md-6 col-lg-6" }
const _hoisted_15 = { class: "col-12 white" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "Text white mt-4" }
const _hoisted_19 = { class: "box" }
const _hoisted_20 = { class: "row g-3" }
const _hoisted_21 = { class: "Text" }
const _hoisted_22 = { class: "box2" }
const _hoisted_23 = { class: "row g-3" }
const _hoisted_24 = {
  key: 0,
  class: "bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ContactEntry = _resolveComponent("ContactEntry")!
  const _component_SpeakerEntry = _resolveComponent("SpeakerEntry")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Banner),
    (_ctx.ImageEntry)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: "cv m-2",
                innerHTML: _ctx.replace(_ctx.ImageEntry.long_summary)
              }, null, 8, _hoisted_4)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_5, [
      (_ctx.isNetworkingAccepted)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "headline cb-headlinenormalxl mb-4" }, "My calendar", -1)),
              _createVNode(_component_router_link, { to: { name: 'Calendar' } }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("i", { class: "fal fa-calendar-alt" }, null, -1)
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "headline cb-headlinenormalxl mb-4" }, "Networking", -1)),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Matches, (match, index) => {
                  return (_openBlock(), _createBlock(_component_ContactEntry, {
                    key: `match-${index}`,
                    member: match
                  }, null, 8, ["member"]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_router_link, { to: { name: 'Networking1' } }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" See all matches "),
                    _createElementVNode("i", { class: "fal fa-chevron-right" }, null, -1)
                  ])),
                  _: 1
                })
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _cache[5] || (_cache[5] = _createElementVNode("p", null, " Here you can see your best networking matches. Go to your profile and take part in the networking ", -1)),
            _createElementVNode("p", null, [
              _createVNode(_component_router_link, {
                to: { name: 'Profile' },
                class: "btn btn-primary"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Go to my profile ")
                ])),
                _: 1
              })
            ])
          ]))
    ]),
    _createElementVNode("section", _hoisted_12, [
      _cache[8] || (_cache[8] = _createElementVNode("h2", {
        class: "headline g-3",
        id: "highlights"
      }, "Program Highlights", -1)),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedAgenda, (agendaItem, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `agenda-${index}`,
              class: _normalizeClass(['row', 'agenda_item', 'mb-4', { highlight: index === 0 }])
            }, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("a", {
                  onClick: ($event: any) => (_ctx.navigateAgenda(agendaItem.objvalueid)),
                  class: "pointer"
                }, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.title(agendaItem.title)), 1),
                  (agendaItem.subtitle)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(agendaItem.subtitle), 1))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_16)
              ])
            ], 2))
          }), 128))
        ]),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-md-6 col-lg-6" }, [
          _createElementVNode("img", {
            src: "/files/landingpage/highlight_xl.png",
            class: "img-fluid",
            alt: "Highlights"
          })
        ], -1)),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(_component_router_link, { to: { name: 'AgendaOverview' } }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" See full program "),
              _createElementVNode("i", { class: "fal fa-chevron-right" }, null, -1)
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_19, [
      _cache[10] || (_cache[10] = _createElementVNode("h2", { class: "headline cb-headlinenormalxl g-3 mb-4" }, "Speakers", -1)),
      _createElementVNode("div", _hoisted_20, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedSpeakers, (speaker, index) => {
          return (_openBlock(), _createBlock(_component_SpeakerEntry, {
            key: `speaker-${index}`,
            member: speaker,
            type: "welcome"
          }, null, 8, ["member"]))
        }), 128)),
        _createElementVNode("div", _hoisted_21, [
          _createVNode(_component_router_link, { to: { name: 'SpeakerOverview' } }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" See all speakers "),
              _createElementVNode("i", { class: "fal fa-chevron-right" }, null, -1)
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_22, [
      _cache[13] || (_cache[13] = _createElementVNode("h2", { class: "headline cb-headlinenormalxl g-3" }, "Activity ranking", -1)),
      _createElementVNode("div", _hoisted_23, [
        _cache[11] || (_cache[11] = _createElementVNode("p", null, " It's no fun without a little bit of competition: Take part and earn points for activities on this conference platform – bookmarking your program highlights, hanging out at our virtual coffee bar, or scheduling networking calls. As soon as you have successfully completed one of the challenges, the corresponding icon will appear in green at the bottom of the page. The three winners of the activity ranking will receive a free on-site ticket to the DW Global Media Forum 2025! They will be announced here and by email on June 30, 2024. ", -1)),
        _cache[12] || (_cache[12] = _createElementVNode("h5", null, "Leader board", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Ranking, (rankingMember, index) => {
          return (_openBlock(), _createBlock(_component_ContactEntry, {
            key: `ranking-${index}`,
            numberkey: index,
            member: rankingMember,
            type: "ranking"
          }, null, 8, ["numberkey", "member"]))
        }), 128)),
        (_ctx.isRestrictedEmployee)
          ? (_openBlock(), _createElementBlock("p", _hoisted_24, " DW employees cannot participate in the activity challenge. "))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}