<template>

  <div class="box">
    <div class="row g-3 mb-5">

      <h3>Networking</h3>

      <MeetingModal :connection="connection" :toggle_modal="toggle_modal" />
      <JitsiCall type="member" v-if="store.state.members.currentCall" />

      <div class="row">
        <div class="col-lg-12">
          <p>
            <b>Get together for a virtual coffee with other conference attendees who are online right now! This is an open group chat.</b>
          </p>
          
          <p>
            Take part in our reaction game and collect points to increase your online activity score!<br>
            The highest online activity scores win a ticket to #GMF25.
          </p>
          
          <p class="pb-2">
            <router-link :to="{name: 'Coffeebar'}" class="btn btn-danger col-lg-3">
              <i class="fa-light fa-mug-hot"></i> Coffee bar
            </router-link>
          </p>

        </div>
      </div>
          
      <div class="row" v-if="!store.state.i.me.accept_networking">
        <div class="col-lg-12">
        <p><b>
            Complete your networking profile to connect with media professionals from around the globe in just a couple of minutes. Good to have you with us this #GMF24!
        </b>          
          </p>
          <p>
            <router-link :to="{name: 'Profile'}" class="btn btn-primary col-lg-3 fw-normal"> 
              To my profile
            </router-link>
          </p>
        </div>
      </div>

      <p v-if="store.state.i.me.accept_networking">
        <b>Connect with more than 1,200 media professionals from around the globe!</b>
      </p> 
      <p v-if="store.state.i.me.accept_networking" class="mt-0">
        How? Simply create your own profile and use the matchmaking function to add new contacts based on the areas of interest you specify in your profile. You will automatically be shown contacts that match your own interests. You can also connect directly with other participants using the chat function. So chat away, schedule video calls or arrange to meet up in Bonn in person on June 17 or 18. We hope you have some memorable conversations!
      </p>

      <div class="row rowtabs"  v-if="store.state.i.me.accept_networking">
        <div class="col-md-6 col-lg-2 mb-1">
          <router-link :to="{name: 'Networking1'}" :class="{'btn highlight_tabs': true,'btn-active': route.meta.tab == 1}">
            My matches
          </router-link>
        </div>
        <div class="col-md-6 col-lg-3 mb-1">
          <router-link :to="{name: 'Networking2'}" :class="{'btn highlight_tabs': true,'btn-active': route.meta.tab == 2}">
            Participant search
          </router-link>
        </div>
        <div class="col-md-6 col-lg-2 mb-1">
          <router-link :to="{name: 'Networking3'}" :class="{'btn highlight_tabs': true, 'btn-active': route.meta.tab == 3}">
            My contacts
          </router-link>
        </div>
        <div class="col-md-6 col-lg-2 mb-1">
          <router-link :to="{name: 'Networking4'}" :class="{'btn highlight_tabs': true, 'btn-active': route.meta.tab == 4}">
            Contact requests
          </router-link>
        </div>

      </div>


      <div v-show="successtring.de !== ''" class="alert alert-success col-lg-12">
        {{ store.getters.i18n(successtring) }}
      </div>


      <div v-show="errorstring.de !== ''" class="alert alert-danger col-lg-12">
        {{ store.getters.i18n(errorstring) }}
      </div>


      <div v-if="store.state.i.me.accept_networking">

        <div v-show="route.meta.tab === 1">

        <span class="col-lg-12 headline cb-headlinenormalxl mb-3">My matches</span>

          <NetworkingUserContact v-for="(u, index) in MatchesFilter" :key="'NetworkingUserContact1_' + index" :user="u" contact_type="matches" @navigate="navigate_from_contacts" @create="createConnection"/>

          <p v-if="Matches.length > number_members1" class="Text pointer" @click="showmore1">Load more...</p>

        </div>

        <div v-show="route.meta.tab === 2">
          <span class="col-lg-12 headline cb-headlinenormalxl mb-3">Participant search</span>

          <p><input placeholder="Search..." type="search" v-model.trim="searchtext2" class="form-control"></p>

          <NetworkingUserContact v-for="(u, index) in MembersFilter" :key="'NetworkingUserContact2_' + index" :user="u" contact_type="members" @navigate="navigate_from_contacts" @create="createConnection"/>

          <p v-if="Members.length > number_members2" class="Text pointer" @click="showmore2">Load more...</p>

        </div>


        <div v-show="route.meta.tab === 3">
          <span class="col-lg-12 headline cb-headlinenormalxl mb-3">My contacts</span>

          <p><input placeholder="Search..." type="search" v-model.trim="searchtext3" class="form-control"></p>

          <NetworkingUserConnection v-for="(u, index) in Contacts" :key="'NetworkingUserConnection1_' + index" :user="u" contact_type="contact" @navigate="navigate_from_connections" @remove="removeConnection" @chat="pickuserchat" @call="pickusercall" @meeting="meeting"/>

        </div>


        <div v-show="route.meta.tab === 4">

          <span class="col-lg-12 headline cb-headlinenormalxl mb-3" v-if="Received.length > 0">Requests received</span>
          <NetworkingUserConnection v-for="(u, index) in Received" :key="'NetworkingUserConnection2_' + index" :user="u" contact_type="received" @navigate="navigate_from_connections" @accept="acceptConnection" @decline="declineConnection" @block="blockConnection"/>

          <span class="col-lg-12 headline cb-headlinenormalxl mb-3" v-if="Send.length > 0">Requests sent</span>
          <NetworkingUserConnection v-for="(u, index) in Send" :key="'NetworkingUserConnection3_' + index" :user="u" contact_type="send" @navigate="navigate_from_connections"/>

        </div>

      </div>

    </div>
  </div>

</template>

<script setup lang="ts">
import {computed, onBeforeUnmount, onMounted, ref, watch, defineComponent} from 'vue';
import {useStore} from 'vuex';
import {useRouter, useRoute, NavigationGuardNext, RouteLocationNormalized} from 'vue-router';
import _ from "lodash";
import JitsiCall from "@/components/jitsi/JitsiCall.vue";
import NetworkingUserContact from "@/components/i/NetworkingUserContact.vue";
import NetworkingUserConnection from "@/components/i/NetworkingUserConnection.vue";
import MeetingModal from "@/components/i/MeetingModal.vue";
import {TranslatedText} from "@/store/types";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import {ConnectionNetworking, ContactsNetworking} from "@/store/i/types";
import {useGtm} from "@/composables/useGtm";

const store = useStore();
const router = useRouter();
const route = useRoute();

// State
const errorstring = ref<TranslatedText>({'de': '', 'en': ''});
const successtring = ref<TranslatedText>({'de': '', 'en': ''});
const number_members1 = ref<number>(20);
const number_members2 = ref<number>(20);
const searchtext2 = ref<string>('');
const searchtext3 = ref<string>('');
const connection = ref<number>(0);
const toggle_modal = ref<boolean>(false);

defineComponent({
  name: 'Networking',
  components: {
    JitsiCall,
    NetworkingUserConnection,
    NetworkingUserContact,
    MeetingModal
  }
});

// Navigation
const navigate_from_contacts = (cp: ContactsNetworking) => {
  router.push({name: 'ContactDetail', params: { contactid: `${cp.objvalueid}` }});
};

const navigate_from_connections = (cp: ConnectionNetworking) => {
  if (cp.receiver == store.state.config.me.objvalueid) {
    router.push({name: 'ContactDetail', params: { contactid: `${cp.sender}` }});
  } else {
    router.push({name: 'ContactDetail', params: { contactid: `${cp.receiver}` }});
  }
};

// Connection Methods
const reset_message = () => {
  errorstring.value = {de: '', en:''};
  successtring.value = {de: '', en:''};
};

const createConnection = async (c: ContactsNetworking) => {
  try {
    errorstring.value = {de: '', en:''};
    successtring.value = {de: '', en:''};
    const dpr = await store.dispatch("i/createConnection", c);
    setTimeout(() => { reset_message() }, 2000);
    if (dpr) {
      successtring.value = {
        de: "Contact request sent",
        en: "Contact request sent"
      };
    } else {
      errorstring.value = {
        de: "Contact request could not be created",
        en: "Contact request could not be created"
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

// Computed Properties
const ContactsNetworking = computed(() => {
  const arr: number[] = [];

  const sC = _.filter(
      store.getters['i/sortedConnectionsNetworking'],
      (c: ConnectionNetworking) => c.dbstate != -1
  );

  sC.forEach((ap: ConnectionNetworking) => {
    arr.push(ap.receiver);
    arr.push(ap.sender);
  });

  let filter = _.filter(
      store.getters['i/sortedContactsNetworking'],
      (c: ContactsNetworking) => c.accept_networking
  );

  const excludes = _(filter)
      .keyBy('objvalueid')
      .at(arr)
      .value();

  filter = _.differenceWith(filter, excludes, _.isEqual);

  if(!store.state.config.me.is_test){
    filter = _.filter(
        filter,
        (c: ContactsNetworking) => !c.is_test
    );
  }

  return filter;
});

const Matches = computed(() => {
  return _.orderBy(ContactsNetworking.value, ["match"], ["desc"]);
});

const MatchesFilter = computed(() => {
  return _.take(Matches.value, number_members1.value);
});

const showmore1 = () => {
  number_members1.value += 20;
};

// Lifecycle Hooks
onMounted(() => {
  handleListeners();

  const { trackPage } = useGtm()
  
  trackPage({
    pageCategory: 'Networking',
    PageName: route.name as string,
    PageType: 'Webapp',
  });
});

const handleListeners = async () => {
  if (store.state.i.me.accept_networking) {
    await store.dispatch("i/changeListenersContactsNetworking", true);
  } else {
    await store.dispatch("i/changeListenersContactsNetworking", false);
  }
};

onBeforeUnmount(() => {
  store.dispatch("i/changeListenersContactsNetworking", false);
});

// Watch
watch(
    () => store.state.i.connected,
    () => handleListeners()
);

watch(
    () => store.state.i.me.accept_networking,
    () => handleListeners()
);

// Navigation Guard
const navguard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
  if (store.state.members.currentCall && !store.getters['i/forcedToPlenum'] && !store.getters['i/redirectToPlenum']) {
    const confirmres = window.confirm(store.getters.i18n({
      de: "If you are leaving the booth now, your call will be ended.",
      en: "If you are leaving the booth now, your call will be ended."
    }));
    if (confirmres) {
      next();
    } else {
      next(false);
    }
  } else {
    next();
  }
};


// Chat and Call Functions
const pickuserchat = (cp: ConnectionNetworking) => {
  const uc = _.find(
      store.state.i.contacts_networking,
      (c) => c.fbuid === cp.fbuid
  );
  if (uc) {
    store.commit('OpenSideNav', 'chat');
    store.commit('i/setChatboxContact', uc);
  }
};

const pickusercall = async (cp: ConnectionNetworking) => {
  if (cp.fbuid !== store.state.config.fbuid) {
    if (store.state.members.currentCall) {
      store.commit("i/addToast", {
        header: {de: "Fehler", en: "Error"},
        msg: {
          de: 'Sie sind bereits im Gespräch.',
          en: 'You are already in a call.'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        showfor: 10000,
        alert_type: 'error'
      });
    } else {
      const contactData = {
        fbuid: cp.fbuid,
        objvalueid: cp.receiver == store.state.config.me.objvalueid ? cp.sender : cp.receiver,
        firstname: cp.firstname,
        lastname: cp.lastname,
        orgname: cp.orgname
      };

      store.commit("members/setContact", contactData);
      await store.dispatch("members/startCall", contactData.objvalueid);

      store.commit("i/addToast", {
        header: {de: "Hinweis", en: "Warning"},
        msg: {
          de: 'Bitte haben Sie einen Moment Geduld...',
          en: 'Please wait a moment...'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        showfor: 10000,
        alert_type: 'error'
      });
    }
  }
};

// Weitere computed properties
const Members = computed(() => {
  let filter = ContactsNetworking.value;

  if (filter.length > 0 && searchtext2.value.length > 0) {
    const swords = _.map(
        _.words(searchtext2.value),
        s => new RegExp(_.escapeRegExp(s),'iu')
    );
    return _.filter(
        filter,
        c => {
          for (let i = swords.length -1; i >= 0; i--) {
            if (!swords[i].test(c.firstname + ' ' + c.lastname)) {
              return false;
            }
          }
          return true;
        }
    );
  }
  return filter;
});

const MembersFilter = computed(() => {
  return _.take(Members.value, number_members2.value);
});

const Contacts = computed(() => {
  let filter = _.filter(
      store.getters['i/sortedConnectionsNetworking'],
      (c: ConnectionNetworking) => c.dbstate != -1 && c.status == 2
  );

  if (filter.length > 0 && searchtext3.value.length > 0) {
    const swords = _.map(
        _.words(searchtext3.value),
        s => new RegExp(_.escapeRegExp(s),'iu')
    );
    return _.filter(
        filter,
        c => {
          for (let i = swords.length -1; i >= 0; i--) {
            if (!swords[i].test(c.firstname + ' ' + c.lastname)) {
              return false;
            }
          }
          return true;
        }
    );
  }
  return filter;
});

const Received = computed(() => {
  return _.filter(
      store.getters['i/sortedConnectionsNetworking'],
      (c: ConnectionNetworking) =>
          c.dbstate != -1 &&
          c.status == 1 &&
          c.receiver == store.state.config.me.objvalueid
  );
});

const Send = computed(() => {
  return _.filter(
      store.getters['i/sortedConnectionsNetworking'],
      (c: ConnectionNetworking) =>
          c.dbstate != -1 &&
          c.status == 1 &&
          c.sender == store.state.config.me.objvalueid
  );
});

// Fehlende Methoden
const showmore2 = () => {
  number_members2.value += 20;
};

const removeConnection = async (c: ConnectionNetworking) => {
  try {
    errorstring.value = {de: '', en:''};
    successtring.value = {de: '', en:''};
    const dpr = await store.dispatch("i/removeConnection", c);
    setTimeout(() => { reset_message() }, 2000);
    if (dpr) {
      successtring.value = {
        de: "Contact removed",
        en: "Contact removed"
      };
    } else {
      errorstring.value = {
        de: "Could not remove contact.",
        en: "Could not remove contact."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

const acceptConnection = async (c: ConnectionNetworking) => {
  try {
    errorstring.value = {de: '', en:''};
    successtring.value = {de: '', en:''};
    const dpr = await store.dispatch("i/acceptConnection", c);
    setTimeout(() => { reset_message() }, 2000);
    if (dpr) {
      successtring.value = {
        de: "Contact request accepted",
        en: "Contact request accepted"
      };
    } else {
      errorstring.value = {
        de: "Could not accept contact.",
        en: "Could not accept contact."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

const declineConnection = async (c: ConnectionNetworking) => {
  try {
    errorstring.value = {de: '', en:''};
    successtring.value = {de: '', en:''};
    const dpr = await store.dispatch("i/declineConnection", c);
    setTimeout(() => { reset_message() }, 2000);
    if (dpr) {
      successtring.value = {
        de: "Contact request declined",
        en: "Contact request declined"
      };
    } else {
      errorstring.value = {
        de: "Could not decline contact.",
        en: "Could not decline contact."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

const blockConnection = async (c: ConnectionNetworking) => {
  try {
    errorstring.value = {de: '', en:''};
    successtring.value = {de: '', en:''};
    const dpr = await store.dispatch("i/blockConnection", c);
    setTimeout(() => { reset_message() }, 2000);
    if (dpr) {
      successtring.value = {
        de: "Contact request blocked",
        en: "Contact request blocked"
      };
    } else {
      errorstring.value = {
        de: "Could not block contact.",
        en: "Could not block contact."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

// Meeting Function
const meeting = (cp: ConnectionNetworking) => {
  toggle_modal.value = !toggle_modal.value;
  connection.value = cp.receiver == store.state.config.me.objvalueid ? cp.sender : cp.receiver;
};

// Expose necessary methods and properties
defineExpose({
  errorstring,
  successtring,
  createConnection,
  removeConnection,
  acceptConnection,
  declineConnection,
  blockConnection,
  navigate_from_contacts,
  navigate_from_connections,
  pickuserchat,
  pickusercall,
  meeting,
  navguard
});
</script>