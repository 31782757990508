import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger mb-2"
}
const _hoisted_3 = { class: "col-md-7 col-sm-12" }
const _hoisted_4 = { class: "col-md-5 col-sm-12" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

import { ref, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import type { TranslatedText } from "@/store/types"
import { AppNetworkErrorOther, networkErrorText } from "@/crud"
import _ from "lodash"


export default /*@__PURE__*/_defineComponent({
  __name: 'Competition',
  setup(__props) {

const store = useStore()
const clickerRef = ref<HTMLElement>()

const errorstring = ref<TranslatedText>({ de: '', en: '' })
const sec = ref(20)
const point = ref(0)
const level = ref(1)
const nextLevel = ref(5)
const click = ref(0)
const speedmoving = ref(280)
const speedrotate = ref(3000)
const rotation = ref(0)
const gamestart = ref(false)
const stopper = ref(0)
const d = ref(0)
const score_reaction = ref(0)
const btn1 = ref(true)
const btn2 = ref(false)

const StartCompetition = () => {
  if (!gamestart.value) {
    gamestart.value = true
    btn1.value = false
    btn2.value = false
    stopper.value = setInterval(chrono, 1000)
    d.value = setInterval(rotating, 10)
  }
}

const SaveData = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    const dpr = await store.dispatch("i/setDataCompetition", { score_reaction: score_reaction.value })
    if (!dpr.sv) {
      errorstring.value = {
        de: "Feld konnte nicht gesetzt werden.",
        en: "Could not set Field."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

const chrono = () => {
  if (sec.value > 0) {
    sec.value--
    if (sec.value % 2 === 0) {
      escape()
    }
  } else {
    clearInterval(stopper.value)
    clearInterval(d.value)
    gamestart.value = false
    if (clickerRef.value) {
      clickerRef.value.style.top = "0px"
      clickerRef.value.style.left = "0px"
      clickerRef.value.style.transform = "rotate(0deg)"
    }
    setDataToSave()
    resetGame()
  }
}

const resetGame = () => {
  btn1.value = false
  btn2.value = true
  sec.value = 20
  point.value = 0
  level.value = 1
  nextLevel.value = 5
  click.value = 0
  speedmoving.value = 280
  speedrotate.value = 3000
  rotation.value = 0
  gamestart.value = false
  stopper.value = 0
  d.value = 0
}

const setDataToSave = () => {
  store.dispatch('i/setDataRankingE', 5)
  if (point.value > score_reaction.value) {
    score_reaction.value = point.value
    SaveData()
  }
}

const points = () => {
  if (gamestart.value && sec.value !== 0) {
    click.value++
    point.value += (level.value * 6)
    nextLevel.value = 5 - (click.value % 5)

    if (click.value % 5 === 0 && click.value !== 0) {
      level.value++
      if (speedmoving.value > 30) speedmoving.value -= 30
      if (speedrotate.value > 300) speedrotate.value -= 300
    }
  }
}

const wrongClick = () => {
  if (gamestart.value && sec.value !== 0) {
    if (point.value > level.value) {
      point.value -= level.value
    }
  }
}

const escape = () => {
  if (gamestart.value) {
    setTimeout(moving, speedmoving.value)
  }
}

const moving = () => {
  if (clickerRef.value) {
    clickerRef.value.style.top = Math.random() * 255 + "px"
    clickerRef.value.style.left = Math.random() * 255 + "px"
  }
}

const rotating = () => {
  rotation.value += 3600/speedrotate.value
  if (clickerRef.value) {
    clickerRef.value.style.transform = `rotate(${rotation.value}deg)`
  }
}

onBeforeUnmount(() => {
  clearInterval(stopper.value)
  clearInterval(d.value)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (errorstring.value.de !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(store).getters.i18n(errorstring.value)), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "game bg-dark container d-inline-block ml-2 mr-1",
        onClick: wrongClick
      }, [
        _createElementVNode("div", {
          class: "rounded text-center clicker",
          ref_key: "clickerRef",
          ref: clickerRef,
          onMouseover: escape,
          onClick: points,
          type: "button"
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: "/projects/dw/gmf/assets/images/reaction-logo.png",
            class: "background img-competition",
            alt: "Competition image"
          }, null, -1)
        ]), 544)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, [
        _cache[1] || (_cache[1] = _createTextVNode("My highest score: ")),
        _createElementVNode("b", null, _toDisplayString(_unref(store).state.i.me.score_reaction), 1)
      ]),
      _createElementVNode("p", null, [
        _cache[2] || (_cache[2] = _createTextVNode(" Score: ")),
        _createElementVNode("b", null, _toDisplayString(point.value), 1),
        _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
        _cache[4] || (_cache[4] = _createTextVNode(" Level: ")),
        _createElementVNode("b", null, _toDisplayString(level.value), 1),
        _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
        _cache[6] || (_cache[6] = _createTextVNode(" Timer: ")),
        _createElementVNode("b", null, _toDisplayString(sec.value), 1)
      ]),
      (btn1.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
            _createElementVNode("span", {
              class: "btn btn-primary btn-lg",
              onClick: StartCompetition
            }, "Start")
          ]))
        : _createCommentVNode("", true),
      (btn2.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
            _createElementVNode("span", {
              class: "btn btn-primary btn-lg",
              onClick: StartCompetition
            }, "Repeat")
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})