import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-lg-9" }
const _hoisted_4 = { class: "row mt-3" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = {
  key: 1,
  class: "mt-4"
}
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 2,
  class: "mt-4"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

import { ref, computed, onMounted } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import AgendaEntry from "@/components/AgendaEntry.vue"
import MeetingEntry from "@/components/MeetingEntry.vue"
import JitsiCall from "@/components/jitsi/JitsiCall.vue"
import _ from "lodash"
import type { AgendaPoint, Meetings } from "@/store/i/types"
import type { JitsiCallRequest } from "@/store/member/types"

// Props
interface Days {
  day1: boolean
  day2: boolean
}

interface EventTypes {
  networking: boolean
  exhibitor_events: boolean
  plenary_sessions: boolean
  breakout_sessions: boolean
}

type EventTypeKey = keyof EventTypes

// Event Type Mapping

export default /*@__PURE__*/_defineComponent({
  __name: 'Agenda',
  props: {
    agendatype: {}
  },
  setup(__props: any) {

const props = __props

// Store, Route, Router
const store = useStore()
const route = useRoute()

// Reactive State
const searchtext = ref('')

// Interfaces
const eventTypeMap: Record<EventTypeKey, number> = {
  networking: 4,
  exhibitor_events: 5,
  plenary_sessions: 6,
  breakout_sessions: 7
}

const currentCall = computed<JitsiCallRequest | undefined>(() => store.state.members.currentCall)


const pickusercall = async (cp: Meetings) => {
  if (cp.fbuid !== store.state.config.fbuid) {
    if (currentCall.value) {
      store.commit("i/addToast", {
        header: {de: "Fehler", en: "Error"},
        msg: {
          de: 'Sie sind bereits im Gespräch.',
          en: 'You are already in a call.'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        showfor: 10000,
        alert_type: 'error'
      })
    } else {
      if (cp.receiver == store.state.config.me.objvalueid) {
        let c = {
          "fbuid": cp.fbuid,
          "objvalueid": cp.sender,
          "gender": cp.gender,
          "title": cp.title,
          "firstname": cp.firstname,
          "lastname": cp.lastname,
          "orgname": cp.orgname
        }
        store.commit("members/setContact", c)
        await store.dispatch("members/startCall", cp.sender)
      } else {
        let c = {
          "fbuid": cp.fbuid,
          "objvalueid": cp.receiver,
          "gender": cp.gender,
          "title": cp.title,
          "firstname": cp.firstname,
          "lastname": cp.lastname,
          "orgname": cp.orgname
        }
        store.commit("members/setContact", c)
        await store.dispatch("members/startCall", cp.receiver)
      }

      store.commit("i/addToast", {
        header: {de: "Hinweis", en: "Warning"},
        msg: {
          de: 'Bitte haben Sie einen Moment Geduld, bis Ihr Ansprechpartner Ihren Anruf entgegen nimmt. Wenn Sie das Gespräch beenden, ohne dass Ihr Ansprechpartner den Anruf entgegen genommen hat, wird dieser per Chat-Nachricht über Ihren Kontaktversuch informiert.',
          en: 'Please be patient until your contact accepts your call. If you end the call before your contact has accepted it, they will be notified of your contact attempt via chat message.'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        showfor: 10000,
        alert_type: 'error'
      })
    }
  }
}

onBeforeRouteLeave((to, from, next) => {
  if (store.state.members.currentCall && !store.getters['i/forcedToPlenum'] && !store.getters['i/redirectToPlenum']) {
    const confirmres = window.confirm(store.getters.i18n({
      de: "Wenn Sie jetzt den Stand verlassen, wird Ihr Gespräch beendet.",
      en: "If you are leaving the booth now, your call will be ended."
    }))
    if (confirmres) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})


// Active Filters State
const activeFilters = ref({
  eventTypes: {
    networking: false,
    exhibitor_events: false,
    plenary_sessions: false,
    breakout_sessions: false
  },
  days: {
    day1: false,
    day2: false
  }
})

// Computed Properties
const noDaysSelected = computed(() => {
  return Object.values(activeFilters.value.days).filter(v => v).length === 0
})

const filterItems = (items: AgendaPoint[]) => {
  const swords = _.map(
      _.words(searchtext.value),
      s => new RegExp(_.escapeRegExp(s), 'iu')
  )

  localStorage.setItem("TextFilter", searchtext.value)

  const activeEventTypeFilters: EventTypeKey[] = Object.entries(activeFilters.value.eventTypes)
      .filter(([_key, value]) => value)
      .map(([key, _value]) => key as EventTypeKey)

  const lookForEventTypeNumber = activeEventTypeFilters.map((k) => eventTypeMap[k])

  return _.filter(
      items,
      (item) => {
        return (swords.length === 0 || swords.some((r) => r.test(item.title + ' ' + item.speakers)))
            && (lookForEventTypeNumber.length === 0 || lookForEventTypeNumber.includes(item.eventtype))
      }
  )
}


/*
 ERSTMAL AUSKOMMENTIERT DA ZU KLÄREN IST WELCHE DATEN 2025 ES SEIN SOLLEN UND OB DAS HARDGECODET BLEIBT
 OHNE AUSKOMMENTIEREN BEKOMMT MAN SONST ERSTMAL MIT DEN TESTDATEN NIX ANGEZEIT!!!
*/

/*
const agendaPointsDay1 = computed(() => {
  let items = _.filter(
      store.getters['i/orderedAgendaPoints'],
      (ap: AgendaPoint) => ap.dbstate > -1 && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
  )

  if (props.agendatype === "calendar") {
    items = _.filter(
        items,
        (ap: AgendaPoint) => _.has(store.state.i.me.fav_agenda_items, ap.objvalueid) && new Date(ap.enddate).getTime() > new Date().getTime()
    )
  }

  if (props.agendatype === "video") {
    items = _.filter(
        items,
        (ap: AgendaPoint) => ap.dbstate > -1 && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024" && ap.show_video
    )
  }

  return filterItems(items)
})

const agendaPointsDay2 = computed(() => {
  let items = _.filter(
      store.getters['i/orderedAgendaPoints'],
      (ap: AgendaPoint) => ap.dbstate > -1 && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
  )

  if (props.agendatype === "calendar") {
    items = _.filter(
        items,
        (ap: AgendaPoint) => _.has(store.state.i.me.fav_agenda_items, ap.objvalueid) && new Date(ap.enddate).getTime() > new Date().getTime()
    )
  }

  if (props.agendatype === "video") {
    items = _.filter(
        items,
        (ap: AgendaPoint) => ap.dbstate > -1 && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024" && ap.show_video
    )
  }

  return filterItems(items)
})
*/

const agendaPointsDay1 = computed(() => {
  let items = store.getters['i/orderedAgendaPoints'];
      
  

  console.log('TODO: check logik/datum _ items day 1', items);



  return items
})

const agendaPointsDay2 = computed(() => {
  let items =  store.getters['i/orderedAgendaPoints'];

  console.log('TODO: check logik/datum _ items day 2', items);


  return items
})

// Meetings computed properties
const meetings1 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 1 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
  )
})

const meetings2 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 2 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
  )
})

const meetings3 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 3 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
  )
})

const meetings4 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 4 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
  )
})

const meetings1_2 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 1 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
  )
})

const meetings2_2 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 2 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
  )
})

const meetings3_2 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 3 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
  )
})

const meetings4_2 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 4 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
  )
})

// Methods
const clearFilter = () => {
  searchtext.value = ''
  activeFilters.value = {
    eventTypes: {
      networking: false,
      exhibitor_events: false,
      breakout_sessions: false,
      plenary_sessions: false
    },
    days: {
      day1: false,
      day2: false
    }
  }

  localStorage.removeItem("DayFilter")
  localStorage.removeItem("EventFilter")
  localStorage.removeItem("TextFilter")
}

const toggleEventTypeFilter = (type: keyof EventTypes) => {
  activeFilters.value.eventTypes[type] = !activeFilters.value.eventTypes[type]
  localStorage.setItem("EventFilter", JSON.stringify(activeFilters.value.eventTypes))
}

const toggleDayFilter = (type: keyof Days) => {
  activeFilters.value.days[type] = !activeFilters.value.days[type]
  localStorage.setItem("DayFilter", JSON.stringify(activeFilters.value.days))
}


// Navigation Guard
onBeforeRouteLeave((to, from, next) => {
  if (store.state.members.currentCall && !store.getters['i/forcedToPlenum'] && !store.getters['i/redirectToPlenum']) {
    const confirmres = window.confirm(store.getters.i18n({
      de: "If you are leaving the booth now, your call will be ended.",
      en: "If you are leaving the booth now, your call will be ended."
    }))
    if (confirmres) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

// Initialization
const initializeFilters = () => {
  if (new Date().toDateString() === "Mon Jun 17 2024") {
    activeFilters.value.days.day1 = true
  } else if (new Date().toDateString() === "Tue Jun 18 2024") {
    activeFilters.value.days.day2 = true
  }

  if (localStorage.getItem('EventFilter')) {
    const storedNamesEvent = JSON.parse(localStorage.getItem('EventFilter') || '{}')
    Object.entries(storedNamesEvent).forEach(([key, value]) => {
      if (value) {
        const k = key as keyof EventTypes
        activeFilters.value.eventTypes[k] = true
      }
    })
  }

  if (localStorage.getItem('DayFilter')) {
    const storedNamesDays = JSON.parse(localStorage.getItem('DayFilter') || '{}')
    Object.entries(storedNamesDays).forEach(([key, value]) => {
      if (value) {
        const k = key as keyof Days
        activeFilters.value.days[k] = true
      }
    })
  }

  if (localStorage.getItem('TextFilter')) {
    searchtext.value = localStorage.getItem('TextFilter') || ''
  }
}
// Lifecycle Hooks
onMounted(async () => {
  console.log('Agenda.vue mounted')
  await store.dispatch("i/fetchAgenda")
})

initializeFilters()



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(store).state.members.currentCall)
      ? (_openBlock(), _createBlock(JitsiCall, {
          key: 0,
          type: "member"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, [
          _withDirectives(_createElementVNode("input", {
            placeholder: "Search title or speaker name",
            type: "search",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchtext).value = $event)),
            class: "form-control"
          }, null, 512), [
            [
              _vModelText,
              searchtext.value,
              void 0,
              { trim: true }
            ]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"col-8\"><a href=\"/files/gmf_programoverview.pdf\" class=\"btn btn-primary m-0 float-start me-2\" target=\"_blank\"><i class=\"far fa-arrow-to-bottom\"></i> Download full program </a><a href=\"/files/gmf_siteplan.pdf\" class=\"btn btn-primary m-0 float-start\" target=\"_blank\"><i class=\"far fa-arrow-to-bottom\"></i> Download site plan </a></div>", 1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              class: "resetfilter btn btn-outline-primary float-end p-3",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (clearFilter()))
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("i", { class: "fal fa-trash" }, null, -1),
              _createTextVNode(" Clear Filter ")
            ]))
          ])
        ]),
        _cache[16] || (_cache[16] = _createElementVNode("h5", { class: "mt-3" }, "Event type", -1)),
        _createElementVNode("span", {
          class: "badge eventtype1 mt-1",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (toggleEventTypeFilter('plenary_sessions')))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass([!activeFilters.value.eventTypes.plenary_sessions ? 'fal fa-circle' : 'fas fa-check-circle'])
          }, null, 2),
          _cache[10] || (_cache[10] = _createTextVNode(" Plenary sessions "))
        ]),
        _createElementVNode("span", {
          class: "badge event-type-7 mt-1",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (toggleEventTypeFilter('breakout_sessions')))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass([!activeFilters.value.eventTypes.breakout_sessions ? 'fal fa-circle' : 'fas fa-check-circle'])
          }, null, 2),
          _cache[11] || (_cache[11] = _createTextVNode(" Breakout sessions "))
        ]),
        _createElementVNode("span", {
          class: "badge eventtype4 mt-1",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (toggleEventTypeFilter('networking')))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass([!activeFilters.value.eventTypes.networking ? 'fal fa-circle' : 'fas fa-check-circle'])
          }, null, 2),
          _cache[12] || (_cache[12] = _createTextVNode(" Networking "))
        ]),
        _createElementVNode("span", {
          class: "badge eventtype5 mt-1",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (toggleEventTypeFilter('exhibitor_events')))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass([!activeFilters.value.eventTypes.exhibitor_events ? 'fal fa-circle' : 'fas fa-check-circle'])
          }, null, 2),
          _cache[13] || (_cache[13] = _createTextVNode(" Exhibitors events "))
        ]),
        _cache[17] || (_cache[17] = _createElementVNode("h5", { class: "mt-3" }, "Date", -1)),
        _createElementVNode("span", {
          class: "badge day1 mt-1",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (toggleDayFilter('day1')))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass([!activeFilters.value.days.day1 ? 'fal fa-circle' : 'fas fa-check-circle'])
          }, null, 2),
          _cache[14] || (_cache[14] = _createTextVNode(" Monday, June 17 "))
        ]),
        _createElementVNode("span", {
          class: "badge day2 mt-1",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (toggleDayFilter('day2')))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass([!activeFilters.value.days.day2 ? 'fal fa-circle' : 'fas fa-check-circle'])
          }, null, 2),
          _cache[15] || (_cache[15] = _createTextVNode(" Tuesday, June 18 "))
        ])
      ])
    ]),
    (noDaysSelected.value || activeFilters.value.days.day1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[20] || (_cache[20] = _createElementVNode("h4", { class: "font-weight-bold pb-3" }, "MONDAY", -1)),
          _createElementVNode("div", _hoisted_7, [
            (agendaPointsDay1.value.length == 0 && _unref(route).name === 'Mediathek')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[18] || (_cache[18] = [
                  _createElementVNode("p", null, "No videos on demand available yet.", -1)
                ])))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(agendaPointsDay1.value, (ae, aeindex) => {
              return (_openBlock(), _createBlock(AgendaEntry, {
                key: `agp${aeindex}`,
                ae: ae
              }, null, 8, ["ae"]))
            }), 128)),
            (_unref(route).name === 'Calendar')
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _cache[19] || (_cache[19] = _createElementVNode("h5", { class: "pb-2 font-weight-bold" }, "Appointments", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(meetings1.value, (ae, aeindex) => {
                    return (_openBlock(), _createBlock(MeetingEntry, {
                      key: `agp_1${aeindex}`,
                      ae: ae
                    }, null, 8, ["ae"]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(meetings2.value, (ae, aeindex) => {
                    return (_openBlock(), _createBlock(MeetingEntry, {
                      key: `agp_2${aeindex}`,
                      ae: ae,
                      onCall: pickusercall
                    }, null, 8, ["ae"]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(meetings3.value, (ae, aeindex) => {
                    return (_openBlock(), _createBlock(MeetingEntry, {
                      key: `agp_3${aeindex}`,
                      ae: ae
                    }, null, 8, ["ae"]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(meetings4.value, (ae, aeindex) => {
                    return (_openBlock(), _createBlock(MeetingEntry, {
                      key: `agp_4${aeindex}`,
                      ae: ae
                    }, null, 8, ["ae"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (noDaysSelected.value || activeFilters.value.days.day2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _cache[23] || (_cache[23] = _createElementVNode("h4", { class: "font-weight-bold pb-3" }, "TUESDAY", -1)),
          _createElementVNode("div", _hoisted_11, [
            (agendaPointsDay2.value.length == 0 && _unref(route).name === 'Mediathek')
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[21] || (_cache[21] = [
                  _createElementVNode("p", null, "No videos on demand available yet.", -1)
                ])))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(agendaPointsDay2.value, (ae, aeindex) => {
              return (_openBlock(), _createBlock(AgendaEntry, {
                key: `agp${aeindex}`,
                ae: ae
              }, null, 8, ["ae"]))
            }), 128)),
            (_unref(route).name === 'Calendar')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _cache[22] || (_cache[22] = _createElementVNode("h5", { class: "pb-2 font-weight-bold" }, "Appointments", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(meetings1_2.value, (ae, aeindex) => {
                    return (_openBlock(), _createBlock(MeetingEntry, {
                      key: `agp_1_2${aeindex}`,
                      ae: ae
                    }, null, 8, ["ae"]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(meetings2_2.value, (ae, aeindex) => {
                    return (_openBlock(), _createBlock(MeetingEntry, {
                      key: `agp_2_2${aeindex}`,
                      ae: ae,
                      onCall: pickusercall
                    }, null, 8, ["ae"]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(meetings3_2.value, (ae, aeindex) => {
                    return (_openBlock(), _createBlock(MeetingEntry, {
                      key: `agp_3_2${aeindex}`,
                      ae: ae
                    }, null, 8, ["ae"]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(meetings4_2.value, (ae, aeindex) => {
                    return (_openBlock(), _createBlock(MeetingEntry, {
                      key: `agp_4_2${aeindex}`,
                      ae: ae
                    }, null, 8, ["ae"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})