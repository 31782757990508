<template>
  <div class="row mb-4">
    <div v-if="errorstring.de !== ''" class="alert alert-danger mb-2">
      {{ store.getters.i18n(errorstring) }}
    </div>

    <div class="col-md-7 col-sm-12">
      <div class="game bg-dark container d-inline-block ml-2 mr-1" @click="wrongClick">
        <div class="rounded text-center clicker"
             ref="clickerRef"
             @mouseover="escape"
             @click="points"
             type="button">
          <img src="/projects/dw/gmf/assets/images/reaction-logo.png" class="background img-competition" alt="Competition image">
        </div>
      </div>
    </div>

    <div class="col-md-5 col-sm-12">
      <p>My highest score: <b>{{ store.state.i.me.score_reaction }}</b></p>
      <p>
        Score: <b>{{ point }}</b><br>
        Level: <b>{{ level }}</b><br>
        Timer: <b>{{ sec }}</b>
      </p>

      <p v-if="btn1">
        <span class="btn btn-primary btn-lg" @click="StartCompetition">Start</span>
      </p>

      <p v-if="btn2">
        <span class="btn btn-primary btn-lg" @click="StartCompetition">Repeat</span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import type { TranslatedText } from "@/store/types"
import { AppNetworkErrorOther, networkErrorText } from "@/crud"
import _ from "lodash"

const store = useStore()
const clickerRef = ref<HTMLElement>()

const errorstring = ref<TranslatedText>({ de: '', en: '' })
const sec = ref(20)
const point = ref(0)
const level = ref(1)
const nextLevel = ref(5)
const click = ref(0)
const speedmoving = ref(280)
const speedrotate = ref(3000)
const rotation = ref(0)
const gamestart = ref(false)
const stopper = ref(0)
const d = ref(0)
const score_reaction = ref(0)
const btn1 = ref(true)
const btn2 = ref(false)

const StartCompetition = () => {
  if (!gamestart.value) {
    gamestart.value = true
    btn1.value = false
    btn2.value = false
    stopper.value = setInterval(chrono, 1000)
    d.value = setInterval(rotating, 10)
  }
}

const SaveData = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    const dpr = await store.dispatch("i/setDataCompetition", { score_reaction: score_reaction.value })
    if (!dpr.sv) {
      errorstring.value = {
        de: "Feld konnte nicht gesetzt werden.",
        en: "Could not set Field."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

const chrono = () => {
  if (sec.value > 0) {
    sec.value--
    if (sec.value % 2 === 0) {
      escape()
    }
  } else {
    clearInterval(stopper.value)
    clearInterval(d.value)
    gamestart.value = false
    if (clickerRef.value) {
      clickerRef.value.style.top = "0px"
      clickerRef.value.style.left = "0px"
      clickerRef.value.style.transform = "rotate(0deg)"
    }
    setDataToSave()
    resetGame()
  }
}

const resetGame = () => {
  btn1.value = false
  btn2.value = true
  sec.value = 20
  point.value = 0
  level.value = 1
  nextLevel.value = 5
  click.value = 0
  speedmoving.value = 280
  speedrotate.value = 3000
  rotation.value = 0
  gamestart.value = false
  stopper.value = 0
  d.value = 0
}

const setDataToSave = () => {
  store.dispatch('i/setDataRankingE', 5)
  if (point.value > score_reaction.value) {
    score_reaction.value = point.value
    SaveData()
  }
}

const points = () => {
  if (gamestart.value && sec.value !== 0) {
    click.value++
    point.value += (level.value * 6)
    nextLevel.value = 5 - (click.value % 5)

    if (click.value % 5 === 0 && click.value !== 0) {
      level.value++
      if (speedmoving.value > 30) speedmoving.value -= 30
      if (speedrotate.value > 300) speedrotate.value -= 300
    }
  }
}

const wrongClick = () => {
  if (gamestart.value && sec.value !== 0) {
    if (point.value > level.value) {
      point.value -= level.value
    }
  }
}

const escape = () => {
  if (gamestart.value) {
    setTimeout(moving, speedmoving.value)
  }
}

const moving = () => {
  if (clickerRef.value) {
    clickerRef.value.style.top = Math.random() * 255 + "px"
    clickerRef.value.style.left = Math.random() * 255 + "px"
  }
}

const rotating = () => {
  rotation.value += 3600/speedrotate.value
  if (clickerRef.value) {
    clickerRef.value.style.transform = `rotate(${rotation.value}deg)`
  }
}

onBeforeUnmount(() => {
  clearInterval(stopper.value)
  clearInterval(d.value)
})
</script>