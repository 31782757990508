import {TranslatedText} from "@/store/types";

const parseData = (data:any, mpdata:FormData, prefix:(string|number)[] = []) => {
    let varname = ""

    for (let di in data) {
        if (di === "sessionname" || di === "action") {
            continue
        }

        varname = ""
        if (typeof data[di] === "string" || typeof data[di] === "number") {
            for (let pi = 0; pi < prefix.length; pi++) {
                if (pi === 0) {
                    varname = prefix[pi] + ''
                } else {
                    varname += '[' + prefix[pi] + ']'
                }
            }

            if (prefix.length > 0) {
                varname += '[' + di + ']'
            } else {
                varname = di +''
            }

            mpdata.append(varname, decodeURI(String(data[di])))
        } else if (typeof data[di] === "object") {
            parseData(data[di], mpdata, [...prefix, di])
        }
    }
}

/*
const waitForCSRFProtector:Promise<boolean> = new Promise(async csrfPresent => {
    window.addEventListener("DOMContentLoaded", async () => {
        let attemptstoconnect = 0

        while (attemptstoconnect < 300 && (typeof (<any>window).CSRFP === "undefined" || (<any>window).CSRFP.CSRFP_TOKEN !== 'eveprotect')) {
            await new Promise((resolve) => {
                window.setTimeout(resolve, 10)
            })

            attemptstoconnect++
        }

        if (typeof (<any>window).CSRFP !== "undefined" && (<any>window).CSRFP.CSRFP_TOKEN === 'eveprotect') {
            csrfPresent(true)
        } else {
            csrfPresent(false)
        }
    }, false)
})
 */

export class AppNetworkErrorServer extends Error {
    constructor(message?: string) {
        super(message);
        this.name = "AppNetworkErrorServer";
    }
}

export class AppNetworkErrorOther extends Error {
    constructor(message?: string) {
        super(message);
        this.name = "AppNetworkErrorOther";
    }
}

export class AppNetworkErrorNotAllowed extends Error {
    constructor(message?: string) {
        super(message);
        this.name = "AppNetworkErrorNotAllowed";
    }
}

export class AppNetworkErrorNotFound extends Error {
    constructor(message?: string) {
        super(message);
        this.name = "AppNetworkErrorNotFound";
    }
}

export class AppNetworkErrorTokenNotSet extends Error {
    constructor(message?: string) {
        super(message);
        this.name = "AppNetworkErrorTokenNotSet";
    }
}

const rcrud = async (
    target: string,
    data: object|FormData = {}
): Promise<any> => {
    const mpdata = {body: (data instanceof FormData) ? data : new FormData()}

    const addheaders = {
        'X-App-Type': 'dw-gmf-event'
    }
    /*
    const hasCSRFProtector = await waitForCSRFProtector
    if (hasCSRFProtector && typeof (<any>window).CSRFP !== "undefined") {
        // @ts-ignore
        addheaders[(<any>window).CSRFP.CSRFP_TOKEN] = (<any>window).CSRFP._getAuthKey()
    }
     */
    
    let method_use = "POST"

    if (!(data instanceof FormData)) {
        if (Object.keys(data).length > 0) {
            parseData(data, mpdata.body)
        } else {
            method_use = "GET"
            // @ts-ignore
            delete mpdata.body
        }
    }

    const isPublicEndpoint = target.includes('/public/');

    if (typeof sessionStorage.getItem("jwt") === "string") {
        // @ts-ignore
        addheaders['Authorization'] = `Bearer ${sessionStorage.getItem("jwt")}`
    } else if (!isPublicEndpoint) {
        // Nur für nicht-öffentliche Endpunkte JWT prüfen
        console.warn(`No JWT found for request to ${target}`);
    }

    return fetch(target, {
        method: method_use,
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Accept": "application/json",
            ...addheaders
        },
        redirect: "follow",
        referrer: "no-referrer",
        ...mpdata
    })
        .then(async response => {
            const res2 = response.clone()
            const res = response.json()

            if (response.status === 404) {
                throw new AppNetworkErrorNotFound(`${target} not found`)
            }

            if (!response.ok && !response.redirected) {
                switch (response.status) {
                    case 502:
                        throw new AppNetworkErrorServer("Server unreachable")
                    case 403:
                        throw new AppNetworkErrorNotAllowed(`${target} not allowed`)
                    default:
                        throw new AppNetworkErrorOther(`${target} gave unexpected error "${response.status}: ${response.statusText}"`)
                }
            }

            (async (response) => {
                let resText = ''
                try {
                    resText = await response.text()
                } catch (e) {
                    console.error(e)
                }

                if ((/Invalid input\. Please verify your input\./).test(resText)) {
                    throw new Error('IDS Error.')
                }
            })(res2)
                .then(() => {})
                .catch((e) => {
                    alert(e.message)
                })

            return res
        });
}

const crud = (
    action: string,
    data: object|FormData = {}
): Promise<any> => rcrud(`/event-api${action}`, data)

let lasttoken: string = ''
let lasttokenage: Date = new Date()

export const getToken = async (): Promise<any> => {
    try {
        const nowtime = new Date();

        if (lasttoken !== '' && (lasttokenage.getTime() + 20000) > nowtime.getTime()) {
            return lasttoken
        }

        const res:any = await crud('getToken')
        if (res && res.hasOwnProperty('token')) {
            lasttoken = res.token
            lasttokenage = nowtime
            return res.token
        } else {
            throw new Error('Token not received')
        }
    } catch (e) {
        throw e
    }
}

export const getConfig = async (): Promise<any> => {
    try {
        const res:any = await crud('getConfig')
        if (res && res.hasOwnProperty('host')) {
            return res
        } else {
            throw new Error('Config not received')
        }
    } catch (e) {
        throw e
    }
}

export const networkErrorText = (e: AppNetworkErrorNotAllowed|AppNetworkErrorOther|AppNetworkErrorServer|AppNetworkErrorTokenNotSet|AppNetworkErrorNotFound): TranslatedText => {
    if (e instanceof AppNetworkErrorNotAllowed) {
        return {
            de: 'Sie sind nicht zum Aufruf dieser Funktion berechtigt.',
            en: 'You are not authorized to access this function.'
        }
    } else if (e instanceof AppNetworkErrorNotFound) {
        return {
            de: 'Die aufgerufene Funktion wurde nicht gefunden.',
            en: 'The function was not found.'
        }
    } else if (e instanceof AppNetworkErrorTokenNotSet) {
        return {
            de: 'Ihre Sitzung konnte nicht gestartet werden.',
            en: 'Session could not be established.'
        }
    } else {
        return {
            de: 'Die Übertragung schlug wegen eines Netzwerkproblems fehl. Bitte prüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
            en: 'Failed to send data due to a network error. Please check your network connection and try again.'
        }
    }
}

//export const keepAlive = (): Promise<any> => rcrud('/eve/scripts/small_keepalive/index.php')

export default crud
