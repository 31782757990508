<template>
  <div :class="{'chat-list':true}" ref="chatListRef">
    <VideoChatEntry
        :chattype="chattype"
        v-for="(msg, index) in msgs"
        :cE="msg"
        :key="'chat_entry' + index + '_' + msg.msgid"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, onBeforeUnmount, nextTick} from 'vue';
import VideoChatEntry from "@/components/i/VideoChatEntry.vue";
import type { Message } from "@/store/i/types";

interface Props {
  msgs: Message[];
  chattype: "chat" | "questions";
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'reachedtop'): void
}>();

const chatListRef = ref<HTMLElement | null>(null);
const correctScroll = ref(true);

const lookIfToHaltAutoscroll = () => {
  if (!chatListRef.value) return;

  const pos = chatListRef.value.scrollTop;
  if (pos < 1) {
    emit('reachedtop');
  }
  const desiredPos = chatListRef.value.scrollHeight - chatListRef.value.clientHeight;
  correctScroll.value = pos >= desiredPos - 20;
};

const scrollBottom = async () => {
  if (!chatListRef.value || !correctScroll.value) return;

  chatListRef.value.removeEventListener('scroll', lookIfToHaltAutoscroll);
  await nextTick();
  chatListRef.value.scrollTop = chatListRef.value.scrollHeight - chatListRef.value.clientHeight;
  await nextTick();
  chatListRef.value.addEventListener('scroll', lookIfToHaltAutoscroll, { passive: true });
};

// Watch msgs prop
watch(() => props.msgs, async () => {
  await scrollBottom();
});

onMounted(() => {
  scrollBottom();
  window.addEventListener('resize', scrollBottom, { passive: true });
  chatListRef.value?.addEventListener('scroll', lookIfToHaltAutoscroll, { passive: true });
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', scrollBottom);
  chatListRef.value?.removeEventListener('scroll', lookIfToHaltAutoscroll);
});
</script>

<style scoped lang="scss">
.chat-list {
  margin: 0;
}
</style>