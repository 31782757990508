import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row scroll-header" }
const _hoisted_2 = { class: "col mt-4" }
const _hoisted_3 = { style: {"font-size":"28px"} }

import { useStore } from 'vuex'
import BigChat from "@/components/i/BigChat.vue"
import VerticalNav from "@/components/VerticalNav.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatVerticalNav',
  setup(__props) {

const store = useStore()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(VerticalNav, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(store).getters.i18n({de: 'Chat', en: 'Chat'})), 1)
        ])
      ]),
      _createVNode(BigChat)
    ]),
    _: 1
  }))
}
}

})