import { initializeApp, FirebaseApp } from 'firebase/app'
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  signInWithCustomToken,
  signOut,
  UserCredential
} from 'firebase/auth'
import { getDatabase } from 'firebase/database'

let firebaseApp: FirebaseApp | null = null
let connectpromise: Promise<UserCredential> | null = null

export const initFirebase = (config: Object): FirebaseApp => {
  firebaseApp = initializeApp(config)
  return firebaseApp
}

export const connectFirebase = async (token: string): Promise<UserCredential> => {
  if (!firebaseApp) throw new Error('Firebase not initialized')
  const auth = getAuth(firebaseApp)
  await setPersistence(auth, browserSessionPersistence)
  connectpromise = signInWithCustomToken(auth, token)
  return connectpromise
}

export const getFireDB = async () => {
  if (!firebaseApp) throw new Error('Firebase not initialized')
  await connectpromise
  return getDatabase(firebaseApp)
}

export const logoutFirebase = () => {
  if (!firebaseApp) throw new Error('Firebase not initialized')
  return signOut(getAuth(firebaseApp))
}

export const objToFirebaseUID = (objvalueid: number, gsuffix = 'members') => `dw-gmf-${gsuffix}-${objvalueid}`