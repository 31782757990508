<template>
  <div class="box">
    <div class="mainform g-3 pb-4">
      <h3>Login</h3>

      <div v-show="errorstring.de" class="alert alert-danger mb-5">
        {{ store.getters.i18n(errorstring) }}
      </div>

      <div>
        <form @submit.prevent="handleLogin" class="form-horizontal">
          <div class="row">
            <div class="col-sm-12 position-relative formitem_parent">
              <label class="form-label" for="musername">E-mail address</label>
              <input
                  type="email"
                  required
                  autocomplete="off"
                  v-model.trim="formData.username"
                  id="musername"
                  class="form-control"
                  @focusin="clearError"
              />
            </div>

            <div class="col-sm-12 position-relative formitem_parent">
              <label class="form-label" for="mpassword">Password</label>
              <input
                  type="password"
                  required
                  autocomplete="off"
                  v-model.trim="formData.password"
                  id="mpassword"
                  class="form-control"
                  @focusin="clearError"
              />
            </div>
          </div>

          <div class="row mt-4 clearfix noprint">
            <div class="col d-flex justify-content-start flex-row-reverse">
              <button
                  class="btn btn-primary m-2"
                  :disabled="!isFormValid"
                  type="submit"
              >
                Login
              </button>
              <a
                  :href="forgotPasswordUrl"
                  target="_blank"
                  class="btn btn-primary m-2 prev lostpw"
              >
                Forgot password
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed, ref, watch, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { TranslatedText } from "@/store/types"
import { networkErrorText, type AppNetworkErrorOther } from "@/crud"
import rankingsMaxPoints from '@/ranking'
import {useGtm} from "@/composables/useGtm";

const store = useStore()
const router = useRouter()
const route = useRoute()

const formData = reactive({
  username: '',
  password: ''
})

const errorstring = ref<TranslatedText>({ de: '', en: '' })
const loginstart = ref<Date | null>(null)

const isFormValid = computed(() =>
    formData.username.length >= 2 && formData.password.length >= 5
)

const forgotPasswordUrl = '/eve/interfaces/modules/registration/index.php?mprefix=dw&pprefix=gmf&regname=webapp&label=lost_password'

const clearError = () => {
  errorstring.value = { de: '', en: '' }
}

const handleRanking = async (date: Date) => {
  if (date.toDateString() === 'Mon Jun 17 2024') {
    if (store.state.i.me.ranking_i < rankingsMaxPoints.ranking_i) {
      await store.dispatch('i/setDataRankingI', 3)
    }
  } else if (date.toDateString() === 'Tue Jun 18 2024') {
    if (store.state.i.me.ranking_j < rankingsMaxPoints.ranking_j) {
      await store.dispatch('i/setDataRankingJ', 5)
    }
  }
}

const handleLogin = async () => {
  try {
    clearError()
    const loginRes = await store.dispatch('login', {
      u: formData.username,
      p: formData.password
    })

    if (loginRes.login) {
      await handleRanking(new Date())

      router.replace({
        name: loginRes.show_onboarding ? 'Step1' : 'Welcome'
      })
    } else {
      errorstring.value = {
        de: 'Bitte überprüfen Sie Ihre Zugangsdaten',
        en: 'Please validate your input'
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

watch(() => route.name, () => {
  if (route.name === 'Login') {
    loginstart.value = null
  }
})

onBeforeMount(() => {
  const { trackPage } = useGtm()
  
  trackPage({
    pageCategory: 'Login',
    PageName: route.name?.toString() ?? 'unknown',
    PageType: 'Webapp',
  })
})
</script>