import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "banner",
  style: {"--banner-image-xl-url":"url('/files/landingpage/header_welcome_xl.png')","--banner-image-xs-url":"url('/files/landingpage/header_welcome_xs.png')"}
}
const _hoisted_2 = { class: "banner-text" }
const _hoisted_3 = {
  key: 0,
  class: "image-container"
}

import { computed } from 'vue'
import { useStore } from 'vuex'
import type { AgendaPoint } from "@/store/i/types"
import _ from "lodash"


export default /*@__PURE__*/_defineComponent({
  __name: 'Banner',
  setup(__props) {

const store = useStore()

const imageEntry = computed<AgendaPoint | undefined>(() => {
  return _.find(
      store.state.i.image,
      (ap: AgendaPoint) => ap.dbstate > -1 && ap.now_live
  )
})

const viewBox = computed<string>(() => {
  return imageEntry.value ? "0 0 1440 460" : "0 0 1440 432"
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, "Dear " + _toDisplayString(_unref(store).state.config.me.firstname) + " " + _toDisplayString(_unref(store).state.config.me.lastname) + ",", 1),
      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
      _cache[1] || (_cache[1] = _createElementVNode("span", null, "Thank you for your participation", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
      _cache[3] || (_cache[3] = _createElementVNode("span", null, "in this year's Global Media Forum.", -1))
    ]),
    (imageEntry.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: {name: 'Welcome'},
            class: "banner-image-entry"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createElementVNode("iframe", {
                src: "https://player.vimeo.com/video/1070977089?badge=0&autopause=0&player_id=0&app_id=58479",
                frameborder: "0",
                allow: "autoplay; fullscreen; picture-in-picture; clipboard-write",
                style: {"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},
                title: "GMF24_Trailer_UT"
              }, null, -1)
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})