import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "row g-3 mb-5" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-12" }
const _hoisted_5 = { class: "pb-2" }
const _hoisted_6 = {
  key: 1,
  class: "row"
}
const _hoisted_7 = { class: "col-lg-12" }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = {
  key: 3,
  class: "mt-0"
}
const _hoisted_10 = {
  key: 4,
  class: "row rowtabs"
}
const _hoisted_11 = { class: "col-md-6 col-lg-2 mb-1" }
const _hoisted_12 = { class: "col-md-6 col-lg-3 mb-1" }
const _hoisted_13 = { class: "col-md-6 col-lg-2 mb-1" }
const _hoisted_14 = { class: "col-md-6 col-lg-2 mb-1" }
const _hoisted_15 = { key: 5 }
const _hoisted_16 = {
  key: 0,
  class: "col-lg-12 headline cb-headlinenormalxl mb-3"
}
const _hoisted_17 = {
  key: 1,
  class: "col-lg-12 headline cb-headlinenormalxl mb-3"
}

import {computed, onBeforeUnmount, onMounted, ref, watch, defineComponent} from 'vue';
import {useStore} from 'vuex';
import {useRouter, useRoute, NavigationGuardNext, RouteLocationNormalized} from 'vue-router';
import _ from "lodash";
import JitsiCall from "@/components/jitsi/JitsiCall.vue";
import NetworkingUserContact from "@/components/i/NetworkingUserContact.vue";
import NetworkingUserConnection from "@/components/i/NetworkingUserConnection.vue";
import MeetingModal from "@/components/i/MeetingModal.vue";
import {TranslatedText} from "@/store/types";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import {ConnectionNetworking, ContactsNetworking} from "@/store/i/types";
import {useGtm} from "@/composables/useGtm";


export default /*@__PURE__*/_defineComponent({
  __name: 'Networking',
  setup(__props, { expose: __expose }) {

const store = useStore();
const router = useRouter();
const route = useRoute();

// State
const errorstring = ref<TranslatedText>({'de': '', 'en': ''});
const successtring = ref<TranslatedText>({'de': '', 'en': ''});
const number_members1 = ref<number>(20);
const number_members2 = ref<number>(20);
const searchtext2 = ref<string>('');
const searchtext3 = ref<string>('');
const connection = ref<number>(0);
const toggle_modal = ref<boolean>(false);

defineComponent({
  name: 'Networking',
  components: {
    JitsiCall,
    NetworkingUserConnection,
    NetworkingUserContact,
    MeetingModal
  }
});

// Navigation
const navigate_from_contacts = (cp: ContactsNetworking) => {
  router.push({name: 'ContactDetail', params: { contactid: `${cp.objvalueid}` }});
};

const navigate_from_connections = (cp: ConnectionNetworking) => {
  if (cp.receiver == store.state.config.me.objvalueid) {
    router.push({name: 'ContactDetail', params: { contactid: `${cp.sender}` }});
  } else {
    router.push({name: 'ContactDetail', params: { contactid: `${cp.receiver}` }});
  }
};

// Connection Methods
const reset_message = () => {
  errorstring.value = {de: '', en:''};
  successtring.value = {de: '', en:''};
};

const createConnection = async (c: ContactsNetworking) => {
  try {
    errorstring.value = {de: '', en:''};
    successtring.value = {de: '', en:''};
    const dpr = await store.dispatch("i/createConnection", c);
    setTimeout(() => { reset_message() }, 2000);
    if (dpr) {
      successtring.value = {
        de: "Contact request sent",
        en: "Contact request sent"
      };
    } else {
      errorstring.value = {
        de: "Contact request could not be created",
        en: "Contact request could not be created"
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

// Computed Properties
const ContactsNetworking = computed(() => {
  const arr: number[] = [];

  const sC = _.filter(
      store.getters['i/sortedConnectionsNetworking'],
      (c: ConnectionNetworking) => c.dbstate != -1
  );

  sC.forEach((ap: ConnectionNetworking) => {
    arr.push(ap.receiver);
    arr.push(ap.sender);
  });

  let filter = _.filter(
      store.getters['i/sortedContactsNetworking'],
      (c: ContactsNetworking) => c.accept_networking
  );

  const excludes = _(filter)
      .keyBy('objvalueid')
      .at(arr)
      .value();

  filter = _.differenceWith(filter, excludes, _.isEqual);

  if(!store.state.config.me.is_test){
    filter = _.filter(
        filter,
        (c: ContactsNetworking) => !c.is_test
    );
  }

  return filter;
});

const Matches = computed(() => {
  return _.orderBy(ContactsNetworking.value, ["match"], ["desc"]);
});

const MatchesFilter = computed(() => {
  return _.take(Matches.value, number_members1.value);
});

const showmore1 = () => {
  number_members1.value += 20;
};

// Lifecycle Hooks
onMounted(() => {
  handleListeners();

  const { trackPage } = useGtm()
  
  trackPage({
    pageCategory: 'Networking',
    PageName: route.name as string,
    PageType: 'Webapp',
  });
});

const handleListeners = async () => {
  if (store.state.i.me.accept_networking) {
    await store.dispatch("i/changeListenersContactsNetworking", true);
  } else {
    await store.dispatch("i/changeListenersContactsNetworking", false);
  }
};

onBeforeUnmount(() => {
  store.dispatch("i/changeListenersContactsNetworking", false);
});

// Watch
watch(
    () => store.state.i.connected,
    () => handleListeners()
);

watch(
    () => store.state.i.me.accept_networking,
    () => handleListeners()
);

// Navigation Guard
const navguard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
  if (store.state.members.currentCall && !store.getters['i/forcedToPlenum'] && !store.getters['i/redirectToPlenum']) {
    const confirmres = window.confirm(store.getters.i18n({
      de: "If you are leaving the booth now, your call will be ended.",
      en: "If you are leaving the booth now, your call will be ended."
    }));
    if (confirmres) {
      next();
    } else {
      next(false);
    }
  } else {
    next();
  }
};


// Chat and Call Functions
const pickuserchat = (cp: ConnectionNetworking) => {
  const uc = _.find(
      store.state.i.contacts_networking,
      (c) => c.fbuid === cp.fbuid
  );
  if (uc) {
    store.commit('OpenSideNav', 'chat');
    store.commit('i/setChatboxContact', uc);
  }
};

const pickusercall = async (cp: ConnectionNetworking) => {
  if (cp.fbuid !== store.state.config.fbuid) {
    if (store.state.members.currentCall) {
      store.commit("i/addToast", {
        header: {de: "Fehler", en: "Error"},
        msg: {
          de: 'Sie sind bereits im Gespräch.',
          en: 'You are already in a call.'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        showfor: 10000,
        alert_type: 'error'
      });
    } else {
      const contactData = {
        fbuid: cp.fbuid,
        objvalueid: cp.receiver == store.state.config.me.objvalueid ? cp.sender : cp.receiver,
        firstname: cp.firstname,
        lastname: cp.lastname,
        orgname: cp.orgname
      };

      store.commit("members/setContact", contactData);
      await store.dispatch("members/startCall", contactData.objvalueid);

      store.commit("i/addToast", {
        header: {de: "Hinweis", en: "Warning"},
        msg: {
          de: 'Bitte haben Sie einen Moment Geduld...',
          en: 'Please wait a moment...'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        showfor: 10000,
        alert_type: 'error'
      });
    }
  }
};

// Weitere computed properties
const Members = computed(() => {
  let filter = ContactsNetworking.value;

  if (filter.length > 0 && searchtext2.value.length > 0) {
    const swords = _.map(
        _.words(searchtext2.value),
        s => new RegExp(_.escapeRegExp(s),'iu')
    );
    return _.filter(
        filter,
        c => {
          for (let i = swords.length -1; i >= 0; i--) {
            if (!swords[i].test(c.firstname + ' ' + c.lastname)) {
              return false;
            }
          }
          return true;
        }
    );
  }
  return filter;
});

const MembersFilter = computed(() => {
  return _.take(Members.value, number_members2.value);
});

const Contacts = computed(() => {
  let filter = _.filter(
      store.getters['i/sortedConnectionsNetworking'],
      (c: ConnectionNetworking) => c.dbstate != -1 && c.status == 2
  );

  if (filter.length > 0 && searchtext3.value.length > 0) {
    const swords = _.map(
        _.words(searchtext3.value),
        s => new RegExp(_.escapeRegExp(s),'iu')
    );
    return _.filter(
        filter,
        c => {
          for (let i = swords.length -1; i >= 0; i--) {
            if (!swords[i].test(c.firstname + ' ' + c.lastname)) {
              return false;
            }
          }
          return true;
        }
    );
  }
  return filter;
});

const Received = computed(() => {
  return _.filter(
      store.getters['i/sortedConnectionsNetworking'],
      (c: ConnectionNetworking) =>
          c.dbstate != -1 &&
          c.status == 1 &&
          c.receiver == store.state.config.me.objvalueid
  );
});

const Send = computed(() => {
  return _.filter(
      store.getters['i/sortedConnectionsNetworking'],
      (c: ConnectionNetworking) =>
          c.dbstate != -1 &&
          c.status == 1 &&
          c.sender == store.state.config.me.objvalueid
  );
});

// Fehlende Methoden
const showmore2 = () => {
  number_members2.value += 20;
};

const removeConnection = async (c: ConnectionNetworking) => {
  try {
    errorstring.value = {de: '', en:''};
    successtring.value = {de: '', en:''};
    const dpr = await store.dispatch("i/removeConnection", c);
    setTimeout(() => { reset_message() }, 2000);
    if (dpr) {
      successtring.value = {
        de: "Contact removed",
        en: "Contact removed"
      };
    } else {
      errorstring.value = {
        de: "Could not remove contact.",
        en: "Could not remove contact."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

const acceptConnection = async (c: ConnectionNetworking) => {
  try {
    errorstring.value = {de: '', en:''};
    successtring.value = {de: '', en:''};
    const dpr = await store.dispatch("i/acceptConnection", c);
    setTimeout(() => { reset_message() }, 2000);
    if (dpr) {
      successtring.value = {
        de: "Contact request accepted",
        en: "Contact request accepted"
      };
    } else {
      errorstring.value = {
        de: "Could not accept contact.",
        en: "Could not accept contact."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

const declineConnection = async (c: ConnectionNetworking) => {
  try {
    errorstring.value = {de: '', en:''};
    successtring.value = {de: '', en:''};
    const dpr = await store.dispatch("i/declineConnection", c);
    setTimeout(() => { reset_message() }, 2000);
    if (dpr) {
      successtring.value = {
        de: "Contact request declined",
        en: "Contact request declined"
      };
    } else {
      errorstring.value = {
        de: "Could not decline contact.",
        en: "Could not decline contact."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

const blockConnection = async (c: ConnectionNetworking) => {
  try {
    errorstring.value = {de: '', en:''};
    successtring.value = {de: '', en:''};
    const dpr = await store.dispatch("i/blockConnection", c);
    setTimeout(() => { reset_message() }, 2000);
    if (dpr) {
      successtring.value = {
        de: "Contact request blocked",
        en: "Contact request blocked"
      };
    } else {
      errorstring.value = {
        de: "Could not block contact.",
        en: "Could not block contact."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

// Meeting Function
const meeting = (cp: ConnectionNetworking) => {
  toggle_modal.value = !toggle_modal.value;
  connection.value = cp.receiver == store.state.config.me.objvalueid ? cp.sender : cp.receiver;
};

// Expose necessary methods and properties
__expose({
  errorstring,
  successtring,
  createConnection,
  removeConnection,
  acceptConnection,
  declineConnection,
  blockConnection,
  navigate_from_contacts,
  navigate_from_connections,
  pickuserchat,
  pickusercall,
  meeting,
  navguard
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[15] || (_cache[15] = _createElementVNode("h3", null, "Networking", -1)),
      _createVNode(MeetingModal, {
        connection: connection.value,
        toggle_modal: toggle_modal.value
      }, null, 8, ["connection", "toggle_modal"]),
      (_unref(store).state.members.currentCall)
        ? (_openBlock(), _createBlock(JitsiCall, {
            key: 0,
            type: "member"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createElementVNode("p", null, [
            _createElementVNode("b", null, "Get together for a virtual coffee with other conference attendees who are online right now! This is an open group chat.")
          ], -1)),
          _cache[4] || (_cache[4] = _createElementVNode("p", null, [
            _createTextVNode(" Take part in our reaction game and collect points to increase your online activity score!"),
            _createElementVNode("br"),
            _createTextVNode(" The highest online activity scores win a ticket to #GMF25. ")
          ], -1)),
          _createElementVNode("p", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: {name: 'Coffeebar'},
              class: "btn btn-danger col-lg-3"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("i", { class: "fa-light fa-mug-hot" }, null, -1),
                _createTextVNode(" Coffee bar ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      (!_unref(store).state.i.me.accept_networking)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[6] || (_cache[6] = _createElementVNode("p", null, [
                _createElementVNode("b", null, " Complete your networking profile to connect with media professionals from around the globe in just a couple of minutes. Good to have you with us this #GMF24! ")
              ], -1)),
              _createElementVNode("p", null, [
                _createVNode(_component_router_link, {
                  to: {name: 'Profile'},
                  class: "btn btn-primary col-lg-3 fw-normal"
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" To my profile ")
                  ])),
                  _: 1
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(store).state.i.me.accept_networking)
        ? (_openBlock(), _createElementBlock("p", _hoisted_8, _cache[7] || (_cache[7] = [
            _createElementVNode("b", null, "Connect with more than 1,200 media professionals from around the globe!", -1)
          ])))
        : _createCommentVNode("", true),
      (_unref(store).state.i.me.accept_networking)
        ? (_openBlock(), _createElementBlock("p", _hoisted_9, " How? Simply create your own profile and use the matchmaking function to add new contacts based on the areas of interest you specify in your profile. You will automatically be shown contacts that match your own interests. You can also connect directly with other participants using the chat function. So chat away, schedule video calls or arrange to meet up in Bonn in person on June 17 or 18. We hope you have some memorable conversations! "))
        : _createCommentVNode("", true),
      (_unref(store).state.i.me.accept_networking)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_router_link, {
                to: {name: 'Networking1'},
                class: _normalizeClass({'btn highlight_tabs': true,'btn-active': _unref(route).meta.tab == 1})
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" My matches ")
                ])),
                _: 1
              }, 8, ["class"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_router_link, {
                to: {name: 'Networking2'},
                class: _normalizeClass({'btn highlight_tabs': true,'btn-active': _unref(route).meta.tab == 2})
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode(" Participant search ")
                ])),
                _: 1
              }, 8, ["class"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_router_link, {
                to: {name: 'Networking3'},
                class: _normalizeClass({'btn highlight_tabs': true, 'btn-active': _unref(route).meta.tab == 3})
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode(" My contacts ")
                ])),
                _: 1
              }, 8, ["class"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_router_link, {
                to: {name: 'Networking4'},
                class: _normalizeClass({'btn highlight_tabs': true, 'btn-active': _unref(route).meta.tab == 4})
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode(" Contact requests ")
                ])),
                _: 1
              }, 8, ["class"])
            ])
          ]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", { class: "alert alert-success col-lg-12" }, _toDisplayString(_unref(store).getters.i18n(successtring.value)), 513), [
        [_vShow, successtring.value.de !== '']
      ]),
      _withDirectives(_createElementVNode("div", { class: "alert alert-danger col-lg-12" }, _toDisplayString(_unref(store).getters.i18n(errorstring.value)), 513), [
        [_vShow, errorstring.value.de !== '']
      ]),
      (_unref(store).state.i.me.accept_networking)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _withDirectives(_createElementVNode("div", null, [
              _cache[12] || (_cache[12] = _createElementVNode("span", { class: "col-lg-12 headline cb-headlinenormalxl mb-3" }, "My matches", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(MatchesFilter.value, (u, index) => {
                return (_openBlock(), _createBlock(NetworkingUserContact, {
                  key: 'NetworkingUserContact1_' + index,
                  user: u,
                  contact_type: "matches",
                  onNavigate: navigate_from_contacts,
                  onCreate: createConnection
                }, null, 8, ["user"]))
              }), 128)),
              (Matches.value.length > number_members1.value)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "Text pointer",
                    onClick: showmore1
                  }, "Load more..."))
                : _createCommentVNode("", true)
            ], 512), [
              [_vShow, _unref(route).meta.tab === 1]
            ]),
            _withDirectives(_createElementVNode("div", null, [
              _cache[13] || (_cache[13] = _createElementVNode("span", { class: "col-lg-12 headline cb-headlinenormalxl mb-3" }, "Participant search", -1)),
              _createElementVNode("p", null, [
                _withDirectives(_createElementVNode("input", {
                  placeholder: "Search...",
                  type: "search",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchtext2).value = $event)),
                  class: "form-control"
                }, null, 512), [
                  [
                    _vModelText,
                    searchtext2.value,
                    void 0,
                    { trim: true }
                  ]
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(MembersFilter.value, (u, index) => {
                return (_openBlock(), _createBlock(NetworkingUserContact, {
                  key: 'NetworkingUserContact2_' + index,
                  user: u,
                  contact_type: "members",
                  onNavigate: navigate_from_contacts,
                  onCreate: createConnection
                }, null, 8, ["user"]))
              }), 128)),
              (Members.value.length > number_members2.value)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "Text pointer",
                    onClick: showmore2
                  }, "Load more..."))
                : _createCommentVNode("", true)
            ], 512), [
              [_vShow, _unref(route).meta.tab === 2]
            ]),
            _withDirectives(_createElementVNode("div", null, [
              _cache[14] || (_cache[14] = _createElementVNode("span", { class: "col-lg-12 headline cb-headlinenormalxl mb-3" }, "My contacts", -1)),
              _createElementVNode("p", null, [
                _withDirectives(_createElementVNode("input", {
                  placeholder: "Search...",
                  type: "search",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchtext3).value = $event)),
                  class: "form-control"
                }, null, 512), [
                  [
                    _vModelText,
                    searchtext3.value,
                    void 0,
                    { trim: true }
                  ]
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Contacts.value, (u, index) => {
                return (_openBlock(), _createBlock(NetworkingUserConnection, {
                  key: 'NetworkingUserConnection1_' + index,
                  user: u,
                  contact_type: "contact",
                  onNavigate: navigate_from_connections,
                  onRemove: removeConnection,
                  onChat: pickuserchat,
                  onCall: pickusercall,
                  onMeeting: meeting
                }, null, 8, ["user"]))
              }), 128))
            ], 512), [
              [_vShow, _unref(route).meta.tab === 3]
            ]),
            _withDirectives(_createElementVNode("div", null, [
              (Received.value.length > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_16, "Requests received"))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Received.value, (u, index) => {
                return (_openBlock(), _createBlock(NetworkingUserConnection, {
                  key: 'NetworkingUserConnection2_' + index,
                  user: u,
                  contact_type: "received",
                  onNavigate: navigate_from_connections,
                  onAccept: acceptConnection,
                  onDecline: declineConnection,
                  onBlock: blockConnection
                }, null, 8, ["user"]))
              }), 128)),
              (Send.value.length > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_17, "Requests sent"))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Send.value, (u, index) => {
                return (_openBlock(), _createBlock(NetworkingUserConnection, {
                  key: 'NetworkingUserConnection3_' + index,
                  user: u,
                  contact_type: "send",
                  onNavigate: navigate_from_connections
                }, null, 8, ["user"]))
              }), 128))
            ], 512), [
              [_vShow, _unref(route).meta.tab === 4]
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})