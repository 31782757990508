import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card mb-3" }
const _hoisted_2 = {
  class: "text-muted",
  style: {"font-size":"1rem"}
}
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass({'card-body card-body-expanded': true})
}
const _hoisted_4 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "card-title" }
const _hoisted_7 = { class: "card-footer" }

import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import { AppNetworkErrorOther, networkErrorText } from "@/crud"
import { TranslatedText } from "@/store/types"
import { Meetings } from "@/store/i/types"


export default /*@__PURE__*/_defineComponent({
  __name: 'MeetingEntry',
  props: {
    ae: {},
    agendatype: {}
  },
  emits: ['call'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit
const store = useStore()
const router = useRouter()
const errorstring = ref<TranslatedText>({ de: '', en: '' })

const formatedDate = computed(() => {
  return DateTime.fromISO(props.ae.startdate, {locale: 'en-us'}).weekdayLong +
      ', ' +
      DateTime.fromISO(props.ae.startdate).toFormat('h:mm a') +
      " - " +
      DateTime.fromISO(props.ae.enddate).toFormat('h:mm a')
})

const call = () => {
  if (props.ae) {
    emit('call', props.ae)
  }
}

const navigateMeeting = () => {
  router.push({
    name: 'MeetingDetail',
    params: { meetingid: `${props.ae.objvalueid}` }
  })
}

const navigateMember = () => {
  const contactid = props.ae.receiver === store.state.config.me.objvalueid
      ? props.ae.sender
      : props.ae.receiver
  router.push({
    name: 'ContactDetail',
    params: { contactid: `${contactid}` }
  })
}

const reset_message = () => {
  errorstring.value = { de: '', en: '' }
}

const removeMeeting = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    const dpr = await store.dispatch("i/removeMeeting", props.ae)
    setTimeout(reset_message, 2000)
    if (!dpr) {
      errorstring.value = {
        de: "Could not remove Appointment.",
        en: "Could not remove Appointment."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

const acceptMeeting = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    const dpr = await store.dispatch("i/acceptMeeting", props.ae)
    setTimeout(reset_message, 2000)
    if (!dpr) {
      errorstring.value = {
        de: "Could not accept Appointment.",
        en: "Could not accept Appointment."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

const declineMeeting = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    const dpr = await store.dispatch("i/declineMeeting", props.ae)
    setTimeout(reset_message, 2000)
    if (!dpr) {
      errorstring.value = {
        de: "Could not decline Appointment.",
        en: "Could not decline Appointment."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["col-lg-9 col-md-12", {'col-md-6 col-lg-4': props.agendatype =='entry'}])
  }, [
    _withDirectives(_createElementVNode("div", { class: "alert alert-danger mb-5" }, _toDisplayString(_unref(store).getters.i18n(errorstring.value)), 513), [
      [_vShow, errorstring.value.de !== '']
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass({'card-header': true, 'meeting-accepted': props.ae.status == 2 && props.agendatype !='entry', 'meeting-declined': props.ae.status == 3 && props.agendatype !='entry', 'meeting-canceled': props.ae.status == 4 && props.agendatype !='entry'})
      }, [
        _createElementVNode("small", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fal fa-calendar-alt" }, null, -1)),
          _createTextVNode(" " + _toDisplayString(formatedDate.value) + " CEST", 1)
        ])
      ], 2),
      _createElementVNode("div", _hoisted_3, [
        (props.agendatype !='entry')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (props.ae.status != 2 && props.ae.status != 4 && props.ae.receiver == _unref(store).state.config.me.objvalueid)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn btn-primary me-2",
                    onClick: _withModifiers(acceptMeeting, ["prevent"])
                  }, "Accept"))
                : _createCommentVNode("", true),
              (props.ae.status != 3 && props.ae.status != 4 && props.ae.receiver == _unref(store).state.config.me.objvalueid)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "btn btn-primary",
                    onClick: _withModifiers(declineMeeting, ["prevent"])
                  }, "Decline"))
                : _createCommentVNode("", true),
              (props.ae.status != 4 && props.ae.sender == _unref(store).state.config.me.objvalueid)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    class: "btn btn-primary",
                    onClick: _withModifiers(removeMeeting, ["prevent"])
                  }, "Cancel"))
                : _createCommentVNode("", true),
              (props.ae.status == 2)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 3,
                    class: "btn btn-primary m-1 float-end",
                    onClick: _withModifiers(call, ["prevent"])
                  }, "Call"))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("a", {
          class: "pointer",
          onClick: navigateMeeting
        }, [
          _createElementVNode("p", {
            class: "card-title bold",
            innerHTML: props.ae.meeting_title
          }, null, 8, _hoisted_5),
          _createElementVNode("p", _hoisted_6, _toDisplayString(props.ae.long_summary), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("small", {
          class: "text-muted pointer",
          onClick: navigateMember
        }, [
          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fal fa-user" }, null, -1)),
          _createTextVNode(" " + _toDisplayString(props.ae.firstname) + " " + _toDisplayString(props.ae.lastname), 1)
        ])
      ])
    ])
  ], 2))
}
}

})