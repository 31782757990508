<template>
  <div class="col-sm-6 col-md-3 linked-partner-logo pointer mb-2">
    <div class="card" @click="navigatepartner">
      <img
          :src="`/files/exhibitor/${props.partner.objvalueid}/logo.jpg?nocache=${(new Date()).getHours()}`"
          class=""
          onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';"
          style="width:100%;height:100%;"
          alt=""
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { Partner } from "@/store/i/types"

const props = defineProps<{
  partner: Partner
}>()

const router = useRouter()

const navigatepartner = () => {
  router.push({
    name: 'PartnerDetail',
    params: { partnerid: `${props.partner.objvalueid}` }
  })
}
</script>