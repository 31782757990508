import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import ToastAlert from "@/components/i/ToastAlert.vue"
import { InteractionAlert } from "@/store/i/types"


// Store

export default /*@__PURE__*/_defineComponent({
  __name: 'ToastDisplay',
  setup(__props) {

const store = useStore()

// Reactive state
const originalParent = ref<HTMLElement | null>(null)
const ias = ref<InteractionAlert[]>([])

// Computed
const unhiddenToasts = computed(() => store.getters['i/unhiddenToasts'])

// Methods
const dismissed = (ia: InteractionAlert) => {
  store.commit('i/removeToast', ia)
}

// Watchers
watch(unhiddenToasts, (newValue) => {
  if (newValue.length === 0) {
    ias.value = []
  } else {
    newValue.forEach((uht: InteractionAlert) => {
      if (!ias.value.some((ia: InteractionAlert) => ia === uht)) {
        ias.value.push(uht)
      }
    })
  }
})

watch(() => store.state.i.videoFullscreen, async (newValue) => {
  const playertoattach = document.querySelector(".video-js")
  if (!playertoattach) return

  const el = document.getElementById('toastswrapper')
  if (!el) return

  if (newValue) {
    originalParent.value = el.parentElement
    originalParent.value?.removeChild(el)
    playertoattach.append(el)
  } else {
    playertoattach.removeChild(el)
    originalParent.value?.append(el)
  }
})

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    id: "toastswrapper",
    class: _normalizeClass({ 'opened': ias.value.length > 0 })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ias.value, (t, index) => {
      return (_openBlock(), _createBlock(ToastAlert, {
        t: t,
        inline: false,
        key: 'tw' + index,
        onDismissed: dismissed
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(store).getters.i18n(t.msg)), 1)
        ]),
        _: 2
      }, 1032, ["t"]))
    }), 128))
  ], 2)), [
    [_vShow, ias.value.length > 0]
  ])
}
}

})