import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "box-header w-100 g-3 row" }
const _hoisted_2 = { class: "col-md-12 col-xl-4 text-center" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "box" }
const _hoisted_5 = { class: "row g-3 ms-2 mt-2" }
const _hoisted_6 = { class: "col-auto no-gutters" }
const _hoisted_7 = { class: "circular_image" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "Name-Nachname" }
const _hoisted_11 = {
  key: 0,
  class: "Role copydetailxs mb-2 bold"
}
const _hoisted_12 = {
  key: 1,
  class: "Role copydetailxs"
}
const _hoisted_13 = { class: "Text" }
const _hoisted_14 = { class: "box2" }
const _hoisted_15 = { class: "row g-3 mt-0" }
const _hoisted_16 = ["src", "alt"]
const _hoisted_17 = { class: "Lorem-ipsum-dolor-in headlineonbrightxs mt-2" }
const _hoisted_18 = { class: "Rectangle" }
const _hoisted_19 = { class: "row g-3" }
const _hoisted_20 = { class: "col-md-6 col-lg-6" }
const _hoisted_21 = { class: "col-12 white" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = { class: "Text white mt-2" }
const _hoisted_25 = {
  key: 0,
  class: "box"
}
const _hoisted_26 = { class: "row g-3 mt-0" }
const _hoisted_27 = { class: "col-12 col-lg-6" }
const _hoisted_28 = { class: "mb-0" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = ["innerHTML"]

import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import _ from "lodash"
import type { Speaker, Ticket } from "@/store/landingpage/types"

interface ReasonsToJoin {
  imageurl: string,
  text: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LandingPage',
  setup(__props) {

const store = useStore()
const router = useRouter()

const navigateAgenda = (agendaid: number) => {
  router.push({
    name: 'AgendaDetail',
    params: { agendaid: agendaid.toString() }
  })
}

const validTickets = computed((): Ticket[] => {
  const filter = _.filter(
      store.getters["landingpage/tickets"],
      (e: Ticket) => !e.isticketcode && !e.isPress
  )

  return _.orderBy(filter,
      [ticket => ticket.sortPos],
      ["asc"]
  )
})

const isLoginAvailable = computed((): boolean => {
  return store.getters["landingpage/loginAvailable"]
})

const highlightedSpeakers = computed(() => {
  return store.getters["landingpage/highlightedSpeakers"]
})

const reasonsToJoin: ReasonsToJoin[] = [
  {
    imageurl: '/files/landingpage/whyattend_1.png',
    text: 'New Setting: Three iconic locations in the heart of Bonn',
  },
  {
    imageurl: '/files/landingpage/whyattend_2.png',
    text: 'Full access ticket includes dinner cruise on the Rhine and more',
  },
  {
    imageurl: '/files/landingpage/whyattend_3.png',
    text: 'Network with media professionals',
  },
  {
    imageurl: '/files/landingpage/whyattend_4.png',
    text: 'Hands-on workshop'
  }
]

const programHighlights = computed(() => {
  return store.getters["landingpage/programHighlights"]
})

const shopsAvailable = computed(() => {
  return store.getters["landingpage/shopsAvailable"]
})

const getSpeakerName = (speaker: Speaker): string => {
  let prepend = ''
  if (speaker.title) {
    prepend = speaker.title + ' '
  }
  return `${prepend}${speaker.firstname} ${speaker.lastname}`
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[12] || (_cache[12] = _createElementVNode("div", {
      class: "banner Mask",
      style: {"background-size":"cover","--header-xs-url":"url('/files/landingpage/header_xs.png')","--header-xl-url":"url('/files/landingpage/header_xl.png')"}
    }, [
      _createElementVNode("iframe", {
        class: "video float-end pr-lg-3 d-none d-lg-block",
        src: "https://player.vimeo.com/video/1070977089?badge=0&autopause=0&player_id=0&app_id=58479",
        width: "640",
        height: "360",
        allow: "autoplay; fullscreen; picture-in-picture",
        allowfullscreen: "",
        title: ""
      })
    ], -1)),
    _cache[13] || (_cache[13] = _createElementVNode("iframe", {
      class: "w-100 pt-3 pr-lg-3 d-block d-lg-none",
      src: "https://player.vimeo.com/video/1070977089?badge=0&autopause=0&player_id=0&app_id=58479",
      width: "640",
      height: "360",
      allow: "autoplay; fullscreen; picture-in-picture",
      allowfullscreen: "",
      title: ""
    }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (isLoginAvailable.value)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: {name: 'Login'},
              class: "col-12 btn btn-primary buttonsprimarytextbutton-text d-flex align-items-center justify-content-center",
              role: "button",
              "aria-label": "Login"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Login ")
              ])),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
              _createElementVNode("span", { class: "headline_light" }, "Join the conversation", -1),
              _createElementVNode("br", null, null, -1),
              _createElementVNode("h2", {
                class: "pt-3",
                style: {"color":"var(--headline-light-color)"}
              }, "#GMF24", -1)
            ])))
      ]),
      _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"col-md-12 col-xl-4 text-center social\" data-v-d0e41f3e><h2 class=\"headline_light\" data-v-d0e41f3e>Share on</h2><a href=\"https://twitter.com/DW_GMF\" target=\"_blank\" data-v-d0e41f3e><i class=\"fab fa-2x fa-x-twitter\" data-v-d0e41f3e></i><span class=\"sr-only\" data-v-d0e41f3e>X (Twitter)</span></a><a href=\"https://www.linkedin.com/showcase/5088224/\" target=\"_blank\" data-v-d0e41f3e><i class=\"fab fa-2x fa-linkedin-in\" data-v-d0e41f3e></i><span class=\"sr-only\" data-v-d0e41f3e>LinkedIn</span></a><a href=\"https://www.facebook.com/dw.gmf\" target=\"_blank\" data-v-d0e41f3e><i class=\"fab fa-2x fa-facebook-f\" data-v-d0e41f3e></i><span class=\"sr-only\" data-v-d0e41f3e>Facebook</span></a></div><div class=\"col-md-12 col-xl-4 text-center\" data-v-d0e41f3e><span class=\"headline_light text-center\" data-v-d0e41f3e>Get in touch with</span><div class=\"row intouch\" data-v-d0e41f3e><div class=\"col-4 text-center\" data-v-d0e41f3e><b data-v-d0e41f3e>900+</b><span class=\"intouch_small text-center\" data-v-d0e41f3e>Participants</span></div><div class=\"col-4 text-center\" data-v-d0e41f3e><b data-v-d0e41f3e>160+</b><span class=\"intouch_small text-center\" data-v-d0e41f3e>Speakers</span></div><div class=\"col-4 text-center\" data-v-d0e41f3e><b data-v-d0e41f3e>100+</b><span class=\"intouch_small text-center\" data-v-d0e41f3e>Countries</span></div></div></div>", 2))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[5] || (_cache[5] = _createElementVNode("span", {
        class: "headline cb-headlinenormalxl g-3",
        id: "speakers"
      }, "Speakers", -1)),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(highlightedSpeakers.value, (speaker, ei) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            to: { name: 'SpeakerDetail', params: { speakerid: speaker.objvalueid  } },
            key: `omember${ei}`,
            title: getSpeakerName(speaker),
            class: "col-xl-4 col-md-6 col-12 mb-5 row"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("img", {
                    src: `/files/members/${ speaker.objvalueid }/foto.jpg`,
                    alt: `${speaker.firstname} ${speaker.lastname}`
                  }, null, 8, _hoisted_8)
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(getSpeakerName(speaker)), 1),
                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                (speaker.orgname === '' && speaker.position)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(speaker.position), 1))
                  : _createCommentVNode("", true),
                (speaker.orgname)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(speaker.orgname), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["to", "title"]))
        }), 128)),
        _createElementVNode("span", _hoisted_13, [
          _createVNode(_component_router_link, { to: {name: 'SpeakerOverview'} }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("See all speakers "),
              _createElementVNode("i", { class: "fal fa-chevron-right" }, null, -1)
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "headline cb-headlinenormalxl g-3" }, "Even more reasons to join", -1)),
      _createElementVNode("div", _hoisted_15, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(reasonsToJoin, (reason, reasonIndex) => {
          return _createElementVNode("div", {
            class: "col-md-6 col-lg-3 g-2",
            key: `reason${reasonIndex}`
          }, [
            _createElementVNode("img", {
              src: reason.imageurl,
              class: "img-fluid col-12",
              alt: reason.text
            }, null, 8, _hoisted_16),
            _createElementVNode("h2", _hoisted_17, _toDisplayString(reason.text), 1)
          ])
        }), 64))
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _cache[9] || (_cache[9] = _createElementVNode("span", {
        class: "headline g-3",
        id: "highlights"
      }, "Program Highlights", -1)),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(programHighlights.value, (agenda, agendaIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["row agenda_item mb-4", {'highlight': agendaIndex === 0}])
            }, [
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_router_link, {
                  to: { name: 'AgendaDetail', params: { agendaid: agenda.objvalueid } }
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("b", {
                      innerHTML: agenda.title
                    }, null, 8, _hoisted_22),
                    (agenda.subtitle)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 0,
                          innerHTML: agenda.subtitle
                        }, null, 8, _hoisted_23))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ])
            ], 2))
          }), 256))
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-md-6 col-lg-6" }, [
          _createElementVNode("img", {
            src: "/files/landingpage/highlight_xl.png",
            class: "img-fluid",
            alt: "Highlights"
          })
        ], -1)),
        _createElementVNode("span", _hoisted_24, [
          _createVNode(_component_router_link, { to: {name: 'AgendaOverview'} }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("See full program "),
              _createElementVNode("i", { class: "fal fa-chevron-right" }, null, -1)
            ])),
            _: 1
          })
        ])
      ])
    ]),
    (shopsAvailable.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          _cache[11] || (_cache[11] = _createElementVNode("span", { class: "headline cb-headlinenormalxl g-3" }, "Tickets", -1)),
          _createElementVNode("div", _hoisted_26, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(validTickets.value, (ticket, ticketIndex) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_27, [
                _createElementVNode("h2", _hoisted_28, [
                  _createTextVNode(_toDisplayString(ticket.label) + " - ", 1),
                  (ticket.isEarlyBirdPrice)
                    ? (_openBlock(), _createElementBlock("s", _hoisted_29, "€ " + _toDisplayString(Math.round(ticket.priceInCents * 1.19) / 100), 1))
                    : _createCommentVNode("", true),
                  _createTextVNode(" only € " + _toDisplayString(Math.round(ticket.currentPriceInCents * 1.19) / 100), 1)
                ]),
                (ticket.isEarlyBirdPrice)
                  ? (_openBlock(), _createElementBlock("h5", _hoisted_30, " (early bird price extended until " + _toDisplayString((new Date(ticket.earlyBirdUntil)).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })) + ") ", 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: "Lorem-ipsum-bis-150 mt-3",
                  innerHTML: ticket.description
                }, null, 8, _hoisted_31)
              ]))
            }), 256)),
            _cache[10] || (_cache[10] = _createElementVNode("a", {
              class: "col-md-12 col-lg-12 btn btn-primary buttonsprimarytextbutton-text d-flex align-items-center justify-content-center",
              href: "/tickets/",
              role: "button"
            }, " TICKETS ", -1))
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"box2\" data-v-d0e41f3e><span class=\"headline cb-headlinenormalxl g-3 mt-1 mb-5\" data-v-d0e41f3e>Main Partners</span><div class=\"row g-3 pt-1\" data-v-d0e41f3e><img src=\"/files/landingpage/partners_1.png\" class=\"img-fluid mt-0\" alt=\"Parter Logos of GMF - Federal foreign office and the state government of North Rhine-Westphalia\" data-v-d0e41f3e></div><span class=\"headline cb-headlinenormalxl g-3 mt-3 mb-5\" data-v-d0e41f3e>Co-Hosted By</span><div class=\"row g-3 pt-1\" data-v-d0e41f3e><img src=\"/files/landingpage/partners_2.png\" class=\"img-fluid mt-0\" alt=\"Logo of the Foundation for International Dialogue of the Savongs Bank in Bonn\" data-v-d0e41f3e></div><span class=\"headline cb-headlinenormalxl g-3 mt-3 mb-5\" data-v-d0e41f3e>Public Partners</span><div class=\"row g-3 pt-1\" data-v-d0e41f3e><img src=\"/files/landingpage/partners_3.png\" class=\"img-fluid mt-0\" alt=\"Public partners of GMF - Federal Ministry for Economic Cooperation and Development; Freude Joy Joie Bonn; Federal Government Commissioner for Culture and the Media\" data-v-d0e41f3e></div><br data-v-d0e41f3e></div>", 1))
  ]))
}
}

})