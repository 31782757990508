<template>
  <div class="box">
    <div class="row g-3 mb-5 highlights">
      <h3 class="pb-1">Program</h3>
      <Agenda agendatype="agenda"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import Agenda from "@/components/Agenda.vue"
import { useGtm } from '@/composables/useGtm'

export default defineComponent({
  name: 'AgendaOverview',

  components: {
    Agenda
  },

  setup() {
    const { trackPage } = useGtm()
    
    onMounted(() => {
      trackPage({
        pageCategory: 'Program',
        PageName: 'Overview',
        PageType: 'Webapp',
      })
    })
  }
})
</script>