<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import { useStore } from 'vuex'
import { InteractionAlert } from "@/store/i/types"
import { DateTime } from "luxon"

// Statt MDBToast:
import { Toast } from 'bootstrap'

// Props
const props = defineProps<{
  t: InteractionAlert
  inline: boolean
}>()

// Emits
const emit = defineEmits<{
  (e: 'dismissed', t: InteractionAlert): void
}>()

// Store
const store = useStore()

// Reactive state
const confirmedCheck = ref(false)
const toastinst = ref<Toast | null>(null)
const washidden = ref(false)
const gottime = ref(new Date())

// Computed
const header = computed(() => {
  if (props.t?.header) {
    return store.getters.i18n(props.t.header)
  }
  return null
})

const date = computed(() => {
  return props.t?.got || new Date()
})

const styledDate = computed(() => {
  const ldate = DateTime.fromJSDate(date.value)
  return (
    ldate
      .setLocale(store.getters.i18n({ de: 'de-de', en: 'en' }))
      .toLocaleString(DateTime.TIME_24_SIMPLE)
    + store.getters.i18n({ de: ' Uhr', en: '' })
  )
})

const link = computed(() => {
  if (props.t?.link) {
    return store.getters.i18n(props.t.link)
  }
  return null
})

// Methods
const openLink = () => {
  if (link.value) {
    emit('dismissed', props.t)
    window.location.href = link.value
  }
}

const hidenow = () => {
  if (toastinst.value) {
    toastinst.value.hide()
  } else {
    emit('dismissed', props.t)
  }
}

const seeIfToHide = () => {
  if (
    !props.inline &&
    props.t?.showfor &&
    toastinst.value &&
    !washidden.value &&
    (store.state.tick.getTime() - gottime.value.getTime()) > props.t.showfor
  ) {
    washidden.value = true
    toastinst.value.hide()
  }
}

const toastElement = ref<HTMLElement | null>(null)

const inittoast = () => {
  if (!props.inline && toastElement.value) {
    // Erstelle das Bootstrap-Toast-Objekt:
    toastinst.value = new Toast(toastElement.value, {
      animation: true,
      autohide: false
    })
    
    // Falls showfor definiert ist, steuern wir die Anzeige selbst
    washidden.value = !(typeof props.t.showfor === "number" && props.t.showfor > 0)
    gottime.value = new Date()

    toastinst.value.show()

    toastElement.value.addEventListener("hidden.bs.toast", () => {
      if (confirmedCheck.value && props.t.announce_confirm && props.t.announce_confirm !== "") {
        store.dispatch("i/announce", { nsp: props.t.announce_confirm, ev: "confirmed" })
      }
      emit('dismissed', props.t)
    }, { passive: true })
  }
}

// Watchers
watch(() => store.state.tick, seeIfToHide)

// Lifecycle hooks
onMounted(() => {
  inittoast()
})

onBeforeUnmount(() => {
  if (!props.inline && toastinst.value) {
    toastinst.value.dispose()
  }
})
</script>

<template>
  <div
    :class="{
      toast: true,
      show: inline,
      'border-primary': inline
    }"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    ref="toastElement"
  >
    <div class="toast-header">
      <strong class="me-auto">{{ header || '' }}&nbsp;</strong>
      <small v-if="inline" class="text-muted">{{ styledDate }}</small>
      <button
        v-if="!inline"
        type="button"
        class="btn-close"
        data-bs-dismiss="toast"
        aria-label="Close"
        @click.prevent="hidenow"
      >
      </button>
    </div>
    <div
      class="toast-body"
      :class="{ 'toast-body': true, 'linked-toast-body': link }"
      @click.stop="openLink"
    >
      <slot></slot>
      <div class="mt-2 pt-2 border-top delete-button" v-if="inline">
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="hidenow"
        >
          {{ store.getters.i18n({ de: 'löschen', en: 'delete' }) }}
        </button>
      </div>
      <div
        class="mt-2 pt-2 border-top confirm d-flex"
        v-if="t.announce_confirm && t.announce_confirm !== ''"
      >
        <div>
          <div>
            <strong>
              {{ store.getters.i18n({
                de: 'Sie haben 5 Minuten Zeit',
                en: 'You have 5 Minutes'
              }) }}
            </strong>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value="1"
              id="confirmedCheck"
              v-model="confirmedCheck"
            />
            <label class="form-check-label" for="confirmedCheck">
              {{ styledDate }}
            </label>
          </div>
        </div>
        <button
          type="button"
          class="ms-auto btn btn-primary"
          :disabled="!confirmedCheck"
          @click.prevent="hidenow"
        >
          {{ store.getters.i18n({ de: 'Abschicken', en: 'Confirm' }) }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.linked-toast-body {
  cursor: pointer;
}
</style>
