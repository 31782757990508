// src/composables/useGtm.ts
import { useGtm as vueGtm } from 'vue-gtm'

interface TrackPageParams {
    pageCategory: string
    PageName: string
    PageType: string
}

interface TrackEventParams {
    event: string
    eventAction: string
    eventCategory: string
    eventLabel: string
}

interface DataLayerObject {
    event?: string
    [key: string]: any
}

// Erweitern des Window-Interface mit optionalem dataLayer
declare global {
    interface Window {
        dataLayer?: DataLayerObject[]
    }
}

if (typeof window.dataLayer === 'undefined') {
    window.dataLayer = []
}

export function useGtm() {
    const gtm = vueGtm()

    const trackPage = (params: TrackPageParams): void => {
        window.dataLayer?.push({
            event: 'pageview',
            ...params
        })
    }

    const trackEvent = (params: TrackEventParams): void => {
        window.dataLayer?.push({
            ...params
        })
    }

    return {
        trackPage,
        trackEvent
    }
}