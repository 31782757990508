import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "col-md-12 col-xl-12" }
const _hoisted_3 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 1,
  class: "mb-3"
}
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 2,
  class: "mb-3"
}
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = {
  class: "btn btn-primary m-1",
  type: "submit"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }

import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { TranslatedText } from "@/store/types"
import { AppNetworkErrorOther, networkErrorText } from "@/crud"
import { JitsiVideoSettings } from "@/store/member/types"

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'OnBoarding4',
  props: {
    type: {}
  },
  setup(__props: any) {

const props = __props

// Store & Router
const store = useStore()
const router = useRouter()

// Reactive state
const errorstring = ref<TranslatedText>({ de: '', en: '' })
const successtring = ref<TranslatedText>({ de: '', en: '' })
const vcSettings = ref<JitsiVideoSettings>({
  output: '',
  mic: '',
  cam: ''
})

// Computed
const outputDevices = computed(() => store.getters['members/outputDevices'])
const micDevices = computed(() => store.getters['members/micDevices'])
const camDevices = computed(() => store.getters['members/camDevices'])
const originalSettings = computed(() => store.getters['members/devicesPicked'])

// Methods
const saveVCSettings = async () => {
  store.commit('members/setPickedDevices', { ...vcSettings.value })
}

const setDataOnBoarding4 = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    successtring.value = { de: '', en: '' }

    if (vcSettings.value.output !== originalSettings.value.output ||
        vcSettings.value.mic !== originalSettings.value.mic ||
        vcSettings.value.cam !== originalSettings.value.cam) {
      await saveVCSettings()
    }

    if (props.type === "step") {
      router.replace({ name: "Welcome" })
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

// Watchers
watch(originalSettings, () => {
  vcSettings.value = { ...originalSettings.value }
})

// Lifecycle hooks
onMounted(() => {
  vcSettings.value = { ...originalSettings.value }
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("p", null, " Please select and save your device settings here: ", -1)),
      _createElementVNode("form", {
        onSubmit: _withModifiers(setDataOnBoarding4, ["prevent"])
      }, [
        (outputDevices.value.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[3] || (_cache[3] = _createElementVNode("label", { for: "setting_output" }, "Output device", -1)),
              _withDirectives(_createElementVNode("select", {
                id: "setting_output",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vcSettings.value.output) = $event)),
                class: "form-select"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(outputDevices.value, (d, di) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: `od-${di}`,
                    value: d.deviceId
                  }, _toDisplayString(d.label !== '' ? d.label : `Output device ${di+1}`), 9, _hoisted_4))
                }), 128))
              ], 512), [
                [_vModelSelect, vcSettings.value.output]
              ])
            ]))
          : _createCommentVNode("", true),
        (micDevices.value.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[4] || (_cache[4] = _createElementVNode("label", { for: "setting_mic" }, "Microphone", -1)),
              _withDirectives(_createElementVNode("select", {
                id: "setting_mic",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((vcSettings.value.mic) = $event)),
                class: "form-select"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(micDevices.value, (d, di) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: `md-${di}`,
                    value: d.deviceId
                  }, _toDisplayString(d.label !== '' ? d.label : `Microphone ${di+1}`), 9, _hoisted_6))
                }), 128))
              ], 512), [
                [_vModelSelect, vcSettings.value.mic]
              ])
            ]))
          : _createCommentVNode("", true),
        (camDevices.value.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _cache[5] || (_cache[5] = _createElementVNode("label", { for: "setting_cam" }, "Camera", -1)),
              _withDirectives(_createElementVNode("select", {
                id: "setting_cam",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((vcSettings.value.cam) = $event)),
                class: "form-select"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(camDevices.value, (d, di) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: `cd-${di}`,
                    value: d.deviceId
                  }, _toDisplayString(d.label !== '' ? d.label : `Camera ${di+1}`), 9, _hoisted_8))
                }), 128))
              ], 512), [
                [_vModelSelect, vcSettings.value.cam]
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.type === 'step' && _unref(store).state.config.me.tn_type === 8)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'Step2' },
                class: "btn btn-primary m-1"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Back ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.type === 'step' && _unref(store).state.config.me.tn_type !== 8)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: { name: 'Step3' },
                class: "btn btn-primary m-1"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" Back ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("button", _hoisted_10, [
            (_ctx.type === 'step')
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Next"))
              : (_openBlock(), _createElementBlock("span", _hoisted_12, "Save"))
          ])
        ])
      ], 32)
    ])
  ]))
}
}

})