<template>
  <div class="box streaming">
    <div class="row g-3">
      <h1 v-if="agendaEntry">{{ title(agendaEntry.title) }}</h1>


      <div
          class="col-sm d-flex text-primary"
          v-if="video?.type === 'stream' && videoMuted"
          @click="unmutebyalert"
      >
        <div class="micon">
          <svg aria-hidden="true" focusable="false" data-icon="volume-mute" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor" d="M454.63 256l55.03-55.03c3.12-3.12 3.12-8.19 0-11.31l-11.31-11.31c-3.12-3.12-8.19-3.12-11.31 0L432 233.37l-55.03-55.03c-3.12-3.12-8.19-3.12-11.31 0l-11.31 11.31c-3.12 3.12-3.12 8.19 0 11.31L409.37 256l-55.03 55.03c-3.12 3.12-3.12 8.19 0 11.31l11.31 11.31c3.12 3.12 8.19 3.12 11.31 0L432 278.63l55.03 55.03c3.12 3.12 8.19 3.12 11.31 0l11.31-11.31c3.12-3.12 3.12-8.19 0-11.31L454.63 256zM231.81 64c-5.91 0-11.92 2.18-16.78 7.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c4.87 4.87 10.88 7.05 16.78 7.05 12.33 0 24.19-9.52 24.19-24.02V88.02C256 73.51 244.13 64 231.81 64zM224 404.67l-75.32-75.3-9.37-9.37H32V192h107.31l9.37-9.37 75.32-75.3v297.34z" class=""></path>
          </svg>
        </div>
        <div class="mtext">
          {{ t('videoMutedMessage') }}
        </div>
      </div>

      <div class="col-sm popout-bar">
        <button
            @click="showhelpbox = !showhelpbox"
            id="streamhelpbutton"
            :disabled="showhelpbox"
            class="btn btn-dark btn-right"
            type="button"
        >
          {{ t('help') }}
        </button>
      </div>
    </div>

    <div class="row g-3">
      <div v-if="!removeforatick" :class="responsiveClassesCol">
        <div v-if="streamFrameSrc" :class="responsiveClasses" data-wrapperfor="frame">
          <iframe
              title=""
              class="embed-responsive-item"
              :src="streamFrameSrc"
              allow="autoplay; fullscreen"
              allowfullscreen
          ></iframe>
        </div>

        <div v-if="placeholderImage && video?.type === 'placeholder'" :class="responsiveClasses" data-wrapperfor="placeholder">
          <img class="plimg img-fluid" alt="Placeholder for stream" :src="placeholderImage" />
        </div>

        <div v-if="streamHlsSrc" :class="responsiveClasses" data-wrapperfor="videojs">
          <VideojsWrapper
              playerid="muclivestreamplayer"
              v-if="streamHlsSrc"
              :ssrc="streamHlsSrc"
              :splaceholder="placeholderImage"
              :sautostart="streamAutoplay"
              :sloop="streamLoop"
              :sautopip="streamAutoPIP"
              @player-ready="videojsready"
          />
        </div>

        <div v-if="videoVariants?.length > 1" class="teaser-standard-button text-center clearfix mt-10">
          <p>
            <a
                v-for="(v, index) in videoVariants"
                :key="`variantLink${index}`"
                @click.prevent="selectedVideo = index"
                href="#"
                :class="['btn', 'm-2', selectedVideo === index ? 'btn-primary' : 'btn-secondary']"
            >
              {{ variantName(v) }}
            </a>
          </p>
        </div>

        <StreamQAWrapper />
        <VotingArea />

        <div id="pp-detail" class="mt-4 mb-4 mb-lg-0" v-if="agendaEntry">
          <div class="row mb-2 mt-4">
            <div class="cv" v-html="agendaEntry.long_summary"></div>
          </div>

          <div class="row mb-2 mt-4">
            <div class="col-4" v-if="speakers.length">
              <span><b>Speakers</b></span><br><br>
              <div v-for="speaker in speakers" :key="speaker.id">
                {{ speaker.firstname }} {{ speaker.lastname }}
              </div>
            </div>
            <div class="col-4" v-if="moderators.length">
              <span><b>Moderator</b></span><br><br>
              <div v-for="moderator in moderators" :key="moderator.id">
                {{ moderator.firstname }} {{ moderator.lastname }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeMount, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useGtm } from '@/composables/useGtm'
import { DateTime } from 'luxon'
import _ from 'lodash'
import type { AgendaPoint, InteractionAlert, MemberEntry, MultiUserRoom, VideoVariant } from '@/store/i/types'
import VideojsWrapper from '@/components/VideojsWrapper.vue'
import VideoChatArea from '@/components/i/VideoChatArea.vue'
import StreamQAWrapper from '@/components/i/StreamQAWrapper.vue'
import VotingArea from '@/components/i/VotingArea.vue'
import videojs from 'video.js'
import type { VideoJsPlayer } from 'video.js'

const store = useStore()
const router = useRouter()
const route = useRoute()
const { trackPage } = useGtm()

// Reaktive Zustände
const selectedVideo = ref(0)
const showhelpbox = ref(false)
const removeforatick = ref(false)
const videoMuted = ref(false)
const videoPlaying = ref(false)
const playerinst = ref<VideoJsPlayer>()
const start_view = ref(new Date().getTime())

// Am Anfang des <script setup>
const agendaEntry = ref<AgendaPoint | null>(null)


// Die title Funktion anpassen
const title = (text: string | undefined): string => {
  if (!text) return ''
  return text
      .replace(/&#34;/g, '"')
      .replace(/&#39;/g, "'")
}
// Computed Properties
const room = computed(() => {
  if (store.state.i.rooms.length > 0) {
    return _.find(
        store.state.i.rooms,
        (r: MultiUserRoom) => r.roomname === store.state.i.mucname
    )
  }
  return null
})

const videoVariants = computed(() => {
  if (room.value?.video) {
    return room.value.video.filter(v => v.enabled)
  }
  return null
})

const video = computed(() =>
    videoVariants.value ? videoVariants.value[selectedVideo.value] : null
)

const responsiveClasses = computed(() =>
    `ratio ratio-${video.value ? video.value.ratio.replace(/by/, 'x') : '16x9'}`
)

const responsiveClassesCol = computed(() =>
    room.value?.withpublicchat ? 'col-lg-8' : 'col'
)

const streamFrameSrc = computed(() =>
    video.value?.type === 'embed' ? video.value.path : null
)

const streamHlsSrc = computed(() =>
    video.value?.type === 'stream' ? video.value.path : null
)

const streamAutoplay = computed(() =>
    video.value?.type === 'stream' && video.value.autostart || undefined
)

const streamLoop = computed(() =>
    video.value?.type === 'stream' && video.value.loop || undefined
)

const streamAutoPIP = computed(() =>
    video.value?.type === 'stream' && video.value.autopip || false
)

const placeholderImage = computed(() =>
    video.value?.placeholder_image || null
)

const AgendaEntry = computed(() =>
    _.find(
        store.state.i.agenda,
        (ap: AgendaPoint) => ap.dbstate > -1 && ap.now_live
    )
)

const Moderators = computed(() =>
    store.state.i.speakers.filter((ap: MemberEntry) =>
        ap.dbstate > -1 &&
        AgendaEntry.value?.moderators[ap.objvalueid] &&
        ap.is_visible
    )
)

const Speakers = computed(() =>
    store.state.i.speakers.filter((ap: MemberEntry) =>
        ap.dbstate > -1 &&
        AgendaEntry.value?.speakers[ap.objvalueid] &&
        ap.is_visible
    )
)

const Artists = computed(() =>
    store.state.i.speakers.filter((ap: MemberEntry) =>
        ap.dbstate > -1 &&
        AgendaEntry.value?.artists[ap.objvalueid] &&
        ap.is_visible
    )
)

const variantName = (v: VideoVariant): string => {
  const vname = {
    de: v.name,
    en: v.name_en
  }

  if (!vname.de) vname.de = vname.en
  if (!vname.en) vname.en = vname.de

  return store.getters.i18n(vname)
}

const unmutebyalert = () => {
  if (playerinst.value) {
    playerinst.value.muted(false)
    playerinst.value.volume(1)
  }
}

const testifmuted = () => {
  if (playerinst.value) {
    videoMuted.value = playerinst.value.muted() || playerinst.value.volume() < 0.05
  }
}

const videojsready = (player?: VideoJsPlayer) => {
  playerinst.value = player
  if (playerinst.value) {
    store.commit('i/setFullscreen', playerinst.value.isFullscreen())

    playerinst.value.on('volumechange', testifmuted)
    playerinst.value.on('fullscreenchange', () => {
      store.commit('i/setFullscreen', playerinst.value?.isFullscreen())
    })

    playerinst.value.on('play', () => {
      videoPlaying.value = true
      testifmuted()
    })

    playerinst.value.on('pause', () => {
      videoPlaying.value = false
    })

    playerinst.value.on('abort', () => {
      videoPlaying.value = false
    })
  }
}

// Watchers
watch(videoVariants, (nV, oV) => {
  if (
      ((nV && nV.length > 1) && (!oV || oV.length < 2)) ||
      (nV && oV && nV.length > 1 && nV.length !== oV.length)
  ) {
    selectedVideo.value = 0
    for (let i = nV.length - 1; i >= 0; i--) {
      if (nV[i].islang && nV[i].islang === store.state.config.lang) {
        selectedVideo.value = i
      }
    }
  }
})

watch([streamHlsSrc, streamFrameSrc], async () => {
  removeforatick.value = true
  await nextTick()
  removeforatick.value = false
})

watch(() => store.state.tick, () => {
  const cap: AgendaPoint|undefined = store.getters['i/currentAgendaPoint']

  if (store.state.i.currentagenda?.objvalueid !== cap?.objvalueid) {
    store.commit('i/setCurrentAgenda', cap)
  }

  if (cap?.roomname !== 'muc-livestream' && store.state.config.me.no_force_plenum !== 1) {
    router.push({name: 'Welcome'})
  }

  if (cap?.needs_idd &&
      !store.state.i.toasts.some((t: InteractionAlert) => t.announce_confirm)
  ) {
    const current_time = DateTime.fromISO(cap.date_idd)
    const diffmin = Math.round(DateTime.fromJSDate(store.state.tick).diff(current_time).as('seconds'))

    if (diffmin === 1) {
      store.commit('i/addToast', {
        header: {de: 'IDD Zeiten', en: 'IDD Times'},
        msg: {
          de: 'Bitte bestätigen Sie uns, dass Sie dem Live-Stream noch folgen!',
          en: 'Please confirm that you are still watching.'
        },
        validforroom: 'event-general',
        got: new Date(),
        hidden: false,
        showfor: 300 * 1000,
        announce_confirm: `watching-${cap.objvalueid}`
      })
    }
  }
})

// Lifecycle Hooks
onBeforeMount(() => {
  trackPage({
    pageCategory: 'Streaming',
    PageName: AgendaEntry.value?.title || 'Stream',
    PageType: 'Webapp'
  })
})

onMounted(() => {
  if (!store.state.i.connected) {
    store.dispatch('i/loadRoomconfViaFallback')
  }
})

// Navigation Guard
defineExpose({
  async beforeRouteLeave(to, from, next) {
    if (store.getters['i/forcedToPlenum'] &&
        (route.name === 'Stage' || route.name === 'StagePlaying')
    ) {
      next(false)
      store.commit('i/addToast', {
        header: {de: 'Plenum', en: 'Plenum'},
        msg: {
          de: 'Sie können das Plenum aktuell nicht verlassen.',
          en: 'You can not leave the stream at this time.'
        },
        validforroom: 'event-general',
        got: new Date(),
        hidden: false,
        alert_type: 'error'
      })
    } else {
      next()
    }
  }
})
</script>