import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-5 mb-5" }
const _hoisted_2 = { class: "circular_image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "col-7 mb-5 ipadfx2" }
const _hoisted_5 = { class: "Name-Nachname mb-1" }
const _hoisted_6 = { class: "Role copydetailxs mb-1 bold" }
const _hoisted_7 = { class: "Role copydetailxs" }

import { useRouter } from 'vue-router'
import { MemberEntry } from "@/store/i/types"
import _ from "lodash"


export default /*@__PURE__*/_defineComponent({
  __name: 'SpeakerEntry',
  props: {
    member: {},
    type: {}
  },
  setup(__props: any) {

const props = __props

const router = useRouter()

const navigateMember = () => {
  router.push({
    name: 'SpeakerDetail',
    params: { speakerid: `${props.member.objvalueid}` }
  })
}

const title = (title: string): string => {
  let processedTitle = _.replace(title, /&#34;/g, '"')
  processedTitle = _.replace(processedTitle, /&#39;/g, "'")
  return processedTitle
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'col-lg-4 row': _ctx.type =='welcome', 'col-lg-6 row': _ctx.type =='overview'})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        onClick: navigateMember,
        class: "pointer"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: `/files/members/${props.member.objvalueid}/foto.jpg?nocache=${(new Date()).getHours()}`,
            onerror: "this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';",
            alt: "Speaker Image"
          }, null, 8, _hoisted_3)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        onClick: navigateMember,
        class: "pointer"
      }, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(props.member.firstname) + " " + _toDisplayString(props.member.lastname), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(title(props.member.position)), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(title(props.member.orgname)), 1)
      ])
    ])
  ], 2))
}
}

})