import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "voting-list" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "d-flex justify-content-center" }
const _hoisted_6 = ["disabled"]

import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import type { VotingInteraction } from "@/store/i/types"
import _ from "lodash"


export default /*@__PURE__*/_defineComponent({
  __name: 'VotingForm',
  props: {
    voting: {}
  },
  setup(__props: any) {

const props = __props

const store = useStore()
const myselection = ref<string[]>([])

const restanswers = computed((): number => {
  return props.voting.maxselections - myselection.value.length
})

const answerhelper = computed((): string => {
  if (restanswers.value < 0 && props.voting.maxselections === 1) {
    return store.getters.i18n({
      de: `Sie haben zu viele Antworten gewählt. Sie dürfen nur eine wählen.`,
      en: `You chose too many answers. Only one is allowed.`
    })
  }
  if (restanswers.value < 0) {
    return store.getters.i18n({
      de: `Sie haben zu viele Antworten gewählt. Sie dürfen nur ${props.voting.maxselections} wählen.`,
      en: `You chose too many answers. Only ${props.voting.maxselections} are allowed.`
    })
  }
  if (restanswers.value === 1 && myselection.value.length > 0) {
    return store.getters.i18n({
      de: `Sie können noch eine weitere Antwort auswählen.`,
      en: `You can choose one more answer.`
    })
  }
  if (restanswers.value === 0) {
    return store.getters.i18n({
      de: `Sie können keine weiteren Antworten auswählen.`,
      en: `You cannot choose any more answers.`
    })
  }
  if (restanswers.value === 1) {
    return store.getters.i18n({
      de: `Sie können eine Antwort auswählen.`,
      en: `You can choose one answer.`
    })
  }
  if (myselection.value.length > 0) {
    return store.getters.i18n({
      de: `Sie können ${props.voting.maxselections} Antworten auswählen. ${restanswers.value} Antworten noch übrig.`,
      en: `You can choose ${props.voting.maxselections} answers, ${restanswers.value} remain.`
    })
  }
  return store.getters.i18n({
    de: `Sie können ${props.voting.maxselections} Antworten wählen.`,
    en: `You can choose ${props.voting.maxselections} answers.`
  })
})

const sendmyanswers = async () => {
  if (restanswers.value >= 0 && myselection.value.length > 0) {
    await Promise.all(
        _.map(
            myselection.value,
            s => store.dispatch("i/castBallot", {
              roomname: props.voting.roomname,
              voteuid: props.voting.uid,
              answer: s
            })
        )
    )
  }
}

return (_ctx: any,_cache: any) => {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_unref(store).state.i.connected)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            method: "post",
            action: "#",
            onSubmit: _withModifiers(sendmyanswers, ["prevent"])
          }, [
            _createTextVNode(_toDisplayString(answerhelper.value) + " ", 1),
            _createElementVNode("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.voting.answers, (a, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: 'voting_' + a.uid + '_answer_' + index,
                  class: _normalizeClass({'active': myselection.value.some(s => s === a.uid)})
                }, [
                  _createElementVNode("label", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      value: a.uid,
                      name: "myselection",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((myselection).value = $event))
                    }, null, 8, _hoisted_4), [
                      [_vModelCheckbox, myselection.value]
                    ]),
                    _createTextVNode(" " + _toDisplayString(a.caption) + " ", 1),
                    _withDirectives(_createVNode(_component_FontAwesomeIcon, { icon: "circlecheckhollow" }, null, 512), [
                      [_vShow, !myselection.value.some(s => s === a.uid)]
                    ]),
                    _withDirectives(_createVNode(_component_FontAwesomeIcon, { icon: "circlecheck" }, null, 512), [
                      [_vShow, myselection.value.some(s => s === a.uid)]
                    ])
                  ])
                ], 2))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                type: "submit",
                disabled: restanswers.value < 0 || myselection.value.length < 1,
                class: "btn btn-primary"
              }, _toDisplayString(_unref(store).getters.i18n({de:'Abstimmen',en:'Vote'})), 9, _hoisted_6)
            ])
          ], 32))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})