import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = {
  key: 0,
  class: "headline cb-headlinenormalxl g-3"
}
const _hoisted_3 = {
  key: 1,
  class: "row g-3"
}
const _hoisted_4 = { class: "col-md-5 col-lg-3 mb-3 sm-center" }
const _hoisted_5 = { class: "circular_image_large" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "col-md-7 col-lg-9 mb-3 text-left ipadfx" }
const _hoisted_8 = { class: "pb-3 pt-0" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 1,
  class: "mb-4"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = {
  key: 0,
  class: "col-12 col-md-5"
}
const _hoisted_13 = { class: "Role copydetailxs" }
const _hoisted_14 = ["href"]
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "Role copydetailxs col-12 col-md-7" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { key: 2 }
const _hoisted_21 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgendaEntry = _resolveComponent("AgendaEntry")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.SpeakerEntry)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Not found"))
      : _createCommentVNode("", true),
    (_ctx.SpeakerEntry)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                src: `/files/members/${_ctx.SpeakerEntry.objvalueid}/foto.jpg?nocache=${(new Date()).getHours()}`,
                width: "250",
                onerror: "this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';",
                alt: ""
              }, null, 8, _hoisted_6)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.SpeakerEntry.firstname) + " " + _toDisplayString(_ctx.SpeakerEntry.lastname), 1),
            (_ctx.SpeakerEntry.position)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, "Position: " + _toDisplayString(_ctx.SpeakerEntry.position), 1))
              : _createCommentVNode("", true),
            (_ctx.SpeakerEntry.orgname)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, "Organization: " + _toDisplayString(_ctx.SpeakerEntry.orgname), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, [
              (_ctx.SpeakerEntry.xing || _ctx.SpeakerEntry.linkedin)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("span", _hoisted_13, [
                      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "headline_light text-center" }, "Follow me", -1)),
                      _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
                      _createElementVNode("a", {
                        href: _ctx.SpeakerEntry.xing,
                        class: "buttonshare",
                        target: "_blank"
                      }, _cache[3] || (_cache[3] = [
                        _createElementVNode("i", { class: "fab fa-xing fa-2x" }, null, -1)
                      ]), 8, _hoisted_14),
                      _createElementVNode("a", {
                        href: _ctx.SpeakerEntry.linkedin,
                        class: "buttonshare",
                        target: "_blank"
                      }, _cache[4] || (_cache[4] = [
                        _createElementVNode("i", { class: "fab fa-2x fa-linkedin-in" }, null, -1)
                      ]), 8, _hoisted_15)
                    ]),
                    _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_16, [
                _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                _cache[13] || (_cache[13] = _createElementVNode("span", { class: "headline_light text-center" }, "Share on", -1)),
                _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                _createElementVNode("a", {
                  class: "a-link--external share buttonshare",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.share('twitter','','en_us'))),
                  "data-label": "twitter"
                }, _cache[9] || (_cache[9] = [
                  _createElementVNode("i", { class: "fab fa-2x fa-x-twitter pe-2" }, null, -1),
                  _createElementVNode("span", { class: "sr-only" }, "X (Twitter)", -1)
                ])),
                _createElementVNode("a", {
                  class: "a-link--external share buttonshare",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.share('linkedin','','en_us'))),
                  "data-label": "linkedin"
                }, _cache[10] || (_cache[10] = [
                  _createElementVNode("i", { class: "fab fa-2x fa-linkedin-in pe-2" }, null, -1),
                  _createElementVNode("span", { class: "sr-only" }, "LinkedIn", -1)
                ])),
                _createElementVNode("a", {
                  class: "a-link--external share buttonshare",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.share('facebook','','en_us'))),
                  "data-label": "facebook"
                }, _cache[11] || (_cache[11] = [
                  _createElementVNode("i", { class: "fab fa-2x fa-facebook-f" }, null, -1),
                  _createElementVNode("span", { class: "sr-only" }, "Facebook", -1)
                ]))
              ]),
              _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1))
            ])
          ]),
          _cache[24] || (_cache[24] = _createElementVNode("hr", null, null, -1)),
          (_ctx.SpeakerEntry.cv)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _cache[16] || (_cache[16] = _createElementVNode("h4", { class: "bold mb-3" }, "Resume", -1)),
                _createElementVNode("div", {
                  class: "cv",
                  innerHTML: _ctx.SpeakerEntry.cv
                }, null, 8, _hoisted_18),
                _cache[17] || (_cache[17] = _createElementVNode("hr", null, null, -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.Speakers.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _cache[18] || (_cache[18] = _createElementVNode("h4", { class: "bold mt-4 mb-4" }, "My speaker program", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Speakers, (ae, aeindex) => {
                  return (_openBlock(), _createBlock(_component_AgendaEntry, {
                    key: `agp1_${aeindex}`,
                    ae: ae,
                    agendatype: "entry"
                  }, null, 8, ["ae"]))
                }), 128)),
                _cache[19] || (_cache[19] = _createElementVNode("hr", null, null, -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.Moderators.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _cache[20] || (_cache[20] = _createElementVNode("h4", { class: "bold mt-4 mb-4" }, "My moderator program", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Moderators, (ae, aeindex) => {
                  return (_openBlock(), _createBlock(_component_AgendaEntry, {
                    key: `agp2_${aeindex}`,
                    ae: ae,
                    agendatype: "entry"
                  }, null, 8, ["ae"]))
                }), 128)),
                _cache[21] || (_cache[21] = _createElementVNode("hr", null, null, -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.Artists.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _cache[22] || (_cache[22] = _createElementVNode("h4", { class: "bold mt-4 mb-4" }, "My artist program", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Artists, (ae, aeindex) => {
                  return (_openBlock(), _createBlock(_component_AgendaEntry, {
                    key: `agp3_${aeindex}`,
                    ae: ae,
                    agendatype: "entry"
                  }, null, 8, ["ae"]))
                }), 128)),
                _cache[23] || (_cache[23] = _createElementVNode("hr", null, null, -1))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}