<template>
  <div class="box">
    <div class="row g-3 mb-5 highlights">
      <h3 class="pb-1">On demand</h3>
      <Agenda agendatype="video" />

      <section v-for="video in videos" :key="video.title">
        <h3 class="pb-1">{{ video.title }}</h3>
        <iframe
            class="col-12 col-md-9 col-lg-6"
            width="560"
            height="315"
            :src="video.src"
            :title="video.title"
            frameborder="0"
            :allow="allowedFeatures"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
        ></iframe>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from 'vue'
import Agenda from "@/components/Agenda.vue"
import {useGtm} from "@/composables/useGtm";


interface VideoContent {
  title: string;
  src: string;
}

export default defineComponent({
  name: 'Mediathek',

  components: {
    Agenda
  },

  setup() {

    const { trackPage } = useGtm()

    const videos: VideoContent[] = [
      {
        title: 'GMF talk',
        src: 'https://www.youtube-nocookie.com/embed/LgQcVZp0uSA?si=stwan5FHHutus4Qc'
      },
      {
        title: 'DW Global Media Forum 2023',
        src: 'https://www.youtube-nocookie.com/embed/videoseries?si=ejHfrAoF9IRPqrzr&controls=0&list=PLnRODqqz08xJcSJUoTuyP2uJmjb-nG9ZN'
      }
    ]

    const allowedFeatures = [
      'accelerometer',
      'autoplay',
      'clipboard-write',
      'encrypted-media',
      'gyroscope',
      'picture-in-picture',
      'web-share'
    ].join('; ')

    onBeforeMount(() => {
      trackPage({
        pageCategory: 'Mediathek',
        PageName: 'Overview',
        PageType: 'Webapp',
      })
    })

    return {
      videos,
      allowedFeatures
    }
  }
})
</script>