import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "mainform g-3 pb-4" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-sm-12 position-relative formitem_parent" }
const _hoisted_5 = { class: "col-sm-12 position-relative formitem_parent" }
const _hoisted_6 = { class: "row mt-4 clearfix noprint" }
const _hoisted_7 = { class: "col d-flex justify-content-start flex-row-reverse" }
const _hoisted_8 = ["disabled"]

import { reactive, computed, ref, watch, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { TranslatedText } from "@/store/types"
import { networkErrorText, type AppNetworkErrorOther } from "@/crud"
import rankingsMaxPoints from '@/ranking'
import {useGtm} from "@/composables/useGtm";

const forgotPasswordUrl = '/eve/interfaces/modules/registration/index.php?mprefix=dw&pprefix=gmf&regname=webapp&label=lost_password'


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const store = useStore()
const router = useRouter()
const route = useRoute()

const formData = reactive({
  username: '',
  password: ''
})

const errorstring = ref<TranslatedText>({ de: '', en: '' })
const loginstart = ref<Date | null>(null)

const isFormValid = computed(() =>
    formData.username.length >= 2 && formData.password.length >= 5
)

const clearError = () => {
  errorstring.value = { de: '', en: '' }
}

const handleRanking = async (date: Date) => {
  if (date.toDateString() === 'Mon Jun 17 2024') {
    if (store.state.i.me.ranking_i < rankingsMaxPoints.ranking_i) {
      await store.dispatch('i/setDataRankingI', 3)
    }
  } else if (date.toDateString() === 'Tue Jun 18 2024') {
    if (store.state.i.me.ranking_j < rankingsMaxPoints.ranking_j) {
      await store.dispatch('i/setDataRankingJ', 5)
    }
  }
}

const handleLogin = async () => {
  try {
    clearError()
    const loginRes = await store.dispatch('login', {
      u: formData.username,
      p: formData.password
    })

    if (loginRes.login) {
      await handleRanking(new Date())

      router.replace({
        name: loginRes.show_onboarding ? 'Step1' : 'Welcome'
      })
    } else {
      errorstring.value = {
        de: 'Bitte überprüfen Sie Ihre Zugangsdaten',
        en: 'Please validate your input'
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

watch(() => route.name, () => {
  if (route.name === 'Login') {
    loginstart.value = null
  }
})

onBeforeMount(() => {
  const { trackPage } = useGtm()
  
  trackPage({
    pageCategory: 'Login',
    PageName: route.name?.toString() ?? 'unknown',
    PageType: 'Webapp',
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Login", -1)),
      _withDirectives(_createElementVNode("div", { class: "alert alert-danger mb-5" }, _toDisplayString(_unref(store).getters.i18n(errorstring.value)), 513), [
        [_vShow, errorstring.value.de]
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("form", {
          onSubmit: _withModifiers(handleLogin, ["prevent"]),
          class: "form-horizontal"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[2] || (_cache[2] = _createElementVNode("label", {
                class: "form-label",
                for: "musername"
              }, "E-mail address", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "email",
                required: "",
                autocomplete: "off",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.username) = $event)),
                id: "musername",
                class: "form-control",
                onFocusin: clearError
              }, null, 544), [
                [
                  _vModelText,
                  formData.username,
                  void 0,
                  { trim: true }
                ]
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[3] || (_cache[3] = _createElementVNode("label", {
                class: "form-label",
                for: "mpassword"
              }, "Password", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "password",
                required: "",
                autocomplete: "off",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.password) = $event)),
                id: "mpassword",
                class: "form-control",
                onFocusin: clearError
              }, null, 544), [
                [
                  _vModelText,
                  formData.password,
                  void 0,
                  { trim: true }
                ]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("button", {
                class: "btn btn-primary m-2",
                disabled: !isFormValid.value,
                type: "submit"
              }, " Login ", 8, _hoisted_8),
              _createElementVNode("a", {
                href: forgotPasswordUrl,
                target: "_blank",
                class: "btn btn-primary m-2 prev lostpw"
              }, " Forgot password ")
            ])
          ])
        ], 32)
      ])
    ])
  ]))
}
}

})