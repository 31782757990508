import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row scroll-header" }
const _hoisted_2 = { class: "col mt-4" }
const _hoisted_3 = { style: {"font-size":"28px"} }
const _hoisted_4 = { class: "col" }

import { ref, computed, onMounted, onBeforeUnmount, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import ToastAlert from "@/components/i/ToastAlert.vue"
import { InteractionAlert } from "@/store/i/types"
import OfflineDB from "@/store/i/offlinedb"
import { DateTime } from "luxon"
import _ from "lodash"


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationVerticalNav',
  setup(__props) {

const store = useStore()
const scrollElement = ref<HTMLElement>()
const archiveNotifications = ref<InteractionAlert[]>([])
const correctScroll = ref(true)

const sortedNotifications = computed((): InteractionAlert[] => {
  return _.orderBy(
      archiveNotifications.value,
      [(n: InteractionAlert) => n.got?.getTime()],
      ["desc"]
  )
})

const readnotifications = async () => {
  if (store.state.sidenav === "notifications") {
    archiveNotifications.value = await OfflineDB.toasts.toCollection().toArray() as InteractionAlert[]
    _.remove(
        archiveNotifications.value,
        (ta: InteractionAlert) => (
            !!(ta.removed) ||
            !!(ta.queued) ||
            (ta.validfrom && ta.validfrom > store.state.tick)
        )
    )
    if (archiveNotifications.value.length > 0) {
      const olderthanaday = _.filter(
          archiveNotifications.value,
          (n: InteractionAlert) => DateTime.fromJSDate(n.got ? n.got : new Date()).diffNow().as('hours') <= -24
      )
      if (olderthanaday.length > 0) {
        olderthanaday.forEach(
            (t: InteractionAlert) => { removeToast(t) }
        )
      }
    }
  } else {
    archiveNotifications.value = []
  }
}

const removeToast = async (t: InteractionAlert) => {
  await OfflineDB.deleteToast(t)
  _.remove(
      archiveNotifications.value,
      (to: InteractionAlert) => to === t
  )
  archiveNotifications.value = [...archiveNotifications.value]
}

const scrollBottom = async () => {
  if (!scrollElement.value) return

  if (correctScroll.value) {
    scrollElement.value.removeEventListener('scroll', lookIfToHaltAutoscroll)
    await nextTick()
    scrollElement.value.scrollTop = scrollElement.value.scrollHeight - scrollElement.value.clientHeight
    await nextTick()
    scrollElement.value.addEventListener('scroll', lookIfToHaltAutoscroll, {passive:true})
  }
}

const lookIfToHaltAutoscroll = () => {
  if (!scrollElement.value) return

  const pos = scrollElement.value.scrollTop
  const desiredPos = scrollElement.value.scrollHeight - scrollElement.value.clientHeight
  correctScroll.value = pos >= desiredPos - 20
}

// Watchers
watch(() => store.state.sidenav, readnotifications)
watch(() => store.state.i.toasts, readnotifications)
watch(archiveNotifications, scrollBottom)

// Lifecycle hooks
onMounted(() => {
  readnotifications()
  scrollBottom()

  if (scrollElement.value) {
    window.addEventListener('resize', scrollBottom, {passive:true})
    scrollElement.value.addEventListener('scroll', lookIfToHaltAutoscroll, {passive:true})
  }
})

onBeforeUnmount(() => {
  if (scrollElement.value) {
    window.removeEventListener('resize', scrollBottom)
    scrollElement.value.removeEventListener('scroll', lookIfToHaltAutoscroll)
  }
})

return (_ctx: any,_cache: any) => {
  const _component_VerticalNav = _resolveComponent("VerticalNav")!

  return (_openBlock(), _createBlock(_component_VerticalNav, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(store).getters.i18n({de: 'Mitteilungen', en: 'Notifications'})), 1)
        ])
      ]),
      _createElementVNode("div", {
        class: "scroll-element row d-block",
        ref_key: "scrollElement",
        ref: scrollElement
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedNotifications.value, (n, ni) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "row mb-2 align-self-start",
            key: `notification${ni}`
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(ToastAlert, {
                inline: true,
                t: n,
                onDismissed: removeToast
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(store).getters.i18n(n.msg)), 1)
                ]),
                _: 2
              }, 1032, ["t"])
            ])
          ]))
        }), 128))
      ], 512)
    ]),
    _: 1
  }))
}
}

})