<template>
  <div class="col">
    <JitsiCall type="member" v-if="store.state.members.currentCall"/>

    <div class="row">
      <div class="col-lg-9">
        <p>
          <input placeholder="Search title or speaker name"
                 type="search"
                 v-model.trim="searchtext"
                 class="form-control">
        </p>

        <div class="row mt-3">
          <div class="col-8">
            <a href="/files/gmf_programoverview.pdf" class="btn btn-primary m-0 float-start me-2" target="_blank">
              <i class="far fa-arrow-to-bottom"></i> Download full program
            </a>
            <a href="/files/gmf_siteplan.pdf" class="btn btn-primary m-0 float-start" target="_blank">
              <i class="far fa-arrow-to-bottom"></i> Download site plan
            </a>
          </div>
          <div class="col-4">
            <a class="resetfilter btn btn-outline-primary float-end p-3" @click="clearFilter()">
              <i class="fal fa-trash"></i> Clear Filter
            </a>
          </div>
        </div>

        <h5 class="mt-3">Event type</h5>
        <span class="badge eventtype1 mt-1" @click="toggleEventTypeFilter('plenary_sessions')">
          <i :class="[!activeFilters.eventTypes.plenary_sessions ? 'fal fa-circle' : 'fas fa-check-circle']"></i>
          Plenary sessions
        </span>
        <span class="badge event-type-7 mt-1" @click="toggleEventTypeFilter('breakout_sessions')">
          <i :class="[!activeFilters.eventTypes.breakout_sessions ? 'fal fa-circle' : 'fas fa-check-circle']"></i>
          Breakout sessions
        </span>
        <span class="badge eventtype4 mt-1" @click="toggleEventTypeFilter('networking')">
          <i :class="[!activeFilters.eventTypes.networking ? 'fal fa-circle' : 'fas fa-check-circle']"></i>
          Networking
        </span>
        <span class="badge eventtype5 mt-1" @click="toggleEventTypeFilter('exhibitor_events')">
          <i :class="[!activeFilters.eventTypes.exhibitor_events ? 'fal fa-circle' : 'fas fa-check-circle']"></i>
          Exhibitors events
        </span>

        <h5 class="mt-3">Date</h5>
        <span class="badge day1 mt-1" @click="toggleDayFilter('day1')">
          <i :class="[!activeFilters.days.day1 ? 'fal fa-circle' : 'fas fa-check-circle']"></i>
          Monday, June 17
        </span>
        <span class="badge day2 mt-1" @click="toggleDayFilter('day2')">
          <i :class="[!activeFilters.days.day2 ? 'fal fa-circle' : 'fas fa-check-circle']"></i>
          Tuesday, June 18
        </span>
      </div>
    </div>

    <div class="mt-4" v-if="noDaysSelected || activeFilters.days.day1">
      <h4 class="font-weight-bold pb-3">MONDAY</h4>
      <div class="row">
        <div v-if="agendaPointsDay1.length == 0 && route.name === 'Mediathek'">
          <p>No videos on demand available yet.</p>
        </div>
        <AgendaEntry v-for="(ae, aeindex) in agendaPointsDay1"
                     :key="`agp${aeindex}`"
                     :ae="ae"/>
        <div v-if="route.name === 'Calendar'">
          <h5 class="pb-2 font-weight-bold">Appointments</h5>
          <MeetingEntry v-for="(ae, aeindex) in meetings1"
                        :key="`agp_1${aeindex}`"
                        :ae="ae"/>
          <MeetingEntry v-for="(ae, aeindex) in meetings2"
                        :key="`agp_2${aeindex}`"
                        :ae="ae"
                        @call="pickusercall"/>
          <MeetingEntry v-for="(ae, aeindex) in meetings3"
                        :key="`agp_3${aeindex}`"
                        :ae="ae"/>
          <MeetingEntry v-for="(ae, aeindex) in meetings4"
                        :key="`agp_4${aeindex}`"
                        :ae="ae"/>
        </div>
      </div>
    </div>

    <div class="mt-4" v-if="noDaysSelected || activeFilters.days.day2">
      <h4 class="font-weight-bold pb-3">TUESDAY</h4>
      <div class="row">
        <div v-if="agendaPointsDay2.length == 0 && route.name === 'Mediathek'">
          <p>No videos on demand available yet.</p>
        </div>
        <AgendaEntry v-for="(ae, aeindex) in agendaPointsDay2"
                     :key="`agp${aeindex}`"
                     :ae="ae"/>
        <div v-if="route.name === 'Calendar'">
          <h5 class="pb-2 font-weight-bold">Appointments</h5>
          <MeetingEntry v-for="(ae, aeindex) in meetings1_2"
                        :key="`agp_1_2${aeindex}`"
                        :ae="ae"/>
          <MeetingEntry v-for="(ae, aeindex) in meetings2_2"
                        :key="`agp_2_2${aeindex}`"
                        :ae="ae"
                        @call="pickusercall"/>
          <MeetingEntry v-for="(ae, aeindex) in meetings3_2"
                        :key="`agp_3_2${aeindex}`"
                        :ae="ae"/>
          <MeetingEntry v-for="(ae, aeindex) in meetings4_2"
                        :key="`agp_4_2${aeindex}`"
                        :ae="ae"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import AgendaEntry from "@/components/AgendaEntry.vue"
import MeetingEntry from "@/components/MeetingEntry.vue"
import JitsiCall from "@/components/jitsi/JitsiCall.vue"
import _ from "lodash"
import type { AgendaPoint, Meetings } from "@/store/i/types"
import type { JitsiCallRequest } from "@/store/member/types"

// Props
const props = defineProps<{
  agendatype?: string
}>()

// Store, Route, Router
const store = useStore()
const route = useRoute()

// Reactive State
const searchtext = ref('')

// Interfaces
interface Days {
  day1: boolean
  day2: boolean
}

interface EventTypes {
  networking: boolean
  exhibitor_events: boolean
  plenary_sessions: boolean
  breakout_sessions: boolean
}

type EventTypeKey = keyof EventTypes

// Event Type Mapping
const eventTypeMap: Record<EventTypeKey, number> = {
  networking: 4,
  exhibitor_events: 5,
  plenary_sessions: 6,
  breakout_sessions: 7
}

const currentCall = computed<JitsiCallRequest | undefined>(() => store.state.members.currentCall)


const pickusercall = async (cp: Meetings) => {
  if (cp.fbuid !== store.state.config.fbuid) {
    if (currentCall.value) {
      store.commit("i/addToast", {
        header: {de: "Fehler", en: "Error"},
        msg: {
          de: 'Sie sind bereits im Gespräch.',
          en: 'You are already in a call.'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        showfor: 10000,
        alert_type: 'error'
      })
    } else {
      if (cp.receiver == store.state.config.me.objvalueid) {
        let c = {
          "fbuid": cp.fbuid,
          "objvalueid": cp.sender,
          "gender": cp.gender,
          "title": cp.title,
          "firstname": cp.firstname,
          "lastname": cp.lastname,
          "orgname": cp.orgname
        }
        store.commit("members/setContact", c)
        await store.dispatch("members/startCall", cp.sender)
      } else {
        let c = {
          "fbuid": cp.fbuid,
          "objvalueid": cp.receiver,
          "gender": cp.gender,
          "title": cp.title,
          "firstname": cp.firstname,
          "lastname": cp.lastname,
          "orgname": cp.orgname
        }
        store.commit("members/setContact", c)
        await store.dispatch("members/startCall", cp.receiver)
      }

      store.commit("i/addToast", {
        header: {de: "Hinweis", en: "Warning"},
        msg: {
          de: 'Bitte haben Sie einen Moment Geduld, bis Ihr Ansprechpartner Ihren Anruf entgegen nimmt. Wenn Sie das Gespräch beenden, ohne dass Ihr Ansprechpartner den Anruf entgegen genommen hat, wird dieser per Chat-Nachricht über Ihren Kontaktversuch informiert.',
          en: 'Please be patient until your contact accepts your call. If you end the call before your contact has accepted it, they will be notified of your contact attempt via chat message.'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        showfor: 10000,
        alert_type: 'error'
      })
    }
  }
}

onBeforeRouteLeave((to, from, next) => {
  if (store.state.members.currentCall && !store.getters['i/forcedToPlenum'] && !store.getters['i/redirectToPlenum']) {
    const confirmres = window.confirm(store.getters.i18n({
      de: "Wenn Sie jetzt den Stand verlassen, wird Ihr Gespräch beendet.",
      en: "If you are leaving the booth now, your call will be ended."
    }))
    if (confirmres) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})


// Active Filters State
const activeFilters = ref({
  eventTypes: {
    networking: false,
    exhibitor_events: false,
    plenary_sessions: false,
    breakout_sessions: false
  },
  days: {
    day1: false,
    day2: false
  }
})

// Computed Properties
const noDaysSelected = computed(() => {
  return Object.values(activeFilters.value.days).filter(v => v).length === 0
})

const filterItems = (items: AgendaPoint[]) => {
  const swords = _.map(
      _.words(searchtext.value),
      s => new RegExp(_.escapeRegExp(s), 'iu')
  )

  localStorage.setItem("TextFilter", searchtext.value)

  const activeEventTypeFilters: EventTypeKey[] = Object.entries(activeFilters.value.eventTypes)
      .filter(([_key, value]) => value)
      .map(([key, _value]) => key as EventTypeKey)

  const lookForEventTypeNumber = activeEventTypeFilters.map((k) => eventTypeMap[k])

  return _.filter(
      items,
      (item) => {
        return (swords.length === 0 || swords.some((r) => r.test(item.title + ' ' + item.speakers)))
            && (lookForEventTypeNumber.length === 0 || lookForEventTypeNumber.includes(item.eventtype))
      }
  )
}


/*
 ERSTMAL AUSKOMMENTIERT DA ZU KLÄREN IST WELCHE DATEN 2025 ES SEIN SOLLEN UND OB DAS HARDGECODET BLEIBT
 OHNE AUSKOMMENTIEREN BEKOMMT MAN SONST ERSTMAL MIT DEN TESTDATEN NIX ANGEZEIT!!!
*/

/*
const agendaPointsDay1 = computed(() => {
  let items = _.filter(
      store.getters['i/orderedAgendaPoints'],
      (ap: AgendaPoint) => ap.dbstate > -1 && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
  )

  if (props.agendatype === "calendar") {
    items = _.filter(
        items,
        (ap: AgendaPoint) => _.has(store.state.i.me.fav_agenda_items, ap.objvalueid) && new Date(ap.enddate).getTime() > new Date().getTime()
    )
  }

  if (props.agendatype === "video") {
    items = _.filter(
        items,
        (ap: AgendaPoint) => ap.dbstate > -1 && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024" && ap.show_video
    )
  }

  return filterItems(items)
})

const agendaPointsDay2 = computed(() => {
  let items = _.filter(
      store.getters['i/orderedAgendaPoints'],
      (ap: AgendaPoint) => ap.dbstate > -1 && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
  )

  if (props.agendatype === "calendar") {
    items = _.filter(
        items,
        (ap: AgendaPoint) => _.has(store.state.i.me.fav_agenda_items, ap.objvalueid) && new Date(ap.enddate).getTime() > new Date().getTime()
    )
  }

  if (props.agendatype === "video") {
    items = _.filter(
        items,
        (ap: AgendaPoint) => ap.dbstate > -1 && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024" && ap.show_video
    )
  }

  return filterItems(items)
})
*/

const agendaPointsDay1 = computed(() => {
  let items = store.getters['i/orderedAgendaPoints'];
      
  

  console.log('TODO: check logik/datum _ items day 1', items);



  return items
})

const agendaPointsDay2 = computed(() => {
  let items =  store.getters['i/orderedAgendaPoints'];

  console.log('TODO: check logik/datum _ items day 2', items);


  return items
})

// Meetings computed properties
const meetings1 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 1 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
  )
})

const meetings2 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 2 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
  )
})

const meetings3 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 3 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
  )
})

const meetings4 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 4 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
  )
})

const meetings1_2 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 1 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
  )
})

const meetings2_2 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 2 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
  )
})

const meetings3_2 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 3 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
  )
})

const meetings4_2 = computed(() => {
  return _.filter(
      store.getters['i/orderedMeetings'],
      (ap: Meetings) => ap.dbstate > -1 && ap.status == 4 && new Date(ap.enddate).getTime() > new Date().getTime()
          && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
  )
})

// Methods
const clearFilter = () => {
  searchtext.value = ''
  activeFilters.value = {
    eventTypes: {
      networking: false,
      exhibitor_events: false,
      breakout_sessions: false,
      plenary_sessions: false
    },
    days: {
      day1: false,
      day2: false
    }
  }

  localStorage.removeItem("DayFilter")
  localStorage.removeItem("EventFilter")
  localStorage.removeItem("TextFilter")
}

const toggleEventTypeFilter = (type: keyof EventTypes) => {
  activeFilters.value.eventTypes[type] = !activeFilters.value.eventTypes[type]
  localStorage.setItem("EventFilter", JSON.stringify(activeFilters.value.eventTypes))
}

const toggleDayFilter = (type: keyof Days) => {
  activeFilters.value.days[type] = !activeFilters.value.days[type]
  localStorage.setItem("DayFilter", JSON.stringify(activeFilters.value.days))
}


// Navigation Guard
onBeforeRouteLeave((to, from, next) => {
  if (store.state.members.currentCall && !store.getters['i/forcedToPlenum'] && !store.getters['i/redirectToPlenum']) {
    const confirmres = window.confirm(store.getters.i18n({
      de: "If you are leaving the booth now, your call will be ended.",
      en: "If you are leaving the booth now, your call will be ended."
    }))
    if (confirmres) {
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

// Initialization
const initializeFilters = () => {
  if (new Date().toDateString() === "Mon Jun 17 2024") {
    activeFilters.value.days.day1 = true
  } else if (new Date().toDateString() === "Tue Jun 18 2024") {
    activeFilters.value.days.day2 = true
  }

  if (localStorage.getItem('EventFilter')) {
    const storedNamesEvent = JSON.parse(localStorage.getItem('EventFilter') || '{}')
    Object.entries(storedNamesEvent).forEach(([key, value]) => {
      if (value) {
        const k = key as keyof EventTypes
        activeFilters.value.eventTypes[k] = true
      }
    })
  }

  if (localStorage.getItem('DayFilter')) {
    const storedNamesDays = JSON.parse(localStorage.getItem('DayFilter') || '{}')
    Object.entries(storedNamesDays).forEach(([key, value]) => {
      if (value) {
        const k = key as keyof Days
        activeFilters.value.days[k] = true
      }
    })
  }

  if (localStorage.getItem('TextFilter')) {
    searchtext.value = localStorage.getItem('TextFilter') || ''
  }
}
// Lifecycle Hooks
onMounted(async () => {
  console.log('Agenda.vue mounted')
  await store.dispatch("i/fetchAgenda")
})

initializeFilters()


</script>