import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Error404 from "@/views/Error404.vue"
import ErrorFrameset from "@/views/ErrorFrameset.vue"
import Login from "@/views/Login.vue"
import Welcome from "@/views/Welcome.vue"
import Streaming from "@/views/Streaming.vue"
import Help from "@/views/Help.vue"
import SpeakerOverview from "@/views/SpeakerOverview.vue"
import SpeakerDetail from "@/views/SpeakerDetail.vue"
import PartnerOverview from "@/views/PartnerOverview.vue"
import PartnerDetail from "@/views/PartnerDetail.vue"
import AgendaOverview from "@/views/AgendaOverview.vue"
import AgendaDetail from "@/views/AgendaDetail.vue"
import MeetingDetail from "@/views/MeetingDetail.vue"
import PhotoExhibition from "@/views/PhotoExhibition.vue"
import Mediathek from "@/views/Mediathek.vue"
import Calendar from "@/views/Calendar.vue"
import Step1 from "@/views/Step1.vue"
import Step2 from "@/views/Step2.vue"
import Step3 from "@/views/Step3.vue"
import Step4 from "@/views/Step4.vue"
import Profile from "@/views/Profile.vue"
import Networking from "@/views/Networking.vue"
import ContactDetail from "@/views/ContactDetail.vue"
import Status from "@/views/Status.vue"
import store, { populateInital } from "@/store/"
import LandingPage from "@/views/LandingPage.vue"
import Coffeebar from "@/views/Coffeebar.vue"
// Ab v9: remove(...) und DatabaseReference aus "firebase/database" statt firebase.database.ThenableReference
import { remove, DatabaseReference } from "firebase/database"

let lastannouncement = ""

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*', // Neue Syntax für Catch-all Route
    name: '404',
    component: Error404,
    meta: {
      needsauth: false,
      title: store.getters.i18n({'de': '404', 'en': '404'}),
      announce: false,
      bodyClass: "small-container-custom"
    }
  },
  {
    path: '/frameerror',
    name: 'FramesetError',
    component: ErrorFrameset,
    meta: {
      needsauth: false,
      title: store.getters.i18n({'de': 'Fehler: Frameset', 'en': 'Error: Frame'}),
      announce: false,
      bodyClass: "small-container-custom"
    }
  },
  {
    path: '/',
    name: "LandingPage",
    component: LandingPage,
    meta: {
      needsauth: false,
      title: store.getters.i18n({'de': 'Willkommen', 'en': 'Welcome'}),
      announce: false
    }
  },
  {
    path: '/event/welcome',
    name: 'Welcome',
    component: Welcome,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Willkommen', 'en': 'Welcome'}),
      announce: false
    }
  },
  {
    path: '/event/',
    redirect: { name: 'Welcome' }
  },
  {
    path: '/event/login',
    name: 'Login',
    component: Login,
    meta: {
      needsauth: false,
      title: store.getters.i18n({'de': 'Login', 'en': 'Log in'}),
      announce: false,
      bodyClass: "small-container-custom"
    }
  },
  {
    path: '/event/logintest',
    name: 'LoginTest',
    component: Login,
    meta: {
      logintest: true,
      needsauth: false,
      title: store.getters.i18n({'de': 'Login', 'en': 'Log in'}),
      announce: false
    }
  },
  {
    path: '/event/logout',
    name: 'Logout',
    component: Login,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Logout', 'en': 'Log out'}),
      announce: false
    }
  },
  {
    path: '/event/step-networking',
    name: 'Step1',
    component: Step1,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'OnBoarding1', 'en': 'OnBoarding1'}),
      announce: false
    }
  },
  {
    path: '/event/step-profile',
    name: 'Step2',
    component: Step2,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'OnBoarding2', 'en': 'OnBoarding2'}),
      announce: false
    }
  },
  {
    path: '/event/step-photo',
    name: 'Step3',
    component: Step3,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'OnBoarding3', 'en': 'OnBoarding3'}),
      announce: false
    }
  },
  {
    path: '/event/step-video-call-settings',
    name: 'Step4',
    component: Step4,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'OnBoarding4', 'en': 'OnBoarding4'}),
      announce: false
    }
  },
  {
    path: '/event/networking',
    name: 'Networking',
    component: Networking,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Networking', 'en': 'Networking'}),
      announce: false,
      tab: 1
    },
    children: [
      {
        path: 'matches',
        name: 'Networking1',
        component: Networking,
        meta: {
          needsauth: true,
          title: store.getters.i18n({'de': 'Networking', 'en': 'Networking'}),
          announce: true,
          tab: 1
        }
      },
      {
        path: 'members',
        name: 'Networking2',
        component: Networking,
        meta: {
          needsauth: true,
          title: store.getters.i18n({'de': 'Networking', 'en': 'Networking'}),
          announce: true,
          tab: 2
        }
      },
      {
        path: 'contacts',
        name: 'Networking3',
        component: Networking,
        meta: {
          needsauth: true,
          title: store.getters.i18n({'de': 'Networking', 'en': 'Networking'}),
          announce: true,
          tab: 3
        }
      },
      {
        path: 'contact_requests',
        name: 'Networking4',
        component: Networking,
        meta: {
          needsauth: true,
          title: store.getters.i18n({'de': 'Networking', 'en': 'Networking'}),
          announce: true,
          tab: 4
        }
      }
    ]
  },
  {
    path: '/event/contact-details/:contactid',
    name: 'ContactDetail',
    component: ContactDetail,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Contact Detail', 'en': 'Contact Detail'}),
      announce: true
    },
  },
  {
    path: '/event/calls',
    name: 'Status',
    component: Status,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Status', 'en': 'Status'}),
      announce: false
    }
  },
  {
    path: '/event/support',
    name: 'Help',
    component: Help,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Support Chat', 'en': 'Support'}),
      announce: false
    }
  },
  {
    path: '/event/stage',
    name: 'Stage',
    component: Streaming,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Plenum', 'en': 'Stage'}),
      announce: false,
      mucname: 'muc-livestream'
    },
    children: [
      {
        path: 'playing',
        name: 'StagePlaying',
        component: Streaming,
        meta: {
          needsauth: true,
          title: store.getters.i18n({'de': 'Plenum Streaming', 'en': 'Plenum Streaming'}),
          announce: true,
          mucname: 'muc-livestream'
        }
      }
    ]
  },
  {
    path: '/event/stage2',
    name: 'Workshop',
    component: Streaming,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Workshop', 'en': 'Workshop'}),
      announce: false,
      mucname: 'muc-workshop'
    },
    children: [
      {
        path: 'playing',
        name: 'WorkshopPlaying',
        component: Streaming,
        meta: {
          needsauth: true,
          title: store.getters.i18n({'de': 'Workshop Streaming', 'en': 'Workshop Streaming'}),
          announce: true,
          mucname: 'muc-workshop'
        }
      }
    ]
  },
  {
    path: '/event/photoexhibition',
    name: 'PhotoExhibition',
    component: PhotoExhibition,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Photo exhibition', 'en': 'Photo exhibition'}),
      announce: false
    }
  },
  {
    path: '/event/coffeebar',
    name: 'Coffeebar',
    component: Coffeebar,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'CoffeeBar', 'en': 'CoffeeBar'}),
      announce: false,
      mucname: 'muc-cafe'
    }
  },
  {
    path: '/event/medialibrary',
    name: 'Mediathek',
    component: Mediathek,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Mediathek', 'en': 'Media library'}),
      announce: false
    }
  },
  {
    path: '/event/speakers',
    name: 'SpeakerOverview',
    component: SpeakerOverview,
    meta: {
      needsauth: false,
      title: store.getters.i18n({'de': 'Speaker', 'en': 'Speaker'}),
      announce: false
    }
  },
  {
    path: '/event/speakers-details/:speakerid',
    name: 'SpeakerDetail',
    component: SpeakerDetail,
    meta: {
      needsauth: false,
      title: store.getters.i18n({'de': 'Speaker Detail', 'en': 'Speaker Detail'}),
      announce: true
    },
  },
  {
    path: '/event/partners',
    name: 'PartnerOverview',
    component: PartnerOverview,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Partner', 'en': 'Partner'}),
      announce: false
    }
  },
  {
    path: '/event/partner-details/:partnerid',
    name: 'PartnerDetail',
    component: PartnerDetail,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Partner Detail', 'en': 'Partner Detail'}),
      announce: true
    },
  },
  {
    path: '/event/program',
    name: 'AgendaOverview',
    component: AgendaOverview,
    meta: {
      needsauth: false,
      title: store.getters.i18n({'de': 'Agenda', 'en': 'Agenda'}),
      announce: false
    }
  },
  {
    path: '/event/program-details/:agendaid',
    name: 'AgendaDetail',
    component: AgendaDetail,
    meta: {
      needsauth: false,
      title: store.getters.i18n({'de': 'Agenda Detail', 'en': 'Agenda Detail'}),
      announce: true
    },
  },
  {
    path: '/event/appointment-details/:meetingid',
    name: 'MeetingDetail',
    component: MeetingDetail,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Meeting Detail', 'en': 'Meeting Detail'}),
      announce: true
    },
  },
  {
    path: '/event/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Calendar', 'en': 'Calendar'}),
      announce: false
    }
  },
  {
    path: '/event/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      needsauth: true,
      title: store.getters.i18n({'de': 'Profile', 'en': 'Profile'}),
      announce: false,
      tab: 1
    },
    children: [
      {
        path: 'my-networking',
        name: 'Profile1',
        component: Profile,
        meta: {
          needsauth: true,
          title: store.getters.i18n({'de': 'Profile', 'en': 'Profile'}),
          announce: true,
          tab: 1
        }
      },
      {
        path: 'my-profile',
        name: 'Profile2',
        component: Profile,
        meta: {
          needsauth: true,
          title: store.getters.i18n({'de': 'Profile', 'en': 'Profile'}),
          announce: true,
          tab: 2
        }
      },
      {
        path: 'my-photo',
        name: 'Profile3',
        component: Profile,
        meta: {
          needsauth: true,
          title: store.getters.i18n({'de': 'Profile', 'en': 'Profile'}),
          announce: true,
          tab: 3
        }
      },
      {
        path: 'my-video-call-settings',
        name: 'Profile4',
        component: Profile,
        meta: {
          needsauth: true,
          title: store.getters.i18n({'de': 'Profile', 'en': 'Profile'}),
          announce: true,
          tab: 4
        }
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

// Statt firebase.database.ThenableReference => DatabaseReference | null
let playannounce: DatabaseReference | null = null

export const announceLeave = async () => {
  if (lastannouncement !== "") {
    store.dispatch("i/announce", { nsp: lastannouncement, ev: "ileft" })
    lastannouncement = ""
  }
}

export const inIframe = () => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

router.beforeEach((to, from, next) => {
  if (inIframe() && to.path !== "/frameerror") {
    next("/frameerror")
    return
  }

  if (to.path === "/projects/dw/gmf/eventapp/") {
    next("/")
  }

  document.title = `Deutsche Welle - GMF 2025`

  ;(async (to, from, next) => {
    await populateInital

    // Authentifizierung prüfen, aber nur für geschützte Routen
    if (to.meta && to.meta.needsauth === true && store.state.jwt === "") {
      next('/event/login')
      return
    }

    // Remove old presence if needed
    if (playannounce !== null) {
      try {
        await remove(playannounce)
      } catch (e) {
        console.error(e)
      }
      playannounce = null
    }

    if (to.name === "Logout") {
      await store.dispatch("logout")
      next('/event/login')
      location.reload()
      return
    }

    if (
      (from.meta && from.meta.mucname && from.meta.mucname !== "") &&
      (!to.meta || !to.meta.mucname || to.meta.mucname !== from.meta.mucname)
    ) {
      store.commit("i/setMucName", "")
      store.dispatch("i/removeInRoomlisteners", from.meta.mucname)
    }

    if (
      (to.meta && to.meta.mucname && to.meta.mucname !== "") &&
      (!from.meta || !from.meta.mucname || from.meta.mucname !== to.meta.mucname)
    ) {
      store.commit("i/setMucName", to.meta.mucname)
      store.dispatch("i/addInRoomlisteners", to.meta.mucname)
    }

    let targetnamespace = ""
    if (to.params && to.params.speakerid && to.params.speakerid !== "") {
      targetnamespace = `speaker-${to.params.speakerid}-view`
    }

    if ((to.meta && to.meta.announce) || targetnamespace !== "") {
      switch (to.name) {
        case "StagePlaying":
        case "WorkshopPlaying":
          // @ts-ignore
          targetnamespace = `${store.state.i.mucname}`
          try {
            // @ts-ignore
            // "i/setPresence" gibt DatabaseReference zurück.
            playannounce = await store.dispatch("i/setPresence", `${store.state.i.mucname}`)
          } catch (e) {
            playannounce = null
          }
          break
        case "Booth":
          break
        default:
          break
      }
      if (targetnamespace !== "" && targetnamespace !== lastannouncement) {
        store.dispatch("i/announce", { nsp: targetnamespace, ev: "ijoined" })
        lastannouncement = targetnamespace
      }
    } else {
      await announceLeave()
    }

    if (to.meta && from.meta && to.meta.bodyClass !== from.meta.bodyClass) {
      document.body.className = `${to.meta.bodyClass}`
    }

    next()
  })(to, from, next)
})

window.addEventListener("beforeunload", async () => {
  await announceLeave()
}, { passive: true })

export default router
