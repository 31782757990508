import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-sm-6 col-md-3 linked-partner-logo pointer mb-2" }
const _hoisted_2 = ["src"]

import { useRouter } from 'vue-router'
import { Partner } from "@/store/i/types"


export default /*@__PURE__*/_defineComponent({
  __name: 'PartnerEntry',
  props: {
    partner: {}
  },
  setup(__props: any) {

const props = __props

const router = useRouter()

const navigatepartner = () => {
  router.push({
    name: 'PartnerDetail',
    params: { partnerid: `${props.partner.objvalueid}` }
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "card",
      onClick: navigatepartner
    }, [
      _createElementVNode("img", {
        src: `/files/exhibitor/${props.partner.objvalueid}/logo.jpg?nocache=${(new Date()).getHours()}`,
        class: "",
        onerror: "this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';",
        style: {"width":"100%","height":"100%"},
        alt: ""
      }, null, 8, _hoisted_2)
    ])
  ]))
}
}

})