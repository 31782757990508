import { Module } from 'vuex';
import {InteractionState} from "@/store/i/types";
import {RootState} from "@/store/types";
import mutations from "@/store/i/mutations";
import actions from "@/store/i/actions";
import getters from "@/store/i/getters";


const interaction: Module<InteractionState, RootState> = {
    namespaced: true,
    state: {
        connected: false,
        historyCutoff: null,
        toasts: [],
        mucname: "",
        currentagenda: [],
        questions: [],
        contactschats: [],
        contacts_networking: [],
        connections_networking: [],
        meetings: [],
        members: [],
        publicchats: [],
        speakers: [],
        partners: [],
        me: {
            objvalueid: 0,
            groupid: 0,
            defaulttimestamp: 0,
            gender: 0,
            title: 0,
            tn_type: 0,
            is_test: false,
            firstname: "",
            lastname: "",
            position: "",
            orgname: "",
            email: "",
            show_email: false,
            phone: "",
            country: "",
            show_vcard: false,
            twitter: "",
            facebook: "",
            linkedin: "",
            xing: "",
            cv: "",
            ranking_a: 0,
            ranking_b: 0,
            ranking_c: 0,
            ranking_d: 0,
            ranking_e: 0,
            ranking_f: 0,
            ranking_g: 0,
            ranking_h: 0,
            ranking_i: 0,
            ranking_j: 0,
            ranking_k: 0,
            ranking_l: 0,
            ranking_m: 0,
            ranking_place: 0,
            accept_networking: false,
            networking_question1: [],
            networking_question2: [],
            networking_question3: "",
            fav_agenda_items: [],
            countrylist: [],
            networkinglist: [],
            score_reaction: 0,
        },
        rooms: [],
        votings: [],
        ballots: [],
        dmmessages: [],
        supportmessages: [],
        showsmallchat: false,
        agenda: [],
        image: [],
        videoFullscreen: false
    },
    actions,
    mutations,
    getters
}

export default interaction