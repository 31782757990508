import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "row g-3 mb-5" }
const _hoisted_3 = { class: "row rowtabs" }
const _hoisted_4 = { class: "col-md-6 col-lg-3 mb-1" }
const _hoisted_5 = { class: "col-md-6 col-lg-3 mb-1" }
const _hoisted_6 = {
  key: 0,
  class: "col-md-6 col-lg-3 mb-1"
}
const _hoisted_7 = { class: "col-md-6 col-lg-3 mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_OnBoarding1 = _resolveComponent("OnBoarding1")!
  const _component_OnBoarding2 = _resolveComponent("OnBoarding2")!
  const _component_OnBoarding3 = _resolveComponent("OnBoarding3")!
  const _component_OnBoarding4 = _resolveComponent("OnBoarding4")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("h3", null, "My Profile", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, {
            to: {name: 'Profile1'},
            class: _normalizeClass({'btn highlight_tabs': true, 'btn-active': _ctx.route.meta.tab === 1})
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Networking")
            ])),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_link, {
            to: {name: 'Profile2'},
            class: _normalizeClass({'btn highlight_tabs': true, 'btn-active': _ctx.route.meta.tab === 2})
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("My Profile")
            ])),
            _: 1
          }, 8, ["class"])
        ]),
        (_ctx.store.state.config.me.tn_type !== 8)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_router_link, {
                to: {name: 'Profile3'},
                class: _normalizeClass({'btn highlight_tabs': true, 'btn-active': _ctx.route.meta.tab === 3})
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Upload picture")
                ])),
                _: 1
              }, 8, ["class"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_router_link, {
            to: {name: 'Profile4'},
            class: _normalizeClass({'btn highlight_tabs': true, 'btn-active': _ctx.route.meta.tab === 4})
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Video call settings")
            ])),
            _: 1
          }, 8, ["class"])
        ])
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_OnBoarding1)
      ], 512), [
        [_vShow, _ctx.route.meta.tab === 1]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_OnBoarding2)
      ], 512), [
        [_vShow, _ctx.route.meta.tab === 2]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_OnBoarding3)
      ], 512), [
        [_vShow, _ctx.route.meta.tab === 3]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_OnBoarding4)
      ], 512), [
        [_vShow, _ctx.route.meta.tab === 4]
      ])
    ])
  ]))
}