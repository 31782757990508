<template>
  <div class="gallery d-block">
    <div class="carousel slide" ref="carouselElem">
      <div class="carousel-inner h-100">
        <div v-for="(image, index) in images"
             :key="`carousel_item_${index}`"
             class="carousel-item h-100"
             :class="{'active': index === 0}">
          <div class="h-100 d-flex justify-content-center">
            <img :src="image.image" class="d-block h-100" :alt="image.title">
          </div>
          <div class="carousel-caption d-none d-md-block">
            <h5>{{ image.title }}</h5>
            <p>{{ image.content }}</p>
          </div>
        </div>
      </div>
      <button class="carousel-control carousel-control-prev" type="button" @click="prev">
        <span class="background" aria-hidden="true"></span>
        <span class="icon carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control carousel-control-next" type="button" @click="next">
        <span class="background" aria-hidden="true"></span>
        <span class="icon carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    <div class="thumbnails">
      <div v-for="(image, index) in images"
           :key="`thumbnail_${index}`"
           class="thumbnail"
           @click="move(index)"
           :style="`background-image: url('${image.image}');`"
           :class="{'active': index === activeImageIndex}"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Carousel } from 'bootstrap'

interface Image {
  content: string
  image: string
  key: string
  sortorder: string
  title: string
}

const props = defineProps<{
  images: Image[]
}>()

const carouselElem = ref<HTMLElement | null>(null)
const carousel = ref<Carousel | null>(null)
const activeImageIndex = ref(0)

onMounted(() => {
  if (carouselElem.value === null) {
    console.warn("Couldn't find carousel")
    return
  }

  carousel.value = Carousel.getOrCreateInstance(carouselElem.value)
  carouselElem.value.addEventListener('slide.bs.carousel', (event: any) => {
    activeImageIndex.value = event.to
  })
})

const next = () => {
  if (carousel.value === null) return
  carousel.value.next()
}

const prev = () => {
  if (carousel.value === null) return
  carousel.value.prev()
}

const move = (index: number) => {
  if (carousel.value === null) return
  carousel.value.to(index)
}
</script>

<style scoped lang="scss">
.gallery {
    .carousel {
        margin: 0.1rem;

        background-color: rgba(0,0,0,.2);
        aspect-ratio: 1024/694;
        flex: 0 0;

        .carousel-caption {
            text-shadow: 1px 1px 2px #000;
        }

        .carousel-control {
            &.carousel-control-prev .background {
              background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0));
            }
            
            &.carousel-control-next .background {
              background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.5));
            }
          
            .icon {
                color: white !important;
                text-shadow: 1px 1px 2px #000;
            }

            .background {
                opacity: 0;
                width: 100%;
                height: 100%;

                position: absolute;
                top: 0;
                left: 0;

                transition: opacity .2s linear;
            }
            &:hover .background {
                opacity: 1;
            }
        }
    }
    .thumbnails {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
        grid-gap: 5px;

        margin: 0.1rem;

        .thumbnail {
            aspect-ratio: 1024/694;
            width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin: 2px;
            outline: 2px solid transparent;
            transition: outline-color 0.2s linear;

            opacity: 1;

            cursor: pointer;

            &:hover {
                opacity: 0.6;
            }
            &.active {
                outline-color: rgb(31, 46, 54);
                opacity: 1;
            }
        }
    }
}
</style>