import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "row g-3 mb-5 highlights" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Agenda = _resolveComponent("Agenda")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "pb-1" }, "Program", -1)),
      _createVNode(_component_Agenda, { agendatype: "agenda" })
    ])
  ]))
}