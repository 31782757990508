import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "loop", "autoplay", "muted", "poster"]
const _hoisted_2 = ["src", "type"]
const _hoisted_3 = { class: "vjs-no-js" }

import { ref, onMounted, onBeforeUnmount, computed, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import videojs from 'video.js'

export interface VideoPlayerPosition {
  position: number
  duration: number
  positionPercentage: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideojsWrapper',
  props: {
    playerid: {},
    ssrc: {},
    splaceholder: {},
    sautostart: { type: Boolean },
    sautopip: { type: Boolean },
    sloop: { type: Boolean },
    watchtime: {},
    videofiletype: {}
  },
  emits: ["player-ready", "playerPositionChanged", "fullscreenchange", "canPlay", "playerError", "playbackPlayed", "playbackStarted", "playbackSeeking", "playbackPaused", "playbackEnded"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const store = useStore()
const notplayed = ref(true)
const isplaying = ref(false)
const isfullscreen = ref(false)
const duration = ref(0)
const position = ref(0)
const positionPercentage = ref(0)
const playerposelement = ref<HTMLElement | null>(null)
const playerinst = ref<any>(undefined)
const autopipd = ref(false)

const pprefix = computed(() => {
  if (!store.state.config.project) {
    return ''
  }

  switch (typeof store.state.config.project) {
    case "object":
      return store.state.config.project.pprefix
    case "string":
      const projectparts: string[] = store.state.config.project.split('.')
      if (!projectparts || projectparts.length < 2) {
        return ''
      }
      return projectparts[1]
    default:
      return ''
  }
})

watch(() => props.ssrc, async () => {
  await nextTick()
  if (!playerinst.value) return

  playerinst.value.src({
    src: props.ssrc,
    type: props.videofiletype
  })

  await nextTick()
  playerinst.value.play()
})

watch(() => props.splaceholder, async () => {
  await nextTick()
  if (!playerinst.value) return
  playerinst.value.poster(props.splaceholder)
})

watch(() => props.sloop, async () => {
  await nextTick()
  if (!playerinst.value) return
  playerinst.value.loop(props.sloop)
})

watch(() => store.state.visible, async () => {
  if (playerinst.value) {
    if (store.state.visible && autopipd.value) {
      if (playerinst.value.isInPictureInPicture()) {
        try {
          await playerinst.value.exitPictureInPicture()
        } catch (e) {
          // Handle error
        }
      }
      autopipd.value = false
      return
    }

    if (
        !store.state.visible &&
        props.sautopip &&
        isplaying.value &&
        !isfullscreen.value &&
        !playerinst.value.isInPictureInPicture()
    ) {
      try {
        await playerinst.value.requestPictureInPicture()
        autopipd.value = playerinst.value.isInPictureInPicture()
      } catch (e) {
        autopipd.value = false
      }
    }
  }
})

const positionRecalc = () => {
  if (playerinst.value && duration.value > 0) {
    position.value = playerinst.value.currentTime()
    positionPercentage.value = Math.round(position.value / duration.value * 100) / 100
    emit("playerPositionChanged", {
      duration: duration.value,
      position: position.value,
      positionPercentage: positionPercentage.value
    })
  }
}

const handlePlayerPosElement = () => {
  if (playerinst.value) {
    const playerdom = playerinst.value.contentEl() as HTMLElement
    if (!playerposelement.value && notplayed.value && props.watchtime && props.watchtime > 0 && duration.value > 0) {
      playerposelement.value = document.createElement("DIV")
      const percentage = props.watchtime / duration.value * 100
      playerposelement.value.setAttribute("class", `maxplaypos${percentage >= 95 ? ' completed':''}`)
      playerposelement.value.setAttribute("style", `width:${percentage}%;`)
      playerdom.append(playerposelement.value)
    } else if (playerposelement.value !== null) {
      playerdom.removeChild(playerposelement.value)
      playerposelement.value = null
    }
  }
}

const playerReady = async () => {
  if (!playerinst.value) return

  emit('player-ready', playerinst.value)

  playerinst.value.on("fullscreenchange", () => {
    if (playerinst.value) {
      isfullscreen.value = playerinst.value.isFullscreen()
      emit('fullscreenchange', isfullscreen.value)
    }
  })

  playerinst.value.on("durationchange", () => {
    if (playerinst.value) {
      duration.value = playerinst.value.duration()
      positionRecalc()
      handlePlayerPosElement()
    }
  })

  playerinst.value.on("timeupdate", () => {
    if (playerinst.value) {
      positionRecalc()
    }
  })

  playerinst.value.on("loadedmetadata", () => {
    if (playerinst.value) {
      duration.value = playerinst.value.duration()
      if (props.watchtime && props.watchtime > 0 && position.value === 0) {
        const percentage = props.watchtime / duration.value * 100
        if (percentage < 95) {
          playerinst.value.currentTime(props.watchtime)
        }
      }
    }
  })

  playerinst.value.on("canplay", () => {
    if (playerinst.value) {
      emit('canPlay', playerinst.value)
    }
  })

  playerinst.value.on("error", (e: any) => {
    if (playerinst.value) {
      emit('playerError', e)
    }
  })

  playerinst.value.on("play", () => {
    if (playerinst.value) {
      isplaying.value = true
      emit('playbackPlayed', playerinst.value)
    }
  })

  playerinst.value.one("play", () => {
    if (playerinst.value) {
      notplayed.value = false
      handlePlayerPosElement()
      emit('playbackStarted', playerinst.value)
    }
  })

  playerinst.value.on("seeking", () => {
    if (playerinst.value) {
      emit('playbackSeeking', playerinst.value)
      positionRecalc()
    }
  })

  playerinst.value.on("pause", () => {
    if (playerinst.value) {
      isplaying.value = false
      emit('playbackPaused', playerinst.value)
    }
  })

  playerinst.value.on("abort", () => {
    if (playerinst.value) {
      isplaying.value = false
    }
  })

  playerinst.value.on("ended", () => {
    if (playerinst.value) {
      isplaying.value = false
      emit('playbackEnded', playerinst.value)
    }
  })

  playerinst.value.fluid(true)
}

onMounted(() => {
  const element = document.getElementById(props.playerid)
  if (!element) {
    throw new Error(`Element with id ${props.playerid} not found`)
  }
  playerinst.value = videojs(element, {
    fluid: true,
    autoplay: props.sautostart,
    muted: props.sautostart,
    loop: props.sloop
  }, playerReady)
})

onBeforeUnmount(() => {
  emit('player-ready', undefined)
  if (playerinst.value) {
    playerinst.value.dispose()
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("video", {
    id: _ctx.playerid,
    class: _normalizeClass(`video-js vjs-default-skin vjs-${pprefix.value}-skin vjs-big-play-centered`),
    controls: "",
    playsinline: "",
    loop: _ctx.sloop,
    autoplay: _ctx.sautostart,
    muted: _ctx.sautostart,
    preload: "auto",
    poster: _ctx.splaceholder,
    "data-setup": "{}"
  }, [
    _createElementVNode("source", {
      src: _ctx.ssrc,
      type: _ctx.videofiletype
    }, null, 8, _hoisted_2),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(store).getters.i18n({de:'Ihr Browser unterstützt kein HTML-Video.',en:'Your browser does not support HTML-Video.'})), 1)
  ], 10, _hoisted_1))
}
}

})