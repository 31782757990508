import { createApp } from 'vue'
import mitt from 'mitt'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import HorizontalNav from "@/components/HorizontalNav.vue"
import VerticalNav from "@/components/VerticalNav.vue"
import FontAwesomeIcon from "@/components/FontAwesomeIcon.vue"
import $ from "jquery"
import VueConfetti from 'vue-confetti'
import { createGtm } from 'vue-gtm'
import * as mdb from 'mdb-vue-ui-kit'
import './styles/app.scss';


// jQuery global
(window as any).$ = $

// App erstellen
const app = createApp(App)
const emitter = mitt()
app.provide('emitter', emitter)

// Komponenten global registrieren
app.component('HorizontalNav', HorizontalNav)
app.component('VerticalNav', VerticalNav)
app.component('FontAwesomeIcon', FontAwesomeIcon)

// GTM konfigurieren
app.use(createGtm({
  id: 'GTM-PBLMQ79',
  enabled: true,
  debug: process.env.NODE_ENV !== 'production',
  loadScript: true,
  vueRouter: router
}))

Object.keys(mdb).forEach(componentKey => {
  app.component(componentKey, mdb[componentKey])
})


// Plugins hinzufügen
app.use(router)
app.use(store)
app.use(VueConfetti)

// App mounten
app.mount('#app')