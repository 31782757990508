import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "mainform g-3 pb-4" }, [
      _createElementVNode("h3", null, "Page in frame"),
      _createElementVNode("p", null, [
        _createElementVNode("a", {
          href: "https://gmf-event.com/event/",
          target: "_blank"
        }, " Please log in via https://gmf-event.com/event/ ")
      ]),
      _createElementVNode("p", null, [
        _createElementVNode("a", {
          class: "btn btn-primary m-1",
          href: "https://gmf-event.com/event/",
          target: "_blank"
        }, " To login ")
      ])
    ], -1)
  ])))
}