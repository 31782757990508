<template>
  <section class="clearfix">
    <div class="chat-message">
      <div v-if="!ismine" class="card bg-other rounded w-75 z-depth-0 mb-0">
        <div class="card-body p-2">
          <p class="card-text text-black">{{ props.m.body }}</p>
        </div>
      </div>
      <p v-if="!ismine">{{ getDate(props.m.ts) }}</p>

      <div v-if="ismine" class="d-flex justify-content-end">
        <div class="card bg-primary rounded w-75 z-depth-0 mb-0">
          <div class="card-body p-2">
            <p class="card-text">{{ props.m.body }}</p>
          </div>
        </div>
      </div>
      <p v-if="ismine" class="d-flex justify-content-end">{{ getDate(props.m.ts) }}</p>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { DateTime } from 'luxon'
import _ from 'lodash'
import type { ChatContact, DMMessage, SupportMessage } from "@/store/i/types"
import type { EveUser } from "@/store/types"

const props = defineProps<{
  m: DMMessage | SupportMessage
}>()

const store = useStore()

const user = computed((): ChatContact | EveUser | null => {
  if (props.m && props.m.sender && props.m.sender !== "me" && props.m.sender !== "support") {
    const fuser = _.find(store.state.i.contactschats, (c) => c.fbuid === (props.m as DMMessage).sender)
    return fuser || null
  }
  return null
})

const ismine = computed((): boolean => {
  if (props.m) {
    switch (props.m.sender) {
      case "support":
        return false
      case "me":
      case store.state.config.fbuid:
        return true
      default:
        return !!(user.value && user.value.fbuid === store.state.config.fbuid)
    }
  }
  return false
})

const issupport = computed((): boolean => {
  if (props.m) {
    return props.m.sender === "support"
  }
  return false
})

const getDate = (date: number): string => {
  const setM = DateTime.fromSeconds(date)
  const ndate = DateTime.fromJSDate(store.state.tick)

  return setM
      .setLocale(store.getters.i18n({ de: 'de', en: 'en' }))
      .toLocaleString(DateTime.DATETIME_SHORT) + store.getters.i18n({ de: ' Uhr', en: ' ' })
}

onMounted(() => {
  if (props.m && !props.m.seen && !ismine.value) {
    const updatePL = {
      flag: 'SEEN',
      msgid: props.m.uuid
    }
    window.setTimeout(() => {
      store.dispatch(issupport.value ? 'i/setSupportFlag' : 'i/setDMFlag', updatePL)
    }, 2000)
  }
})
</script>

<style scoped lang="scss">
.chat-room.small-chat .profile-photo.message-photo {
  margin-top: 0;
}

.msgwrapper {
  max-width: 90%;
}

.bg-other {
  background-color: #dddddd !important;
}
</style>