<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import VideoChatList from "@/components/i/VideoChatList.vue"
import type { Message } from "@/store/i/types"
import _ from "lodash"

// Props
const props = defineProps<{
  chattype: "chat" | "questions"
}>()

// Store
const store = useStore()

// Reactive state
const currentText = ref('')
const disable = ref(false)
const maxmsgs = ref(20)
const shiftDown = ref(false)

// Computed
const msgs = computed(() => {
  const messages = props.chattype === "questions"
      ? store.state.i.questions
      : store.state.i.publicchats

  const msgTime = 0

  const neccmsgs = _.orderBy(
      _.filter(
          messages,
          (m: Message) => m.ts * 1000 > msgTime && m.roomname === store.state.i.mucname
      ),
      [(m: Message) => m.ts],
      ["desc"]
  )

  return _.slice(neccmsgs, 0, _.min([maxmsgs.value, neccmsgs.length])).reverse()
})

const usedCharacters = computed(() => {
  const SPECIAL_CHARS_REGEX = /[*.:\/\\?()&%$§!+#,;-]/g;
  const npoints = currentText.value.replace(SPECIAL_CHARS_REGEX, "");
  return npoints.length
})

const remainingChraracters = computed(() => 280 - usedCharacters.value)

const textPlaceholder = computed(() => {
  if (props.chattype === "questions") {
    return store.getters.i18n({de: 'Ihre Frage', en: 'Your question'})
  }
  return store.getters.i18n({de: 'Ihre Nachricht', en: 'Your message'})
})

const textPlaceholder2 = computed(() => {
  if (props.chattype === "questions") {
    return store.getters.i18n({de: '', en: ''})
  }
  return store.getters.i18n({
    de: 'Bitte beachten Sie, dass Ihre Nachricht für alle Teilnehmer sichtbar ist.',
    en: 'Please be advised that your message will be displayed to all other participants.'
  })
})

const disabledByQuestionCount = computed(() => !store.state.i.connected)

// Methods
const scrollReachedTop = async () => {
  const ccount = msgs.value.length
  const oldest_chat = _.min([0, ..._.map(msgs.value, (m: Message) => m.ts)]) as number
  if (ccount > 0 && oldest_chat > 0) {
    await store.dispatch("i/getMorePubchats", oldest_chat)
  }
  maxmsgs.value += 5
}

const shiftC = (nd: boolean|Event) => {
  if (typeof nd === "boolean") {
    shiftDown.value = nd
    return
  }
  const event = nd as KeyboardEvent
  switch (event.key.toLowerCase()) {
    case "shift":
      shiftDown.value = true
      break
    case "enter":
      break
    default:
      shiftDown.value = false
  }
}

const sendByEnter = (e: Event) => {
  if (!shiftDown.value) {
    e.preventDefault()
    send(e)
  }
}

const send = async (e: Event) => {
  if (!disabledByQuestionCount.value &&
      usedCharacters.value > 1 &&
      remainingChraracters.value >= 0) {
    disable.value = true
    if (props.chattype === "questions") {
      await store.dispatch('i/pushInteractionMessage', currentText.value)
    } else {
      await store.dispatch('i/pushPublicChatMessage', currentText.value)
    }
    disable.value = false
    currentText.value = ''
  }
}
</script>

<template>
  <div :class="{
    'chat-area': true,
    'chat-type-chat': (chattype === 'chat'),
    'chat-type-questions': true
  }">
    <VideoChatList
        :chattype="chattype"
        :msgs="msgs"
        @reachedtop="scrollReachedTop"
    />
    <div class="enter-area row">
      <div class="col">
        <div class="mb-2" v-if="textPlaceholder2 !== ''">
          <small>{{ textPlaceholder2 }}</small>
        </div>
        <div class="form-outline mb-2">
          <input
              type="text"
              class="form-control"
              spellcheck="true"
              autocomplete="off"
              :disabled="disable || disabledByQuestionCount"
              v-model.trim="currentText"
              @keydown="shiftC"
              @keyup="shiftC(false)"
              @keypress.enter="sendByEnter"
              :id="`enter-text-${chattype}`"
          />
          <label class="form-label" :for="`enter-text-${chattype}`">
            {{ textPlaceholder }}
          </label>
        </div>
        <div class="mb-2 d-flex">
          <small>
            {{ remainingChraracters }}
            {{ store.getters.i18n({de: 'Zeichen zur Verfügung', en:'characters remaining'}) }}
          </small>
          <button
              :data-tracking-name="chattype"
              class="ms-auto btn btn-primary"
              :disabled="disable || disabledByQuestionCount"
              type="button"
              @click.stop="send"
          >
            {{ store.getters.i18n({de: 'Senden', en: 'Send'}) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>