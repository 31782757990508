<template>
  <div v-if="show">
    <div class="modal fade show" style="display: block;" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-lg modal-fullscreen-md-down">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">Appointment request to {{contactEntry?.firstname}} {{contactEntry?.lastname}}</h2>
            <button type="button" data-mdb-dismiss="modal" aria-label="Close" class="btn-close" @click.prevent="close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="createMeeting(contactEntry)">
              <div v-show="successtring.de !== ''" class="alert alert-success col-lg-12">
                {{ store.getters.i18n(successtring) }}
              </div>

              <div v-show="errorstring.de !== ''" class="alert alert-danger mb-5">
                {{ store.getters.i18n(errorstring) }}
              </div>

              <p>
                Timeslots marked as "possibly not available" show the timeslots when the person may already be in another appointment.
              </p>

              <div class="mb-4">
                <label class="form-label" >Date</label>
                <select class="form-control"  v-model=date>
                  <option v-for="(e, ei) in days" :value=e.key>{{e.name}}</option>
                </select>
              </div>

              <div class="mb-4">
                <label class="form-label" >Duration</label>
                <select class="form-control"  v-model=duration @change="change">
                  <option v-for="(e, ei) in durations" :value=e.key>{{e.name}}</option>
                </select>
              </div>

              <div class="mb-4">
                <label class="form-label" >Time</label>
                <select class="form-control"  v-model=time>
                  <option v-for="(e, ei) in times" :value=e.key>{{e.name}} {{getNameAvailable(e.key)}}</option>
                </select>
              </div>

              <div class="mb-2">
                <label class="form-label" >Subject</label>
                <input type="text" v-model="meeting_title" class="form-control" />
              </div>

              <div class="mb-5">
                <label class="form-label" >Message</label>
                <textarea v-model="long_summary" class="form-control" />
              </div>

              <div class="d-flex align-items-center">
                <button class="btn btn-primary m-1" @click.prevent="close">Cancel</button>
                <button class="btn btn-primary m-1" type="submit"
                        :disabled="!date || !duration || !time || !meeting_title || !long_summary">
                  Send request
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted} from 'vue'
import { useStore } from 'vuex'
import { TranslatedText } from "@/store/types"
import { ContactsNetworking } from "@/store/i/types"
import { AppNetworkErrorOther, networkErrorText } from "@/crud"
import { DateTime } from 'luxon'
import _ from 'lodash'

const props = defineProps<{
  connection?: number
  toggle_modal?: boolean
}>()

const store = useStore()

// Reactive state
const show = ref(false)
const meeting_title = ref("")
const long_summary = ref("")
const date = ref("")
const duration = ref("")
const time = ref("")
const meetings = ref<any[]>([])
const meetings_loaded = ref(false)
const errorstring = ref<TranslatedText>({ de: '', en: '' })
const successtring = ref<TranslatedText>({ de: '', en: '' })

// Computed
const contactEntry = computed(() => {
  return _.find(
      store.state.i.contacts_networking,
      (e: ContactsNetworking) => `${e.objvalueid}` === `${props.connection}`
  )
})

const days = computed(() => [
  { key: "17.06.2024", name: 'June 17' },
  { key: "18.06.2024", name: 'June 18' },
])

const durations = computed(() => [
  { key: '1', name: '15 min' },
  { key: '2', name: '30 min' },
])

const times = computed(() => {
  switch (duration.value) {
    case "1":
      return [
        {key:"09:00", name:'9:00 AM'},
        {key:"09:15", name:'9:15 AM'},
        {key:"09:30", name:'9:30 AM'},
        {key:"09:45", name:'9:45 AM'},
        {key:"10:00", name:'10:00 AM'},
        {key:"10:15", name:'10:15 AM'},
        {key:"10:30", name:'10:30 AM'},
        {key:"10:45", name:'10:45 AM'},
        {key:"11:00", name:'11:00 AM'},
        {key:"11:15", name:'11:15 AM'},
        {key:"11:30", name:'11:30 AM'},
        {key:"11:45", name:'11:45 AM'},
        {key:"12:00", name:'12:00 PM'},
        {key:"12:15", name:'12:15 PM'},
        {key:"12:30", name:'12:30 PM'},
        {key:"12:45", name:'12:45 PM'},
        {key:"13:00", name:'13:00 PM'},
        {key:"13:15", name:'13:15 PM'},
        {key:"13:30", name:'13:30 PM'},
        {key:"13:45", name:'13:45 PM'},
        {key:"14:00", name:'14:00 PM'},
        {key:"14:15", name:'14:15 PM'},
        {key:"14:30", name:'14:30 PM'},
        {key:"14:45", name:'14:45 PM'},
        {key:"15:00", name:'15:00 PM'},
        {key:"15:15", name:'15:15 PM'},
        {key:"15:30", name:'15:30 PM'},
        {key:"15:45", name:'15:45 PM'},
        {key:"16:00", name:'16:00 PM'},
        {key:"16:15", name:'16:15 PM'},
        {key:"16:30", name:'16:30 PM'},
        {key:"16:45", name:'16:45 PM'},
        {key:"17:00", name:'17:00 PM'},
        {key:"17:15", name:'17:15 PM'},
        {key:"17:30", name:'17:30 PM'},
        {key:"17:45", name:'17:45 PM'},
      ]
    case "2":
      return [
        {key:"09:00", name:'9:00 AM'},
        {key:"09:30", name:'9:30 AM'},
        {key:"10:00", name:'10:00 AM'},
        {key:"10:30", name:'10:30 AM'},
        {key:"11:00", name:'11:00 AM'},
        {key:"11:30", name:'11:30 AM'},
        {key:"12:00", name:'12:00 PM'},
        {key:"12:30", name:'12:30 PM'},
        {key:"13:00", name:'13:00 PM'},
        {key:"13:30", name:'13:30 PM'},
        {key:"14:00", name:'14:00 PM'},
        {key:"14:30", name:'14:30 PM'},
        {key:"15:00", name:'15:00 PM'},
        {key:"15:30", name:'15:30 PM'},
        {key:"16:00", name:'16:00 PM'},
        {key:"16:30", name:'16:30 PM'},
        {key:"17:00", name:'17:00 PM'},
        {key:"17:30", name:'17:30 PM'},
      ]
    default:
      return null
  }
})

// Methods
const close = () => {
  date.value = ''
  duration.value = ''
  time.value = ''
  meeting_title.value = ''
  long_summary.value = ''
  show.value = false
  meetings_loaded.value = false
}

const reset_message = () => {
  errorstring.value = { de: '', en: '' }
  successtring.value = { de: '', en: '' }
}

const change = () => {
  time.value = ''
}

const getNameAvailable = (key: string) => {
  const meetingDate = new Date(DateTime.fromFormat(`${date.value} ${key}`, "dd.MM.yyyy hh:mm").toISO()).getTime()
  let show_text = false

  Object.entries(meetings.value).forEach(([key, val]) => {
    const startdate = new Date(val["startdate"]).getTime()
    const enddate = new Date(val["enddate"]).getTime()
    if (meetingDate >= startdate && meetingDate < enddate) {
      show_text = true
      return true
    }
  })

  return show_text ? "possibly not available" : ""
}

const createMeeting = async (ContactEntry: any) => {
  try {
    const meetingData = {
      objvalueid: ContactEntry.objvalueid,
      startdate: `${date.value} ${time.value}`,
      duration: duration.value,
      meeting_title: meeting_title.value,
      long_summary: long_summary.value,
    }

    errorstring.value = { de: '', en: '' }
    successtring.value = { de: '', en: '' }

    const dpr = await store.dispatch("i/createMeeting", meetingData)
    setTimeout(reset_message, 2000)

    if (dpr) {
      successtring.value = {
        de: "Appointment request sent",
        en: "Appointment request sent"
      }

      const contactData = {
        fbuid: ContactEntry.fbuid,
        objvalueid: ContactEntry.objvalueid,
        gender: ContactEntry.gender,
        title: ContactEntry.title,
        firstname: ContactEntry.firstname,
        lastname: ContactEntry.lastname,
        orgname: ContactEntry.orgname
      }

      await store.dispatch('i/checkIfContactNeedsToBeAdded', { ...contactData })
      await store.dispatch('i/sendmydm', {
        body: "I sent you a request for an appointment",
        ts: Date.now() / 1000,
        sender: store.state.config.fbuid,
        receiver: contactData.fbuid
      })
      close()
    } else {
      errorstring.value = {
        de: "Appointment request could not be created",
        en: "Appointment request could not be created"
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

// Watchers
watch(() => props.toggle_modal, () => {
  show.value = !!props.connection
})

watch([() => date.value, () => duration.value], async () => {
  if (contactEntry.value && date.value && duration.value && !meetings_loaded.value) {
    try {
      errorstring.value = { de: '', en: '' }
      meetings.value = await store.dispatch("i/getSlotsNotAvailable", contactEntry.value.objvalueid)
      meetings_loaded.value = true
    } catch (e) {
      errorstring.value = networkErrorText(e as AppNetworkErrorOther)
    }
  }
})

// Lifecycle
onMounted(() => {
  show.value = !!props.connection
})
</script>
