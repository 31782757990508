import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "navbar navbar-expand-lg navbar-light bg-light p0" }
const _hoisted_2 = { class: "container-fluid container-nav p0" }
const _hoisted_3 = {
  key: 0,
  class: "navbar-nav me-auto"
}
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = {
  key: 1,
  class: "navbar-nav me-auto"
}
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "nav-item" }
const _hoisted_13 = { class: "nav-item" }
const _hoisted_14 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_15 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_16 = {
  key: 1,
  class: "navbar-nav navbar-icons ms-auto"
}
const _hoisted_17 = {
  key: 0,
  class: "nav-item me-3"
}
const _hoisted_18 = ["title"]
const _hoisted_19 = { class: "nav-item me-3" }
const _hoisted_20 = { class: "nav-item me-3" }
const _hoisted_21 = { class: "nav-item me-3" }
const _hoisted_22 = { class: "nav-item me-3 mt-2" }
const _hoisted_23 = { key: 2 }

import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import _ from "lodash"
import { AgendaPoint, ConnectionNetworking } from "@/store/i/types"


export default /*@__PURE__*/_defineComponent({
  __name: 'HorizontalNav',
  setup(__props) {

const store = useStore()
const route = useRoute()

const isDropdownActive = ref(false)
const mainNavActive = ref(false)

const ChangeNavi = () => {
  isDropdownActive.value = !isDropdownActive.value
}

const toggleMainNav = () => {
  mainNavActive.value = !mainNavActive.value
}

const hasNavigation = computed(() => {
  const excludedRoutes = ["Login", "Step1", "Step2", "Step3", "Step4", "404", "FramesetError"]
  return !excludedRoutes.some(n => n === route.name)
})

const isLoggedIn = computed(() => {
  return store.state.config.me.objvalueid !== -1
})

const Received = computed(() => {
  return _.filter(
      store.getters['i/sortedConnectionsNetworking'],
      (c: ConnectionNetworking) => c.dbstate != -1 &&
          c.status == 1 &&
          c.receiver == store.state.config.me.objvalueid
  )
})

const callAmount = computed(() => {
  return store.getters['members/incomingCalls'](false).length
})

const Live1 = computed(() => {
  return _.find(
      store.state.i.agenda,
      (ap: AgendaPoint) => ap.dbstate > -1 && ap.now_live && !ap.is_break
  )
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: {name: 'Welcome'},
        class: _normalizeClass({'navbar-brand logo': true})
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            src: "/projects/dw/gmf/assets/images/gmf_logo_2024.jpg",
            alt: "Global Media Forum",
            class: "GMF-Logo-XS-2021-RGB",
            border: "0"
          }, null, -1)
        ])),
        _: 1
      }),
      (hasNavigation.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(`navi-main ${mainNavActive.value ? 'navi-main-active' : ''}`),
            id: "navbar"
          }, [
            (isLoggedIn.value)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                  _createElementVNode("li", _hoisted_4, [
                    _createVNode(_component_router_link, {
                      to: {name: 'Welcome'},
                      class: _normalizeClass({'nav-link home': true})
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createElementVNode("i", { class: "fal fa-2x fa-home" }, null, -1),
                        _createElementVNode("span", { class: "sr-only" }, "Home", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_5, [
                    _createVNode(_component_router_link, {
                      to: {name: 'AgendaOverview'},
                      class: _normalizeClass({'nav-link': true})
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("Program")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_6, [
                    _createVNode(_component_router_link, {
                      to: {name: 'SpeakerOverview'},
                      class: _normalizeClass({'nav-link': true})
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Speakers")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_7, [
                    _createVNode(_component_router_link, {
                      to: {name: 'Mediathek'},
                      class: _normalizeClass({'nav-link': true})
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("On demand")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_8, [
                    _createVNode(_component_router_link, {
                      to: {name: 'Networking1'},
                      class: _normalizeClass({'nav-link': true})
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("Networking")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_9, [
                    _createVNode(_component_router_link, {
                      to: {name: 'PartnerOverview'},
                      class: _normalizeClass({'nav-link': true})
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("Partners")
                      ])),
                      _: 1
                    })
                  ])
                ]))
              : (_openBlock(), _createElementBlock("ul", _hoisted_10, [
                  _createElementVNode("li", _hoisted_11, [
                    _createVNode(_component_router_link, {
                      to: {name: 'Welcome'},
                      class: _normalizeClass({'nav-link home': true})
                    }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createElementVNode("i", { class: "fal fa-2x fa-home" }, null, -1),
                        _createElementVNode("span", { class: "sr-only" }, "Home", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_12, [
                    _createVNode(_component_router_link, {
                      to: {name: 'SpeakerOverview'},
                      class: "nav-item nav-link"
                    }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode("Speakers "),
                        _createElementVNode("span", { class: "sr-only" }, "(current)", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_13, [
                    _createVNode(_component_router_link, {
                      to: {name: 'AgendaOverview'},
                      class: "nav-item nav-link"
                    }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("Program ")
                      ])),
                      _: 1
                    })
                  ]),
                  (_unref(store).getters["landingpage/shopsAvailable"])
                    ? (_openBlock(), _createElementBlock("li", _hoisted_14, _cache[11] || (_cache[11] = [
                        _createElementVNode("a", {
                          class: "nav-item nav-link",
                          href: "/tickets/"
                        }, "Tickets", -1)
                      ])))
                    : _createCommentVNode("", true),
                  (_unref(store).getters["landingpage/shopsAvailable"])
                    ? (_openBlock(), _createElementBlock("li", _hoisted_15, _cache[12] || (_cache[12] = [
                        _createElementVNode("a", {
                          class: "nav-item nav-link",
                          href: "/press-accreditation/"
                        }, "Press accreditation", -1)
                      ])))
                    : _createCommentVNode("", true)
                ]))
          ], 2))
        : _createCommentVNode("", true),
      (hasNavigation.value && isLoggedIn.value)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_16, [
            (!_unref(store).state.i.connected)
              ? (_openBlock(), _createElementBlock("li", _hoisted_17, [
                  _createElementVNode("a", {
                    ref: "connectionstatus",
                    title: _unref(store).getters.i18n({de: 'Sie sind offline', en: 'Offline'}),
                    href: "#",
                    class: "nav-link text-danger"
                  }, _cache[13] || (_cache[13] = [
                    _createElementVNode("i", { class: "fas fa-2x fa-wifi-slash" }, null, -1),
                    _createElementVNode("span", { class: "sr-only" }, "Offline", -1)
                  ]), 8, _hoisted_18)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", _hoisted_19, [
              _createVNode(_component_router_link, {
                to: {name: 'Networking4'},
                class: _normalizeClass({'nav-link': true}),
                title: "Contact requests"
              }, {
                default: _withCtx(() => [
                  _cache[14] || (_cache[14] = _createElementVNode("i", { class: "fal fa-2x fa-user-friends" }, null, -1)),
                  _cache[15] || (_cache[15] = _createElementVNode("span", { class: "sr-only" }, "Contact requests", -1)),
                  _withDirectives(_createElementVNode("div", { class: "badge rounded-pill badge-notification bg-dark" }, _toDisplayString(Received.value.length), 513), [
                    [_vShow, Received.value.length > 0]
                  ])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_20, [
              _createVNode(_component_router_link, {
                to: {name: 'Status'},
                class: "nav-link call-icon",
                title: "Video calls"
              }, {
                default: _withCtx(() => [
                  _cache[16] || (_cache[16] = _createElementVNode("i", { class: "fal fa-2x fa-video" }, null, -1)),
                  _cache[17] || (_cache[17] = _createElementVNode("span", { class: "sr-only" }, "Video calls", -1)),
                  _withDirectives(_createElementVNode("div", { class: "badge rounded-pill badge-notification bg-dark" }, _toDisplayString(callAmount.value), 513), [
                    [_vShow, callAmount.value > 0]
                  ])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_21, [
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(store).commit('setSideNav', 'chat')), ["prevent"])),
                href: "#",
                class: "nav-link",
                title: "Chat"
              }, [
                _cache[18] || (_cache[18] = _createElementVNode("i", { class: "fal fa-2x fa-comment" }, null, -1)),
                _cache[19] || (_cache[19] = _createElementVNode("span", { class: "sr-only" }, "Chat", -1)),
                _withDirectives(_createElementVNode("div", { class: "badge rounded-pill badge-notification bg-dark" }, _toDisplayString(_unref(store).getters['i/unreadchats']), 513), [
                  [_vShow, _unref(store).getters['i/unreadchats'] > 0]
                ])
              ])
            ]),
            _createElementVNode("li", _hoisted_22, [
              _createElementVNode("button", {
                class: "btn btn-outline-primary dropdown-toggle nsmartphone",
                onClick: _withModifiers(ChangeNavi, ["prevent"])
              }, _toDisplayString(_unref(store).state.config.me.firstname) + " " + _toDisplayString(_unref(store).state.config.me.lastname), 1),
              _createElementVNode("a", {
                class: "smartphone nav-link pointer",
                onClick: _withModifiers(ChangeNavi, ["prevent"])
              }, _cache[20] || (_cache[20] = [
                _createElementVNode("i", { class: "fal fa-user-circle fa-2x" }, null, -1)
              ])),
              _withDirectives(_createElementVNode("div", {
                class: "dropdown-menu dropdown-menu-profile show",
                onClick: _withModifiers(ChangeNavi, ["prevent"])
              }, [
                _createVNode(_component_router_link, {
                  to: {name: 'Profile1'},
                  class: "dropdown-item"
                }, {
                  default: _withCtx(() => _cache[21] || (_cache[21] = [
                    _createTextVNode("My Profile")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: {name: 'Calendar'},
                  class: "dropdown-item"
                }, {
                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createTextVNode("My Calendar")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: {name: 'Help'},
                  class: "dropdown-item"
                }, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createTextVNode("Support")
                  ])),
                  _: 1
                }),
                _createVNode(_component_router_link, {
                  to: {name: 'Logout'},
                  class: "dropdown-item"
                }, {
                  default: _withCtx(() => _cache[24] || (_cache[24] = [
                    _createTextVNode("Logout")
                  ])),
                  _: 1
                })
              ], 512), [
                [_vShow, isDropdownActive.value]
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (hasNavigation.value && !isLoggedIn.value && _unref(store).getters["landingpage/loginAvailable"])
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
            _createVNode(_component_router_link, {
              to: {name: 'Login'},
              class: "btn btn-primary next float-end"
            }, {
              default: _withCtx(() => _cache[25] || (_cache[25] = [
                _createTextVNode(" Login ")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (hasNavigation.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 3,
            class: "navbar-toggler",
            type: "button",
            onClick: toggleMainNav,
            "aria-controls": "navbarToggler",
            "aria-expanded": "false"
          }, _cache[26] || (_cache[26] = [
            _createElementVNode("i", { class: "fal fa-2x fa-bars" }, null, -1),
            _createElementVNode("span", { class: "sr-only" }, "Toggle navigation", -1)
          ])))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})