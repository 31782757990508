import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "call-wrapper" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = {
  key: 0,
  id: "calltoolbox"
}
const _hoisted_7 = ["title"]
const _hoisted_8 = ["title"]
const _hoisted_9 = ["title"]
const _hoisted_10 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JitsiFontAwesomeIcon = _resolveComponent("JitsiFontAwesomeIcon")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      id: "interlocutor",
      class: _normalizeClass({'card': true, 'card-big' : _ctx.expanded}),
      ref: "interlocutorElement",
      style: _normalizeStyle(`--amplitude:${_ctx.interlocutor ? _ctx.interlocutor.audioLevel : 0};`)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({'ratio': true, 'ratio-16x9': true, 'novideo': !_ctx.interlocutor || !_ctx.interlocutor.hasVideo})
      }, [
        (!_ctx.interlocutor || !_ctx.interlocutor.hasVideo)
          ? (_openBlock(), _createBlock(_component_JitsiFontAwesomeIcon, {
              key: 0,
              icon: "user"
            }))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.interlocutor && _ctx.interlocutor.isMuted)
        ? (_openBlock(), _createBlock(_component_JitsiFontAwesomeIcon, {
            key: 0,
            icon: "microphonemuted"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (!_ctx.interlocutor && _ctx.inContact)
          ? (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.i18n({
          de: `Warte auf ${_ctx.inContact ? `${_ctx.inContact.firstname} ${_ctx.inContact.lastname}` : 'Teilnehmer'}`,
          en: `Waiting for ${_ctx.inContact ? `${_ctx.inContact.firstname} ${_ctx.inContact.lastname}` : 'connection'}`
        })), 1))
          : _createCommentVNode("", true),
        (_ctx.interlocutor && _ctx.inContact)
          ? (_openBlock(), _createElementBlock("h5", _hoisted_4, _toDisplayString(_ctx.inContact.firstname) + " " + _toDisplayString(_ctx.inContact.lastname), 1))
          : _createCommentVNode("", true)
      ])
    ], 6), [
      [_vShow, _ctx.converence_joined]
    ]),
    _createElementVNode("div", {
      id: "me",
      class: "card",
      ref: "myElement",
      style: _normalizeStyle(`--amplitude:${_ctx.me ? _ctx.me.audioLevel : 0};`)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass({'ratio': true, 'ratio-16x9': true, 'novideo': !_ctx.me || !_ctx.me.hasVideo})
      }, [
        (!_ctx.me || !_ctx.me.hasVideo)
          ? (_openBlock(), _createBlock(_component_JitsiFontAwesomeIcon, {
              key: 0,
              icon: "user"
            }))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.me && _ctx.me.isMuted)
        ? (_openBlock(), _createBlock(_component_JitsiFontAwesomeIcon, {
            key: 0,
            icon: "microphonemuted"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.i18n({de: "Ich", en: "Me"})), 1)
      ])
    ], 4),
    (_ctx.connected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("p", null, _toDisplayString(_ctx.i18n({de: "Verbindingsqualität", en: "Connection quality"})) + ": " + _toDisplayString(_ctx.myQuality.connectionQuality === 100
            ? _ctx.i18n({de: "sehr gut", en: "very good"})
            : (_ctx.myQuality.connectionQuality > 80
                ? _ctx.i18n({de: "gut", en: "good"})
                : (_ctx.myQuality.connectionQuality > 30
                    ? _ctx.i18n({de: "ausreichend", en: "ok"})
                    : _ctx.i18n({de: "schlecht", en: "poor"})))), 1)
          ], 512), [
            [_vShow, _ctx.myQuality.connectionQuality < 50]
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCamera && _ctx.toggleCamera(...args))),
              class: "btn btn-dark",
              title: _ctx.i18n({
            de: `Kamera ${_ctx.me && _ctx.me.hasVideo ? 'deaktivieren':'aktivieren'}`,
            en: `Camera ${_ctx.me && _ctx.me.hasVideo ? 'disable':'enable'}`
          })
            }, [
              _createVNode(_component_JitsiFontAwesomeIcon, {
                icon: `video${_ctx.me && !_ctx.me.hasVideo ? 'off':''}`
              }, null, 8, ["icon"])
            ], 8, _hoisted_7),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.endConnection && _ctx.endConnection(...args))),
              class: "btn btn-primary",
              title: _ctx.i18n({de: 'Gespräch beenden', en: 'End Call'})
            }, [
              _createVNode(_component_JitsiFontAwesomeIcon, { icon: "phone" })
            ], 8, _hoisted_8),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleMute && _ctx.toggleMute(...args))),
              class: "btn btn-dark",
              title: _ctx.i18n({de: `Audio ${_ctx.me && _ctx.me.isMuted ? 'entmuten':'muten'}`, en: `Audio ${_ctx.me && _ctx.me.isMuted ? 'unmute':'mute'}`})
            }, [
              _createVNode(_component_JitsiFontAwesomeIcon, {
                icon: `microphone${_ctx.me && _ctx.me.isMuted ? 'muted':''}`
              }, null, 8, ["icon"])
            ], 8, _hoisted_9),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleExpanded && _ctx.toggleExpanded(...args))),
              class: "btn btn-dark",
              title: _ctx.i18n({de: `Window ${_ctx.expanded ? 'Minimize':'Maximize'}`, en: `Window ${_ctx.expanded ? 'Minimize':'Maximize'}` })
            }, [
              _createVNode(_component_JitsiFontAwesomeIcon, {
                icon: `${_ctx.expanded ? 'minimize':'maximize'}`
              }, null, 8, ["icon"])
            ], 8, _hoisted_10)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512)), [
    [_vShow, _ctx.connected]
  ])
}