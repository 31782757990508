<template>
  <div
      id="app"
      class="container-fluid"
      :class="{'side-nav-closed': store.state.sidenav === '', 'side-nav-opened': store.state.sidenav !== ''}"
  >
    <NotificationVerticalNav v-if="store.state.sidenav === 'notifications'" />
    <ChatVerticalNav v-if="store.state.sidenav === 'chat'" />
    <ToastDisplay />

    <div class="container-fluid maincontent">
      <HorizontalNav />
      <router-view />
      <Footer />
    </div>

    <AccessibilityModal />
  </div>
</template>

<script lang="ts">
import { defineComponent, provide, ref, computed, onMounted, onUpdated, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import NotificationVerticalNav from "@/components/i/NotificationVerticalNav.vue"
import ChatVerticalNav from "@/components/i/ChatVerticalNav.vue"
import Footer from "@/components/Footer.vue"
import ToastDisplay from "@/components/i/ToastDisplay.vue"
import AccessibilityModal from "@/components/AccessiblityModal.vue"
import rankingsMaxPoints from '@/ranking'
import type { RankingObject } from '@/store/i/types'
import Collapse from 'bootstrap/js/dist/collapse'
import HorizontalNav from "@/components/HorizontalNav.vue" 
import { useGtm } from '@/composables/useGtm'

declare global {
  interface Window {
    $confetti: any;
  }
}

export default defineComponent({
  name: 'App',
  components: {
    NotificationVerticalNav,
    ChatVerticalNav,
    Footer,
    ToastDisplay,
    AccessibilityModal,
    HorizontalNav
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    // Reactive Refs
    const confettiActive = ref(false)
    const preventInitialConfetti = ref(true)
    const mainnav = ref<any>(null)

    // Konfetti-Tracking
    const trackConfettiPerRanking = ref<Record<keyof RankingObject, boolean>>({
      ranking_a: false,
      ranking_b: false,
      ranking_c: false,
      ranking_d: false,
      ranking_e: false,
      ranking_f: false,
      ranking_g: false,
      ranking_h: false,
      ranking_i: false,
      ranking_j: false,
      ranking_k: false,
      ranking_l: false,
      ranking_m: false,
    })

    // Computed Properties
    const myPointsObject = computed(() => store.getters["i/myPointsObject"])
    const isForcedToPlenum = computed(() => store.getters['i/forcedToPlenum'])
    const isredirectToPlenum = computed(() => store.getters['i/redirectToPlenum'])
    const isPublicRoute = computed(() => {
      // Prüft, ob die aktuelle Route eine der öffentlichen Routen ist
      const publicRoutes = ['SpeakerOverview', 'AgendaOverview', 'SpeakerDetail', 'AgendaDetail']
      return publicRoutes.includes(route.name as string)
    })

    // Route Watcher
    watch(route, () => {
      if (mainnav.value) {
        mainnav.value.hide()
      }
    }, { deep: true })

    // Points Watcher
    watch(myPointsObject, (newRankingValues: RankingObject) => {
      let showRanking = false

      if (store.state.i.me.tn_type == 26 ||
          store.state.i.me.tn_type == 33 ||
          store.state.i.me.tn_type == 37) {
        preventInitialConfetti.value = false
        return
      }

      Object.entries(newRankingValues).forEach((entry) => {
        const k = entry[0] as keyof RankingObject
        const v = entry[1] as number
        const maxPointsReached = rankingsMaxPoints[k] <= v && !trackConfettiPerRanking.value[k]

        if (maxPointsReached) {
          trackConfettiPerRanking.value[k] = true
          showRanking = true
        }
      })

      if (preventInitialConfetti.value) {
        preventInitialConfetti.value = false
        return
      }

      if (confettiActive.value || !showRanking) {
        return
      }

      window.$confetti.start()
      confettiActive.value = true

      setTimeout(() => {
        window.$confetti.stop()
        confettiActive.value = false
      }, 3000)
    })

    // Session End Watcher
    watch(() => store.state.tick, () => {
      if (store.state.jwt !== '' &&
          route.meta &&
          route.meta.needsauth &&
          store.state.config.session_end &&
          store.state.config.session_end !== "") {
        if (store.state.tick.getTime() >= (new Date(store.state.config.session_end)).getTime()) {
          store.dispatch("i/forceLogout")
        }
      }
    })

    // JWT und Firebase UID Watcher
    watch([() => store.state.jwt, () => store.state.config.fbuid], async () => {
      console.log('Changed JWT')
      store.dispatch('landingpage/populate')
      if (store.state.jwt !== '' && store.state.config.fbuid !== '') {
        console.log('Valid JWT')
        await Promise.all([
          store.dispatch('i/populate'),
          store.dispatch('members/populate')
        ])
        await store.dispatch("tradeForFirebaseToken")
      }
    })

    // Plenum Force-Redirect Watcher
    watch([() => isForcedToPlenum.value, () => route.meta?.needsauth, () => route.name], () => {
      if (route.meta?.needsauth &&
          isForcedToPlenum.value &&
          route.name !== "Stage" &&
          route.name !== "StagePlaying" &&
          route.name !== "OnBoarding") {
        router.push({name: "Stage"})
      }
    })

    // Plenum Redirect Watcher
    watch([() => isredirectToPlenum.value, () => route.meta?.needsauth], () => {
      if (route.meta?.needsauth &&
          isredirectToPlenum.value &&
          route.name !== "Stage" &&
          route.name !== "StagePlaying" &&
          route.name !== "OnBoarding") {
        router.push({name: "Stage"})
      }
    })

    const updateBootstrap = () => {
      document.querySelectorAll('.collapse').forEach((collapsable) => {
        const tcollapse = new Collapse(collapsable, {toggle: false})
        if (collapsable.classList.contains("navbar-collapse")) {
          mainnav.value = tcollapse
        }
      })
    }

    // Lifecycle Hooks
    onMounted(async () => {
      if (store.state.jwt !== '' && store.state.config.fbuid !== '') {
        await Promise.all([
          store.dispatch('i/populate'),
          store.dispatch('members/populate')
        ])
        await store.dispatch("tradeForFirebaseToken")
      }

      await new Promise(resolve => { window.setTimeout(resolve, 1000) })
      updateBootstrap()
    })

    onUpdated(() => {
      updateBootstrap()
    })

    provide('isPublicRoute', isPublicRoute)
    
    return {
      confettiActive,
      preventInitialConfetti,
      mainnav,
      trackConfettiPerRanking,
      myPointsObject,
      isForcedToPlenum,
      isredirectToPlenum,
      isPublicRoute,
      updateBootstrap,
      store
    }
  }
})
</script>

<style lang="scss">
.side-nav-closed {
  .maincontent {
    margin-left: 0;
    width: 100%;
    transition: margin-left .3s ease-in-out, width .3s ease-in-out;
  }
}

.side-nav-opened {
  .maincontent {
    margin-left: 400px;
    width: calc(100% - 400px);
    transition: margin-left .3s ease-in-out, width .3s ease-in-out;
  }
}

.maincontent {
  //padding-top: 56px;
  min-height: 100vh;
  background-color: #f8f9fa;
  position: relative;
  overflow-x: hidden;
}
</style>