import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

import { ref, onMounted } from 'vue'
import Loading from "@/components/Loading.vue"
import axios from "axios"
import GalleryV2 from "@/components/GalleryV2.vue"

interface Image {
  content: string
  image: string
  key: string
  sortorder: string
  title: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GalleryV2Loader',
  props: {
    url: {}
  },
  setup(__props: any) {

const props = __props

const loading = ref(true)
const error = ref(false)
const images = ref<Image[]>([])

onMounted(async () => {
  try {
    const response = await axios.get(props.url)

    if (response.status !== 200) {
      error.value = true
      console.error("Couldn't get image slide: Request not successful", response)
      return
    }

    if (response.data.length < 1) {
      error.value = true
      console.error("Couldn't get image slide: empty response")
      return
    }

    images.value = response.data
  } catch (e) {
    error.value = true
    console.error("Couldn't get image slide:", e)
  } finally {
    loading.value = false
  }
})

return (_ctx: any,_cache: any) => {
  return (loading.value)
    ? (_openBlock(), _createBlock(Loading, { key: 0 }))
    : (error.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1))
      : (_openBlock(), _createBlock(GalleryV2, {
          key: 2,
          images: images.value
        }, null, 8, ["images"]))
}
}

})