<template>
  <div v-if="voting" id="stream-voting-area"
       :class="{
          'text-center': true,
          'inline-voting-area': !onlyvotingresult,
          'w-100': onlyvotingresult,
          'd-flex': onlyvotingresult,
          'flex-column': onlyvotingresult,
          'justify-content-center': onlyvotingresult,
          'align-items-center': onlyvotingresult,
          'ivoted': ivoted,
          'mb-4': true,
          'mb-lg-0': true
    }">
    <div class="row mb-4" v-if="!hidetitle && !ivoted">
      <div class="col text-center">
        <h5 @click="toogle" v-if="!onlyvotingresult" class="mt-2">
            <span class="ms-2 pb-2">
              <FontAwesomeIcon icon="arrow-down" v-show="!show" />
              <FontAwesomeIcon icon="arrow-up" v-show="show" />
            </span>
          {{ voting.question }}
        </h5>
        <div v-else class="big-question">{{ voting.question }}</div>
      </div>
    </div>
    <div v-show="show" >
    <div class="row" v-if="hasDeadline && !ivoted" v-show="!hidetitle">
      <div v-if="!deadlineReached" class="col">
        {{ store.getters.i18n({de:'Das Voting endet ' + toDeadline,en:'Voting ends ' + toDeadline}) }}.
      </div>
      <div v-else class="col">
        {{ store.getters.i18n({de:'Das Voting ist beendet.',en:'The voting has ended.'}) }}
      </div>
    </div>

      <VotingForm :voting="voting" v-if="!onlyvotingresult && votemode === 'vote' && !deadlineReached" />
      <div class="row" v-if="ivoted">
        <div class="col">
          <h3>{{ store.getters.i18n({de:'Danke für Ihre Abstimmung.',en:'Thank you for voting.'}) }}</h3>
        </div>
      </div>
    </div>

  </div>
</template>
<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import VotingForm from "@/components/i/VotingForm.vue";
import FontAwesomeIcon from "@/components/FontAwesomeIcon.vue";
import _ from "lodash";
import { DateTime } from "luxon";
import type { MultiUserRoom, RoomInteraction, VotingBallot, VotingInteraction } from "@/store/i/types";

const store = useStore();
const show = ref(true);
const onlyvotingresult = ref(false);
const hidetitle = ref(false);
const votemode = ref<"vote"|"results">("vote");
const originalParent = ref<HTMLElement | null>(null);
const labelscut = ref(false);

const toogle = () => {
  show.value = !show.value;
};

const room = computed((): MultiUserRoom | null | undefined => {
  if (store.state.i.rooms.length > 0) {
    return _.find(
        store.state.i.rooms,
        r => r.roomname === store.state.i.mucname
    );
  }
  return null;
});

const ivoted = computed(() => {
  return !onlyvotingresult.value && votemode.value === "results";
});

const voting = computed(() => {
  if (
      room.value?.interaction &&
      'type' in room.value.interaction &&
      room.value.interaction.type === "voting" &&
      'voting' in room.value.interaction &&
      typeof room.value.interaction.voting === 'string' &&
      room.value.interaction.voting !== ""
  ) {
    const cvote = _.find(
        store.state.i.votings,
        v => v.uid === (room.value?.interaction as RoomInteraction & { voting: string }).voting
    );

    if (cvote) {
      return cvote;
    }
  }

  votemode.value = "vote";
  return false;
});

const voteuid = computed(() => voting.value ? voting.value.uid : '');

const myanswers = computed((): VotingBallot[] => {
  if (voting.value) {
    return store.getters["i/myBallotsForVoting"](voting.value.roomname, voting.value.uid);
  }
  return [];
});

const hasDeadline = computed(() => {
  const interaction = room.value?.interaction;

  if (!interaction || typeof interaction !== 'object') {
    return false;
  }

  return !!(
      'type' in interaction &&
      interaction.type === "voting" &&
      'votingduedate' in interaction &&
      interaction.votingduedate &&
      interaction.votingduedate !== ""
  );
});

const deadlineReached = computed(() => {
  const interaction = room.value?.interaction;

  if (
      !hasDeadline.value ||
      !interaction ||
      !('votingduedate' in interaction) ||
      typeof interaction.votingduedate !== 'string'
  ) {
    return false;
  }

  return DateTime.fromISO(interaction.votingduedate) < DateTime.fromJSDate(store.state.tick);
});

const toDeadline = computed(() => {
  const interaction = room.value?.interaction;

  if (
      !hasDeadline.value ||
      !interaction ||
      !('votingduedate' in interaction) ||
      typeof interaction.votingduedate !== 'string'
  ) {
    return '';
  }

  const vdd = DateTime.fromISO(interaction.votingduedate);
  const vsecs = Math.round(vdd.diff(DateTime.fromJSDate(store.state.tick), "seconds").seconds);

  if (vsecs <= 30) {
    return store.getters.i18n({de:`in ${vsecs} Sekunden`,en:`in ${vsecs} seconds`});
  }

  return vdd.setLocale(store.getters.i18n({de:'de',en:'en'}))
      .toRelative({ base: DateTime.fromJSDate(store.state.tick) });
});

const voteSum = computed(() => {
  if (!voting.value) {
    return 0;
  }

  return voting.value.answers.reduce((sum: number, answer: { uid: string }) => {
    const voteCount = store.getters["i/ballotsForVotingAnswer"](
        voting.value.roomname,
        voting.value.uid,
        answer.uid
    ).length;
    return sum + voteCount;
  }, 0);
});

const percentageForAnswer = (auid: string): string => {
  if (!voting.value || voteSum.value < 1) {
    return "";
  }

  const votecountA = store.getters["i/ballotsForVotingAnswer"](
      voting.value.roomname,
      voting.value.uid,
      auid
  ).length;

  return Math.round(votecountA * 100 / voteSum.value) + "%";
};

watch(voteuid, () => {
  votemode.value = "vote";
});

watch(myanswers, (nv: VotingBallot[]) => {
  votemode.value = nv.length > 0 ? "results" : "vote";
});

const togglegreenkey = (e: KeyboardEvent) => {
  switch (e.key) {
    case "g":
      document.body.style.background = !document.body.style.background ? '#0F0' : '';
      break;
    case "t":
      hidetitle.value = !hidetitle.value;
      redrawChart();
    default:
      break;
  }
};

const fullscreenhandle = async (nv: boolean) => {
  const playertoattach = document.querySelector(".video-js");
  if (!playertoattach) return;

  if (nv) {
    originalParent.value = document.getElementById('stream-voting-area')?.parentElement ?? null;
    originalParent.value?.removeChild(document.getElementById('stream-voting-area')!);
    playertoattach.append(document.getElementById('stream-voting-area')!);
  } else {
    playertoattach.removeChild(document.getElementById('stream-voting-area')!);
    originalParent.value?.append(document.getElementById('stream-voting-area')!);
  }
};

const redrawChart = () => {
  // Implementation hier
};

onMounted(() => {
  if (onlyvotingresult.value) {
    document.body.addEventListener("keyup", togglegreenkey, {passive:true});
  }

  if (store.state.i.videoFullscreen) {
    fullscreenhandle(true);
  }

  votemode.value = myanswers.value.length > 0 ? "results" : "vote";
});

onBeforeUnmount(() => {
  if (onlyvotingresult.value) {
    document.body.removeEventListener("keyup", togglegreenkey);
  }
});

// Watch für Fullscreen
watch(() => store.state.i.videoFullscreen, fullscreenhandle);
</script>

