<template>
  <div class="row mb-4" v-if="visibleContact">
    <div class="pointer col-md-1 col-4 mb-2" @click.prevent="navigate">
      <div class="circular_image circular_image_small">
        <img :src="userimage" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
      </div>
    </div>

    <div class="pointer col-md-4 col-8 mb-2" @click.prevent="navigate">
      <div class="Name-Nachname mb-1 ipad-2">{{ props.user.firstname }} {{ props.user.lastname }}</div>
      <div class="Role copydetailxs mb-2 ipad-2 bold">{{ title(props.user.orgname) }}</div>
    </div>

    <div class="col-md-7 col-12">
      <button class="btn btn-primary m-1" style="float:right" v-if="props.contact_type === 'contact'" @click.prevent="remove">
        <span class="smartphone"><FontAwesomeIcon icon="user-xmark" /></span>
        <span class="nsmartphone">Remove contact</span>
      </button>
      
      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'contact'"  @click.prevent="meeting">
        <span class="smartphone"><FontAwesomeIcon icon="calendar-pen" /></span>
        <span class="nsmartphone">Appointment request</span>
      </button>

      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'contact'"  @click.prevent="call">
        <span class="smartphone"><FontAwesomeIcon icon="video" /></span>
        <span class="nsmartphone">Call</span>
      </button>

      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'contact'"  @click.prevent="chat">
        <span class="smartphone"><FontAwesomeIcon icon="message" /></span>
        <span class="nsmartphone">Chat</span>
      </button>

      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'received'" @click.prevent="block">
        <span class="smartphone"><FontAwesomeIcon icon="ban" /></span>
        <span class="nsmartphone">Block</span>
      </button>

      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'received'"  @click.prevent="decline">
        <span class="smartphone"><FontAwesomeIcon icon="user-xmark" /></span>
        <span class="nsmartphone">Reject</span>
      </button>

      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'received'"  @click.prevent="accept">
        <span class="smartphone"><FontAwesomeIcon icon="user-check" /></span>
        <span class="nsmartphone">Accept</span>
      </button>
      <span v-if="props.contact_type === 'received' || props.contact_type === 'send'">{{ formatedDate }} CEST</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'
import { DateTime } from 'luxon'
import type { ConnectionNetworking, ContactsNetworking } from '@/store/i/types'

const props = defineProps<{
  user: ConnectionNetworking
  contact_type?: string
}>()

const emit = defineEmits<{
  (e: 'chat', user: ConnectionNetworking): void
  (e: 'call', user: ConnectionNetworking): void
  (e: 'navigate', user: ConnectionNetworking): void
  (e: 'meeting', user: ConnectionNetworking): void
  (e: 'remove', user: ConnectionNetworking): void
  (e: 'accept', user: ConnectionNetworking): void
  (e: 'decline', user: ConnectionNetworking): void
  (e: 'block', user: ConnectionNetworking): void
}>()

const store = useStore()

const title = (title: string): string => {
  title = _.replace(title, /&#34;/g, '"')
  title = _.replace(title, /&#39;/g, "'")
  return title
}

const chat = () => emit('chat', props.user)
const call = () => emit('call', props.user)
const navigate = () => emit('navigate', props.user)
const meeting = () => emit('meeting', props.user)
const remove = () => emit('remove', props.user)
const accept = () => emit('accept', props.user)
const decline = () => emit('decline', props.user)
const block = () => emit('block', props.user)

const contactEntry = computed(() => {
  if (props.user) {
    if (props.user.receiver === store.state.config.me.objvalueid) {
      return _.find(
          store.state.i.contacts_networking,
          (e: ContactsNetworking) =>
              `${e.objvalueid}` === `${props.user.sender}` && e.accept_networking
      )
    } else {
      return _.find(
          store.state.i.contacts_networking,
          (e: ContactsNetworking) =>
              `${e.objvalueid}` === `${props.user.receiver}` && e.accept_networking
      )
    }
  }
})

const visibleContact = computed(() => !!contactEntry.value)

const formatedDate = computed(() => {
  return DateTime.fromISO(props.user.request_datetime, {locale: 'en-us'}).monthLong +
      " " +
      DateTime.fromISO(props.user.request_datetime).toFormat('d, h:mm a')
})

const userimage = computed(() => {
  if (props.user) {
    if (props.user.receiver === store.state.config.me.objvalueid) {
      return '/files/members/' + props.user.sender + '/foto.jpg'
    } else {
      return '/files/members/' + props.user.receiver + '/foto.jpg'
    }
  }
  return '/projects/dw/gmf/assets/images/placeholder.jpg'
})
</script>