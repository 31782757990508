import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = {
  key: 0,
  class: "headline cb-headlinenormalxl g-3"
}
const _hoisted_3 = {
  key: 1,
  class: "headline cb-headlinenormalxl g-3"
}
const _hoisted_4 = {
  key: 2,
  class: "row g-3"
}
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "mb-3 mt-5 bold" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 0,
  class: "bold"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "row agenda_item_full" }
const _hoisted_13 = { class: "col-12" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { class: "row mb-5 mt-5" }
const _hoisted_16 = {
  key: 0,
  class: "col-lg-3 col-6"
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { key: 1 }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = { class: "col-lg-3 col-6" }
const _hoisted_22 = { class: "Role copydetailxs" }
const _hoisted_23 = { class: "col-lg-3 col-6" }
const _hoisted_24 = { class: "Role copydetailxs" }
const _hoisted_25 = { class: "col-lg-3 col-6" }
const _hoisted_26 = { class: "Role copydetailxs" }
const _hoisted_27 = {
  key: 1,
  class: "mt-5 mb-5"
}
const _hoisted_28 = {
  key: 2,
  class: "embed-responsive embed-responsive-16by9"
}
const _hoisted_29 = ["src"]
const _hoisted_30 = { key: 3 }
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { class: "row m-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.notFound)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Not found"))
      : _createCommentVNode("", true),
    (!_ctx.agendaEntry)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Loading"))
      : _createCommentVNode("", true),
    (_ctx.agendaEntry)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h4", null, [
              _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fal fa-calendar-alt" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.formatedDate(_ctx.agendaEntry.startdate, _ctx.agendaEntry.enddate)) + " CEST", 1)
            ]),
            _createElementVNode("h4", _hoisted_6, [
              _createElementVNode("span", {
                innerHTML: _ctx.title(_ctx.agendaEntry.title)
              }, null, 8, _hoisted_7),
              (_ctx.agendaEntry.subtitle)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                    _cache[7] || (_cache[7] = _createTextVNode("- ")),
                    _createElementVNode("span", {
                      innerHTML: _ctx.agendaEntry.subtitle
                    }, null, 8, _hoisted_9)
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.agendaEntry.hosted_by)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, [
                  _cache[8] || (_cache[8] = _createTextVNode(" Hosted by: ")),
                  _createElementVNode("div", {
                    innerHTML: _ctx.agendaEntry.hosted_by
                  }, null, 8, _hoisted_11)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", {
                  class: "Sendung_Beschreibung_full",
                  innerHTML: _ctx.agendaEntry.long_summary
                }, null, 8, _hoisted_14)
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              (_ctx.agendaEntry.room?.length > 0 || _ctx.agendaEntry.stream?.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _cache[13] || (_cache[13] = _createElementVNode("span", { class: "headline_light text-center" }, "Takes place at", -1)),
                    _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                    (_ctx.agendaEntry.room)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
                          _cache[9] || (_cache[9] = _createElementVNode("i", { class: "fal fa-2x fa-map-marker-alt" }, null, -1)),
                          _createElementVNode("span", {
                            innerHTML: _ctx.agendaEntry.room
                          }, null, 8, _hoisted_18),
                          _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1))
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.agendaEntry.stream)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, [
                          _cache[11] || (_cache[11] = _createElementVNode("i", { class: "fal fa-2x fa-signal-stream" }, null, -1)),
                          _createElementVNode("span", {
                            innerHTML: _ctx.agendaEntry.stream
                          }, null, 8, _hoisted_20),
                          _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("span", _hoisted_22, [
                  _cache[18] || (_cache[18] = _createElementVNode("span", { class: "headline_light text-center" }, "Share on", -1)),
                  _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("a", {
                    class: "a-link--external share buttonshare",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.share('twitter','','en_us'))),
                    "data-label": "twitter"
                  }, _cache[15] || (_cache[15] = [
                    _createElementVNode("i", { class: "fab fa-2x fa-x-twitter pe-2" }, null, -1),
                    _createElementVNode("span", { class: "sr-only" }, "X (Twitter)", -1)
                  ])),
                  _createElementVNode("a", {
                    class: "a-link--external share buttonshare",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.share('linkedin','','en_us'))),
                    "data-label": "linkedin"
                  }, _cache[16] || (_cache[16] = [
                    _createElementVNode("i", { class: "fab fa-2x fa-linkedin-in pe-2" }, null, -1),
                    _createElementVNode("span", { class: "sr-only" }, "LinkedIn", -1)
                  ])),
                  _createElementVNode("a", {
                    class: "a-link--external share buttonshare",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.share('facebook','','en_us'))),
                    "data-label": "facebook"
                  }, _cache[17] || (_cache[17] = [
                    _createElementVNode("i", { class: "fab fa-2x fa-facebook-f" }, null, -1),
                    _createElementVNode("span", { class: "sr-only" }, "Facebook", -1)
                  ])),
                  _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1)),
                  _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("span", _hoisted_24, [
                  _cache[24] || (_cache[24] = _createElementVNode("span", { class: "headline_light text-center" }, "Bookmark", -1)),
                  _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1)),
                  (_ctx.include(_ctx.agendaEntry.objvalueid))
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        class: "a-link--external share buttonshare",
                        title: "Bookmark",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.unsetFav(_ctx.agendaEntry.objvalueid)))
                      }, _cache[22] || (_cache[22] = [
                        _createElementVNode("i", { class: "fal fa-calendar-plus fa-2x" }, null, -1)
                      ])))
                    : _createCommentVNode("", true),
                  (_ctx.exclude(_ctx.agendaEntry.objvalueid))
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        class: "a-link--external share buttonshare",
                        title: "Bookmark",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setFav(_ctx.agendaEntry.objvalueid)))
                      }, _cache[23] || (_cache[23] = [
                        _createElementVNode("i", { class: "fal fa-calendar-minus fa-2x" }, null, -1)
                      ])))
                    : _createCommentVNode("", true),
                  _cache[26] || (_cache[26] = _createElementVNode("br", null, null, -1)),
                  _cache[27] || (_cache[27] = _createElementVNode("br", null, null, -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("span", _hoisted_26, [
                  _cache[29] || (_cache[29] = _createElementVNode("span", { class: "headline_light text-center" }, "ICS DOWNLOAD ", -1)),
                  _cache[30] || (_cache[30] = _createElementVNode("br", null, null, -1)),
                  _createElementVNode("a", {
                    class: "a-link--external share buttonshare",
                    title: "ICS Download",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.generateFileICS(_ctx.agendaEntry.objvalueid)))
                  }, _cache[28] || (_cache[28] = [
                    _createElementVNode("i", { class: "fal fa-calendar-check fa-2x" }, null, -1)
                  ])),
                  _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1)),
                  _cache[32] || (_cache[32] = _createElementVNode("br", null, null, -1))
                ])
              ]),
              (_ctx.agendaEntry.show_video && _ctx.agendaEntry.video_link)
                ? (_openBlock(), _createElementBlock("hr", _hoisted_27))
                : _createCommentVNode("", true),
              (_ctx.agendaEntry.show_video && _ctx.agendaEntry.video_link)
                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                    _createElementVNode("iframe", {
                      title: "",
                      class: "embed-responsive-item",
                      src: _ctx.agendaEntry.video_link,
                      frameborder: "0",
                      allow: "autoplay; fullscreen",
                      allowfullscreen: ""
                    }, null, 8, _hoisted_29)
                  ]))
                : _createCommentVNode("", true),
              _cache[34] || (_cache[34] = _createElementVNode("hr", { class: "mt-5 mb-5" }, null, -1)),
              (_ctx.agendaEntry.show_area)
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    (_ctx.speakers.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          _cache[33] || (_cache[33] = _createElementVNode("h3", null, "Speakers", -1)),
                          _createElementVNode("div", _hoisted_32, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.speakers, (speaker, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "col-md-6 row",
                                key: index
                              }))
                            }), 128))
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}