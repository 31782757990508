import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass({'chat-entry':true})
}
const _hoisted_2 = {
  key: 0,
  class: "voteless-time"
}
const _hoisted_3 = {
  key: 1,
  class: "chatmeta"
}
const _hoisted_4 = { class: "chatmeta--name" }
const _hoisted_5 = { class: "chatmeta--company" }
const _hoisted_6 = ["innerHTML"]

import { computed } from 'vue';
import type { Message, MessageSender } from "@/store/i/types";
import { DateTime } from "luxon";
import { useStore } from 'vuex';

interface Props {
  cE?: Message;
  chattype: "chat" | "questions";
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VideoChatEntry',
  props: {
    cE: {},
    chattype: {}
  },
  setup(__props: any) {

const props = __props;
const store = useStore();

const user = computed(() => {
  if (props.cE && typeof props.cE.sender !== "string") {
    return props.cE.sender;
  }
  return undefined;
});

const msgIsFromMe = computed(() => {
  if (props.cE) {
    if (typeof props.cE.sender !== "string") {
      return props.cE.sender.fbuid === store.state.config.fbuid;
    }
    return props.cE.sender === store.state.config.fbuid;
  }
  return false;
});

const filteredBody = computed(() => props.cE?.body || '');

const date = computed(() => {
  const d = new Date();
  if (props.cE?.ts) {
    d.setTime(props.cE.ts * 1000);
  }
  return d;
});

const dateString = computed(() => {
  const setDT = DateTime.fromJSDate(date.value);
  return setDT.setLocale(store.getters.i18n({de:'de',en:'en'}))
          .toLocaleString(DateTime.DATETIME_SHORT) +
      store.getters.i18n({de:' Uhr',en:' '});
});

const uName = computed(() => {
  if (msgIsFromMe.value) {
    return store.getters.i18n({de: 'Ich', en: 'Me'});
  }

  if (props.cE && typeof props.cE.sender === "string" && (/-cp-/).test(props.cE.sender)) {
    return store.getters.i18n({de: 'Event Team', en: 'Event Team'});
  }

  if (user.value) {
    const userarray: string[] = [];
    if (user.value.gender) {
      switch (user.value.gender) {
        case 1:
          userarray.push(store.getters.i18n({de: 'Herr', en: 'Mr.'}));
          break;
        case 2:
          userarray.push(store.getters.i18n({de: 'Frau', en: 'Ms.'}));
          break;
      }
    }

    if (user.value.firstname) {
      userarray.push(user.value.firstname);
    }
    if (user.value.lastname) {
      userarray.push(user.value.lastname);
    }

    return userarray.join(" ");
  }

  return 'anonym';
});

const uCompany = computed(() => {
  if (user.value?.orgname) {
    return ` - ${user.value.orgname}`;
  }
  return '';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.chattype === 'questions')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("small", null, _toDisplayString(dateString.value), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.chattype === 'chat')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("small", _hoisted_4, [
            _createTextVNode(_toDisplayString(uName.value), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(uCompany.value), 1)
          ]),
          _createElementVNode("small", null, _toDisplayString(dateString.value), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", { innerHTML: filteredBody.value }, null, 8, _hoisted_6)
  ]))
}
}

})