<script setup lang="ts">
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { TranslatedText } from "@/store/types"
import { AppNetworkErrorOther, networkErrorText } from "@/crud"

// Props
const props = defineProps<{
  type?: string
}>()

// Store & Router
const store = useStore()
const router = useRouter()

// Reactive state
const errorstring = ref<TranslatedText>({ de: '', en: '' })
const successtring = ref<TranslatedText>({ de: '', en: '' })

// Methods
const reset_message = () => {
  errorstring.value = { de: '', en: '' }
  successtring.value = { de: '', en: '' }
}

const setDataOnBoarding2 = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    successtring.value = { de: '', en: '' }

    const dpr = await store.dispatch("i/setDataOnBoarding2", {
      position: store.state.i.me.position,
      orgname: store.state.i.me.orgname,
      show_email: store.state.i.me.show_email ? 1 : 0,
      phone: store.state.i.me.phone,
      country: store.state.i.me.country,
      show_vcard: store.state.i.me.show_vcard ? 1 : 0,
      twitter: store.state.i.me.twitter,
      facebook: store.state.i.me.facebook,
      linkedin: store.state.i.me.linkedin,
      cv: store.state.i.me.cv,
    })

    setTimeout(reset_message, 2000)

    if (dpr.sv) {
      successtring.value = {
        de: "Data saved.",
        en: "Data saved."
      }
      if (props.type === "step") {
        if (store.state.config.me.tn_type !== 8) {
          router.replace({ name: "Step3" })
        } else {
          router.replace({ name: "Step4" })
        }
      }
    } else {
      errorstring.value = {
        de: "Could not set data.",
        en: "Could not set data."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}
</script>

<template>
  <div class="">
    <div class="col-md-12 col-xl-12">
      <form @submit.prevent="setDataOnBoarding2">
        <div v-show="successtring.de !== ''" class="alert alert-success col-lg-12">
          {{ store.getters.i18n(successtring) }}
        </div>

        <div v-show="errorstring.de !== ''" class="alert alert-danger mb-5">
          {{ store.getters.i18n(errorstring) }}
        </div>

        <div class="mb-3">
          <b>First name:</b> {{ store.state.i.me.firstname }}
        </div>
        <div class="mb-3">
          <b>Last name:</b> {{ store.state.i.me.lastname }}
        </div>
        <div class="mb-4">
          <b>E-Mail adress:</b> {{ store.state.i.me.email }}
        </div>

        <div class="d-flex align-items-center mb-2">
          <div class="me-auto form-check">
            <input class="form-check-input" v-model="store.state.i.me.show_email" type="checkbox" name="show_email"
                   id="show_email">
            <label class="form-check-label" for="show_email">
              Show E-Mail adress in my networking profile
            </label>
          </div>
        </div>

        <div class="mb-2">
          <label class="form-label">Position</label>
          <input type="text" v-model="store.state.i.me.position" class="form-control"/>
        </div>

        <div class="mb-2">
          <label class="form-label">Organization</label>
          <input type="text" v-model="store.state.i.me.orgname" class="form-control"/>
        </div>

        <div class="mb-2">
          <label class="form-label">Phone</label>
          <input type="text" v-model="store.state.i.me.phone" class="form-control"/>
        </div>

        <div class="mb-4">
          <label class="form-label">Country</label>
          <select class="form-control" v-model="store.state.i.me.country">
            <option v-for="(e, ei) in store.state.i.me.countrylist" :key="ei" :value="ei">{{ e }}</option>
          </select>
        </div>

        <div class="d-flex align-items-center mb-2">
          <div class="me-auto form-check">
            <input class="form-check-input" v-model="store.state.i.me.show_vcard" type="checkbox" name="show_vcard"
                   id="show_vcard">
            <label class="form-check-label" for="show_vcard">
              Show Vcard download
            </label>
          </div>
        </div>

        <div class="mb-2">
          <label class="form-label">Twitter</label>
          <input type="text" v-model="store.state.i.me.twitter" class="form-control"/>
        </div>

        <div class="mb-2">
          <label class="form-label">LinkedIn</label>
          <input type="text" v-model="store.state.i.me.linkedin" class="form-control"/>
        </div>

        <div class="mb-2">
          <label class="form-label">Facebook</label>
          <input type="text" v-model="store.state.i.me.facebook" class="form-control"/>
        </div>

        <div class="mb-5">
          <label class="form-label">CV (max. 400 characters)</label>
          <textarea v-model="store.state.i.me.cv" class="form-control"/>
        </div>

        <div class="d-flex align-items-center">
          <router-link :to="{ name: 'Step1' }" class="btn btn-primary m-1" v-if="type === 'step'">Back</router-link>
          <button class="btn btn-primary m-1" type="submit">
            <span v-if="type === 'step'">Next</span>
            <span v-else>Save</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>