<template>
  <div class="box">
    <span class="headline cb-headlinenormalxl g-3" v-if="notFound">Not found</span>
    <span class="headline cb-headlinenormalxl g-3" v-if="!agendaEntry">Loading</span>
    <div class="row g-3" v-if="agendaEntry">
      <div class="col">
        <h4><i class="fal fa-calendar-alt"></i> {{ formatedDate(agendaEntry.startdate, agendaEntry.enddate) }} CEST</h4>
        <h4 class="mb-3 mt-5 bold">
          <span v-html="title(agendaEntry.title)"></span>
          <span v-if="agendaEntry.subtitle">- <span v-html="agendaEntry.subtitle"></span></span>
        </h4>
        <p v-if="agendaEntry.hosted_by" class="bold">
          Hosted by: <div v-html="agendaEntry.hosted_by"></div>
        </p>
        <div class="row agenda_item_full">
          <div class="col-12">
            <span class="Sendung_Beschreibung_full" v-html="agendaEntry.long_summary"></span>
          </div>
        </div> 
        <div class="row mb-5 mt-5">
          <div class="col-lg-3 col-6" v-if="agendaEntry.room?.length > 0 || agendaEntry.stream?.length > 0">
            <span class="headline_light text-center">Takes place at</span><br>
            <span v-if="agendaEntry.room">
              <i class="fal fa-2x fa-map-marker-alt"></i> 
              <span v-html="agendaEntry.room"></span><br>
            </span>
            <span v-if="agendaEntry.stream">
              <i class="fal fa-2x fa-signal-stream"></i> 
              <span v-html="agendaEntry.stream"></span><br>
            </span>
          </div>
          <div class="col-lg-3 col-6">
            <span class="Role copydetailxs">
              <span class="headline_light text-center">Share on</span><br>
              <a class="a-link--external share buttonshare" @click="share('twitter','','en_us')" data-label="twitter">
                <i class="fab fa-2x fa-x-twitter pe-2"></i>
                <span class="sr-only">X (Twitter)</span>
              </a>
              <a class="a-link--external share buttonshare" @click="share('linkedin','','en_us')" data-label="linkedin">
                <i class="fab fa-2x fa-linkedin-in pe-2"></i>
                <span class="sr-only">LinkedIn</span>
              </a>
              <a class="a-link--external share buttonshare" @click="share('facebook','','en_us')" data-label="facebook">
                <i class="fab fa-2x fa-facebook-f"></i>
                <span class="sr-only">Facebook</span>
              </a>
              <br><br>
            </span>
          </div>
          <div class="col-lg-3 col-6">
            <span class="Role copydetailxs">
              <span class="headline_light text-center">Bookmark</span><br>
              <a class="a-link--external share buttonshare"
                 title="Bookmark"
                 @click="unsetFav(agendaEntry.objvalueid)"
                 v-if="include(agendaEntry.objvalueid)">
                <i class="fal fa-calendar-plus fa-2x"></i>
              </a>
              <a class="a-link--external share buttonshare"
                 title="Bookmark"
                 @click="setFav(agendaEntry.objvalueid)"
                 v-if="exclude(agendaEntry.objvalueid)">
                <i class="fal fa-calendar-minus fa-2x"></i>
              </a>
              <br><br>
            </span>
          </div>
          <div class="col-lg-3 col-6">
            <span class="Role copydetailxs">
              <span class="headline_light text-center">ICS DOWNLOAD </span><br>
              <a class="a-link--external share buttonshare"
                 title="ICS Download"
                 @click="generateFileICS(agendaEntry.objvalueid)">
                <i class="fal fa-calendar-check fa-2x"></i>
              </a>
              <br><br>
            </span>
          </div>

          <hr class="mt-5 mb-5" v-if="agendaEntry.show_video && agendaEntry.video_link">

          <div class="embed-responsive embed-responsive-16by9"
               v-if="agendaEntry.show_video && agendaEntry.video_link">
            <iframe
                title=""
                class="embed-responsive-item"
                :src="agendaEntry.video_link"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen>
            </iframe>
          </div>

          <hr class="mt-5 mb-5">

          <div v-if="agendaEntry.show_area">
            <div v-if="speakers.length > 0">
              <h3>Speakers</h3>
              <div class="row m-1">
                <div class="col-md-6 row" v-for="(speaker, index) in speakers" :key="index">
                  <!-- Speakers content hier -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { DateTime } from "luxon"
import _ from "lodash"
import { networkErrorText } from "@/crud"
import { TranslatedText } from "@/store/types"
import { useGtm } from '@/composables/useGtm'

export default defineComponent({
  name: 'AgendaDetail',
  
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const errorstring = ref<TranslatedText>({ de: '', en: '' })
    const agendaEntry = ref<any | null>(null)
    const notFound = ref(false)
    const speakers = ref<any[]>([])
    
    // Bevor die Komponente gemounted wird
    onBeforeMount(async () => {
      try {
        // Versuche zunächst, die Daten aus dem Store zu laden
        const agendaId = route.params.agendaid;
        let entry = _.find(
          store.state.i.agenda,
          (e) => `${e.objvalueid}` === `${agendaId}`
        );
        
        // Wenn keine Daten im Store gefunden wurden, lade sie über die API
        if (!entry) {
          const response = await store.dispatch('i/fetchAgendaDetails', agendaId);
          if (response && response.agenda) {
            agendaEntry.value = response.agenda;
          } else {
            notFound.value = true;
          }
        } else {
          agendaEntry.value = entry;
        }
      } catch (e) {
        console.error('Error loading agenda details', e);
        errorstring.value = networkErrorText(e as any);
        notFound.value = true;
      }
    });
    
    // GTM tracking
    const { trackPage } = useGtm()
    trackPage({
      pageCategory: 'Program',
      PageName: 'Detailpage',
      PageType: 'Webapp',
    });
    
    // Methods
    const formatedDate = (startdate: string, enddate: string): string => {
      return DateTime.fromISO(startdate, { locale: 'en-us' }).monthLong +
          " " + DateTime.fromISO(startdate).toFormat('d, h:mm a') +
          " - " + DateTime.fromISO(enddate).toFormat('h:mm a')
    }
    
    const title = (text: string): string => {
      text = _.replace(text || "", /&#34;/g, '"')
      return _.replace(text, /&#39;/g, "'")
    }
    
    const share = (action: string, text: string, language: string) => {
      const link = window.location.href
      const encodedUrl = encodeURIComponent(link + (language === 'en_us' ?
          ((link.indexOf('?') !== -1) ? '&' : '?') + 'hl=en' : ''))
    
      if (action === "facebook") {
        window.open("https://www.facebook.com/sharer.php?u=" + encodedUrl)
      } else if (action === "twitter") {
        window.open("https://twitter.com/intent/tweet?source=webclient&url=" + encodedUrl)
      } else if (action === "linkedin") {
        window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + encodedUrl)
      }
    }
    
  const include = (objvalueid: any) => {
  

  return _.has(store.state.i.me.fav_agenda_items, objvalueid)
    }
    
    const exclude = (objvalueid: any) => {
      return !_.has(store.state.i.me.fav_agenda_items, objvalueid)
    }
    
    const setFav = async (objvalueid: any) => {
      try {
        errorstring.value = { de: '', en: '' }
        const dpr = await store.dispatch("i/setDataFav", { objvalueid })
        if (!dpr.sv) {
          errorstring.value = {
            de: "Feld konnte nicht gesetzt werden.",
            en: "Could not set Field."
          }
        }
      } catch (e) {
        errorstring.value = networkErrorText(e as any)
      }
    }
    
    const unsetFav = async (objvalueid: any) => {
      try {
        errorstring.value = { de: '', en: '' }
        const dpr = await store.dispatch("i/setDataUnFav", { objvalueid })
        if (!dpr.sv) {
          errorstring.value = {
            de: "Feld konnte nicht gesetzt werden.",
            en: "Could not set Field."
          }
        }
      } catch (e) {
        errorstring.value = networkErrorText(e as any)
      }
    }
    
    const generateFileICS = (objvalueid: any) => {
      window.location.href = `/projects/dw/gmf/sources/scripts/portal.php?&action=generateFileICS&objvalueid=${objvalueid}`
    }
    
    return {
      agendaEntry,
      notFound,
      formatedDate,
      title,
      share,
      include,
      exclude,
      setFav,
      unsetFav,
      generateFileICS,
      errorstring,
      speakers
    }
  }
})
</script>

<style scoped>
.box {
  padding: 20px;
}

.embed-responsive {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.embed-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.buttonshare {
  cursor: pointer;
}

.share {
  margin-right: 10px;
}
</style>