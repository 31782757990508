<template>
    <section id="bigchatbox" class="chat-room">
      <div v-if="!contact" class="scroll-element row d-block" ref="scrollelement">
        <p>
          <span v-if="store.state.config.withsupportchat && store.state.i.connected && showsupport" id="chatindicator" @click.prevent="toggleChatbox" class="btn btn-primary">
            Support Chat <span v-if="unread > 0" class="badge badge-danger float-right ms-3">{{ unread }}</span>
          </span>
        </p>

        <div class="z-depth-1 pb-0">
          <ul class="list-unstyled friend-list my-custom-scrollbar">
            <li class="p-8 pt-3 me-4" v-if="store.getters['i/sortedContacts'].length !== 0">
                <input placeholder="Namen suchen" type="search" v-model.trim="searchtext" class="form-control">
            </li>
            <div class="me-4">
              <ChatContactUser
                  v-for="(u, index) in sortedContacts"
                  :key="'chatcontactuser' + index"
                  :user="u"
                  :selected="false"
                  :isSmall="true"
                  @pick="pickuser"
              />
            </div>
          </ul>
        </div>
      </div>

    <div v-if="contact" class="small-convo">


      <button @click.prevent="returnToList" type="button" class="btn  btn-primary mb-3">
        {{ store.getters.i18n({de: 'Übersicht', en: 'Overview'}) }}
      </button>

        <div class="d-flex justify-content-between p-2 resize-y">
          <div class="heading d-flex justify-content-start">
            <div v-if="userimage" class="profile-photo me-4">
              <div class="circular_image circular_image_small">
                <img :src="userimage" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
              </div>
              <span v-if="isonline" class="state"></span>
            </div>
            <div class="data">
              <p class="name mb-0 text-truncate" v-if="!isSupport">
                <strong>{{ contact.firstname }} {{ contact.lastname }}</strong><br>
                {{ formatTitle(contact.orgname) }}
              </p>
              <p class="name mb-0 text-truncate" v-if="isSupport">
                <strong>{{ store.getters.i18n({de:'Support', en:'Support'}) }}</strong>
              </p>
              <p class="name mb-0" v-if="isSupport && showtext">
                  The Support Chat will be available from 8 a.m. to 6 p.m. on June 17 and 18. Until June 17, 8 am, a response may take up to 48h.
              </p>
              <p class="name mb-0" v-if="isSupport && showtext2">
                  The Support Chat will be available from 8 a.m. to 6 p.m. on June 17 and 18. From June 20 on the support chat is not available anymore.
              </p>
            </div>
          </div>
        </div>

        <ChatSmallMsgWrapper />

        <div class="form-group basic-textarea">
              <textarea class="form-control pl-2 my-0" ref="msgenterbox"
                        v-model="mymessage"
                        autocomplete="off"
                        id="enterbox"
                        @keydown.passive="writing"
                        @keypress.enter.prevent="sendmymessage"
                        rows="3"
                        :placeholder="store.getters.i18n({de:'Ihre Nachricht',en:'Your message'})">
              </textarea>
        </div>

        <div class="mt-2">
          <div>
            <button :disabled="mymessage === '' || sending" @click.passive="sendmymessage" type="button" class="btn btn-primary">
              {{ store.getters.i18n({de: 'Senden', en: 'Send'}) }}
            </button>
          </div>
        </div>

      </div>
    </section>
</template>
<script setup lang="ts">
import { ref, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import type { ChatContact } from "@/store/i/types"
import ChatContactUser from "@/components/i/ChatContactUser.vue"
import ChatSmallMsgWrapper from "@/components/i/ChatSmallMsgWrapper.vue"
import _ from "lodash"
import { eveMessageLib } from "@/dummyEveMessageLib"

const store = useStore()
const msgenterbox = ref<HTMLTextAreaElement>()
const mymessage = ref('')
const sending = ref(false)
const searchtext = ref('')
const nowWriting = ref(false)
const isonline = ref(false)
const resizeMode = ref(false)

const stopWriting = _.debounce((vm) => {
  nowWriting.value = false
  if (contact.value !== "support") {
    eveMessageLib.msg.send.announcetouser(
        'stopwriting',
        {
          objvalueid: (contact.value as ChatContact).objvalueid,
          gsuffix: "members"
        },
        { anonymous: true, persist: false },
        (res: boolean) => {}
    )
  } else {
    eveMessageLib.msg.send.announce(
        'cp.supportchat',
        'stopwriting',
        { anonymous: true, persist: false },
        (res: boolean) => {}
    )
  }
}, 2000)

const showsupport = computed(() => {
  return 1
})

const isSupport = computed((): boolean => {
  return store.state.i.showsmallchat === "support"
})

const pickuser = (fbuid: string): void => {
  const uc = _.find(
      store.state.i.contactschats,
      (c) => c.fbuid === fbuid
  )

  if (uc) {
    store.commit('i/setChatboxContact', uc)
  }
}

const justSupportChat = computed((): boolean => {
  return true
})

const toggleChatbox = (): void => {
  store.commit(
      'i/showChatbox',
      (justSupportChat.value ? 'support' : true)
  )
}


const unread = computed((): number => {
  const unreadMessages = _.filter(
      store.state.chat.supportMessages,
      (um) => {
        return (
            !um.seen &&
            um.receiver === 'me'
        )
      }
  )
  return unreadMessages.length
})

const returnToList = (): void => {
  if (store.state.config.withnetworking) {
    store.commit('i/showChatbox')
  } else {
    hideChatbox()
  }
}

// Zusätzlich benötigen Sie die hideChatbox Methode
const hideChatbox = (): void => {
  store.state.i.showsmallchat = false
}

const showtext = computed(() => {
  const date = new Date("2022-06-20T08:00:00+02:00").getTime()
  const currenttime = store.state.tick.getTime()
  return currenttime < date ? 1 : 0
})

const showtext2 = computed(() => {
  const date = new Date("2022-06-22T00:00:00+02:00").getTime()
  const currenttime = store.state.tick.getTime()
  return currenttime > date ? 1 : 0
})



const formatTitle = (title: string): string => {
  let formattedTitle = _.replace(title, /&#34;/g, '"')
  formattedTitle = _.replace(formattedTitle, /&#39;/g, "'")
  return formattedTitle
}

const writing = (e: KeyboardEvent) => {
  if (e.key.length > 1) return

  if (!nowWriting.value) {
    nowWriting.value = true
    if (contact.value !== "support") {
      eveMessageLib.msg.send.announcetouser(
          'startwriting',
          {
            objvalueid: (contact.value as ChatContact).objvalueid,
            gsuffix: "members"
          },
          { anonymous: true, persist: false },
          (res: boolean) => {}
      )
    } else {
      eveMessageLib.msg.send.announce(
          'cp.supportchat',
          'startwriting',
          { anonymous: true, persist: false },
          (res: boolean) => {}
      )
    }
  }

  stopWriting(this)
}

const contact = computed(() => {
  if (store.state.i.showsmallchat === true) {
    return false
  }
  return store.state.i.showsmallchat
})

const sortedContacts = computed(() => {
  const sC = store.getters['i/sortedContacts']
  if (sC.length > 0 && searchtext.value.length > 0) {
    const swords = _.map(
        _.words(searchtext.value),
        s => new RegExp(_.escapeRegExp(s), 'iu')
    )
    return _.filter(
        sC,
        c => {
          for (let i = swords.length - 1; i >= 0; i--) {
            if (!swords[i].test(c.firstname + ' ' + c.lastname)) {
              return false
            }
          }
          return true
        }
    )
  }
  return sC
})

const userimage = computed((): string|null => {
  if (isSupport.value) {
    return '/projects/dw/gmf/assets/images/support-icon.jpg'
  }

  if (contact.value && contact.value !== "support") {
    return '/files/members/' + (contact.value as ChatContact).objvalueid + '/foto.jpg'
  }

  return '/projects/dw/gmf/assets/images/placeholder.jpg'
})

const sendmymessage = async () => {
  if (sending.value) return

  sending.value = true
  if (contact.value && mymessage.value !== "") {
    try {
      if (contact.value === "support") {
        await store.dispatch('i/sendmysupportmsg', {
          body: mymessage.value,
          ts: Date.now() / 1000,
          sender: "me",
          receiver: "support"
        })
      } else if ((contact.value as ChatContact).objvalueid > 0) {
        await store.dispatch('i/checkIfContactNeedsToBeAdded', {...contact.value})
        await store.dispatch('i/sendmydm', {
          body: mymessage.value,
          ts: Date.now() / 1000,
          sender: store.state.config.fbuid,
          receiver: (contact.value as ChatContact).fbuid
        })
      } else {
        return
      }

      mymessage.value = ""
      await nextTick()
      msgenterbox.value?.focus()
    } catch (e) {
      console.error(e)
    }
  }
  sending.value = false
}

const checkOnlineState = async () => {
  if (contact.value) {
    let checkres: boolean|null = null
    if (contact.value === "support") {
      checkres = await store.dispatch("i/checkSupportAvailable")
    } else {
      checkres = await store.dispatch("i/checkUserOnlineState", (contact.value as ChatContact).objvalueid)
    }

    isonline.value = !!checkres
    window.setTimeout(checkOnlineState, 1000)
  } else {
    isonline.value = false
  }
}

// Watch für Änderungen am Contact
watch(contact, () => {
  void checkOnlineState()
})
</script>


<style scoped lang="scss">
    .my-custom-scrollbar {
      overflow-y: scroll;
      height:calc(100vh - 200px);
      margin-bottom: 1rem;
  }
</style>