import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "col-md-12 col-xl-12" }
const _hoisted_3 = { class: "d-flex align-items-center mb-4" }
const _hoisted_4 = { class: "me-auto form-check" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = { class: "form-check" }
const _hoisted_8 = ["value", "disabled"]
const _hoisted_9 = { class: "form-check-label" }
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = { class: "form-check" }
const _hoisted_12 = ["value", "disabled"]
const _hoisted_13 = { class: "form-check-label" }
const _hoisted_14 = { class: "mb-4" }
const _hoisted_15 = { class: "d-flex align-items-center" }
const _hoisted_16 = {
  class: "btn btn-primary m-1",
  type: "submit"
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }

import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { TranslatedText } from "@/store/types"
import { AppNetworkErrorOther, AppNetworkErrorNotAllowed, AppNetworkErrorServer, AppNetworkErrorTokenNotSet, AppNetworkErrorNotFound } from "@/crud"
import _ from 'lodash'

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'OnBoarding1',
  props: {
    type: {}
  },
  setup(__props: any) {

const props = __props

// Store
const store = useStore()

// Reactive state
const errorstring = ref<TranslatedText>({ de: '', en: '' })
const successtring = ref<TranslatedText>({ de: '', en: '' })
const networking_question1 = ref<number[]>([])
const networking_question2 = ref<number[]>([])


const getNetworkErrorText = (e: AppNetworkErrorOther | AppNetworkErrorNotAllowed | AppNetworkErrorServer | AppNetworkErrorTokenNotSet | AppNetworkErrorNotFound): TranslatedText => {
  return {
    de: 'Ein Netzwerkfehler ist aufgetreten',
    en: 'A network error occurred'
  }
}


// Computed
const disabled1 = computed(() => {
  return (ei: number) => {
    return networking_question1.value.length >= 5 && !networking_question1.value.includes(ei)

  }
})

const disabled2 = computed(() => {
  return (ei: number) => {
    return networking_question2.value.length >= 5 && !networking_question2.value.includes(ei)
  }
})

// Methods
const setDataOnBoarding1 = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    successtring.value = { de: '', en: '' }

    const networking1: { [key: number]: number } = {}
    const networking2: { [key: number]: number } = {}

    networking_question1.value.forEach((value, index) => {
      networking1[index] = value
    })

    networking_question2.value.forEach((value, index) => {
      networking2[index] = value
    })

    await store.dispatch('i/setNetworking', {
      networking_question1: networking1,
      networking_question2: networking2
    })

    successtring.value = {
      de: 'Ihre Daten wurden erfolgreich gespeichert',
      en: 'Your data has been saved successfully'
    }

    if (props.type === 'step') {
      await store.dispatch('i/setOnBoardingStep', 2)
    }

  } catch (e) {
    if (e instanceof AppNetworkErrorOther) {
      errorstring.value = getNetworkErrorText(e)
    } else {
      errorstring.value = {
        de: 'Ein unbekannter Fehler ist aufgetreten',
        en: 'An unknown error occurred'
      }
    }
    console.error(e)
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _withModifiers(setDataOnBoarding1, ["prevent"])
      }, [
        _withDirectives(_createElementVNode("div", { class: "alert alert-success col-lg-12" }, _toDisplayString(_unref(store).getters.i18n(successtring.value)), 513), [
          [_vShow, successtring.value.de !== '']
        ]),
        _withDirectives(_createElementVNode("div", { class: "alert alert-danger mb-5" }, _toDisplayString(_unref(store).getters.i18n(errorstring.value)), 513), [
          [_vShow, errorstring.value.de !== '']
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("p", null, " Welcome to the 2024 DW Global Media Forum conference platform. It is good to have you with us this #GMF24! ", -1)),
        _cache[9] || (_cache[9] = _createElementVNode("p", null, " Complete your networking profile to connect with media professionals from around the globe in just a couple of minutes. ", -1)),
        _cache[10] || (_cache[10] = _createElementVNode("p", null, " Once you have created your profile, you can use the matchmaking function to add new contacts based on the areas of interest you specify in your profile. You will automatically be shown contacts that match your own interests. You can also connect directly with other participants using the chat function. ", -1)),
        _cache[11] || (_cache[11] = _createElementVNode("p", null, " This networking tool is optional. If you do not want to participate in our networking features, simply do not check the box at the bottom of this page. ", -1)),
        _cache[12] || (_cache[12] = _createElementVNode("p", { class: "pb-5" }, " By the way: You can add or change your details in your profile at any time. ", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(store).state.i.me.accept_networking) = $event)),
              type: "checkbox",
              name: "accept_networking",
              id: "accept_networking"
            }, null, 512), [
              [_vModelCheckbox, _unref(store).state.i.me.accept_networking]
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("label", {
              class: "form-check-label",
              for: "accept_networking"
            }, " Yes, I want to network. ", -1))
          ])
        ]),
        (_unref(store).state.i.me.accept_networking)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-check-label" }, [
                  _createElementVNode("b", null, "My expertise (max.5)")
                ], -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.i.me.networkinglist, (e, ei) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((networking_question1).value = $event)),
                      value: ei,
                      disabled: disabled1.value(ei)
                    }, null, 8, _hoisted_8), [
                      [_vModelCheckbox, networking_question1.value]
                    ]),
                    _createElementVNode("label", _hoisted_9, _toDisplayString(e), 1)
                  ]))
                }), 256))
              ]),
              _createElementVNode("div", _hoisted_10, [
                _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-check-label" }, [
                  _createElementVNode("b", null, "My interests (max.5)")
                ], -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.i.me.networkinglist, (e, ei) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _withDirectives(_createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((networking_question2).value = $event)),
                      value: ei,
                      disabled: disabled2.value(ei)
                    }, null, 8, _hoisted_12), [
                      [_vModelCheckbox, networking_question2.value]
                    ]),
                    _createElementVNode("label", _hoisted_13, _toDisplayString(e), 1)
                  ]))
                }), 256))
              ]),
              _createElementVNode("div", _hoisted_14, [
                _cache[7] || (_cache[7] = _createElementVNode("label", { class: "form-label" }, "My most-used social network", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(store).state.i.me.networking_question3) = $event)),
                  class: "form-control"
                }, null, 512), [
                  [_vModelText, _unref(store).state.i.me.networking_question3]
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("button", _hoisted_16, [
            (_ctx.type === 'step')
              ? (_openBlock(), _createElementBlock("span", _hoisted_17, "Next"))
              : (_openBlock(), _createElementBlock("span", _hoisted_18, "Save"))
          ])
        ])
      ], 32)
    ])
  ]))
}
}

})