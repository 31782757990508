import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-5 mb-5"
}
const _hoisted_2 = { class: "circular_image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "Name-Nachname mb-1" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "Role copydetailxs mb-1 bold" }
const _hoisted_7 = { class: "Role copydetailxs" }
const _hoisted_8 = {
  key: 0,
  class: "Role copydetailxs badge badge-pill"
}
const _hoisted_9 = {
  key: 1,
  class: "Role copydetailxs badge badge-pill"
}

import { useRouter, useRoute } from 'vue-router'
import type { ContactsNetworking } from "@/store/i/types"
import _ from "lodash"

interface Props {
  member: ContactsNetworking
  type?: string
  numberkey?: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactEntry',
  props: {
    member: {},
    type: {},
    numberkey: {}
  },
  setup(__props: any) {

const props = __props
const router = useRouter()
const route = useRoute()

const navigateMember = () => {
  router.push({
    name: 'ContactDetail',
    params: { contactid: `${props.member.objvalueid}` }
  })
}

const position_ranking = (keyvalue?: number): number => {
  return keyvalue ? keyvalue + 1 : 1
}

const title = (title: string): string => {
  let processedTitle = _.replace(title, /&#34;/g, '"')
  processedTitle = _.replace(processedTitle, /&#39;/g, "'")
  return processedTitle
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["row", {'col-lg-4 col-md-6 col-12': _unref(route).name === 'Welcome', 'col-lg-6': _unref(route).name != 'Welcome'}])
  }, [
    (_unref(route).name != 'Welcome')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("a", {
            onClick: navigateMember,
            class: "pointer"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: `/files/members/${props.member.objvalueid}/foto.jpg`,
                onerror: "this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';",
                alt: "Contact Image"
              }, null, 8, _hoisted_3)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["mb-5 ipadfx2", {'col': _unref(route).name === 'Welcome', 'col-7': _unref(route).name != 'Welcome'}])
    }, [
      _createElementVNode("a", {
        onClick: navigateMember,
        class: "pointer"
      }, [
        _createElementVNode("div", _hoisted_4, [
          (props.type === 'ranking')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(position_ranking(props.numberkey)) + ".", 1))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(props.member.firstname) + " " + _toDisplayString(props.member.lastname), 1)
        ]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(title(props.member.position)), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(title(props.member.orgname)), 1),
        (props.type != 'ranking')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(props.member.match) + " %", 1))
          : _createCommentVNode("", true),
        (props.type === 'ranking')
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(props.member.ranking), 1))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ], 2))
}
}

})