<template>
  <div class="vertical-nav">
    <button
        type="button"
        class="btn-close btn-close"
        aria-label="Close"
        @click.prevent="store.commit('setSideNav', '')"
    ></button>
    <div class="row m-0">
      <div class="col vertical-nav-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

onMounted(() => {
  document.body.classList.add("noscroll")
})

onBeforeUnmount(() => {
  document.body.classList.remove("noscroll")
})
</script>