import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "col-md-12 col-xl-12" }
const _hoisted_3 = { class: "circular_image circular_image_large mb-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "d-flex align-items-center"
}

import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { TranslatedText } from "@/store/types"
import { AppNetworkErrorOther, networkErrorText } from "@/crud"
import rankingsMaxPoints from '@/ranking'

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'OnBoarding3',
  props: {
    type: {}
  },
  setup(__props: any) {

const props = __props

// Store & Router
const store = useStore()
const router = useRouter()

// Reactive state
const errorstring = ref<TranslatedText>({ de: '', en: '' })
const successtring = ref<TranslatedText>({ de: '', en: '' })
const file = ref<File | null>(null)

// Methods
const reset_message = () => {
  errorstring.value = { de: '', en: '' }
  successtring.value = { de: '', en: '' }
}

const next = async () => {
  if (props.type === "step") {
    router.replace({ name: "Step4" })
  }
}

const setDataOnBoarding3 = async (event: Event) => {
  const target = event.target as HTMLInputElement
  if (!target.files?.length) return

  file.value = target.files[0]

  if (file.value.size > 1000000) {
    errorstring.value = {
      de: "Could not save image. Max. allowed file size is 1MB (Mbyte)",
      en: "Could not save image. Max. allowed file size is 1MB (Mbyte)"
    }
    return
  }

  const formData = new FormData()
  formData.append('file', file.value)

  try {
    const dpr = await store.dispatch("i/setDataOnBoarding3", formData)
    setTimeout(reset_message, 2000)

    if (dpr.sv) {
      successtring.value = {
        de: "Image saved.",
        en: "Image saved."
      }

      // ranking activity m - Bild hochgeladen 6 Punkte
      await store.dispatch('i/setDataRankingM', rankingsMaxPoints.ranking_m)
    } else {
      errorstring.value = {
        de: "Could not save image.",
        en: "Could not save image."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", { class: "alert alert-success col-lg-12" }, _toDisplayString(_unref(store).getters.i18n(successtring.value)), 513), [
        [_vShow, successtring.value.de !== '']
      ]),
      _withDirectives(_createElementVNode("div", { class: "alert alert-danger mb-5" }, _toDisplayString(_unref(store).getters.i18n(errorstring.value)), 513), [
        [_vShow, errorstring.value.de !== '']
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("p", null, " Please prepare a profile picture in 250x250px (max. 1 MB) and upload it here! ", -1)),
      _createElementVNode("p", null, [
        _createElementVNode("input", {
          type: "file",
          name: "import_file",
          onChange: _cache[0] || (_cache[0] = ($event: any) => (setDataOnBoarding3($event))),
          accept: "image/jpeg"
        }, null, 32)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: `/files/members/${_unref(store).state.config.me.objvalueid}/foto.jpg?nocache=${Date.now()}`,
          onerror: "this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';",
          alt: ""
        }, null, 8, _hoisted_4)
      ]),
      (_ctx.type === 'step')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: { name: 'Step2' },
              class: "btn btn-primary m-1"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Back")
              ])),
              _: 1
            }),
            _createVNode(_component_router_link, {
              to: { name: 'Step4' },
              class: "btn btn-primary m-1"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Next")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})