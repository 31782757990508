<template>
  <VerticalNav>
    <div class="row scroll-header">
      <div class="col mt-4">
        <p style="font-size: 28px">
          {{ store.getters.i18n({de: 'Mitteilungen', en: 'Notifications'}) }}
        </p>
      </div>
    </div>

    <div class="scroll-element row d-block" ref="scrollElement">
      <div
          class="row mb-2 align-self-start"
          v-for="(n, ni) in sortedNotifications"
          :key="`notification${ni}`"
      >
        <div class="col">
          <ToastAlert
              :inline="true"
              :t="n"
              @dismissed="removeToast"
          >
            {{ store.getters.i18n(n.msg) }}
          </ToastAlert>
        </div>
      </div>
    </div>
  </VerticalNav>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import ToastAlert from "@/components/i/ToastAlert.vue"
import { InteractionAlert } from "@/store/i/types"
import OfflineDB from "@/store/i/offlinedb"
import { DateTime } from "luxon"
import _ from "lodash"

const store = useStore()
const scrollElement = ref<HTMLElement>()
const archiveNotifications = ref<InteractionAlert[]>([])
const correctScroll = ref(true)

const sortedNotifications = computed((): InteractionAlert[] => {
  return _.orderBy(
      archiveNotifications.value,
      [(n: InteractionAlert) => n.got?.getTime()],
      ["desc"]
  )
})

const readnotifications = async () => {
  if (store.state.sidenav === "notifications") {
    archiveNotifications.value = await OfflineDB.toasts.toCollection().toArray() as InteractionAlert[]
    _.remove(
        archiveNotifications.value,
        (ta: InteractionAlert) => (
            !!(ta.removed) ||
            !!(ta.queued) ||
            (ta.validfrom && ta.validfrom > store.state.tick)
        )
    )
    if (archiveNotifications.value.length > 0) {
      const olderthanaday = _.filter(
          archiveNotifications.value,
          (n: InteractionAlert) => DateTime.fromJSDate(n.got ? n.got : new Date()).diffNow().as('hours') <= -24
      )
      if (olderthanaday.length > 0) {
        olderthanaday.forEach(
            (t: InteractionAlert) => { removeToast(t) }
        )
      }
    }
  } else {
    archiveNotifications.value = []
  }
}

const removeToast = async (t: InteractionAlert) => {
  await OfflineDB.deleteToast(t)
  _.remove(
      archiveNotifications.value,
      (to: InteractionAlert) => to === t
  )
  archiveNotifications.value = [...archiveNotifications.value]
}

const scrollBottom = async () => {
  if (!scrollElement.value) return

  if (correctScroll.value) {
    scrollElement.value.removeEventListener('scroll', lookIfToHaltAutoscroll)
    await nextTick()
    scrollElement.value.scrollTop = scrollElement.value.scrollHeight - scrollElement.value.clientHeight
    await nextTick()
    scrollElement.value.addEventListener('scroll', lookIfToHaltAutoscroll, {passive:true})
  }
}

const lookIfToHaltAutoscroll = () => {
  if (!scrollElement.value) return

  const pos = scrollElement.value.scrollTop
  const desiredPos = scrollElement.value.scrollHeight - scrollElement.value.clientHeight
  correctScroll.value = pos >= desiredPos - 20
}

// Watchers
watch(() => store.state.sidenav, readnotifications)
watch(() => store.state.i.toasts, readnotifications)
watch(archiveNotifications, scrollBottom)

// Lifecycle hooks
onMounted(() => {
  readnotifications()
  scrollBottom()

  if (scrollElement.value) {
    window.addEventListener('resize', scrollBottom, {passive:true})
    scrollElement.value.addEventListener('scroll', lookIfToHaltAutoscroll, {passive:true})
  }
})

onBeforeUnmount(() => {
  if (scrollElement.value) {
    window.removeEventListener('resize', scrollBottom)
    scrollElement.value.removeEventListener('scroll', lookIfToHaltAutoscroll)
  }
})
</script>