<template>
  <div class="box">
    <MeetingModal :connection="connection" :toggle_modal="toggle_modal" />
    <JitsiCall type="member" v-if="currentCall" />

    <div class="row g-3" v-if="contactEntry">

      <div class="col-md-5 col-lg-3 mb-3 sm-center">
        <div class="circular_image_large">
          <img :src="`/files/members/${contactEntry.objvalueid}/foto.jpg`" width="250" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
        </div>
      </div>

      <div class="col-md-7 col-lg-9 mb-3 text-left ipadfx">
        <h3 class="pb-3 pt-0"> {{contactEntry.firstname}} {{contactEntry.lastname}}</h3>
        <div v-if="contactEntry.position">Position: {{title(contactEntry.position)}}</div>
        <div v-if="contactEntry.orgname">Organization: {{title(contactEntry.orgname)}}</div>
        <div v-if="contactEntry.show_email && contactEntry.email">E-Mail adress: <a :href="`mailto:${contactEntry.email}`" >{{contactEntry.email}}</a></div>
        <div v-if="contactEntry.phone">Phone: {{contactEntry.phone}}</div>
        <div v-if="contactEntry.country" class="mb-4">Country: {{countrylist[contactEntry.country]}}</div>

        <div class="row">
          <div class="col-12 col-md-3" v-if="contactEntry.linkedin || contactEntry.twitter || contactEntry.facebook">
            <span class="Role copydetailxs"><br>
                <span class="headline_light text-center">Follow me</span>
              <br>
              <a  v-if="contactEntry.twitter" :href=contactEntry.twitter class="buttonshare" target="_blank"><i class="fab fa-2x fa-x-twitter"></i>
                    <span class="sr-only">X (Twitter)</span></a>
              <a  v-if="contactEntry.linkedin" :href=contactEntry.linkedin class="buttonshare" target="_blank"><i class="fab fa-2x fa-linkedin-in"></i>
                  <span class="sr-only">LinkedIn</span></a>
              <a  v-if="contactEntry.facebook" :href=contactEntry.facebook class="buttonshare" target="_blank"><i class="fab fa-2x fa-facebook-f"></i>
                  <span class="sr-only">Facebook</span></a>
            </span>
            <br>
          </div>

          <span class="Role copydetailxs col-12 col-md-3" v-if="contactEntry.show_vcard">
            <br>
              <span class="headline_light text-center">VCARD DOWNLOAD</span><br>
              <a class="buttonshare" @click="exportvCard(contactEntry.objvalueid)"><i class="fal fa-address-card"></i></a>
          </span>

          <span class="Role copydetailxs col-12 col-md-6" v-if="contactEntry.accept_networking && contactEntry.objvalueid != store.state.config.me.objvalueid">
            <br>
              <span class="headline_light text-center">Networking</span><br>
                 <button class="btn btn-primary m-1"  v-if="!connectionEntry1" @click.prevent="createConnection(contactEntry)">
                      <span class="smartphone"><i class="fal fa-user-plus"></i></span>
                      <span class="nsmartphone">Send contact request</span>
                 </button>
                 <button class="btn btn-outline-primary m-1" v-if="connectionEntry2" >
                     <span class="smartphone"><i class="fal fa-user-plus"></i></span>
                      <span class="nsmartphone">Contact request sent</span>
                 </button>
                 <button class="btn btn-outline-primary m-1" v-if="connectionEntry3" >
                      <span class="smartphone"><i class="fal fa-user-plus"></i></span>
                      <span class="nsmartphone">Contact request received</span>
                 </button>
                 <button class="btn btn-primary m-1" v-if="connectionEntry4" @click.prevent="pickuserchat(contactEntry)">
                      <span class="smartphone"><i class="fal fa-comment"></i></span>
                      <span class="nsmartphone">Chat</span>
                 </button>
                 <button class="btn btn-primary m-1" v-if="connectionEntry4" @click.prevent="pickusercall(contactEntry)">
                      <span class="smartphone"><i class="fal fa-video"></i></span>
                      <span class="nsmartphone">Call</span>
                 </button>
                 <button class="btn btn-primary m-1" v-if="connectionEntry4" @click.prevent="meeting(contactEntry)">
                      <span class="smartphone"><i class="fal fa-calendar-edit"></i></span>
                      <span class="nsmartphone">Appointment request</span>
                 </button>
          </span>
          <br>

        </div>

      </div>

      <hr>

      <div v-if="contactEntry.objvalueid != store.state.config.me.objvalueid">
        <h4 class="bold mb-3">Matching result</h4>
        <div class="mb-2">
          <span class="badge badge-pill">  {{ contactEntry.match }} %</span>
        </div>
        <div class="mb-2">
          <b>My Expertise</b><br>
          <span v-for="(e, ei) in networking_question1"><span :title="is_selected1(String(ei)) ? 'Match' : 'No match'" :class="{'badge badge-no-match': true, 'badge-light' : is_selected1(String(ei))}">{{networkinglist[ei]}}</span></span>
        </div>
        <div class="mb-2">
          <b>My interests</b><br>
          <span v-for="(e, ei) in networking_question2"><span :title="is_selected2(String(ei)) ? 'Match' : 'No match'" :class="{'badge badge-no-match': true, 'badge-light' : is_selected2(String(ei))}">{{networkinglist[ei]}}</span></span>
        </div>
        <div class="mb-2">
          <b>My Calendar</b><br>
          <span v-for="(e, ei) in fav_agenda_items"><span  :title="is_selected3(String(ei)) ? 'Match' : 'No match'" :class="{'badge badge-no-match': true, 'badge-light' : is_selected3(String(ei))}">{{title(agendaEntryTitle(String(ei)))}}</span></span>
          <p v-if="fav_agenda_items.length === 0">This participant did not bookmark any program items.</p>
        </div>
        <hr>
      </div>

      <div v-if="contactEntry.cv">
        <h4 class="bold mb-3">Resume</h4>
        <div class="cv" v-html="contactEntry.cv"></div>
        <hr>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute, onBeforeRouteLeave} from 'vue-router';
import { useStore } from 'vuex';
import { useGtm } from '@/composables/useGtm'
import _ from 'lodash';
import JitsiCall from "@/components/jitsi/JitsiCall.vue";
import MeetingModal from "@/components/i/MeetingModal.vue";
import type { AgendaPoint, ConnectionNetworking, ContactsNetworking } from "@/store/i/types";
import { AppNetworkErrorOther, networkErrorText } from "@/crud";
import type { TranslatedText } from "@/store/types";

// Hooks initialisieren
const route = useRoute();
const store = useStore();
const gtm = useGtm();

// Reactive Refs
const errorstring = ref<TranslatedText>({ de: '', en: '' });
const successtring = ref<TranslatedText>({ de: '', en: '' });
const connection = ref(0);
const toggle_modal = ref(false);

// Computed Properties
const contactEntry = computed(() => {
  return _.find(
      store.state.i.contacts_networking,
      (e: ContactsNetworking) => `${e.objvalueid}` === `${route.params.contactid}`
  );
});

const connectionEntry1 = computed(() => {
  return _.find(
      store.state.i.connections_networking,
      (e: ConnectionNetworking) =>
          (`${e.sender}` === `${route.params.contactid}` ||
              `${e.receiver}` === `${route.params.contactid}`) &&
          e.dbstate != -1
  );
});

const connectionEntry2 = computed(() => {
  return _.find(
      store.state.i.connections_networking,
      (e: ConnectionNetworking) =>
          `${e.receiver}` === `${route.params.contactid}` &&
          e.status == 1 &&
          e.dbstate != -1
  );
});

const connectionEntry3 = computed(() => {
  return _.find(
      store.state.i.connections_networking,
      (e: ConnectionNetworking) =>
          `${e.sender}` === `${route.params.contactid}` &&
          e.status == 1 &&
          e.dbstate != -1
  );
});

const connectionEntry4 = computed(() => {
  return _.find(
      store.state.i.connections_networking,
      (e: ConnectionNetworking) =>
          (`${e.sender}` === `${route.params.contactid}` ||
              `${e.receiver}` === `${route.params.contactid}`) &&
          e.status == 2 &&
          e.dbstate != -1
  );
});

const countrylist = computed(() => store.state.i.me.countrylist);
const networkinglist = computed(() => store.state.i.me.networkinglist);
const networking_question1 = computed(() => contactEntry.value?.networking_question1 || 0);
const networking_question2 = computed(() => contactEntry.value?.networking_question2 || 0);
const fav_agenda_items = computed(() => contactEntry.value?.fav_agenda_items || 0);
const currentCall = computed(() => store.state.members.currentCall);

const agendaEntry = computed(() => {
  return _.filter(
      store.getters['i/orderedAgendaPoints'],
      (ap: AgendaPoint) => ap.dbstate > -1
  );
});

// Methoden
const title = (title: string): string => {
  let processed = _.replace(title, /&#34;/g, '"');
  processed = _.replace(processed, /&#39;/g, "'");
  return processed;
};

const agendaEntryTitle = (ei: string): string => {
  const entry = _.find(
      agendaEntry.value,
      (e: AgendaPoint) => `${e.objvalueid}` === ei
  );
  return entry ? entry.title : '';
};

const is_selected1 = (ei: string): boolean => {
  return _.includes(_.keys(store.state.i.me.networking_question2), ei);
};

const is_selected2 = (ei: string): boolean => {
  return _.includes(_.keys(store.state.i.me.networking_question1), ei);
};

const is_selected3 = (ei: string): boolean => {
  return _.includes(_.keys(store.state.i.me.fav_agenda_items), ei);
};

const exportvCard = (objvalueid: number): void => {
  window.location.href = `/projects/dw/gmf/sources/scripts/portal.php?&action=ExportvCard&objvalueid=${objvalueid}`;
};

const reset_message = (): void => {
  errorstring.value = { de: '', en: '' };
  successtring.value = { de: '', en: '' };
};

const createConnection = async (c: ContactsNetworking): Promise<void> => {
  try {
    errorstring.value = { de: '', en: '' };
    successtring.value = { de: '', en: '' };
    const dpr = await store.dispatch("i/createConnection", c);
    setTimeout(reset_message, 2000);

    if (dpr) {
      successtring.value = {
        de: "Kontaktanfrage wurde gesendet",
        en: "Contact request sent"
      };
    } else {
      errorstring.value = {
        de: "Kontaktanfrage konnte nicht erstellt werden",
        en: "Contact request could not be created"
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

const pickuserchat = (cp: ContactsNetworking): void => {
  const uc = _.find(
      store.state.i.contacts_networking,
      (c) => c.fbuid === cp.fbuid
  );
  if (uc) {
    store.commit('OpenSideNav', 'chat');
    store.commit('i/setChatboxContact', uc);
  }
};

const pickusercall = async (cp: ContactsNetworking): Promise<void> => {
  if (cp.fbuid !== store.state.config.fbuid) {
    if (currentCall.value) {
      store.commit("i/addToast", {
        header: { de: "Fehler", en: "Error" },
        msg: {
          de: 'Sie sind bereits im Gespräch.',
          en: 'You are already in a call.'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        showfor: 10000,
        alert_type: 'error'
      });
    } else {
      const c = {
        fbuid: cp.fbuid,
        objvalueid: cp.objvalueid,
        firstname: cp.firstname,
        lastname: cp.lastname,
        orgname: cp.orgname,
      };

      store.commit("members/setContact", c);
      await store.dispatch("members/startCall", cp.objvalueid);

      store.commit("i/addToast", {
        header: { de: "Hinweis", en: "Warning" },
        msg: {
          de: 'Bitte haben Sie einen Moment Geduld, bis Ihr Ansprechpartner Ihren Anruf entgegen nimmt. Wenn Sie das Gespräch beenden, ohne dass Ihr Ansprechpartner den Anruf entgegen genommen hat, wird dieser per Chat-Nachricht über Ihren Kontaktversuch informiert.',
          en: 'Please be patient until your contact accepts your call. If you end the conversation before your contact has accepted the call, they will be informed about your contact attempt via chat message.',
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        showfor: 10000,
        alert_type: 'error'
      });
    }
  }
};

const meeting = (cp: ContactsNetworking): void => {
  toggle_modal.value = !toggle_modal.value;
  connection.value = cp.objvalueid;
};

// Lifecycle Hooks
onMounted(() => {
  const { trackPage } = useGtm()


  trackPage({
    pageCategory: 'Contact',
    PageName: 'Detailpage',
    PageType: 'Webapp',
  });
});

onBeforeRouteLeave((to, from, next) => {
  if (store.state.members.currentCall &&
      !store.getters['i/forcedToPlenum'] &&
      !store.getters['i/redirectToPlenum']) {
    const confirmres = window.confirm(store.getters.i18n({
      de: "If you are leaving the booth now, your call will be ended.",
      en: "If you are leaving the booth now, your call will be ended."
    }));
    confirmres ? next() : next(false);
  } else {
    next();
  }
});

// Expose für Template
defineExpose({
  contactEntry,
  connectionEntry1,
  connectionEntry2,
  connectionEntry3,
  connectionEntry4,
  countrylist,
  networkinglist,
  currentCall,
  connection,
  toggle_modal,
  title,
  agendaEntryTitle,
  is_selected1,
  is_selected2,
  is_selected3,
  exportvCard,
  createConnection,
  pickuserchat,
  pickusercall,
  meeting,
  errorstring,
  successtring,
  networking_question1,
  networking_question2,
  fav_agenda_items
});
</script>