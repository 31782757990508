<template>
  <div :class="{
    'col-lg-4 col-md-6 col-12 mediathek': agendatype === 'video' || route.name === 'Mediathek',
    'col-lg-9 col-12': agendatype != 'video' && route.name != 'Mediathek'
  }">
    <div :class="`card mb-3 et${ae.eventtype}`">
      <div class="card-header" v-if="agendatype != 'video' && route.name != 'Mediathek'">
        <small class="text-muted" style="font-size:1.2rem; padding-right:5px;cursor:pointer;"
               title="Bookmark" @click="unsetFav" v-if="Include">
          <i class="fal fa-calendar-plus"></i>
        </small>
        <small class="text-muted" style="font-size:1.2rem; padding-right:5px;cursor:pointer;"
               title="Bookmark" @click="setFav" v-if="Exclude">
          <i class="fal fa-calendar-minus"></i>
        </small>
        <small class="text-muted" style="font-size:1.2rem; padding-right:5px;cursor:pointer;"
               title="ICS Download" @click="generateFileICS">
          <i class="fal fa-calendar-check"></i>
        </small>
        <small style="font-size:1rem;">{{formatedFromTime}} - {{formatedToTime}} CEST</small>
      </div>

      <div class="card-body" :class="{'p-0': agendatype === 'video' || route.name === 'Mediathek'}">
        <a @click="navigateAgenda" class="pointer">
          <img v-if="agendatype === 'video' || route.name === 'Mediathek'"
               onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/preview.jpg';"
               :src="`/files/agenda_items/${ae.objvalueid}/preview.jpg?nocache=${(new Date()).getMinutes()}`"
               alt="Preview-Image">

          <p class="card-title bold">{{title}}</p>
          <p v-if="agendatype != 'video' && route.name != 'Mediathek'"
             class="card-title" v-html="ae.subtitle"></p>
          <p class="card-text bold"
             v-if="ae.hosted_by && (agendatype != 'video' && route.name != 'Mediathek')">
            Hosted by: <span v-html="ae.hosted_by"></span>
          </p>
        </a>
      </div>

      <div class="card-footer"
           v-if="(ae.room || ae.stream) && (agendatype != 'video' && route.name != 'Mediathek')">
        <small class="text-muted">
          <span v-if="ae.room"><i class="fal fa-map-marker-alt"></i> <span v-html="ae.room"></span><br></span>
          <span v-if="ae.stream"><i class="fal fa-signal-stream"></i> <span v-html="ae.stream"></span><br></span>
        </small>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted  } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import _ from 'lodash'
import type { AgendaPoint } from '@/store/i/types'
import type { TranslatedText } from '@/store/types'
import { networkErrorText, type AppNetworkErrorOther } from '@/crud'
import { DateTime } from 'luxon'


// Lifecycle Hooks
onMounted(async () => {
  console.log('AgendaEntry.vue mounted')
  await store.dispatch("i/fetchAgenda")
})


// Props
interface Props {
  ae: AgendaPoint
  type?: number
  agendatype?: string
}
const props = defineProps<Props>()

// Store, Router & Route
const store = useStore()
const router = useRouter()
const route = useRoute()

// Reactive State
const errorstring = ref<TranslatedText>({ de: '', en: '' })

// Computed Properties
const Include = computed(() =>
    _.has(store.state.i.me.fav_agenda_items, props.ae.objvalueid)
)

const Exclude = computed(() =>
    !_.has(store.state.i.me.fav_agenda_items, props.ae.objvalueid)
)

const title = computed(() => props.ae.title)

const formatedFromTime = computed(() => {
  return DateTime.fromISO(props.ae.fromtime).toFormat('HH:mm')
})

const formatedToTime = computed(() => {
  return DateTime.fromISO(props.ae.totime).toFormat('HH:mm')
})

// Methods
const navigateAgenda = () => {
  router.push({
    name: 'AgendaDetail',
    params: { agendaid: `${props.ae.objvalueid}` }
  })
}

const setFav = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    const dpr = await store.dispatch("i/setDataFav", {
      objvalueid: props.ae.objvalueid
    })
    if (!dpr.sv) {
      errorstring.value = {
        de: "Feld konnte nicht gesetzt werden.",
        en: "Could not set Field."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

const unsetFav = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    const dpr = await store.dispatch("i/setDataUnFav", {
      objvalueid: props.ae.objvalueid
    })
    if (!dpr.sv) {
      errorstring.value = {
        de: "Feld konnte nicht entfernt werden.",
        en: "Could not remove Field."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

const generateFileICS = () => {
  window.location.href = `/projects/dw/gmf/sources/scripts/portal.php?&action=generateFileICS&objvalueid=${props.ae.objvalueid}`
}




</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.mediathek img {
  width: 100%;
}

.card-title {
  margin-top: 1rem;
}

.bold {
  font-weight: bold;
}
</style>