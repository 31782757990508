import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "modal fade show",
  style: {"display":"block"}
}
const _hoisted_3 = { class: "modal-dialog" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "modal-header" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { class: "form-check form-switch" }
const _hoisted_8 = ["checked"]
const _hoisted_9 = { class: "mt-2" }
const _hoisted_10 = { class: "form-check form-switch" }
const _hoisted_11 = ["checked"]
const _hoisted_12 = { class: "mt-2" }
const _hoisted_13 = ["value"]

import { ref, onMounted, inject } from 'vue'
import type { Emitter as MittEmitter } from 'mitt'

type Events = {
  openAccessibilityModal: void
  closeAccessibilityModal: void
}

interface AccessibilityOptions {
  hideImages: boolean
  underlineLink: boolean
  fontSize: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AccessiblityModal',
  setup(__props) {

const emitter = inject('emitter') as MittEmitter<Events>
const show = ref(false)
const accessibilityOptions = ref<AccessibilityOptions>({
  hideImages: false,
  underlineLink: false,
  fontSize: 0
})

const getAccessibiltyOptions = (): AccessibilityOptions => {
  const defaultOptions: AccessibilityOptions = {
    hideImages: false,
    underlineLink: false,
    fontSize: 0
  }

  const storageValue = localStorage.getItem('accessibility-options')
  if (!storageValue) {
    return defaultOptions
  }

  const storedOptions = JSON.parse(storageValue)
  return { ...defaultOptions, ...storedOptions }
}

const applyAccessibilityOptions = () => {
  document.body.classList.toggle('hide-images', accessibilityOptions.value.hideImages)
  document.body.classList.toggle('underline-link', accessibilityOptions.value.underlineLink)

  const value = (accessibilityOptions.value.fontSize * 0.1666) + 1
  document.body.style.setProperty('--desired-font-size', `${value}em`)
  document.body.style.setProperty('--font-scale', `${value}`)

  localStorage.setItem('accessibility-options', JSON.stringify(accessibilityOptions.value))
}

const close = () => {
  emitter.emit('closeAccessibilityModal')
}

const toggleHideImages = () => {
  accessibilityOptions.value.hideImages = !accessibilityOptions.value.hideImages
  applyAccessibilityOptions()
}

const toggleUnderlineLink = () => {
  accessibilityOptions.value.underlineLink = !accessibilityOptions.value.underlineLink
  applyAccessibilityOptions()
}

const setFontSize = (ev: Event) => {
  const target = ev.target as HTMLInputElement
  accessibilityOptions.value.fontSize = parseInt(target.value)
  applyAccessibilityOptions()
}

onMounted(() => {
  emitter.on('openAccessibilityModal', () => {
    show.value = true
  })

  emitter.on('closeAccessibilityModal', () => {
    show.value = false
  })

  accessibilityOptions.value = getAccessibiltyOptions()
  applyAccessibilityOptions()
})

return (_ctx: any,_cache: any) => {
  return (show.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "modal-title" }, "Accessablity", -1)),
                _createElementVNode("button", {
                  type: "button",
                  "aria-label": "Close",
                  class: "btn-close",
                  onClick: _withModifiers(close, ["prevent"])
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", null, [
                  _cache[2] || (_cache[2] = _createElementVNode("h6", { class: "fw-bold" }, "Hide images and animations", -1)),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      role: "switch",
                      id: "toggle-contrast",
                      onChange: toggleHideImages,
                      checked: accessibilityOptions.value.hideImages
                    }, null, 40, _hoisted_8),
                    _cache[1] || (_cache[1] = _createElementVNode("label", {
                      class: "form-check-label",
                      for: "toggle-contrast"
                    }, "Change mode", -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _cache[4] || (_cache[4] = _createElementVNode("h6", { class: "fw-bold" }, "Underline links", -1)),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("input", {
                      class: "form-check-input",
                      type: "checkbox",
                      role: "switch",
                      id: "toggle-underline-link",
                      onChange: toggleUnderlineLink,
                      checked: accessibilityOptions.value.underlineLink
                    }, null, 40, _hoisted_11),
                    _cache[3] || (_cache[3] = _createElementVNode("label", {
                      class: "form-check-label",
                      for: "toggle-contrast"
                    }, "Change mode", -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[5] || (_cache[5] = _createElementVNode("h6", { class: "fw-bold" }, "Font-Size", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("input", {
                      class: "form-custom-range w-100",
                      type: "range",
                      min: "-1",
                      max: "2",
                      step: "1",
                      onChange: setFontSize,
                      value: accessibilityOptions.value.fontSize
                    }, null, 40, _hoisted_13)
                  ])
                ])
              ])
            ])
          ])
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "modal-backdrop fade show" }, null, -1))
      ]))
    : _createCommentVNode("", true)
}
}

})