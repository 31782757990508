import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "row g-3 mb-5 highlights" }
const _hoisted_3 = { class: "pb-1" }
const _hoisted_4 = ["src", "title", "allow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Agenda = _resolveComponent("Agenda")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "pb-1" }, "On demand", -1)),
      _createVNode(_component_Agenda, { agendatype: "video" }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videos, (video) => {
        return (_openBlock(), _createElementBlock("section", {
          key: video.title
        }, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(video.title), 1),
          _createElementVNode("iframe", {
            class: "col-12 col-md-9 col-lg-6",
            width: "560",
            height: "315",
            src: video.src,
            title: video.title,
            frameborder: "0",
            allow: _ctx.allowedFeatures,
            referrerpolicy: "strict-origin-when-cross-origin",
            allowfullscreen: ""
          }, null, 8, _hoisted_4)
        ]))
      }), 128))
    ])
  ]))
}