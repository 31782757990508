<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { TranslatedText } from "@/store/types"
import { AppNetworkErrorOther, networkErrorText } from "@/crud"
import { JitsiVideoSettings } from "@/store/member/types"

// Props
const props = defineProps<{
  type?: string
}>()

// Store & Router
const store = useStore()
const router = useRouter()

// Reactive state
const errorstring = ref<TranslatedText>({ de: '', en: '' })
const successtring = ref<TranslatedText>({ de: '', en: '' })
const vcSettings = ref<JitsiVideoSettings>({
  output: '',
  mic: '',
  cam: ''
})

// Computed
const outputDevices = computed(() => store.getters['members/outputDevices'])
const micDevices = computed(() => store.getters['members/micDevices'])
const camDevices = computed(() => store.getters['members/camDevices'])
const originalSettings = computed(() => store.getters['members/devicesPicked'])

// Methods
const saveVCSettings = async () => {
  store.commit('members/setPickedDevices', { ...vcSettings.value })
}

const setDataOnBoarding4 = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    successtring.value = { de: '', en: '' }

    if (vcSettings.value.output !== originalSettings.value.output ||
        vcSettings.value.mic !== originalSettings.value.mic ||
        vcSettings.value.cam !== originalSettings.value.cam) {
      await saveVCSettings()
    }

    if (props.type === "step") {
      router.replace({ name: "Welcome" })
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

// Watchers
watch(originalSettings, () => {
  vcSettings.value = { ...originalSettings.value }
})

// Lifecycle hooks
onMounted(() => {
  vcSettings.value = { ...originalSettings.value }
})
</script>

<template>
  <div class="">
    <div class="col-md-12 col-xl-12">
      <p>
        Please select and save your device settings here:
      </p>

      <form @submit.prevent="setDataOnBoarding4">
        <div class="mb-3" v-if="outputDevices.length > 0">
          <label for="setting_output">Output device</label>
          <select id="setting_output" v-model="vcSettings.output" class="form-select">
            <option v-for="(d, di) in outputDevices"
                    :key="`od-${di}`"
                    :value="d.deviceId">
              {{ d.label !== '' ? d.label : `Output device ${di+1}` }}
            </option>
          </select>
        </div>

        <div class="mb-3" v-if="micDevices.length > 0">
          <label for="setting_mic">Microphone</label>
          <select id="setting_mic" v-model="vcSettings.mic" class="form-select">
            <option v-for="(d, di) in micDevices"
                    :key="`md-${di}`"
                    :value="d.deviceId">
              {{ d.label !== '' ? d.label : `Microphone ${di+1}` }}
            </option>
          </select>
        </div>

        <div class="mb-3" v-if="camDevices.length > 0">
          <label for="setting_cam">Camera</label>
          <select id="setting_cam" v-model="vcSettings.cam" class="form-select">
            <option v-for="(d, di) in camDevices"
                    :key="`cd-${di}`"
                    :value="d.deviceId">
              {{ d.label !== '' ? d.label : `Camera ${di+1}` }}
            </option>
          </select>
        </div>

        <div class="d-flex align-items-center">
          <router-link :to="{ name: 'Step2' }"
                       class="btn btn-primary m-1"
                       v-if="type === 'step' && store.state.config.me.tn_type === 8">
            Back
          </router-link>
          <router-link :to="{ name: 'Step3' }"
                       class="btn btn-primary m-1"
                       v-if="type === 'step' && store.state.config.me.tn_type !== 8">
            Back
          </router-link>
          <button class="btn btn-primary m-1" type="submit">
            <span v-if="type === 'step'">Next</span>
            <span v-else>Save</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>