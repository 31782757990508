import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "box coffeebar coffeebar-bg" }
const _hoisted_2 = { class: "row g-3 mb-5 mt-2 mb-2" }
const _hoisted_3 = { class: "col-lg-6" }
const _hoisted_4 = { class: "row dw-bg mt-2 me-2" }
const _hoisted_5 = {
  key: 0,
  id: "publicchatwrapper2",
  class: "dw-bg"
}
const _hoisted_6 = { class: "col-lg-6 coffebar-bottom" }
const _hoisted_7 = { class: "row dw-bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoChatArea = _resolveComponent("VideoChatArea")!
  const _component_Competition = _resolveComponent("Competition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "row dw-bg me-2" }, [
          _createElementVNode("h3", { class: "pb-1" }, "Coffee bar"),
          _createElementVNode("p", null, "Get together for a virtual coffee with other conference attendees who are online right now! This is an open group chat.")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("h4", null, "Room chat", -1)),
          (_ctx.room && _ctx.room.withpublicchat)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_VideoChatArea, { chattype: "chat" })
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[2] || (_cache[2] = _createElementVNode("h4", null, "Reaction Game", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("p", null, [
            _createTextVNode("Take part in our reaction game and collect points to increase your online activity score!"),
            _createElementVNode("br"),
            _createTextVNode(" The highest online activity scores win a ticket to #GMF25.")
          ], -1)),
          _cache[4] || (_cache[4] = _createElementVNode("p", null, [
            _createElementVNode("small", null, "Rules: You have 20 seconds to click on the logo as often as possible. But be careful, the logo moves faster and faster.")
          ], -1)),
          _createVNode(_component_Competition)
        ])
      ])
    ])
  ]))
}