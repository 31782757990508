<template>
  <div>
    <Banner />

    <section v-if="ImageEntry" class="box_header">
      <div class="row g-3 pt-0">
        <div class="col-md-12">
          <div class="cv m-2" v-html="replace(ImageEntry.long_summary)" />
        </div>
      </div>
    </section>

    <section class="box2 pb-0">
      <template v-if="isNetworkingAccepted">
        <div class="row g-3 pb-3">
          <div class="col-lg-3 calendar g-0">
            <p class="headline cb-headlinenormalxl mb-4">My calendar</p>
            <router-link :to="{ name: 'Calendar' }">
              <i class="fal fa-calendar-alt" />
            </router-link>
          </div>

          <div class="col-lg-9 g-0">
            <p class="headline cb-headlinenormalxl mb-4">Networking</p>
            <div class="row">
              <ContactEntry
                  v-for="(match, index) in Matches"
                  :key="`match-${index}`"
                  :member="match"
              />
            </div>

            <div class="Text float-end mb-4">
              <router-link :to="{ name: 'Networking1' }">
                See all matches <i class="fal fa-chevron-right" />
              </router-link>
            </div>
          </div>
        </div>
      </template>

      <div v-else class="row g-3">
        <p>
          Here you can see your best networking matches. Go to your profile and take part in the networking
        </p>
        <p>
          <router-link :to="{ name: 'Profile' }" class="btn btn-primary">
            Go to my profile
          </router-link>
        </p>
      </div>
    </section>

    <section class="Rectangle">
      <h2 class="headline g-3" id="highlights">Program Highlights</h2>
      <div class="row g-3">
        <div class="col-md-6 col-lg-6">
          <div
              v-for="(agendaItem, index) in orderedAgenda"
              :key="`agenda-${index}`"
              :class="['row', 'agenda_item', 'mb-4', { highlight: index === 0 }]"
          >
            <div class="col-12 white">
              <a @click="navigateAgenda(agendaItem.objvalueid)" class="pointer">
                <b>{{ title(agendaItem.title) }}</b>
                <p v-if="agendaItem.subtitle">{{ agendaItem.subtitle }}</p>
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6">
          <img
              src="/files/landingpage/highlight_xl.png"
              class="img-fluid"
              alt="Highlights"
          >
        </div>

        <div class="Text white mt-4">
          <router-link :to="{ name: 'AgendaOverview' }">
            See full program <i class="fal fa-chevron-right" />
          </router-link>
        </div>
      </div>
    </section>

    <section class="box">
      <h2 class="headline cb-headlinenormalxl g-3 mb-4">Speakers</h2>
      <div class="row g-3">
        <SpeakerEntry
            v-for="(speaker, index) in orderedSpeakers"
            :key="`speaker-${index}`"
            :member="speaker"
            type="welcome"
        />

        <div class="Text">
          <router-link :to="{ name: 'SpeakerOverview' }">
            See all speakers <i class="fal fa-chevron-right" />
          </router-link>
        </div>
      </div>
    </section>

    <section class="box2">
      <h2 class="headline cb-headlinenormalxl g-3">Activity ranking</h2>
      <div class="row g-3">
        <p>
          It's no fun without a little bit of competition: Take part and earn points for activities on this conference platform – bookmarking your program highlights, hanging out at our virtual coffee bar, or scheduling networking calls. As soon as you have successfully completed one of the challenges, the corresponding icon will appear in green at the bottom of the page. The three winners of the activity ranking will receive a free on-site ticket to the DW Global Media Forum 2025! They will be announced here and by email on June 30, 2024.
        </p>

        <h5>Leader board</h5>
        <ContactEntry
            v-for="(rankingMember, index) in Ranking"
            :key="`ranking-${index}`"
            :numberkey="index"
            :member="rankingMember"
            type="ranking"
        />

        <p
            v-if="isRestrictedEmployee"
            class="bold"
        >
          DW employees cannot participate in the activity challenge.
        </p>
      </div>
    </section>
  </div>
</template>

<script lang="ts">

import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Banner from '@/components/Banner.vue'
import ContactEntry from '@/components/ContactEntry.vue'
import SpeakerEntry from '@/components/SpeakerEntry.vue'
import { useGtm } from '@/composables/useGtm'

interface IMember {
  lastname: string
  tn_type: number
  ranking_i: number
  ranking_j: number
}

interface IAgendaEntry {
  date: number
  title: string
  subtitle?: string
  objvalueid: number
}

interface IImageEntry {
  long_summary: string
}

interface State {
  i: {
    me: {
      accept_networking: boolean
      tn_type: number
    }
    welcome_text: IImageEntry | null
    matches: IMember[]
    agenda: IAgendaEntry[]
    speakers: IMember[]
    ranking: IMember[]
  }
}

export default defineComponent({
  name: 'Welcome',

  components: {
    Banner,
    ContactEntry,
    SpeakerEntry
  },

  setup() {
    const { trackPage } = useGtm()

    const store = useStore<State>()
    const router = useRouter()

    const isNetworkingAccepted = computed(() =>
        store.state.i.me.accept_networking
    )

    const isRestrictedEmployee = computed(() => {
      const restrictedTypes = [26, 33, 37]
      return restrictedTypes.includes(store.state.i.me.tn_type)
    })

    const ImageEntry = computed<IImageEntry | null>(() =>
        store.state.i.welcome_text || null
    )

    const Matches = computed<IMember[]>(() =>
        store.state.i.matches?.slice(0, 6) || []
    )

    const orderedAgenda = computed<IAgendaEntry[]>(() => {
      const agenda = store.state.i.agenda || []
      return [...agenda].sort((a, b) => a.date - b.date)
    })

    const orderedSpeakers = computed<IMember[]>(() => {
      const speakers = store.state.i.speakers || []
      return [...speakers]
          .sort((a, b) => a.lastname.localeCompare(b.lastname))
          .slice(0, 6)
    })

    const Ranking = computed<IMember[]>(() =>
        store.state.i.ranking?.slice(0, 3) || []
    )

    const replace = (text: string): string => {
      if (!text) return ''
      return text.replace(/\[br\]/g, '<br/>')
    }

    const title = (text: string): string => {
      if (!text) return ''
      return text.replace(/\[br\]/g, ' ')
    }

    const navigateAgenda = (id: number): void => {
      router.push({
        name: 'AgendaDetail',
        params: { id: id.toString() }
      })
    }

    trackPage({
      pageCategory: 'Welcome',
      PageName: 'Overview',
      PageType: 'Webapp',
    })

    return {
      isNetworkingAccepted,
      isRestrictedEmployee,
      ImageEntry,
      Matches,
      orderedAgenda,
      orderedSpeakers,
      Ranking,
      replace,
      title,
      navigateAgenda
    }
  }
})
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.calendar {
  text-align: center;

  i {
    font-size: 5rem;
    color: var(--primary);
  }
}

.Rectangle {
  background-color: var(--primary);
  color: white;
  padding: 2rem;
  margin: 2rem 0;
}

.highlight {
  background-color: var(--highlight);
  padding: 1rem;
  border-radius: 0.25rem;
}
</style>