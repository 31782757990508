import { computed } from 'vue';
import { useStore } from 'vuex';
import { TranslatedText } from '@/store/types';

export function useI18n() {
    const store = useStore();

    const i18n = (text: TranslatedText): string => {
        const lang = store.state.language || 'de';
        return text[lang] || text.de || '';
    };

    return {
        i18n
    };
}