import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, unref as _unref, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = {
  key: 1,
  class: "row g-3"
}
const _hoisted_3 = { class: "col-md-5 col-lg-3 mb-3 sm-center" }
const _hoisted_4 = { class: "circular_image_large" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "col-md-7 col-lg-9 mb-3 text-left ipadfx" }
const _hoisted_7 = { class: "pb-3 pt-0" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = ["href"]
const _hoisted_12 = { key: 3 }
const _hoisted_13 = {
  key: 4,
  class: "mb-4"
}
const _hoisted_14 = { class: "row" }
const _hoisted_15 = {
  key: 0,
  class: "col-12 col-md-3"
}
const _hoisted_16 = { class: "Role copydetailxs" }
const _hoisted_17 = ["href"]
const _hoisted_18 = ["href"]
const _hoisted_19 = ["href"]
const _hoisted_20 = {
  key: 1,
  class: "Role copydetailxs col-12 col-md-3"
}
const _hoisted_21 = {
  key: 2,
  class: "Role copydetailxs col-12 col-md-6"
}
const _hoisted_22 = {
  key: 1,
  class: "btn btn-outline-primary m-1"
}
const _hoisted_23 = {
  key: 2,
  class: "btn btn-outline-primary m-1"
}
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { class: "mb-2" }
const _hoisted_26 = { class: "badge badge-pill" }
const _hoisted_27 = { class: "mb-2" }
const _hoisted_28 = ["title"]
const _hoisted_29 = { class: "mb-2" }
const _hoisted_30 = ["title"]
const _hoisted_31 = { class: "mb-2" }
const _hoisted_32 = ["title"]
const _hoisted_33 = { key: 0 }
const _hoisted_34 = { key: 1 }
const _hoisted_35 = ["innerHTML"]

import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute, onBeforeRouteLeave} from 'vue-router';
import { useStore } from 'vuex';
import { useGtm } from '@/composables/useGtm'
import _ from 'lodash';
import JitsiCall from "@/components/jitsi/JitsiCall.vue";
import MeetingModal from "@/components/i/MeetingModal.vue";
import type { AgendaPoint, ConnectionNetworking, ContactsNetworking } from "@/store/i/types";
import { AppNetworkErrorOther, networkErrorText } from "@/crud";
import type { TranslatedText } from "@/store/types";

// Hooks initialisieren

export default /*@__PURE__*/_defineComponent({
  __name: 'ContactDetail',
  setup(__props, { expose: __expose }) {

const route = useRoute();
const store = useStore();
const gtm = useGtm();

// Reactive Refs
const errorstring = ref<TranslatedText>({ de: '', en: '' });
const successtring = ref<TranslatedText>({ de: '', en: '' });
const connection = ref(0);
const toggle_modal = ref(false);

// Computed Properties
const contactEntry = computed(() => {
  return _.find(
      store.state.i.contacts_networking,
      (e: ContactsNetworking) => `${e.objvalueid}` === `${route.params.contactid}`
  );
});

const connectionEntry1 = computed(() => {
  return _.find(
      store.state.i.connections_networking,
      (e: ConnectionNetworking) =>
          (`${e.sender}` === `${route.params.contactid}` ||
              `${e.receiver}` === `${route.params.contactid}`) &&
          e.dbstate != -1
  );
});

const connectionEntry2 = computed(() => {
  return _.find(
      store.state.i.connections_networking,
      (e: ConnectionNetworking) =>
          `${e.receiver}` === `${route.params.contactid}` &&
          e.status == 1 &&
          e.dbstate != -1
  );
});

const connectionEntry3 = computed(() => {
  return _.find(
      store.state.i.connections_networking,
      (e: ConnectionNetworking) =>
          `${e.sender}` === `${route.params.contactid}` &&
          e.status == 1 &&
          e.dbstate != -1
  );
});

const connectionEntry4 = computed(() => {
  return _.find(
      store.state.i.connections_networking,
      (e: ConnectionNetworking) =>
          (`${e.sender}` === `${route.params.contactid}` ||
              `${e.receiver}` === `${route.params.contactid}`) &&
          e.status == 2 &&
          e.dbstate != -1
  );
});

const countrylist = computed(() => store.state.i.me.countrylist);
const networkinglist = computed(() => store.state.i.me.networkinglist);
const networking_question1 = computed(() => contactEntry.value?.networking_question1 || 0);
const networking_question2 = computed(() => contactEntry.value?.networking_question2 || 0);
const fav_agenda_items = computed(() => contactEntry.value?.fav_agenda_items || 0);
const currentCall = computed(() => store.state.members.currentCall);

const agendaEntry = computed(() => {
  return _.filter(
      store.getters['i/orderedAgendaPoints'],
      (ap: AgendaPoint) => ap.dbstate > -1
  );
});

// Methoden
const title = (title: string): string => {
  let processed = _.replace(title, /&#34;/g, '"');
  processed = _.replace(processed, /&#39;/g, "'");
  return processed;
};

const agendaEntryTitle = (ei: string): string => {
  const entry = _.find(
      agendaEntry.value,
      (e: AgendaPoint) => `${e.objvalueid}` === ei
  );
  return entry ? entry.title : '';
};

const is_selected1 = (ei: string): boolean => {
  return _.includes(_.keys(store.state.i.me.networking_question2), ei);
};

const is_selected2 = (ei: string): boolean => {
  return _.includes(_.keys(store.state.i.me.networking_question1), ei);
};

const is_selected3 = (ei: string): boolean => {
  return _.includes(_.keys(store.state.i.me.fav_agenda_items), ei);
};

const exportvCard = (objvalueid: number): void => {
  window.location.href = `/projects/dw/gmf/sources/scripts/portal.php?&action=ExportvCard&objvalueid=${objvalueid}`;
};

const reset_message = (): void => {
  errorstring.value = { de: '', en: '' };
  successtring.value = { de: '', en: '' };
};

const createConnection = async (c: ContactsNetworking): Promise<void> => {
  try {
    errorstring.value = { de: '', en: '' };
    successtring.value = { de: '', en: '' };
    const dpr = await store.dispatch("i/createConnection", c);
    setTimeout(reset_message, 2000);

    if (dpr) {
      successtring.value = {
        de: "Kontaktanfrage wurde gesendet",
        en: "Contact request sent"
      };
    } else {
      errorstring.value = {
        de: "Kontaktanfrage konnte nicht erstellt werden",
        en: "Contact request could not be created"
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

const pickuserchat = (cp: ContactsNetworking): void => {
  const uc = _.find(
      store.state.i.contacts_networking,
      (c) => c.fbuid === cp.fbuid
  );
  if (uc) {
    store.commit('OpenSideNav', 'chat');
    store.commit('i/setChatboxContact', uc);
  }
};

const pickusercall = async (cp: ContactsNetworking): Promise<void> => {
  if (cp.fbuid !== store.state.config.fbuid) {
    if (currentCall.value) {
      store.commit("i/addToast", {
        header: { de: "Fehler", en: "Error" },
        msg: {
          de: 'Sie sind bereits im Gespräch.',
          en: 'You are already in a call.'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        showfor: 10000,
        alert_type: 'error'
      });
    } else {
      const c = {
        fbuid: cp.fbuid,
        objvalueid: cp.objvalueid,
        firstname: cp.firstname,
        lastname: cp.lastname,
        orgname: cp.orgname,
      };

      store.commit("members/setContact", c);
      await store.dispatch("members/startCall", cp.objvalueid);

      store.commit("i/addToast", {
        header: { de: "Hinweis", en: "Warning" },
        msg: {
          de: 'Bitte haben Sie einen Moment Geduld, bis Ihr Ansprechpartner Ihren Anruf entgegen nimmt. Wenn Sie das Gespräch beenden, ohne dass Ihr Ansprechpartner den Anruf entgegen genommen hat, wird dieser per Chat-Nachricht über Ihren Kontaktversuch informiert.',
          en: 'Please be patient until your contact accepts your call. If you end the conversation before your contact has accepted the call, they will be informed about your contact attempt via chat message.',
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        showfor: 10000,
        alert_type: 'error'
      });
    }
  }
};

const meeting = (cp: ContactsNetworking): void => {
  toggle_modal.value = !toggle_modal.value;
  connection.value = cp.objvalueid;
};

// Lifecycle Hooks
onMounted(() => {
  const { trackPage } = useGtm()


  trackPage({
    pageCategory: 'Contact',
    PageName: 'Detailpage',
    PageType: 'Webapp',
  });
});

onBeforeRouteLeave((to, from, next) => {
  if (store.state.members.currentCall &&
      !store.getters['i/forcedToPlenum'] &&
      !store.getters['i/redirectToPlenum']) {
    const confirmres = window.confirm(store.getters.i18n({
      de: "If you are leaving the booth now, your call will be ended.",
      en: "If you are leaving the booth now, your call will be ended."
    }));
    confirmres ? next() : next(false);
  } else {
    next();
  }
});

// Expose für Template
__expose({
  contactEntry,
  connectionEntry1,
  connectionEntry2,
  connectionEntry3,
  connectionEntry4,
  countrylist,
  networkinglist,
  currentCall,
  connection,
  toggle_modal,
  title,
  agendaEntryTitle,
  is_selected1,
  is_selected2,
  is_selected3,
  exportvCard,
  createConnection,
  pickuserchat,
  pickusercall,
  meeting,
  errorstring,
  successtring,
  networking_question1,
  networking_question2,
  fav_agenda_items
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MeetingModal, {
      connection: connection.value,
      toggle_modal: toggle_modal.value
    }, null, 8, ["connection", "toggle_modal"]),
    (currentCall.value)
      ? (_openBlock(), _createBlock(JitsiCall, {
          key: 0,
          type: "member"
        }))
      : _createCommentVNode("", true),
    (contactEntry.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: `/files/members/${contactEntry.value.objvalueid}/foto.jpg`,
                width: "250",
                onerror: "this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';",
                alt: ""
              }, null, 8, _hoisted_5)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h3", _hoisted_7, _toDisplayString(contactEntry.value.firstname) + " " + _toDisplayString(contactEntry.value.lastname), 1),
            (contactEntry.value.position)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, "Position: " + _toDisplayString(title(contactEntry.value.position)), 1))
              : _createCommentVNode("", true),
            (contactEntry.value.orgname)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, "Organization: " + _toDisplayString(title(contactEntry.value.orgname)), 1))
              : _createCommentVNode("", true),
            (contactEntry.value.show_email && contactEntry.value.email)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _cache[5] || (_cache[5] = _createTextVNode("E-Mail adress: ")),
                  _createElementVNode("a", {
                    href: `mailto:${contactEntry.value.email}`
                  }, _toDisplayString(contactEntry.value.email), 9, _hoisted_11)
                ]))
              : _createCommentVNode("", true),
            (contactEntry.value.phone)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, "Phone: " + _toDisplayString(contactEntry.value.phone), 1))
              : _createCommentVNode("", true),
            (contactEntry.value.country)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, "Country: " + _toDisplayString(countrylist.value[contactEntry.value.country]), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_14, [
              (contactEntry.value.linkedin || contactEntry.value.twitter || contactEntry.value.facebook)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("span", _hoisted_16, [
                      _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "headline_light text-center" }, "Follow me", -1)),
                      _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
                      (contactEntry.value.twitter)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            href: contactEntry.value.twitter,
                            class: "buttonshare",
                            target: "_blank"
                          }, _cache[6] || (_cache[6] = [
                            _createElementVNode("i", { class: "fab fa-2x fa-x-twitter" }, null, -1),
                            _createElementVNode("span", { class: "sr-only" }, "X (Twitter)", -1)
                          ]), 8, _hoisted_17))
                        : _createCommentVNode("", true),
                      (contactEntry.value.linkedin)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: contactEntry.value.linkedin,
                            class: "buttonshare",
                            target: "_blank"
                          }, _cache[7] || (_cache[7] = [
                            _createElementVNode("i", { class: "fab fa-2x fa-linkedin-in" }, null, -1),
                            _createElementVNode("span", { class: "sr-only" }, "LinkedIn", -1)
                          ]), 8, _hoisted_18))
                        : _createCommentVNode("", true),
                      (contactEntry.value.facebook)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 2,
                            href: contactEntry.value.facebook,
                            class: "buttonshare",
                            target: "_blank"
                          }, _cache[8] || (_cache[8] = [
                            _createElementVNode("i", { class: "fab fa-2x fa-facebook-f" }, null, -1),
                            _createElementVNode("span", { class: "sr-only" }, "Facebook", -1)
                          ]), 8, _hoisted_19))
                        : _createCommentVNode("", true)
                    ]),
                    _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1))
                  ]))
                : _createCommentVNode("", true),
              (contactEntry.value.show_vcard)
                ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                    _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                    _cache[15] || (_cache[15] = _createElementVNode("span", { class: "headline_light text-center" }, "VCARD DOWNLOAD", -1)),
                    _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("a", {
                      class: "buttonshare",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (exportvCard(contactEntry.value.objvalueid)))
                    }, _cache[13] || (_cache[13] = [
                      _createElementVNode("i", { class: "fal fa-address-card" }, null, -1)
                    ]))
                  ]))
                : _createCommentVNode("", true),
              (contactEntry.value.accept_networking && contactEntry.value.objvalueid != _unref(store).state.config.me.objvalueid)
                ? (_openBlock(), _createElementBlock("span", _hoisted_21, [
                    _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
                    _cache[24] || (_cache[24] = _createElementVNode("span", { class: "headline_light text-center" }, "Networking", -1)),
                    _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1)),
                    (!connectionEntry1.value)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-primary m-1",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (createConnection(contactEntry.value)), ["prevent"]))
                        }, _cache[17] || (_cache[17] = [
                          _createElementVNode("span", { class: "smartphone" }, [
                            _createElementVNode("i", { class: "fal fa-user-plus" })
                          ], -1),
                          _createElementVNode("span", { class: "nsmartphone" }, "Send contact request", -1)
                        ])))
                      : _createCommentVNode("", true),
                    (connectionEntry2.value)
                      ? (_openBlock(), _createElementBlock("button", _hoisted_22, _cache[18] || (_cache[18] = [
                          _createElementVNode("span", { class: "smartphone" }, [
                            _createElementVNode("i", { class: "fal fa-user-plus" })
                          ], -1),
                          _createElementVNode("span", { class: "nsmartphone" }, "Contact request sent", -1)
                        ])))
                      : _createCommentVNode("", true),
                    (connectionEntry3.value)
                      ? (_openBlock(), _createElementBlock("button", _hoisted_23, _cache[19] || (_cache[19] = [
                          _createElementVNode("span", { class: "smartphone" }, [
                            _createElementVNode("i", { class: "fal fa-user-plus" })
                          ], -1),
                          _createElementVNode("span", { class: "nsmartphone" }, "Contact request received", -1)
                        ])))
                      : _createCommentVNode("", true),
                    (connectionEntry4.value)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 3,
                          class: "btn btn-primary m-1",
                          onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (pickuserchat(contactEntry.value)), ["prevent"]))
                        }, _cache[20] || (_cache[20] = [
                          _createElementVNode("span", { class: "smartphone" }, [
                            _createElementVNode("i", { class: "fal fa-comment" })
                          ], -1),
                          _createElementVNode("span", { class: "nsmartphone" }, "Chat", -1)
                        ])))
                      : _createCommentVNode("", true),
                    (connectionEntry4.value)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 4,
                          class: "btn btn-primary m-1",
                          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (pickusercall(contactEntry.value)), ["prevent"]))
                        }, _cache[21] || (_cache[21] = [
                          _createElementVNode("span", { class: "smartphone" }, [
                            _createElementVNode("i", { class: "fal fa-video" })
                          ], -1),
                          _createElementVNode("span", { class: "nsmartphone" }, "Call", -1)
                        ])))
                      : _createCommentVNode("", true),
                    (connectionEntry4.value)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 5,
                          class: "btn btn-primary m-1",
                          onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (meeting(contactEntry.value)), ["prevent"]))
                        }, _cache[22] || (_cache[22] = [
                          _createElementVNode("span", { class: "smartphone" }, [
                            _createElementVNode("i", { class: "fal fa-calendar-edit" })
                          ], -1),
                          _createElementVNode("span", { class: "nsmartphone" }, "Appointment request", -1)
                        ])))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _cache[26] || (_cache[26] = _createElementVNode("br", null, null, -1))
            ])
          ]),
          _cache[37] || (_cache[37] = _createElementVNode("hr", null, null, -1)),
          (contactEntry.value.objvalueid != _unref(store).state.config.me.objvalueid)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _cache[33] || (_cache[33] = _createElementVNode("h4", { class: "bold mb-3" }, "Matching result", -1)),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("span", _hoisted_26, _toDisplayString(contactEntry.value.match) + " %", 1)
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _cache[27] || (_cache[27] = _createElementVNode("b", null, "My Expertise", -1)),
                  _cache[28] || (_cache[28] = _createElementVNode("br", null, null, -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(networking_question1.value, (e, ei) => {
                    return (_openBlock(), _createElementBlock("span", null, [
                      _createElementVNode("span", {
                        title: is_selected1(String(ei)) ? 'Match' : 'No match',
                        class: _normalizeClass({'badge badge-no-match': true, 'badge-light' : is_selected1(String(ei))})
                      }, _toDisplayString(networkinglist.value[ei]), 11, _hoisted_28)
                    ]))
                  }), 256))
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _cache[29] || (_cache[29] = _createElementVNode("b", null, "My interests", -1)),
                  _cache[30] || (_cache[30] = _createElementVNode("br", null, null, -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(networking_question2.value, (e, ei) => {
                    return (_openBlock(), _createElementBlock("span", null, [
                      _createElementVNode("span", {
                        title: is_selected2(String(ei)) ? 'Match' : 'No match',
                        class: _normalizeClass({'badge badge-no-match': true, 'badge-light' : is_selected2(String(ei))})
                      }, _toDisplayString(networkinglist.value[ei]), 11, _hoisted_30)
                    ]))
                  }), 256))
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _cache[31] || (_cache[31] = _createElementVNode("b", null, "My Calendar", -1)),
                  _cache[32] || (_cache[32] = _createElementVNode("br", null, null, -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fav_agenda_items.value, (e, ei) => {
                    return (_openBlock(), _createElementBlock("span", null, [
                      _createElementVNode("span", {
                        title: is_selected3(String(ei)) ? 'Match' : 'No match',
                        class: _normalizeClass({'badge badge-no-match': true, 'badge-light' : is_selected3(String(ei))})
                      }, _toDisplayString(title(agendaEntryTitle(String(ei)))), 11, _hoisted_32)
                    ]))
                  }), 256)),
                  (fav_agenda_items.value.length === 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_33, "This participant did not bookmark any program items."))
                    : _createCommentVNode("", true)
                ]),
                _cache[34] || (_cache[34] = _createElementVNode("hr", null, null, -1))
              ]))
            : _createCommentVNode("", true),
          (contactEntry.value.cv)
            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                _cache[35] || (_cache[35] = _createElementVNode("h4", { class: "bold mb-3" }, "Resume", -1)),
                _createElementVNode("div", {
                  class: "cv",
                  innerHTML: contactEntry.value.cv
                }, null, 8, _hoisted_35),
                _cache[36] || (_cache[36] = _createElementVNode("hr", null, null, -1))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})