import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "row g-3 mb-5" }
const _hoisted_3 = { class: "col-md-12 col-xl-12" }
const _hoisted_4 = {
  key: 0,
  class: "badge badge-danger float-right ms-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Help", -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("p", null, " Welcome to the Global Media Forum 2025! ", -1)),
        _cache[3] || (_cache[3] = _createElementVNode("p", null, " You can access all relevant conference areas via the start page – find the live program, networking options and online workshops right there. Using the\"speech bubble\" at the upper right-hand corner next to your name, you can follow up with your individual chats any time. Feel free to have a look around! ", -1)),
        _cache[4] || (_cache[4] = _createElementVNode("p", null, " If you have any technical questions or problems, please feel free to contact our support chat. ", -1)),
        _cache[5] || (_cache[5] = _createElementVNode("p", null, " The Support chat will be available from 8 a.m. to 6 p.m. on both June 17 and 18. If you have questions beforehand, a response may take up to 48 hours. ", -1)),
        _cache[6] || (_cache[6] = _createElementVNode("p", null, " We look forward to a successful digital event with you! ", -1)),
        _createElementVNode("p", null, [
          (_ctx.isSupported)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                id: "chatindicator",
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleChatbox && _ctx.toggleChatbox(...args)), ["prevent"])),
                class: "btn btn-primary me-3"
              }, [
                _cache[1] || (_cache[1] = _createTextVNode(" Support Chat ")),
                (_ctx.unread > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.unread), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}