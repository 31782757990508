<template>
  <div class="">

    <div class="col-md-12 col-xl-12">

      <form @submit.prevent="setDataOnBoarding1">

        <div v-show="successtring.de !== ''" class="alert alert-success col-lg-12">
          {{ store.getters.i18n(successtring) }}
        </div>

        <div v-show="errorstring.de !== ''" class="alert alert-danger mb-5">
          {{ store.getters.i18n(errorstring) }}
        </div>

        <p>
          Welcome to the 2024 DW Global Media Forum conference platform. It is good to have you with us this #GMF24!
        </p>
        <p>
          Complete your networking profile to connect with media professionals from around the globe in just a couple of minutes.
        </p>
        <p>
          Once you have created your profile, you can use the matchmaking function to add new contacts based on the areas of interest you specify in your profile. You will automatically be shown contacts that match your own interests. You can also connect directly with other participants using the chat function.
        </p>
        <p>
          This networking tool is optional. If you do not want to participate in our networking features, simply do not check the box at the bottom of this page.
        </p>
        <p class="pb-5">
          By the way: You can add or change your details in your profile at any time.
        </p>

        <div class="d-flex align-items-center mb-4">
          <div class="me-auto form-check">
            <input class="form-check-input" v-model="store.state.i.me.accept_networking" type="checkbox" name="accept_networking" id="accept_networking">
            <label class="form-check-label" for="accept_networking">
              Yes, I want to network.
            </label>
          </div>
        </div>

        <div v-if="store.state.i.me.accept_networking">

          <div class="mb-4">
            <label class="form-check-label"><b>My expertise (max.5)</b></label>
            <div class="form-check" v-for="(e, ei) in store.state.i.me.networkinglist"  >
              <input class="form-check-input" type="checkbox" v-model="networking_question1" :value=ei :disabled="disabled1(ei)">
              <label class="form-check-label">{{e}}</label>
            </div>
          </div>

          <div class="mb-4">
            <label class="form-check-label"><b>My interests (max.5)</b></label>
            <div class="form-check" v-for="(e, ei) in store.state.i.me.networkinglist"  >
              <input class="form-check-input" type="checkbox" v-model="networking_question2" :value=ei :disabled="disabled2(ei)">
              <label class="form-check-label">{{e}}</label>
            </div>
          </div>

          <div class="mb-4">
            <label class="form-label" >My most-used social network</label>
            <input type="text" v-model="store.state.i.me.networking_question3" class="form-control" />
          </div>

        </div>


        <div class="d-flex align-items-center">
          <button class="btn btn-primary m-1" type="submit">
            <span v-if="type === 'step'">Next</span>
            <span v-else>Save</span>
          </button>
        </div>


      </form>

    </div>

  </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { TranslatedText } from "@/store/types"
import { AppNetworkErrorOther, AppNetworkErrorNotAllowed, AppNetworkErrorServer, AppNetworkErrorTokenNotSet, AppNetworkErrorNotFound } from "@/crud"
import _ from 'lodash'

// Props
const props = defineProps<{
  type?: string
}>()

// Store
const store = useStore()

// Reactive state
const errorstring = ref<TranslatedText>({ de: '', en: '' })
const successtring = ref<TranslatedText>({ de: '', en: '' })
const networking_question1 = ref<number[]>([])
const networking_question2 = ref<number[]>([])


const getNetworkErrorText = (e: AppNetworkErrorOther | AppNetworkErrorNotAllowed | AppNetworkErrorServer | AppNetworkErrorTokenNotSet | AppNetworkErrorNotFound): TranslatedText => {
  return {
    de: 'Ein Netzwerkfehler ist aufgetreten',
    en: 'A network error occurred'
  }
}


// Computed
const disabled1 = computed(() => {
  return (ei: number) => {
    return networking_question1.value.length >= 5 && !networking_question1.value.includes(ei)

  }
})

const disabled2 = computed(() => {
  return (ei: number) => {
    return networking_question2.value.length >= 5 && !networking_question2.value.includes(ei)
  }
})

// Methods
const setDataOnBoarding1 = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    successtring.value = { de: '', en: '' }

    const networking1: { [key: number]: number } = {}
    const networking2: { [key: number]: number } = {}

    networking_question1.value.forEach((value, index) => {
      networking1[index] = value
    })

    networking_question2.value.forEach((value, index) => {
      networking2[index] = value
    })

    await store.dispatch('i/setNetworking', {
      networking_question1: networking1,
      networking_question2: networking2
    })

    successtring.value = {
      de: 'Ihre Daten wurden erfolgreich gespeichert',
      en: 'Your data has been saved successfully'
    }

    if (props.type === 'step') {
      await store.dispatch('i/setOnBoardingStep', 2)
    }

  } catch (e) {
    if (e instanceof AppNetworkErrorOther) {
      errorstring.value = getNetworkErrorText(e)
    } else {
      errorstring.value = {
        de: 'Ein unbekannter Fehler ist aufgetreten',
        en: 'An unknown error occurred'
      }
    }
    console.error(e)
  }
}
</script>
