<template>
  <div class="my-custom-scrollbar2" ref="scrollContainer">
    <div class="card-body p-3">
      <div class="chat-message">
        <ChatContactMessageSmall
            v-for="(msg, index) in convo"
            :key="'smallmsg' + index"
            :m="msg"
        />
      </div>
    </div>
    <transition name="popin">
      <div class="write-indecator" v-show="otherpersonwriting">
        {{ displayName }}
        {{ store.getters.i18n({de:'schreibt',en:'is writing'}) }}
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import ChatContactMessageSmall from "@/components/i/ChatContactMessageSmall.vue"
import type { DMMessage, ChatContact, SupportMessage } from "@/store/i/types"

const store = useStore()
const scrollContainer = ref<HTMLElement | null>(null)
const otherpersonwriting = ref(false)
const correctScroll = ref(true)

const displayName = computed(() => {
  if (contact.value && typeof contact.value === 'object' && 'firstname' in contact.value) {
    return contact.value.firstname
  }
  return store.getters.i18n({de:'Support',en:'Support'})
})

const contact = computed((): false | ChatContact | "support" => {
  if (store.state.i.showsmallchat === true) {
    return false
  }
  return store.state.i.showsmallchat
})

const convo = computed((): (DMMessage | SupportMessage)[] => {
  if (contact.value) {
    if (contact.value === "support") {
      return store.getters['i/supportchats']
    }
    return store.getters['i/dmsbyuser'](contact.value.fbuid)
  }
  return []
})

const scrollBottom = async () => {
  if (correctScroll.value && scrollContainer.value) {
    scrollContainer.value.removeEventListener('scroll', lookIfToHaltAutoscroll)
    await nextTick()
    scrollContainer.value.scrollTo(0, scrollContainer.value.scrollHeight)
    await nextTick()
    scrollContainer.value.addEventListener('scroll', lookIfToHaltAutoscroll, { passive: true })
  }
}

const lookIfToHaltAutoscroll = () => {
  if (scrollContainer.value) {
    const pos = scrollContainer.value.scrollTop
    const desiredPos = scrollContainer.value.scrollHeight - scrollContainer.value.clientHeight
    correctScroll.value = pos >= desiredPos - 20
  }
}

// Watchers
watch([() => otherpersonwriting.value, convo], () => {
  void scrollBottom()
})

// Lifecycle hooks
onMounted(() => {
  void scrollBottom()
  window.addEventListener('resize', scrollBottom, { passive: true })
  if (scrollContainer.value) {
    scrollContainer.value.addEventListener('scroll', lookIfToHaltAutoscroll, { passive: true })
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', scrollBottom)
  if (scrollContainer.value) {
    scrollContainer.value.removeEventListener('scroll', lookIfToHaltAutoscroll)
  }
})
</script>

<style scoped lang="scss">
.my-custom-scrollbar2 {
  overflow-y: scroll;
  height: calc(100vh - 380px);
  margin-bottom: 1rem;
}

.write-indecator {
  color: #000;
}

.popin-enter-active,
.popin-leave-active {
  transition: opacity .5s;
  opacity: 1;
}

.popin-enter,
.popin-leave-to {
  opacity: 0;
}
</style>