import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row mb-4"
}
const _hoisted_2 = { class: "circular_image circular_image_small" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "Name-Nachname mb-1 ipad-2" }
const _hoisted_5 = { class: "Role copydetailxs mb-2 ipad-2 bold" }
const _hoisted_6 = { class: "col-md-7 col-12" }
const _hoisted_7 = { class: "smartphone" }
const _hoisted_8 = { class: "smartphone" }
const _hoisted_9 = { class: "smartphone" }
const _hoisted_10 = { class: "smartphone" }
const _hoisted_11 = { class: "smartphone" }
const _hoisted_12 = { class: "smartphone" }
const _hoisted_13 = { class: "smartphone" }
const _hoisted_14 = { key: 7 }

import { computed } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'
import { DateTime } from 'luxon'
import type { ConnectionNetworking, ContactsNetworking } from '@/store/i/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'NetworkingUserConnection',
  props: {
    user: {},
    contact_type: {}
  },
  emits: ["chat", "call", "navigate", "meeting", "remove", "accept", "decline", "block"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const store = useStore()

const title = (title: string): string => {
  title = _.replace(title, /&#34;/g, '"')
  title = _.replace(title, /&#39;/g, "'")
  return title
}

const chat = () => emit('chat', props.user)
const call = () => emit('call', props.user)
const navigate = () => emit('navigate', props.user)
const meeting = () => emit('meeting', props.user)
const remove = () => emit('remove', props.user)
const accept = () => emit('accept', props.user)
const decline = () => emit('decline', props.user)
const block = () => emit('block', props.user)

const contactEntry = computed(() => {
  if (props.user) {
    if (props.user.receiver === store.state.config.me.objvalueid) {
      return _.find(
          store.state.i.contacts_networking,
          (e: ContactsNetworking) =>
              `${e.objvalueid}` === `${props.user.sender}` && e.accept_networking
      )
    } else {
      return _.find(
          store.state.i.contacts_networking,
          (e: ContactsNetworking) =>
              `${e.objvalueid}` === `${props.user.receiver}` && e.accept_networking
      )
    }
  }
})

const visibleContact = computed(() => !!contactEntry.value)

const formatedDate = computed(() => {
  return DateTime.fromISO(props.user.request_datetime, {locale: 'en-us'}).monthLong +
      " " +
      DateTime.fromISO(props.user.request_datetime).toFormat('d, h:mm a')
})

const userimage = computed(() => {
  if (props.user) {
    if (props.user.receiver === store.state.config.me.objvalueid) {
      return '/files/members/' + props.user.sender + '/foto.jpg'
    } else {
      return '/files/members/' + props.user.receiver + '/foto.jpg'
    }
  }
  return '/projects/dw/gmf/assets/images/placeholder.jpg'
})

return (_ctx: any,_cache: any) => {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (visibleContact.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "pointer col-md-1 col-4 mb-2",
          onClick: _withModifiers(navigate, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("img", {
              src: userimage.value,
              onerror: "this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';",
              alt: ""
            }, null, 8, _hoisted_3)
          ])
        ]),
        _createElementVNode("div", {
          class: "pointer col-md-4 col-8 mb-2",
          onClick: _withModifiers(navigate, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(props.user.firstname) + " " + _toDisplayString(props.user.lastname), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(title(props.user.orgname)), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (props.contact_type === 'contact')
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-primary m-1",
                style: {"float":"right"},
                onClick: _withModifiers(remove, ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_7, [
                  _createVNode(_component_FontAwesomeIcon, { icon: "user-xmark" })
                ]),
                _cache[0] || (_cache[0] = _createElementVNode("span", { class: "nsmartphone" }, "Remove contact", -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.contact_type == 'contact')
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn-primary m-1",
                style: {"float":"right"},
                onClick: _withModifiers(meeting, ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_8, [
                  _createVNode(_component_FontAwesomeIcon, { icon: "calendar-pen" })
                ]),
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "nsmartphone" }, "Appointment request", -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.contact_type == 'contact')
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                class: "btn btn-primary m-1",
                style: {"float":"right"},
                onClick: _withModifiers(call, ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_9, [
                  _createVNode(_component_FontAwesomeIcon, { icon: "video" })
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "nsmartphone" }, "Call", -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.contact_type == 'contact')
            ? (_openBlock(), _createElementBlock("button", {
                key: 3,
                class: "btn btn-primary m-1",
                style: {"float":"right"},
                onClick: _withModifiers(chat, ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_10, [
                  _createVNode(_component_FontAwesomeIcon, { icon: "message" })
                ]),
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "nsmartphone" }, "Chat", -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.contact_type == 'received')
            ? (_openBlock(), _createElementBlock("button", {
                key: 4,
                class: "btn btn-primary m-1",
                style: {"float":"right"},
                onClick: _withModifiers(block, ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_11, [
                  _createVNode(_component_FontAwesomeIcon, { icon: "ban" })
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "nsmartphone" }, "Block", -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.contact_type == 'received')
            ? (_openBlock(), _createElementBlock("button", {
                key: 5,
                class: "btn btn-primary m-1",
                style: {"float":"right"},
                onClick: _withModifiers(decline, ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_12, [
                  _createVNode(_component_FontAwesomeIcon, { icon: "user-xmark" })
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "nsmartphone" }, "Reject", -1))
              ]))
            : _createCommentVNode("", true),
          (_ctx.contact_type == 'received')
            ? (_openBlock(), _createElementBlock("button", {
                key: 6,
                class: "btn btn-primary m-1",
                style: {"float":"right"},
                onClick: _withModifiers(accept, ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_13, [
                  _createVNode(_component_FontAwesomeIcon, { icon: "user-check" })
                ]),
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "nsmartphone" }, "Accept", -1))
              ]))
            : _createCommentVNode("", true),
          (props.contact_type === 'received' || props.contact_type === 'send')
            ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(formatedDate.value) + " CEST", 1))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})