<script setup lang="ts">
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { TranslatedText } from "@/store/types"
import { AppNetworkErrorOther, networkErrorText } from "@/crud"
import rankingsMaxPoints from '@/ranking'

// Props
const props = defineProps<{
  type?: string
}>()

// Store & Router
const store = useStore()
const router = useRouter()

// Reactive state
const errorstring = ref<TranslatedText>({ de: '', en: '' })
const successtring = ref<TranslatedText>({ de: '', en: '' })
const file = ref<File | null>(null)

// Methods
const reset_message = () => {
  errorstring.value = { de: '', en: '' }
  successtring.value = { de: '', en: '' }
}

const next = async () => {
  if (props.type === "step") {
    router.replace({ name: "Step4" })
  }
}

const setDataOnBoarding3 = async (event: Event) => {
  const target = event.target as HTMLInputElement
  if (!target.files?.length) return

  file.value = target.files[0]

  if (file.value.size > 1000000) {
    errorstring.value = {
      de: "Could not save image. Max. allowed file size is 1MB (Mbyte)",
      en: "Could not save image. Max. allowed file size is 1MB (Mbyte)"
    }
    return
  }

  const formData = new FormData()
  formData.append('file', file.value)

  try {
    const dpr = await store.dispatch("i/setDataOnBoarding3", formData)
    setTimeout(reset_message, 2000)

    if (dpr.sv) {
      successtring.value = {
        de: "Image saved.",
        en: "Image saved."
      }

      // ranking activity m - Bild hochgeladen 6 Punkte
      await store.dispatch('i/setDataRankingM', rankingsMaxPoints.ranking_m)
    } else {
      errorstring.value = {
        de: "Could not save image.",
        en: "Could not save image."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}
</script>

<template>
  <div class="">
    <div class="col-md-12 col-xl-12">
      <div v-show="successtring.de !== ''" class="alert alert-success col-lg-12">
        {{ store.getters.i18n(successtring) }}
      </div>

      <div v-show="errorstring.de !== ''" class="alert alert-danger mb-5">
        {{ store.getters.i18n(errorstring) }}
      </div>

      <p>
        Please prepare a profile picture in 250x250px (max. 1 MB) and upload it here!
      </p>

      <p>
        <input type="file" name="import_file" @change="setDataOnBoarding3($event)" accept="image/jpeg">
      </p>

      <div class="circular_image circular_image_large mb-4">
        <img
            :src="`/files/members/${store.state.config.me.objvalueid}/foto.jpg?nocache=${Date.now()}`"
            onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';"
            alt=""
        >
      </div>

      <div v-if="type === 'step'" class="d-flex align-items-center">
        <router-link :to="{ name: 'Step2' }" class="btn btn-primary m-1">Back</router-link>
        <router-link :to="{ name: 'Step4' }" class="btn btn-primary m-1">Next</router-link>
      </div>
    </div>
  </div>
</template>
