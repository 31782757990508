<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'
import VideoChatArea from "@/components/i/VideoChatArea.vue"
import FontAwesomeIcon from "@/components/FontAwesomeIcon.vue"
import { MultiUserRoom } from "@/store/i/types"

// Store
const store = useStore()

// Reactive state
const show = ref(true)
const originalParent = ref<HTMLElement | null>(null)

// Methods
const toogle = () => {
  show.value = !show.value
}

// Computed
const isSelected = computed(() => {
  if (store.state.i.rooms.length > 0) {
    const uroom: MultiUserRoom | undefined = _.find(
        store.state.i.rooms,
        (r: MultiUserRoom) => r.roomname === store.state.i.mucname
    )
    return !!(
        uroom !== null &&
        typeof uroom === "object" &&
        uroom.interaction &&
        uroom.interaction.type === "questions"
    )
  }
  return false
})

// Fullscreen handling
const fullscreenhandle = async (nv: boolean) => {
  const playertoattach = document.querySelector(".video-js")
  if (!playertoattach) return

  const el = document.getElementById('private-question-ia')
  if (!el) return

  if (nv) {
    originalParent.value = el.parentElement
    originalParent.value?.removeChild(el)
    playertoattach.append(el)
  } else {
    playertoattach.removeChild(el)
    originalParent.value?.append(el)
  }
}

// Watchers
watch(
    () => store.state.i.videoFullscreen,
    (newValue) => fullscreenhandle(newValue)
)

// Lifecycle hooks
onMounted(() => {
  if (store.state.i.videoFullscreen) {
    fullscreenhandle(true)
  }
})
</script>

<template>
  <div id="private-question-ia" class="mt-4 mb-4 mb-lg-0" v-if="isSelected">
    <h5 @click="toogle" class="mt-2">
      <span class="ms-2 pb-2">
        <FontAwesomeIcon icon="arrow-down" v-show="!show" />
        <FontAwesomeIcon icon="arrow-up" v-show="show" />
      </span>
      {{ store.getters.i18n({
      de: 'Stellen Sie hier Ihre Frage',
      en: 'Ask your question here'
    }) }}
    </h5>

    <div class="row mb-2 mt-4" v-show="show">
      <div class="col">
        <div>
          {{ store.getters.i18n({
          de: 'Ihre Fragen sind nur für Sie, die Regie und den Speaker sichtbar. Bitte haben Sie Verständnis, dass nicht alle Fragen beantwortet werden können.',
          en: 'Your questions are only visible to you, the editorial team and the speaker. Please be aware that not all questions can be answered.'
        }) }}
        </div>
      </div>
      <VideoChatArea chattype="questions" />
    </div>
  </div>
</template>