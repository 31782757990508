import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createVNode as _createVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  id: "bigchatbox",
  class: "chat-room"
}
const _hoisted_2 = {
  key: 0,
  class: "scroll-element row d-block",
  ref: "scrollelement"
}
const _hoisted_3 = {
  key: 0,
  class: "badge badge-danger float-right ms-3"
}
const _hoisted_4 = { class: "z-depth-1 pb-0" }
const _hoisted_5 = { class: "list-unstyled friend-list my-custom-scrollbar" }
const _hoisted_6 = {
  key: 0,
  class: "p-8 pt-3 me-4"
}
const _hoisted_7 = { class: "me-4" }
const _hoisted_8 = {
  key: 1,
  class: "small-convo"
}
const _hoisted_9 = { class: "d-flex justify-content-between p-2 resize-y" }
const _hoisted_10 = { class: "heading d-flex justify-content-start" }
const _hoisted_11 = {
  key: 0,
  class: "profile-photo me-4"
}
const _hoisted_12 = { class: "circular_image circular_image_small" }
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 0,
  class: "state"
}
const _hoisted_15 = { class: "data" }
const _hoisted_16 = {
  key: 0,
  class: "name mb-0 text-truncate"
}
const _hoisted_17 = {
  key: 1,
  class: "name mb-0 text-truncate"
}
const _hoisted_18 = {
  key: 2,
  class: "name mb-0"
}
const _hoisted_19 = {
  key: 3,
  class: "name mb-0"
}
const _hoisted_20 = { class: "form-group basic-textarea" }
const _hoisted_21 = ["onKeypress", "placeholder"]
const _hoisted_22 = { class: "mt-2" }
const _hoisted_23 = ["disabled"]

import { ref, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import type { ChatContact } from "@/store/i/types"
import ChatContactUser from "@/components/i/ChatContactUser.vue"
import ChatSmallMsgWrapper from "@/components/i/ChatSmallMsgWrapper.vue"
import _ from "lodash"
import { eveMessageLib } from "@/dummyEveMessageLib"


export default /*@__PURE__*/_defineComponent({
  __name: 'BigChat',
  setup(__props) {

const store = useStore()
const msgenterbox = ref<HTMLTextAreaElement>()
const mymessage = ref('')
const sending = ref(false)
const searchtext = ref('')
const nowWriting = ref(false)
const isonline = ref(false)
const resizeMode = ref(false)

const stopWriting = _.debounce((vm) => {
  nowWriting.value = false
  if (contact.value !== "support") {
    eveMessageLib.msg.send.announcetouser(
        'stopwriting',
        {
          objvalueid: (contact.value as ChatContact).objvalueid,
          gsuffix: "members"
        },
        { anonymous: true, persist: false },
        (res: boolean) => {}
    )
  } else {
    eveMessageLib.msg.send.announce(
        'cp.supportchat',
        'stopwriting',
        { anonymous: true, persist: false },
        (res: boolean) => {}
    )
  }
}, 2000)

const showsupport = computed(() => {
  return 1
})

const isSupport = computed((): boolean => {
  return store.state.i.showsmallchat === "support"
})

const pickuser = (fbuid: string): void => {
  const uc = _.find(
      store.state.i.contactschats,
      (c) => c.fbuid === fbuid
  )

  if (uc) {
    store.commit('i/setChatboxContact', uc)
  }
}

const justSupportChat = computed((): boolean => {
  return true
})

const toggleChatbox = (): void => {
  store.commit(
      'i/showChatbox',
      (justSupportChat.value ? 'support' : true)
  )
}


const unread = computed((): number => {
  const unreadMessages = _.filter(
      store.state.chat.supportMessages,
      (um) => {
        return (
            !um.seen &&
            um.receiver === 'me'
        )
      }
  )
  return unreadMessages.length
})

const returnToList = (): void => {
  if (store.state.config.withnetworking) {
    store.commit('i/showChatbox')
  } else {
    hideChatbox()
  }
}

// Zusätzlich benötigen Sie die hideChatbox Methode
const hideChatbox = (): void => {
  store.state.i.showsmallchat = false
}

const showtext = computed(() => {
  const date = new Date("2022-06-20T08:00:00+02:00").getTime()
  const currenttime = store.state.tick.getTime()
  return currenttime < date ? 1 : 0
})

const showtext2 = computed(() => {
  const date = new Date("2022-06-22T00:00:00+02:00").getTime()
  const currenttime = store.state.tick.getTime()
  return currenttime > date ? 1 : 0
})



const formatTitle = (title: string): string => {
  let formattedTitle = _.replace(title, /&#34;/g, '"')
  formattedTitle = _.replace(formattedTitle, /&#39;/g, "'")
  return formattedTitle
}

const writing = (e: KeyboardEvent) => {
  if (e.key.length > 1) return

  if (!nowWriting.value) {
    nowWriting.value = true
    if (contact.value !== "support") {
      eveMessageLib.msg.send.announcetouser(
          'startwriting',
          {
            objvalueid: (contact.value as ChatContact).objvalueid,
            gsuffix: "members"
          },
          { anonymous: true, persist: false },
          (res: boolean) => {}
      )
    } else {
      eveMessageLib.msg.send.announce(
          'cp.supportchat',
          'startwriting',
          { anonymous: true, persist: false },
          (res: boolean) => {}
      )
    }
  }

  stopWriting(this)
}

const contact = computed(() => {
  if (store.state.i.showsmallchat === true) {
    return false
  }
  return store.state.i.showsmallchat
})

const sortedContacts = computed(() => {
  const sC = store.getters['i/sortedContacts']
  if (sC.length > 0 && searchtext.value.length > 0) {
    const swords = _.map(
        _.words(searchtext.value),
        s => new RegExp(_.escapeRegExp(s), 'iu')
    )
    return _.filter(
        sC,
        c => {
          for (let i = swords.length - 1; i >= 0; i--) {
            if (!swords[i].test(c.firstname + ' ' + c.lastname)) {
              return false
            }
          }
          return true
        }
    )
  }
  return sC
})

const userimage = computed((): string|null => {
  if (isSupport.value) {
    return '/projects/dw/gmf/assets/images/support-icon.jpg'
  }

  if (contact.value && contact.value !== "support") {
    return '/files/members/' + (contact.value as ChatContact).objvalueid + '/foto.jpg'
  }

  return '/projects/dw/gmf/assets/images/placeholder.jpg'
})

const sendmymessage = async () => {
  if (sending.value) return

  sending.value = true
  if (contact.value && mymessage.value !== "") {
    try {
      if (contact.value === "support") {
        await store.dispatch('i/sendmysupportmsg', {
          body: mymessage.value,
          ts: Date.now() / 1000,
          sender: "me",
          receiver: "support"
        })
      } else if ((contact.value as ChatContact).objvalueid > 0) {
        await store.dispatch('i/checkIfContactNeedsToBeAdded', {...contact.value})
        await store.dispatch('i/sendmydm', {
          body: mymessage.value,
          ts: Date.now() / 1000,
          sender: store.state.config.fbuid,
          receiver: (contact.value as ChatContact).fbuid
        })
      } else {
        return
      }

      mymessage.value = ""
      await nextTick()
      msgenterbox.value?.focus()
    } catch (e) {
      console.error(e)
    }
  }
  sending.value = false
}

const checkOnlineState = async () => {
  if (contact.value) {
    let checkres: boolean|null = null
    if (contact.value === "support") {
      checkres = await store.dispatch("i/checkSupportAvailable")
    } else {
      checkres = await store.dispatch("i/checkUserOnlineState", (contact.value as ChatContact).objvalueid)
    }

    isonline.value = !!checkres
    window.setTimeout(checkOnlineState, 1000)
  } else {
    isonline.value = false
  }
}

// Watch für Änderungen am Contact
watch(contact, () => {
  void checkOnlineState()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (!contact.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", null, [
            (_unref(store).state.config.withsupportchat && _unref(store).state.i.connected && showsupport.value)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  id: "chatindicator",
                  onClick: _withModifiers(toggleChatbox, ["prevent"]),
                  class: "btn btn-primary"
                }, [
                  _cache[2] || (_cache[2] = _createTextVNode(" Support Chat ")),
                  (unread.value > 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(unread.value), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("ul", _hoisted_5, [
              (_unref(store).getters['i/sortedContacts'].length !== 0)
                ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                    _withDirectives(_createElementVNode("input", {
                      placeholder: "Namen suchen",
                      type: "search",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchtext).value = $event)),
                      class: "form-control"
                    }, null, 512), [
                      [
                        _vModelText,
                        searchtext.value,
                        void 0,
                        { trim: true }
                      ]
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedContacts.value, (u, index) => {
                  return (_openBlock(), _createBlock(ChatContactUser, {
                    key: 'chatcontactuser' + index,
                    user: u,
                    selected: false,
                    isSmall: true,
                    onPick: pickuser
                  }, null, 8, ["user"]))
                }), 128))
              ])
            ])
          ])
        ], 512))
      : _createCommentVNode("", true),
    (contact.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("button", {
            onClick: _withModifiers(returnToList, ["prevent"]),
            type: "button",
            class: "btn btn-primary mb-3"
          }, _toDisplayString(_unref(store).getters.i18n({de: 'Übersicht', en: 'Overview'})), 1),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              (userimage.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("img", {
                        src: userimage.value,
                        onerror: "this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';",
                        alt: ""
                      }, null, 8, _hoisted_13)
                    ]),
                    (isonline.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_15, [
                (!isSupport.value)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_16, [
                      _createElementVNode("strong", null, _toDisplayString(contact.value.firstname) + " " + _toDisplayString(contact.value.lastname), 1),
                      _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                      _createTextVNode(" " + _toDisplayString(formatTitle(contact.value.orgname)), 1)
                    ]))
                  : _createCommentVNode("", true),
                (isSupport.value)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_17, [
                      _createElementVNode("strong", null, _toDisplayString(_unref(store).getters.i18n({de:'Support', en:'Support'})), 1)
                    ]))
                  : _createCommentVNode("", true),
                (isSupport.value && showtext.value)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_18, " The Support Chat will be available from 8 a.m. to 6 p.m. on June 17 and 18. Until June 17, 8 am, a response may take up to 48h. "))
                  : _createCommentVNode("", true),
                (isSupport.value && showtext2.value)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_19, " The Support Chat will be available from 8 a.m. to 6 p.m. on June 17 and 18. From June 20 on the support chat is not available anymore. "))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createVNode(ChatSmallMsgWrapper),
          _createElementVNode("div", _hoisted_20, [
            _withDirectives(_createElementVNode("textarea", {
              class: "form-control pl-2 my-0",
              ref_key: "msgenterbox",
              ref: msgenterbox,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((mymessage).value = $event)),
              autocomplete: "off",
              id: "enterbox",
              onKeydownPassive: writing,
              onKeypress: _withKeys(_withModifiers(sendmymessage, ["prevent"]), ["enter"]),
              rows: "3",
              placeholder: _unref(store).getters.i18n({de:'Ihre Nachricht',en:'Your message'})
            }, "              ", 40, _hoisted_21), [
              [_vModelText, mymessage.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", null, [
              _createElementVNode("button", {
                disabled: mymessage.value === '' || sending.value,
                onClickPassive: sendmymessage,
                type: "button",
                class: "btn btn-primary"
              }, _toDisplayString(_unref(store).getters.i18n({de: 'Senden', en: 'Send'})), 41, _hoisted_23)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})