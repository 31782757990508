import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "circular_image circular_image_small" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "Name-Nachname mb-1 ipad-2" }
const _hoisted_5 = { class: "Role copydetailxs mb-2 ipad-2 bold" }
const _hoisted_6 = { class: "col-md-7 col-12" }
const _hoisted_7 = { class: "sm-r col-8" }
const _hoisted_8 = { class: "badge badge-pill sm-r" }
const _hoisted_9 = { class: "ms-2 pb-2" }
const _hoisted_10 = { class: "sm-l col-4" }
const _hoisted_11 = { class: "smartphone" }
const _hoisted_12 = {
  key: 0,
  class: "sm-mt-4 col-12"
}
const _hoisted_13 = { class: "mb-2" }
const _hoisted_14 = ["title"]
const _hoisted_15 = { class: "mb-2" }
const _hoisted_16 = ["title"]
const _hoisted_17 = { class: "mb-2" }
const _hoisted_18 = ["title"]
const _hoisted_19 = { key: 0 }

import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'
import type { AgendaPoint, ContactsNetworking } from '@/store/i/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'NetworkingUserContact',
  props: {
    user: {},
    contact_type: {}
  },
  emits: ["navigate", "create"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const store = useStore()
const showMatching = ref(false)

const change_matching = () => {
  showMatching.value = !showMatching.value
}

const navigate = () => {
  emit('navigate', props.user)
}

const title = (title: string): string => {
  return _.replace(_.replace(title, /&#34;/g, '"'), /&#39;/g, "'")
}

const create = () => {
  emit('create', props.user)
}

const networkinglist = computed(() => store.state.i.me.networkinglist)

const networking_question1 = computed(() => props.user?.networking_question1 || [])
const networking_question2 = computed(() => props.user?.networking_question2 || [])


const fav_agenda_items = computed(() => props.user?.fav_agenda_items || 0)

const agendaEntry = computed(() => {
  return _.filter(
      store.getters['i/orderedAgendaPoints'],
      (ap: AgendaPoint) => ap.dbstate > -1
  )
})

const agendaEntryTitle = (ei: string) => {
  const entry = _.find(
      agendaEntry.value,
      (e: AgendaPoint) => `${e.objvalueid}` === ei
  )
  return entry ? entry.title : ''
}

const is_selected1 = (ei: string) => {
  return _.includes(_.keys(store.state.i.me.networking_question2), ei)
}

const is_selected2 = (ei: string) => {
  return _.includes(_.keys(store.state.i.me.networking_question1), ei)
}

const is_selected3 = (ei: string) => {
  return _.includes(_.keys(store.state.i.me.fav_agenda_items), ei)
}

return (_ctx: any,_cache: any) => {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "pointer col-md-1 col-4",
      onClick: _withModifiers(navigate, ["prevent"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: `/files/members/${props.user.objvalueid}/foto.jpg`,
          onerror: "this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';",
          alt: ""
        }, null, 8, _hoisted_3)
      ])
    ]),
    _createElementVNode("div", {
      class: "pointer col-md-4 col-8",
      onClick: _withModifiers(navigate, ["prevent"])
    }, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(props.user.firstname) + " " + _toDisplayString(props.user.lastname), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(title(props.user.orgname)), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("span", _hoisted_7, [
        _createElementVNode("span", _hoisted_8, _toDisplayString(props.user.match) + "%", 1),
        _createElementVNode("span", {
          class: "bold mb-3 ms-2 pointer",
          onClick: change_matching
        }, [
          _cache[0] || (_cache[0] = _createTextVNode(" Matching result ")),
          _createElementVNode("span", _hoisted_9, [
            _withDirectives(_createVNode(_component_FontAwesomeIcon, { icon: "arrow-down" }, null, 512), [
              [_vShow, !showMatching.value]
            ]),
            _withDirectives(_createVNode(_component_FontAwesomeIcon, { icon: "arrow-up" }, null, 512), [
              [_vShow, showMatching.value]
            ])
          ])
        ])
      ]),
      _createElementVNode("span", _hoisted_10, [
        _createElementVNode("button", {
          class: "btn btn-primary m-1",
          onClick: _withModifiers(create, ["prevent"])
        }, [
          _createElementVNode("span", _hoisted_11, [
            _createVNode(_component_FontAwesomeIcon, { icon: "user-plus" })
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "nsmartphone" }, "Send contact request", -1))
        ])
      ]),
      (showMatching.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _cache[2] || (_cache[2] = _createElementVNode("b", null, "My Expertise", -1)),
              _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(networking_question1.value, (e, ei) => {
                return (_openBlock(), _createElementBlock("span", { key: ei }, [
                  _createElementVNode("span", {
                    title: is_selected1(String(ei)) ? 'Match' : 'No match',
                    class: _normalizeClass({
                'badge badge-no-match': true, 
                'badge-light': is_selected1(String(ei))
              })
                  }, _toDisplayString(networkinglist.value[String(ei)]), 11, _hoisted_14)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_15, [
              _cache[4] || (_cache[4] = _createElementVNode("b", null, "My interests", -1)),
              _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(networking_question2.value, (e, ei) => {
                return (_openBlock(), _createElementBlock("span", { key: ei }, [
                  _createElementVNode("span", {
                    title: is_selected2(String(ei)) ? 'Match' : 'No match',
                    class: _normalizeClass({
                'badge badge-no-match': true, 
                'badge-light': is_selected2(String(ei))
              })
                  }, _toDisplayString(networkinglist.value[String(ei)]), 11, _hoisted_16)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[6] || (_cache[6] = _createElementVNode("b", null, "My Calendar", -1)),
              _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fav_agenda_items.value, (e, ei) => {
                return (_openBlock(), _createElementBlock("span", { key: ei }, [
                  _createElementVNode("span", {
                    title: is_selected3(String(ei)) ? 'Match' : 'No match',
                    class: _normalizeClass({
                'badge badge-no-match': true, 
                'badge-light': is_selected3(String(ei))
              })
                  }, _toDisplayString(title(agendaEntryTitle(String(ei)))), 11, _hoisted_18)
                ]))
              }), 128)),
              (fav_agenda_items.value.length === 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_19, "This participant did not bookmark any program items."))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})