<template>
  <div class="box">
    <span class="headline cb-headlinenormalxl g-3" v-if="!partnerEntry">
      Not found
    </span>

    <div class="row g-3" v-if="partnerEntry">
      <div class="col-md-5 col-lg-3 mb-3 sm-center">
        <div>
          <img
              :src="partnerLogoUrl"
              width="250"
              :alt="partnerEntry.title"
              @error="handleImageError"
          >
        </div>
      </div>

      <div class="col-md-7 col-lg-9 mb-3 text-left ipadfx">
        <h3 class="pb-3 pt-0">{{ partnerEntry.title }}</h3>

        <div v-if="partnerEntry.email">
          E-Mail: <a :href="`mailto:${partnerEntry.email}`">{{ partnerEntry.email }}</a>
        </div>

        <div v-if="partnerEntry.website">
          Website: <a :href="partnerEntry.website">{{ partnerEntry.website }}</a>
        </div>

        <div class="row" v-if="hasSocialMedia">
          <div class="col-12">
            <span class="Role copydetailxs">
              <br>
              <span class="headline_light text-center">FIND US ON</span>
              <br>
              <template v-for="(link, platform) in socialMediaLinks" :key="platform">
                <a v-if="link" :href="link" class="buttonshare" target="_blank">
                  <i :class="socialMediaIcons[platform]"></i>
                  <span class="sr-only">{{ platform }}</span>
                </a>
              </template>
            </span>
            <br>
          </div>
        </div>
      </div>

      <hr>

      <div v-if="partnerEntry.description">
        <h4 class="bold mb-3">About us</h4>
        <div class="cv" v-html="partnerEntry.description"></div>
        <hr>
      </div>

      <div v-if="program.length">
        <h4 class="bold mt-4 mb-4">Program</h4>
        <AgendaEntry
            v-for="(ae, index) in program"
            :key="`agp${index}`"
            :ae="ae"
            agendatype="entry"
        />
        <hr>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { AgendaPoint, Partner } from "@/store/i/types"
import AgendaEntry from "@/components/AgendaEntry.vue"
import _ from "lodash"
import { useGtm } from "@/composables/useGtm";

export default defineComponent({
  name: 'SpeakerDetail',

  components: {
    AgendaEntry
  },

  setup() {
    const { trackPage } = useGtm()


    const store = useStore()
    const route = useRoute()

    const socialMediaIcons = {
      Twitter: 'fab fa-2x fa-x-twitter',
      LinkedIn: 'fab fa-2x fa-linkedin-in',
      Facebook: 'fab fa-2x fa-facebook-f'
    }

    onBeforeMount(() => {
      trackPage
      ({
        pageCategory: 'Partner',
        PageName: 'Detailpage',
        PageType: 'Webapp',
      })
    })

    const partnerEntry = computed(() =>
        _.find(
            store.state.i.partners,
            (e: Partner) => `${e.objvalueid}` === `${route.params.partnerid}`
        )
    )

    const partnerLogoUrl = computed(() =>
        `/files/exhibitor/${partnerEntry.value?.objvalueid}/logo.jpg?nocache=${new Date().getHours()}`
    )

    const program = computed(() =>
        _.filter(
            store.getters['i/orderedAgendaPoints'],
            (ap: AgendaPoint) => ap.dbstate > -1 && _.has(partnerEntry.value?.agenda, ap.objvalueid)
        )
    )

    const socialMediaLinks = computed(() => ({
      Twitter: partnerEntry.value?.twitter,
      LinkedIn: partnerEntry.value?.linkedin,
      Facebook: partnerEntry.value?.facebook
    }))

    const hasSocialMedia = computed(() =>
        Object.values(socialMediaLinks.value).some(Boolean)
    )

    const handleImageError = (event: Event) => {
      const target = event.target as HTMLImageElement
      target.onerror = null
      target.src = '/projects/dw/gmf/assets/images/placeholder.jpg'
    }

    return {
      partnerEntry,
      partnerLogoUrl,
      program,
      socialMediaLinks,
      socialMediaIcons,
      hasSocialMedia,
      handleImageError
    }
  }
})
</script>