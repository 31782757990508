<template>
  <div class="box">
    <div class="mainform g-3 pb-4">
      <h3>Video call settings</h3>
      <OnBoarding4 type="step"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from 'vue'
import OnBoarding4 from "@/components/i/OnBoarding4.vue"
import type { PropType } from 'vue'
import { useGtm } from '@/composables/useGtm'


export default defineComponent({
  name: 'Step1',

  components: {
    OnBoarding4
  },

  props: {
    OnBoarding4: {
      type: Object as PropType<typeof OnBoarding4>,
      required: true
    }
  },

  setup() {
    const { trackPage } = useGtm()

    onBeforeMount(() => {
      trackPage({
        pageCategory: 'Networking',
        PageName: 'Video Call Settings',
        PageType: 'Webapp'
      })
    })
  }

})
</script>