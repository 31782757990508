import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["viewBox"]
const _hoisted_2 = {
  key: 0,
  fill: "currentColor",
  d: "M -75.496683,251.7445 -18.92814,342.25416 C -12.776311,352.08295 -0.40194274,356.11346 10.34608,351.87082 L 123.48317,306.61598 C 133.38266,302.65619 139.53449,292.54456 138.40312,281.93796 L 130.62494,204.01479 C 210.31588,175.23554 299.90631,174.59915 381.22359,204.01479 L 373.44541,281.93796 C 372.38475,292.61527 378.46587,302.65619 388.36537,306.61598 L 501.50245,351.87082 C 512.39189,356.11346 524.76626,352.08295 530.91809,342.25416 L 587.48664,251.7445 C 593.35562,242.33998 592.08283,230.03632 584.09252,222.04601 403.00248,40.955965 109.27032,40.673123 -72.10257,222.04601 -80.022166,229.96561 -81.43638,242.26927 -75.496683,251.7445 Z"
}
const _hoisted_3 = {
  key: 1,
  fill: "currentColor",
  d: "M176 352c53.02 0 96-42.98 96-96V96c0-53.02-42.98-96-96-96S80 42.98 80 96v160c0 53.02 42.98 96 96 96zm160-160h-16c-8.84 0-16 7.16-16 16v48c0 74.8-64.49 134.82-140.79 127.38C96.71 376.89 48 317.11 48 250.3V208c0-8.84-7.16-16-16-16H16c-8.84 0-16 7.16-16 16v40.16c0 89.64 63.97 169.55 152 181.69V464H96c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16h-56v-33.77C285.71 418.47 352 344.9 352 256v-48c0-8.84-7.16-16-16-16z"
}

import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'JitsiFontAwesomeIcon',
  props: {
    icon: {}
  },
  setup(__props: any) {

const props = __props


const viewBox = computed((): string|undefined => {
  switch (props.icon) {
    case "phone":
    case "minimize":
      return "0 0 512 512"
    case "microphone":
      return "0 0 352 512"
    case "microphonemuted":
    case "videooff":
      return "0 0 640 512"
    case "video":
      return "0 0 576 512"
    case "user":
    case "maximize":
      return "0 0 448 512"
    default:
      return undefined
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    "aria-hidden": "true",
    focusable: "false",
    xmlns: "http://www.w3.org/2000/svg",
    class: _normalizeClass(`svg-icon-inline icon-${_ctx.icon}`),
    viewBox: viewBox.value
  }, [
    (_ctx.icon === 'phone')
      ? (_openBlock(), _createElementBlock("path", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.icon === 'microphone')
      ? (_openBlock(), _createElementBlock("path", _hoisted_3))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}
}

})