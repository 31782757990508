<template>
  <div class="box">
    <div class="mainform g-3 pb-4">
      <h3>My Profile</h3>
      <OnBoarding2 type="step"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from 'vue'
import OnBoarding2 from "@/components/i/OnBoarding2.vue"
import { useGtm } from '@/composables/useGtm'


export default defineComponent({
  name: 'Step1',

  components: {
    OnBoarding2
  },

  setup() {

    const { trackPage } = useGtm()

    onBeforeMount(() => {
      trackPage({
        pageCategory: 'Networking',
        PageName: 'My Profile',
        PageType: 'Webapp',
      })
    })
  }
})
</script>