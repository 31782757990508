<template>
  <div class="col-lg-9 col-md-12" :class="{'col-md-6 col-lg-4': props.agendatype =='entry'}">
    <div v-show="errorstring.de !== ''" class="alert alert-danger mb-5">
      {{ store.getters.i18n(errorstring) }}
    </div>

    <div class="card mb-3">
      <div :class="{'card-header': true, 'meeting-accepted': props.ae.status == 2 && props.agendatype !='entry', 'meeting-declined': props.ae.status == 3 && props.agendatype !='entry', 'meeting-canceled': props.ae.status == 4 && props.agendatype !='entry'}">
        <small class="text-muted" style="font-size: 1rem;"><i class="fal fa-calendar-alt"></i> {{formatedDate}} CEST</small>
      </div>

      <div :class="{'card-body card-body-expanded': true}">
        <div class="mb-4" v-if="props.agendatype !='entry'">
          <button v-if="props.ae.status != 2 && props.ae.status != 4 && props.ae.receiver == store.state.config.me.objvalueid" class="btn btn-primary me-2" @click.prevent="acceptMeeting">Accept</button>
          <button v-if="props.ae.status != 3 && props.ae.status != 4 && props.ae.receiver == store.state.config.me.objvalueid" class="btn btn-primary" @click.prevent="declineMeeting">Decline</button>
          <button v-if="props.ae.status != 4 && props.ae.sender == store.state.config.me.objvalueid" class="btn btn-primary" @click.prevent="removeMeeting">Cancel</button>
          <button v-if="props.ae.status == 2" class="btn btn-primary m-1 float-end" @click.prevent="call">Call</button>
        </div>
        <a class="pointer" @click="navigateMeeting">
          <p class="card-title bold" v-html="props.ae.meeting_title"></p>
          <p class="card-title">{{props.ae.long_summary}}</p>
        </a>
      </div>

      <div class="card-footer">
        <small class="text-muted pointer" @click="navigateMember">
          <i class="fal fa-user"></i>
          {{props.ae.firstname}} {{props.ae.lastname}}
        </small>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { DateTime } from 'luxon'
import { AppNetworkErrorOther, networkErrorText } from "@/crud"
import { TranslatedText } from "@/store/types"
import { Meetings } from "@/store/i/types"

const props = defineProps<{
  ae: Meetings
  agendatype?: string
}>()

const emit = defineEmits(['call'])
const store = useStore()
const router = useRouter()
const errorstring = ref<TranslatedText>({ de: '', en: '' })

const formatedDate = computed(() => {
  return DateTime.fromISO(props.ae.startdate, {locale: 'en-us'}).weekdayLong +
      ', ' +
      DateTime.fromISO(props.ae.startdate).toFormat('h:mm a') +
      " - " +
      DateTime.fromISO(props.ae.enddate).toFormat('h:mm a')
})

const call = () => {
  if (props.ae) {
    emit('call', props.ae)
  }
}

const navigateMeeting = () => {
  router.push({
    name: 'MeetingDetail',
    params: { meetingid: `${props.ae.objvalueid}` }
  })
}

const navigateMember = () => {
  const contactid = props.ae.receiver === store.state.config.me.objvalueid
      ? props.ae.sender
      : props.ae.receiver
  router.push({
    name: 'ContactDetail',
    params: { contactid: `${contactid}` }
  })
}

const reset_message = () => {
  errorstring.value = { de: '', en: '' }
}

const removeMeeting = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    const dpr = await store.dispatch("i/removeMeeting", props.ae)
    setTimeout(reset_message, 2000)
    if (!dpr) {
      errorstring.value = {
        de: "Could not remove Appointment.",
        en: "Could not remove Appointment."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

const acceptMeeting = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    const dpr = await store.dispatch("i/acceptMeeting", props.ae)
    setTimeout(reset_message, 2000)
    if (!dpr) {
      errorstring.value = {
        de: "Could not accept Appointment.",
        en: "Could not accept Appointment."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

const declineMeeting = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    const dpr = await store.dispatch("i/declineMeeting", props.ae)
    setTimeout(reset_message, 2000)
    if (!dpr) {
      errorstring.value = {
        de: "Could not decline Appointment.",
        en: "Could not decline Appointment."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}
</script>