import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "row g-3 mb-5 partners" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PartnerEntry = _resolveComponent("PartnerEntry")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Partners", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, [
          _withDirectives(_createElementVNode("input", {
            placeholder: "Search...",
            type: "search",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchtext) = $event)),
            class: "form-control"
          }, null, 512), [
            [
              _vModelText,
              _ctx.searchtext,
              void 0,
              { trim: true }
            ]
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partnerSections, (section) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: section.id
          }, [
            (section.partners.length > 0)
              ? (_openBlock(), _createElementBlock("h4", {
                  key: 0,
                  class: _normalizeClass({'mt-4': section.id !== 1})
                }, _toDisplayString(section.title), 3))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.partners, (partner, index) => {
              return (_openBlock(), _createBlock(_component_PartnerEntry, {
                key: `partner${section.id}-${index}`,
                partner: partner
              }, null, 8, ["partner"]))
            }), 128))
          ], 64))
        }), 128))
      ])
    ])
  ]))
}