<script setup lang="ts">
import { computed } from 'vue';
import type { Message, MessageSender } from "@/store/i/types";
import { DateTime } from "luxon";
import { useStore } from 'vuex';

interface Props {
  cE?: Message;
  chattype: "chat" | "questions";
}

const props = defineProps<Props>();
const store = useStore();

const user = computed(() => {
  if (props.cE && typeof props.cE.sender !== "string") {
    return props.cE.sender;
  }
  return undefined;
});

const msgIsFromMe = computed(() => {
  if (props.cE) {
    if (typeof props.cE.sender !== "string") {
      return props.cE.sender.fbuid === store.state.config.fbuid;
    }
    return props.cE.sender === store.state.config.fbuid;
  }
  return false;
});

const filteredBody = computed(() => props.cE?.body || '');

const date = computed(() => {
  const d = new Date();
  if (props.cE?.ts) {
    d.setTime(props.cE.ts * 1000);
  }
  return d;
});

const dateString = computed(() => {
  const setDT = DateTime.fromJSDate(date.value);
  return setDT.setLocale(store.getters.i18n({de:'de',en:'en'}))
          .toLocaleString(DateTime.DATETIME_SHORT) +
      store.getters.i18n({de:' Uhr',en:' '});
});

const uName = computed(() => {
  if (msgIsFromMe.value) {
    return store.getters.i18n({de: 'Ich', en: 'Me'});
  }

  if (props.cE && typeof props.cE.sender === "string" && (/-cp-/).test(props.cE.sender)) {
    return store.getters.i18n({de: 'Event Team', en: 'Event Team'});
  }

  if (user.value) {
    const userarray: string[] = [];
    if (user.value.gender) {
      switch (user.value.gender) {
        case 1:
          userarray.push(store.getters.i18n({de: 'Herr', en: 'Mr.'}));
          break;
        case 2:
          userarray.push(store.getters.i18n({de: 'Frau', en: 'Ms.'}));
          break;
      }
    }

    if (user.value.firstname) {
      userarray.push(user.value.firstname);
    }
    if (user.value.lastname) {
      userarray.push(user.value.lastname);
    }

    return userarray.join(" ");
  }

  return 'anonym';
});

const uCompany = computed(() => {
  if (user.value?.orgname) {
    return ` - ${user.value.orgname}`;
  }
  return '';
});
</script>

<template>
  <div :class="{'chat-entry':true}">
    <div class="voteless-time" v-if="chattype === 'questions'">
      <small>{{ dateString }}</small>
    </div>
    <div class="chatmeta" v-if="chattype === 'chat'">
      <small class="chatmeta--name">
        {{ uName }}<span class="chatmeta--company">{{ uCompany }}</span>
      </small>
      <small>{{ dateString }}</small>
    </div>
    <div v-html="filteredBody"></div>
  </div>
</template>