<template>
  <div class="box coffeebar coffeebar-bg">
    <div class="row g-3 mb-5 mt-2 mb-2">
      <div class="col-lg-6">
        <div class="row dw-bg me-2">
          <h3 class="pb-1">Coffee bar</h3>
          <p>Get together for a virtual coffee with other conference attendees who are online right now! This is an open group chat.</p>
        </div>
        <div class="row dw-bg mt-2 me-2">
          <h4>Room chat</h4>
          <div id="publicchatwrapper2" class="dw-bg" v-if="room && room.withpublicchat">
            <VideoChatArea chattype="chat" />
          </div>
        </div>
      </div>

      <div class="col-lg-6 coffebar-bottom">
        <div class="row dw-bg">
          <h4>Reaction Game</h4>
          <p>Take part in our reaction game and collect points to increase your online activity score!<br>
            The highest online activity scores win a ticket to #GMF25.</p>
          <p>
            <small>Rules: You have 20 seconds to click on the logo as often as possible. But be careful, the logo moves faster and faster.</small>
          </p>
          <Competition/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, computed, onMounted, getCurrentInstance, watch, onBeforeMount} from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import _ from 'lodash'
import { MultiUserRoom } from "@/store/i/types"
import Competition from "@/components/Competition.vue"
import VideoChatArea from "@/components/i/VideoChatArea.vue"
import {useGtm} from "@/composables/useGtm";

export default defineComponent({
  name: 'Coffeebar',

  components: {
    VideoChatArea,
    Competition
  },

  setup() {
    const store = useStore()
    const route = useRoute()
    const app = getCurrentInstance()

    const room = computed(() => {
      if (store.state.i.rooms.length > 0) {
        return _.find(
            store.state.i.rooms,
            (r: MultiUserRoom) => r.roomname === store.state.i.mucname
        )
      }
      return null
    })

    const setRoom = () => {
      if (route.meta) {
        store.commit('i/setMucName', route.meta.mucname)
        store.dispatch('i/getHistory')
      }
    }

    watch(() => route.meta.mucname, () => {
      setRoom()
    })

    const { trackPage } = useGtm()
        
    onMounted(() => {
      setRoom()

      trackPage({
        pageCategory: 'Networking',
        PageName: 'Video Call Settings',
        PageType: 'Webapp'
      })

    })

    return {
      room
    }
  }
})
</script>