import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = {
  key: 0,
  class: "headline cb-headlinenormalxl g-3"
}
const _hoisted_3 = {
  key: 1,
  class: "row g-3"
}
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { class: "mb-3 mt-5 bold" }
const _hoisted_10 = { class: "row agenda_item_full" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "mb-5 mt-5 col" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-md-4 row" }
const _hoisted_17 = { class: "col-5 mb-5 no-gutters" }
const _hoisted_18 = { class: "circular_image" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "col-7 mb-5 text-left" }
const _hoisted_21 = { class: "Name-Nachname" }
const _hoisted_22 = { class: "Role copydetailxs" }

import { computed, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'lodash';
import { DateTime } from 'luxon';
import type { Meetings } from '@/store/i/types';
import type { TranslatedText } from '@/store/types';
import { AppNetworkErrorOther, networkErrorText } from '@/crud';
import { useGtm } from '@/composables/useGtm'


export default /*@__PURE__*/_defineComponent({
  __name: 'MeetingDetail',
  setup(__props) {

const router = useRouter();
const route = useRoute();
const store = useStore();
const gtm = useGtm();
const errorstring = ref<TranslatedText>({ de: '', en: '' });

onMounted(() => {
  const { trackPage } = useGtm()
  
  trackPage({
    pageCategory: 'Networking',
    PageName: 'Meeting',
    PageType: 'Webapp',
  });
});

const meetingEntry = computed(() => {
  return _.find(
      store.state.i.meetings,
      (e: Meetings) => `${e.objvalueid}` === `${route.params.meetingid}`
  );
});

const userimage = computed(() => {
  if (meetingEntry.value) {
    if (meetingEntry.value.receiver == store.state.config.me.objvalueid) {
      return '/files/members/' + meetingEntry.value.sender + '/foto.jpg';
    } else {
      return '/files/members/' + meetingEntry.value.receiver + '/foto.jpg';
    }
  }
  return '/projects/dw/gmf/assets/images/placeholder.jpg';
});

const navigateMember = () => {
  if (meetingEntry.value) {
    if (meetingEntry.value.receiver == store.state.config.me.objvalueid) {
      router.push({ name: 'ContactDetail', params: { contactid: `${meetingEntry.value.sender}` } });
    } else {
      router.push({ name: 'ContactDetail', params: { contactid: `${meetingEntry.value.receiver}` } });
    }
  }
};

const title = (title: string): string => {
  title = _.replace(title, /&#34;/g, '"');
  title = _.replace(title, /&#39;/g, "'");
  return title;
};

const formatedDate = (startdate: string, enddate: string): string => {
  return DateTime.fromISO(startdate, { locale: 'en-us' }).monthLong +
      " " + DateTime.fromISO(startdate).toFormat('d, h:mm a') +
      " - " + DateTime.fromISO(enddate).toFormat('h:mm a');
};

const generateFileICS = (objvalueid: any) => {
  window.location.href = '/projects/dw/gmf/sources/scripts/portal.php?&action=generateFileICS&objvalueid=' + objvalueid;
};

const reset_message = () => {
  errorstring.value = { de: '', en: '' };
};

const removeMeeting = async () => {
  try {
    errorstring.value = { de: '', en: '' };
    const dpr = await store.dispatch("i/removeMeeting", meetingEntry.value);
    setTimeout(reset_message, 2000);
    if (!dpr) {
      errorstring.value = {
        de: "Could not remove Appointment.",
        en: "Could not remove Appointment."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

const acceptMeeting = async () => {
  try {
    errorstring.value = { de: '', en: '' };
    const dpr = await store.dispatch("i/acceptMeeting", meetingEntry.value);
    setTimeout(reset_message, 2000);
    if (!dpr) {
      errorstring.value = {
        de: "Could not accept Appointment.",
        en: "Could not accept Appointment."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

const declineMeeting = async () => {
  try {
    errorstring.value = { de: '', en: '' };
    const dpr = await store.dispatch("i/declineMeeting", meetingEntry.value);
    setTimeout(reset_message, 2000);
    if (!dpr) {
      errorstring.value = {
        de: "Could not decline Appointment.",
        en: "Could not decline Appointment."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!meetingEntry.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Not found"))
      : _createCommentVNode("", true),
    (meetingEntry.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h4", null, [
              _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fal fa-calendar-alt" }, null, -1)),
              _createTextVNode(" " + _toDisplayString(formatedDate(meetingEntry.value.startdate, meetingEntry.value.enddate)) + " CEST ", 1),
              (meetingEntry.value.status == 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, "- Open"))
                : _createCommentVNode("", true),
              (meetingEntry.value.status == 2)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, "- Accepted"))
                : _createCommentVNode("", true),
              (meetingEntry.value.status == 3)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, "- Declined"))
                : _createCommentVNode("", true),
              (meetingEntry.value.status == 4)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, "- Canceled"))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("h4", _hoisted_9, _toDisplayString(title(meetingEntry.value.meeting_title)), 1),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", {
                  class: "Sendung_Beschreibung_full",
                  innerHTML: meetingEntry.value.long_summary
                }, null, 8, _hoisted_12)
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "headline_light text-center" }, null, -1)),
                _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                (meetingEntry.value.status != 2 && meetingEntry.value.status != 4 && meetingEntry.value.receiver == _unref(store).state.config.me.objvalueid)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "btn btn-primary",
                      onClick: _withModifiers(acceptMeeting, ["prevent"])
                    }, "Accept"))
                  : _createCommentVNode("", true),
                (meetingEntry.value.status != 3 && meetingEntry.value.status != 4 && meetingEntry.value.receiver == _unref(store).state.config.me.objvalueid)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "btn btn-primary",
                      onClick: _withModifiers(declineMeeting, ["prevent"])
                    }, "Decline"))
                  : _createCommentVNode("", true),
                (meetingEntry.value.status != 4 && meetingEntry.value.sender == _unref(store).state.config.me.objvalueid)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 2,
                      class: "btn btn-primary",
                      onClick: _withModifiers(removeMeeting, ["prevent"])
                    }, "Cancel"))
                  : _createCommentVNode("", true)
              ]),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "mb-5 mt-5 col" }, null, -1)),
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "mb-5 mt-5 col" }, [
                _createElementVNode("span", { class: "Role copydetailxs" }, [
                  _createElementVNode("span", { class: "headline_light text-center" }, "ICS DOWNLOAD"),
                  _createElementVNode("br"),
                  _createElementVNode("br"),
                  _createElementVNode("br")
                ])
              ], -1)),
              _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1)),
              _createElementVNode("div", null, [
                _createElementVNode("div", null, [
                  _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Meeting partner", -1)),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("a", {
                          onClick: navigateMember,
                          class: "pointer"
                        }, [
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("img", {
                              src: userimage.value,
                              onerror: "this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';",
                              alt: ""
                            }, null, 8, _hoisted_19)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("a", {
                          onClick: navigateMember,
                          class: "pointer"
                        }, [
                          _createElementVNode("span", _hoisted_21, _toDisplayString(meetingEntry.value.firstname) + " " + _toDisplayString(meetingEntry.value.lastname), 1),
                          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("span", _hoisted_22, _toDisplayString(meetingEntry.value.orgname), 1)
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})