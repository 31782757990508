<template>
  <div class="box">
    <div class="row g-3 mb-5 highlights">
      <h3>My Calendar</h3>
      <Agenda agendatype="calendar"/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, getCurrentInstance, onBeforeMount} from 'vue'
import Agenda from "@/components/Agenda.vue"
import {useGtm} from "@/composables/useGtm";

export default defineComponent({
  name: 'Calendar',

  components: {
    Agenda
  },

  setup() {
    const app = getCurrentInstance()

    onMounted(() => {
      const { trackPage } = useGtm()

        trackPage({
        pageCategory: 'Program',
        PageName: 'MyCalendarPage',
        PageType: 'Webapp',
      })
    })
  }
})
</script>