import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "row g-3 mb-5" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SpeakerEntry = _resolveComponent("SpeakerEntry")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", null, "All speakers", -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedSpeakers, (e, ei) => {
          return (_openBlock(), _createBlock(_component_SpeakerEntry, {
            key: `omember${ei}`,
            member: e,
            type: "overview"
          }, null, 8, ["member"]))
        }), 128))
      ])
    ])
  ]))
}