<template>
  <div :class="{'col-lg-4 row': type =='welcome', 'col-lg-6 row': type =='overview'}">
    <div class="col-5 mb-5">
      <a @click="navigateMember" class="pointer">
        <div class="circular_image">
          <img
              :src="`/files/members/${props.member.objvalueid}/foto.jpg?nocache=${(new Date()).getHours()}`"
              onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';"
              alt="Speaker Image"
          />
        </div>
      </a>
    </div>
    <div class="col-7 mb-5 ipadfx2">
      <a @click="navigateMember" class="pointer">
        <div class="Name-Nachname mb-1">{{ props.member.firstname }} {{ props.member.lastname }}</div>
        <div class="Role copydetailxs mb-1 bold">{{ title(props.member.position) }}</div>
        <div class="Role copydetailxs">{{ title(props.member.orgname) }}</div>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { MemberEntry } from "@/store/i/types"
import _ from "lodash"

const props = defineProps<{
  member: MemberEntry,
  type?: string
}>()

const router = useRouter()

const navigateMember = () => {
  router.push({
    name: 'SpeakerDetail',
    params: { speakerid: `${props.member.objvalueid}` }
  })
}

const title = (title: string): string => {
  let processedTitle = _.replace(title, /&#34;/g, '"')
  processedTitle = _.replace(processedTitle, /&#39;/g, "'")
  return processedTitle
}
</script>