import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card-body p-3" }
const _hoisted_2 = { class: "chat-message" }

import { ref, computed, onMounted, onBeforeUnmount, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import ChatContactMessageSmall from "@/components/i/ChatContactMessageSmall.vue"
import type { DMMessage, ChatContact, SupportMessage } from "@/store/i/types"


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatSmallMsgWrapper',
  setup(__props) {

const store = useStore()
const scrollContainer = ref<HTMLElement | null>(null)
const otherpersonwriting = ref(false)
const correctScroll = ref(true)

const displayName = computed(() => {
  if (contact.value && typeof contact.value === 'object' && 'firstname' in contact.value) {
    return contact.value.firstname
  }
  return store.getters.i18n({de:'Support',en:'Support'})
})

const contact = computed((): false | ChatContact | "support" => {
  if (store.state.i.showsmallchat === true) {
    return false
  }
  return store.state.i.showsmallchat
})

const convo = computed((): (DMMessage | SupportMessage)[] => {
  if (contact.value) {
    if (contact.value === "support") {
      return store.getters['i/supportchats']
    }
    return store.getters['i/dmsbyuser'](contact.value.fbuid)
  }
  return []
})

const scrollBottom = async () => {
  if (correctScroll.value && scrollContainer.value) {
    scrollContainer.value.removeEventListener('scroll', lookIfToHaltAutoscroll)
    await nextTick()
    scrollContainer.value.scrollTo(0, scrollContainer.value.scrollHeight)
    await nextTick()
    scrollContainer.value.addEventListener('scroll', lookIfToHaltAutoscroll, { passive: true })
  }
}

const lookIfToHaltAutoscroll = () => {
  if (scrollContainer.value) {
    const pos = scrollContainer.value.scrollTop
    const desiredPos = scrollContainer.value.scrollHeight - scrollContainer.value.clientHeight
    correctScroll.value = pos >= desiredPos - 20
  }
}

// Watchers
watch([() => otherpersonwriting.value, convo], () => {
  void scrollBottom()
})

// Lifecycle hooks
onMounted(() => {
  void scrollBottom()
  window.addEventListener('resize', scrollBottom, { passive: true })
  if (scrollContainer.value) {
    scrollContainer.value.addEventListener('scroll', lookIfToHaltAutoscroll, { passive: true })
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', scrollBottom)
  if (scrollContainer.value) {
    scrollContainer.value.removeEventListener('scroll', lookIfToHaltAutoscroll)
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "my-custom-scrollbar2",
    ref_key: "scrollContainer",
    ref: scrollContainer
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(convo.value, (msg, index) => {
          return (_openBlock(), _createBlock(ChatContactMessageSmall, {
            key: 'smallmsg' + index,
            m: msg
          }, null, 8, ["m"]))
        }), 128))
      ])
    ]),
    _createVNode(_Transition, { name: "popin" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", { class: "write-indecator" }, _toDisplayString(displayName.value) + " " + _toDisplayString(_unref(store).getters.i18n({de:'schreibt',en:'is writing'})), 513), [
          [_vShow, otherpersonwriting.value]
        ])
      ]),
      _: 1
    })
  ], 512))
}
}

})