<template>
  <div class="">
    <div class="mainform g-3 pb-4">
      <h3>My Networking</h3>
      <OnBoarding1 type="step"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from 'vue'
import OnBoarding1 from "@/components/i/OnBoarding1.vue"
import { useGtm } from '@/composables/useGtm'


export default defineComponent({
  name: 'Step1',

  components: {
    OnBoarding1
  },

  setup() {
    const { trackPage } = useGtm()

    onBeforeMount(() => {
      trackPage({
        pageCategory: 'Networking',
        PageType: 'Webapp',
        PageName: 'My Networking',
      })
    })
  }
})
</script>