<template>
  <div class="box">
    <div class="mainform g-3 pb-4">
      <h3>Upload picture</h3>
      <OnBoarding3 type="step"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, PropType } from 'vue'
import OnBoarding3 from "@/components/i/OnBoarding3.vue"
import { useGtm } from '@/composables/useGtm'


export default defineComponent({
  name: 'Step1',

  components: {
    OnBoarding3
  },

  props: {
    OnBoarding3: {
      type: Object as PropType<typeof OnBoarding3>,
      required: true
    }
  },

  setup() {
    const { trackPage } = useGtm()

    onBeforeMount(() => {
      trackPage({
        pageCategory: 'Networking',
        PageName: 'Picture Upload',
        PageType: 'Webapp',
      })
    })
  }
})
</script>