import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "contact-image-wrapper" }
const _hoisted_2 = { class: "circular_image circular_image_small" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "online-indecator"
}
const _hoisted_5 = { class: "chat-dm-name text-small" }
const _hoisted_6 = {
  key: 0,
  class: "last-message m-0"
}
const _hoisted_7 = {
  key: 1,
  class: "m-0"
}
const _hoisted_8 = {
  key: 0,
  class: "mb-0"
}
const _hoisted_9 = {
  key: 1,
  class: "badge badge-danger float-right"
}

import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { getFireDB } from '@/firebaseConnections'
import type { ChatContact, DMMessage } from '@/store/i/types'


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatContactUser',
  props: {
    user: {},
    selected: { type: Boolean },
    isSmall: { type: Boolean },
    isSupported: { type: Boolean }
  },
  emits: ["pick"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const store = useStore()
const isonline = ref(false)

const title = (title: string): string => {
  let formattedTitle = _.replace(title, /&#34;/g, '"')
  formattedTitle = _.replace(formattedTitle, /&#39;/g, "'")
  return formattedTitle
}

const pickme = () => {
  if (props.user) {
    emit('pick', props.user.fbuid)
  }
}

const userfbuid = computed(() =>
    props.user && props.user.fbuid !== '' ? props.user.fbuid : ''
)

const userMessages = computed((): DMMessage[] => {
  if (props.user) {
    return store.getters['i/dmsbyuser'](props.user.fbuid)
  }
  return []
})

const lastUserMessage = computed(() => _.last(userMessages.value))

const lastMessageMsgString = computed(() => {
  if (lastUserMessage.value?.ts) {
    const setM = DateTime.fromSeconds(lastUserMessage.value.ts)
    return setM
            .setLocale(store.getters.i18n({de:'de',en:'en'}))
            .toLocaleString(DateTime.DATETIME_SHORT) +
        store.getters.i18n({de:' Uhr',en:' '})
  }
  return ''
})

const unread = computed(() => {
  const unreadMessages = _.filter(userMessages.value, (um) =>
      !um.seen && um.receiver === store.state.config.fbuid
  )
  return unreadMessages.length
})

const lastMessage = computed(() =>
    lastUserMessage.value ? lastUserMessage.value.body : null
)

const addUseridEventlistener = async () => {
  if (userfbuid.value) {
    const fbdb = await getFireDB()
    fbdb.ref(`/presence/event-general/${userfbuid.value}`).on("child_added", () => {
      isonline.value = true
    })
    fbdb.ref(`/presence/event-general/${userfbuid.value}`).on("child_removed", () => {
      isonline.value = false
    })
  }
}

const removeUseridEventlistener = async (userid?: string) => {
  const fbdb = await getFireDB()
  const id = userid || userfbuid.value
  fbdb.ref(`/presence/event-general/${id}`).off("child_added")
  fbdb.ref(`/presence/event-general/${id}`).off("child_removed")
}

watch(userfbuid, async (newVal, oldVal) => {
  if (oldVal) {
    await removeUseridEventlistener(oldVal)
  }
  await addUseridEventlistener()
})

onMounted(() => {
  void addUseridEventlistener()
})

onBeforeUnmount(() => {
  void removeUseridEventlistener()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass({'active': props.selected, 'grey': props.selected, 'lighten-3': props.selected, 'px-3': !props.isSmall, 'py-2': true, 'readed': !unread.value})
  }, [
    _createElementVNode("span", {
      onClick: _withModifiers(pickme, ["prevent"]),
      class: "d-flex justify-content-between pointer"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: `/files/members/${props.user?.objvalueid}/foto.jpg`,
            onerror: "this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';",
            alt: ""
          }, null, 8, _hoisted_3)
        ]),
        (isonline.value)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "user", {}, () => [
          _createElementVNode("b", null, _toDisplayString(props.user?.firstname) + " " + _toDisplayString(props.user?.lastname), 1),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(title(props.user?.orgname || '')), 1),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
        ]),
        (lastMessage.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(lastMessage.value ? lastMessage.value : _unref(store).getters.i18n({de:'Keine Nachricht', en:'No message'})), 1))
          : _createCommentVNode("", true),
        (props.isSmall && lastUserMessage.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(lastUserMessage.value ? lastMessageMsgString.value : ' '), 1))
          : _createCommentVNode("", true)
      ]),
      (lastUserMessage.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass({'chat-footer': true, 'chat-footer-small': props.isSmall})
          }, [
            _renderSlot(_ctx.$slots, "meta", {
              unread: unread.value,
              isSupported: props.isSupported,
              lastUserMessage: lastUserMessage.value,
              lastMessageMsgString: lastMessageMsgString.value
            }, () => [
              (!props.isSmall)
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(lastUserMessage.value ? lastMessageMsgString.value : ' '), 1))
                : _createCommentVNode("", true),
              (unread.value > 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(unread.value), 1))
                : _createCommentVNode("", true)
            ])
          ], 2))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})