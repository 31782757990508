import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-header"
}
const _hoisted_2 = { style: {"font-size":"1rem"} }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "card-title bold" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 2,
  class: "card-text bold"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "card-footer"
}
const _hoisted_9 = { class: "text-muted" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["innerHTML"]

import { computed, ref, onMounted  } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import _ from 'lodash'
import type { AgendaPoint } from '@/store/i/types'
import type { TranslatedText } from '@/store/types'
import { networkErrorText, type AppNetworkErrorOther } from '@/crud'
import { DateTime } from 'luxon'


// Lifecycle Hooks
interface Props {
  ae: AgendaPoint
  type?: number
  agendatype?: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AgendaEntry',
  props: {
    ae: {},
    type: {},
    agendatype: {}
  },
  setup(__props: any) {

onMounted(async () => {
  console.log('AgendaEntry.vue mounted')
  await store.dispatch("i/fetchAgenda")
})


// Props
const props = __props

// Store, Router & Route
const store = useStore()
const router = useRouter()
const route = useRoute()

// Reactive State
const errorstring = ref<TranslatedText>({ de: '', en: '' })

// Computed Properties
const Include = computed(() =>
    _.has(store.state.i.me.fav_agenda_items, props.ae.objvalueid)
)

const Exclude = computed(() =>
    !_.has(store.state.i.me.fav_agenda_items, props.ae.objvalueid)
)

const title = computed(() => props.ae.title)

const formatedFromTime = computed(() => {
  return DateTime.fromISO(props.ae.fromtime).toFormat('HH:mm')
})

const formatedToTime = computed(() => {
  return DateTime.fromISO(props.ae.totime).toFormat('HH:mm')
})

// Methods
const navigateAgenda = () => {
  router.push({
    name: 'AgendaDetail',
    params: { agendaid: `${props.ae.objvalueid}` }
  })
}

const setFav = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    const dpr = await store.dispatch("i/setDataFav", {
      objvalueid: props.ae.objvalueid
    })
    if (!dpr.sv) {
      errorstring.value = {
        de: "Feld konnte nicht gesetzt werden.",
        en: "Could not set Field."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

const unsetFav = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    const dpr = await store.dispatch("i/setDataUnFav", {
      objvalueid: props.ae.objvalueid
    })
    if (!dpr.sv) {
      errorstring.value = {
        de: "Feld konnte nicht entfernt werden.",
        en: "Could not remove Field."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

const generateFileICS = () => {
  window.location.href = `/projects/dw/gmf/sources/scripts/portal.php?&action=generateFileICS&objvalueid=${props.ae.objvalueid}`
}





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    'col-lg-4 col-md-6 col-12 mediathek': _ctx.agendatype === 'video' || _unref(route).name === 'Mediathek',
    'col-lg-9 col-12': _ctx.agendatype != 'video' && _unref(route).name != 'Mediathek'
  })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`card mb-3 et${_ctx.ae.eventtype}`)
    }, [
      (_ctx.agendatype != 'video' && _unref(route).name != 'Mediathek')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (Include.value)
              ? (_openBlock(), _createElementBlock("small", {
                  key: 0,
                  class: "text-muted",
                  style: {"font-size":"1.2rem","padding-right":"5px","cursor":"pointer"},
                  title: "Bookmark",
                  onClick: unsetFav
                }, _cache[0] || (_cache[0] = [
                  _createElementVNode("i", { class: "fal fa-calendar-plus" }, null, -1)
                ])))
              : _createCommentVNode("", true),
            (Exclude.value)
              ? (_openBlock(), _createElementBlock("small", {
                  key: 1,
                  class: "text-muted",
                  style: {"font-size":"1.2rem","padding-right":"5px","cursor":"pointer"},
                  title: "Bookmark",
                  onClick: setFav
                }, _cache[1] || (_cache[1] = [
                  _createElementVNode("i", { class: "fal fa-calendar-minus" }, null, -1)
                ])))
              : _createCommentVNode("", true),
            _createElementVNode("small", {
              class: "text-muted",
              style: {"font-size":"1.2rem","padding-right":"5px","cursor":"pointer"},
              title: "ICS Download",
              onClick: generateFileICS
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("i", { class: "fal fa-calendar-check" }, null, -1)
            ])),
            _createElementVNode("small", _hoisted_2, _toDisplayString(formatedFromTime.value) + " - " + _toDisplayString(formatedToTime.value) + " CEST", 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["card-body", {'p-0': _ctx.agendatype === 'video' || _unref(route).name === 'Mediathek'}])
      }, [
        _createElementVNode("a", {
          onClick: navigateAgenda,
          class: "pointer"
        }, [
          (_ctx.agendatype === 'video' || _unref(route).name === 'Mediathek')
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                onerror: "this.onerror=null;this.src='/projects/dw/gmf/assets/images/preview.jpg';",
                src: `/files/agenda_items/${_ctx.ae.objvalueid}/preview.jpg?nocache=${(new Date()).getMinutes()}`,
                alt: "Preview-Image"
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_4, _toDisplayString(title.value), 1),
          (_ctx.agendatype != 'video' && _unref(route).name != 'Mediathek')
            ? (_openBlock(), _createElementBlock("p", {
                key: 1,
                class: "card-title",
                innerHTML: _ctx.ae.subtitle
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.ae.hosted_by && (_ctx.agendatype != 'video' && _unref(route).name != 'Mediathek'))
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                _cache[3] || (_cache[3] = _createTextVNode(" Hosted by: ")),
                _createElementVNode("span", {
                  innerHTML: _ctx.ae.hosted_by
                }, null, 8, _hoisted_7)
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2),
      ((_ctx.ae.room || _ctx.ae.stream) && (_ctx.agendatype != 'video' && _unref(route).name != 'Mediathek'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("small", _hoisted_9, [
              (_ctx.ae.room)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                    _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fal fa-map-marker-alt" }, null, -1)),
                    _cache[5] || (_cache[5] = _createTextVNode()),
                    _createElementVNode("span", {
                      innerHTML: _ctx.ae.room
                    }, null, 8, _hoisted_11),
                    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.ae.stream)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                    _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fal fa-signal-stream" }, null, -1)),
                    _cache[8] || (_cache[8] = _createTextVNode()),
                    _createElementVNode("span", {
                      innerHTML: _ctx.ae.stream
                    }, null, 8, _hoisted_13),
                    _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}
}

})