import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "gallery d-block" }
const _hoisted_2 = { class: "carousel-inner h-100" }
const _hoisted_3 = { class: "h-100 d-flex justify-content-center" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "carousel-caption d-none d-md-block" }
const _hoisted_6 = { class: "thumbnails" }
const _hoisted_7 = ["onClick"]

import { ref, onMounted } from 'vue'
import { Carousel } from 'bootstrap'

interface Image {
  content: string
  image: string
  key: string
  sortorder: string
  title: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GalleryV2',
  props: {
    images: {}
  },
  setup(__props: any) {

const props = __props

const carouselElem = ref<HTMLElement | null>(null)
const carousel = ref<Carousel | null>(null)
const activeImageIndex = ref(0)

onMounted(() => {
  if (carouselElem.value === null) {
    console.warn("Couldn't find carousel")
    return
  }

  carousel.value = Carousel.getOrCreateInstance(carouselElem.value)
  carouselElem.value.addEventListener('slide.bs.carousel', (event: any) => {
    activeImageIndex.value = event.to
  })
})

const next = () => {
  if (carousel.value === null) return
  carousel.value.next()
}

const prev = () => {
  if (carousel.value === null) return
  carousel.value.prev()
}

const move = (index: number) => {
  if (carousel.value === null) return
  carousel.value.to(index)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "carousel slide",
      ref_key: "carouselElem",
      ref: carouselElem
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `carousel_item_${index}`,
            class: _normalizeClass(["carousel-item h-100", {'active': index === 0}])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: image.image,
                class: "d-block h-100",
                alt: image.title
              }, null, 8, _hoisted_4)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h5", null, _toDisplayString(image.title), 1),
              _createElementVNode("p", null, _toDisplayString(image.content), 1)
            ])
          ], 2))
        }), 128))
      ]),
      _createElementVNode("button", {
        class: "carousel-control carousel-control-prev",
        type: "button",
        onClick: prev
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("span", {
          class: "background",
          "aria-hidden": "true"
        }, null, -1),
        _createElementVNode("span", {
          class: "icon carousel-control-prev-icon",
          "aria-hidden": "true"
        }, null, -1),
        _createElementVNode("span", { class: "visually-hidden" }, "Previous", -1)
      ])),
      _createElementVNode("button", {
        class: "carousel-control carousel-control-next",
        type: "button",
        onClick: next
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("span", {
          class: "background",
          "aria-hidden": "true"
        }, null, -1),
        _createElementVNode("span", {
          class: "icon carousel-control-next-icon",
          "aria-hidden": "true"
        }, null, -1),
        _createElementVNode("span", { class: "visually-hidden" }, "Next", -1)
      ]))
    ], 512),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `thumbnail_${index}`,
          class: _normalizeClass(["thumbnail", {'active': index === activeImageIndex.value}]),
          onClick: ($event: any) => (move(index)),
          style: _normalizeStyle(`background-image: url('${image.image}');`)
        }, null, 14, _hoisted_7))
      }), 128))
    ])
  ]))
}
}

})