import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderSlot as _renderSlot, unref as _unref, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "toast-header" }
const _hoisted_2 = { class: "me-auto" }
const _hoisted_3 = {
  key: 0,
  class: "text-muted"
}
const _hoisted_4 = {
  key: 0,
  class: "mt-2 pt-2 border-top delete-button"
}
const _hoisted_5 = {
  key: 1,
  class: "mt-2 pt-2 border-top confirm d-flex"
}
const _hoisted_6 = { class: "form-check" }
const _hoisted_7 = {
  class: "form-check-label",
  for: "confirmedCheck"
}
const _hoisted_8 = ["disabled"]

import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import { useStore } from 'vuex'
import { InteractionAlert } from "@/store/i/types"
import { DateTime } from "luxon"

// Statt MDBToast:
import { Toast } from 'bootstrap'

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'ToastAlert',
  props: {
    t: {},
    inline: { type: Boolean }
  },
  emits: ["dismissed"],
  setup(__props: any, { emit: __emit }) {

const props = __props

// Emits
const emit = __emit

// Store
const store = useStore()

// Reactive state
const confirmedCheck = ref(false)
const toastinst = ref<Toast | null>(null)
const washidden = ref(false)
const gottime = ref(new Date())

// Computed
const header = computed(() => {
  if (props.t?.header) {
    return store.getters.i18n(props.t.header)
  }
  return null
})

const date = computed(() => {
  return props.t?.got || new Date()
})

const styledDate = computed(() => {
  const ldate = DateTime.fromJSDate(date.value)
  return (
    ldate
      .setLocale(store.getters.i18n({ de: 'de-de', en: 'en' }))
      .toLocaleString(DateTime.TIME_24_SIMPLE)
    + store.getters.i18n({ de: ' Uhr', en: '' })
  )
})

const link = computed(() => {
  if (props.t?.link) {
    return store.getters.i18n(props.t.link)
  }
  return null
})

// Methods
const openLink = () => {
  if (link.value) {
    emit('dismissed', props.t)
    window.location.href = link.value
  }
}

const hidenow = () => {
  if (toastinst.value) {
    toastinst.value.hide()
  } else {
    emit('dismissed', props.t)
  }
}

const seeIfToHide = () => {
  if (
    !props.inline &&
    props.t?.showfor &&
    toastinst.value &&
    !washidden.value &&
    (store.state.tick.getTime() - gottime.value.getTime()) > props.t.showfor
  ) {
    washidden.value = true
    toastinst.value.hide()
  }
}

const toastElement = ref<HTMLElement | null>(null)

const inittoast = () => {
  if (!props.inline && toastElement.value) {
    // Erstelle das Bootstrap-Toast-Objekt:
    toastinst.value = new Toast(toastElement.value, {
      animation: true,
      autohide: false
    })
    
    // Falls showfor definiert ist, steuern wir die Anzeige selbst
    washidden.value = !(typeof props.t.showfor === "number" && props.t.showfor > 0)
    gottime.value = new Date()

    toastinst.value.show()

    toastElement.value.addEventListener("hidden.bs.toast", () => {
      if (confirmedCheck.value && props.t.announce_confirm && props.t.announce_confirm !== "") {
        store.dispatch("i/announce", { nsp: props.t.announce_confirm, ev: "confirmed" })
      }
      emit('dismissed', props.t)
    }, { passive: true })
  }
}

// Watchers
watch(() => store.state.tick, seeIfToHide)

// Lifecycle hooks
onMounted(() => {
  inittoast()
})

onBeforeUnmount(() => {
  if (!props.inline && toastinst.value) {
    toastinst.value.dispose()
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      toast: true,
      show: _ctx.inline,
      'border-primary': _ctx.inline
    }),
    role: "alert",
    "aria-live": "assertive",
    "aria-atomic": "true",
    ref_key: "toastElement",
    ref: toastElement
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("strong", _hoisted_2, _toDisplayString(header.value || '') + " ", 1),
      (_ctx.inline)
        ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(styledDate.value), 1))
        : _createCommentVNode("", true),
      (!_ctx.inline)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "toast",
            "aria-label": "Close",
            onClick: _withModifiers(hidenow, ["prevent"])
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["toast-body", { 'toast-body': true, 'linked-toast-body': link.value }]),
      onClick: _withModifiers(openLink, ["stop"])
    }, [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.inline)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: _withModifiers(hidenow, ["prevent"])
            }, _toDisplayString(_unref(store).getters.i18n({ de: 'löschen', en: 'delete' })), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.t.announce_confirm && _ctx.t.announce_confirm !== '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("strong", null, _toDisplayString(_unref(store).getters.i18n({
                de: 'Sie haben 5 Minuten Zeit',
                en: 'You have 5 Minutes'
              })), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _withDirectives(_createElementVNode("input", {
                  class: "form-check-input",
                  type: "checkbox",
                  value: "1",
                  id: "confirmedCheck",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((confirmedCheck).value = $event))
                }, null, 512), [
                  [_vModelCheckbox, confirmedCheck.value]
                ]),
                _createElementVNode("label", _hoisted_7, _toDisplayString(styledDate.value), 1)
              ])
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "ms-auto btn btn-primary",
              disabled: !confirmedCheck.value,
              onClick: _withModifiers(hidenow, ["prevent"])
            }, _toDisplayString(_unref(store).getters.i18n({ de: 'Abschicken', en: 'Confirm' })), 9, _hoisted_8)
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}
}

})