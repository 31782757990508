import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelCheckbox as _vModelCheckbox, vModelText as _vModelText, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "col-md-12 col-xl-12" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = { class: "d-flex align-items-center mb-2" }
const _hoisted_7 = { class: "me-auto form-check" }
const _hoisted_8 = { class: "mb-2" }
const _hoisted_9 = { class: "mb-2" }
const _hoisted_10 = { class: "mb-2" }
const _hoisted_11 = { class: "mb-4" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "d-flex align-items-center mb-2" }
const _hoisted_14 = { class: "me-auto form-check" }
const _hoisted_15 = { class: "mb-2" }
const _hoisted_16 = { class: "mb-2" }
const _hoisted_17 = { class: "mb-2" }
const _hoisted_18 = { class: "mb-5" }
const _hoisted_19 = { class: "d-flex align-items-center" }
const _hoisted_20 = {
  class: "btn btn-primary m-1",
  type: "submit"
}
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }

import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { TranslatedText } from "@/store/types"
import { AppNetworkErrorOther, networkErrorText } from "@/crud"

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'OnBoarding2',
  props: {
    type: {}
  },
  setup(__props: any) {

const props = __props

// Store & Router
const store = useStore()
const router = useRouter()

// Reactive state
const errorstring = ref<TranslatedText>({ de: '', en: '' })
const successtring = ref<TranslatedText>({ de: '', en: '' })

// Methods
const reset_message = () => {
  errorstring.value = { de: '', en: '' }
  successtring.value = { de: '', en: '' }
}

const setDataOnBoarding2 = async () => {
  try {
    errorstring.value = { de: '', en: '' }
    successtring.value = { de: '', en: '' }

    const dpr = await store.dispatch("i/setDataOnBoarding2", {
      position: store.state.i.me.position,
      orgname: store.state.i.me.orgname,
      show_email: store.state.i.me.show_email ? 1 : 0,
      phone: store.state.i.me.phone,
      country: store.state.i.me.country,
      show_vcard: store.state.i.me.show_vcard ? 1 : 0,
      twitter: store.state.i.me.twitter,
      facebook: store.state.i.me.facebook,
      linkedin: store.state.i.me.linkedin,
      cv: store.state.i.me.cv,
    })

    setTimeout(reset_message, 2000)

    if (dpr.sv) {
      successtring.value = {
        de: "Data saved.",
        en: "Data saved."
      }
      if (props.type === "step") {
        if (store.state.config.me.tn_type !== 8) {
          router.replace({ name: "Step3" })
        } else {
          router.replace({ name: "Step4" })
        }
      }
    } else {
      errorstring.value = {
        de: "Could not set data.",
        en: "Could not set data."
      }
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther)
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _withModifiers(setDataOnBoarding2, ["prevent"])
      }, [
        _withDirectives(_createElementVNode("div", { class: "alert alert-success col-lg-12" }, _toDisplayString(_unref(store).getters.i18n(successtring.value)), 513), [
          [_vShow, successtring.value.de !== '']
        ]),
        _withDirectives(_createElementVNode("div", { class: "alert alert-danger mb-5" }, _toDisplayString(_unref(store).getters.i18n(errorstring.value)), 513), [
          [_vShow, errorstring.value.de !== '']
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[10] || (_cache[10] = _createElementVNode("b", null, "First name:", -1)),
          _createTextVNode(" " + _toDisplayString(_unref(store).state.i.me.firstname), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[11] || (_cache[11] = _createElementVNode("b", null, "Last name:", -1)),
          _createTextVNode(" " + _toDisplayString(_unref(store).state.i.me.lastname), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[12] || (_cache[12] = _createElementVNode("b", null, "E-Mail adress:", -1)),
          _createTextVNode(" " + _toDisplayString(_unref(store).state.i.me.email), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(store).state.i.me.show_email) = $event)),
              type: "checkbox",
              name: "show_email",
              id: "show_email"
            }, null, 512), [
              [_vModelCheckbox, _unref(store).state.i.me.show_email]
            ]),
            _cache[13] || (_cache[13] = _createElementVNode("label", {
              class: "form-check-label",
              for: "show_email"
            }, " Show E-Mail adress in my networking profile ", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[14] || (_cache[14] = _createElementVNode("label", { class: "form-label" }, "Position", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(store).state.i.me.position) = $event)),
            class: "form-control"
          }, null, 512), [
            [_vModelText, _unref(store).state.i.me.position]
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[15] || (_cache[15] = _createElementVNode("label", { class: "form-label" }, "Organization", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(store).state.i.me.orgname) = $event)),
            class: "form-control"
          }, null, 512), [
            [_vModelText, _unref(store).state.i.me.orgname]
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[16] || (_cache[16] = _createElementVNode("label", { class: "form-label" }, "Phone", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(store).state.i.me.phone) = $event)),
            class: "form-control"
          }, null, 512), [
            [_vModelText, _unref(store).state.i.me.phone]
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[17] || (_cache[17] = _createElementVNode("label", { class: "form-label" }, "Country", -1)),
          _withDirectives(_createElementVNode("select", {
            class: "form-control",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(store).state.i.me.country) = $event))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.i.me.countrylist, (e, ei) => {
              return (_openBlock(), _createElementBlock("option", {
                key: ei,
                value: ei
              }, _toDisplayString(e), 9, _hoisted_12))
            }), 128))
          ], 512), [
            [_vModelSelect, _unref(store).state.i.me.country]
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(store).state.i.me.show_vcard) = $event)),
              type: "checkbox",
              name: "show_vcard",
              id: "show_vcard"
            }, null, 512), [
              [_vModelCheckbox, _unref(store).state.i.me.show_vcard]
            ]),
            _cache[18] || (_cache[18] = _createElementVNode("label", {
              class: "form-check-label",
              for: "show_vcard"
            }, " Show Vcard download ", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _cache[19] || (_cache[19] = _createElementVNode("label", { class: "form-label" }, "Twitter", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(store).state.i.me.twitter) = $event)),
            class: "form-control"
          }, null, 512), [
            [_vModelText, _unref(store).state.i.me.twitter]
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _cache[20] || (_cache[20] = _createElementVNode("label", { class: "form-label" }, "LinkedIn", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(store).state.i.me.linkedin) = $event)),
            class: "form-control"
          }, null, 512), [
            [_vModelText, _unref(store).state.i.me.linkedin]
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _cache[21] || (_cache[21] = _createElementVNode("label", { class: "form-label" }, "Facebook", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(store).state.i.me.facebook) = $event)),
            class: "form-control"
          }, null, 512), [
            [_vModelText, _unref(store).state.i.me.facebook]
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _cache[22] || (_cache[22] = _createElementVNode("label", { class: "form-label" }, "CV (max. 400 characters)", -1)),
          _withDirectives(_createElementVNode("textarea", {
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(store).state.i.me.cv) = $event)),
            class: "form-control"
          }, null, 512), [
            [_vModelText, _unref(store).state.i.me.cv]
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          (_ctx.type === 'step')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'Step1' },
                class: "btn btn-primary m-1"
              }, {
                default: _withCtx(() => _cache[23] || (_cache[23] = [
                  _createTextVNode("Back")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("button", _hoisted_20, [
            (_ctx.type === 'step')
              ? (_openBlock(), _createElementBlock("span", _hoisted_21, "Next"))
              : (_openBlock(), _createElementBlock("span", _hoisted_22, "Save"))
          ])
        ])
      ], 32)
    ])
  ]))
}
}

})