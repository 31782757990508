import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "enter-area row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_4 = { class: "form-outline mb-2" }
const _hoisted_5 = ["disabled", "id"]
const _hoisted_6 = ["for"]
const _hoisted_7 = { class: "mb-2 d-flex" }
const _hoisted_8 = ["data-tracking-name", "disabled"]

import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import VideoChatList from "@/components/i/VideoChatList.vue"
import type { Message } from "@/store/i/types"
import _ from "lodash"

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'VideoChatArea',
  props: {
    chattype: {}
  },
  setup(__props: any) {

const props = __props

// Store
const store = useStore()

// Reactive state
const currentText = ref('')
const disable = ref(false)
const maxmsgs = ref(20)
const shiftDown = ref(false)

// Computed
const msgs = computed(() => {
  const messages = props.chattype === "questions"
      ? store.state.i.questions
      : store.state.i.publicchats

  const msgTime = 0

  const neccmsgs = _.orderBy(
      _.filter(
          messages,
          (m: Message) => m.ts * 1000 > msgTime && m.roomname === store.state.i.mucname
      ),
      [(m: Message) => m.ts],
      ["desc"]
  )

  return _.slice(neccmsgs, 0, _.min([maxmsgs.value, neccmsgs.length])).reverse()
})

const usedCharacters = computed(() => {
  const SPECIAL_CHARS_REGEX = /[*.:\/\\?()&%$§!+#,;-]/g;
  const npoints = currentText.value.replace(SPECIAL_CHARS_REGEX, "");
  return npoints.length
})

const remainingChraracters = computed(() => 280 - usedCharacters.value)

const textPlaceholder = computed(() => {
  if (props.chattype === "questions") {
    return store.getters.i18n({de: 'Ihre Frage', en: 'Your question'})
  }
  return store.getters.i18n({de: 'Ihre Nachricht', en: 'Your message'})
})

const textPlaceholder2 = computed(() => {
  if (props.chattype === "questions") {
    return store.getters.i18n({de: '', en: ''})
  }
  return store.getters.i18n({
    de: 'Bitte beachten Sie, dass Ihre Nachricht für alle Teilnehmer sichtbar ist.',
    en: 'Please be advised that your message will be displayed to all other participants.'
  })
})

const disabledByQuestionCount = computed(() => !store.state.i.connected)

// Methods
const scrollReachedTop = async () => {
  const ccount = msgs.value.length
  const oldest_chat = _.min([0, ..._.map(msgs.value, (m: Message) => m.ts)]) as number
  if (ccount > 0 && oldest_chat > 0) {
    await store.dispatch("i/getMorePubchats", oldest_chat)
  }
  maxmsgs.value += 5
}

const shiftC = (nd: boolean|Event) => {
  if (typeof nd === "boolean") {
    shiftDown.value = nd
    return
  }
  const event = nd as KeyboardEvent
  switch (event.key.toLowerCase()) {
    case "shift":
      shiftDown.value = true
      break
    case "enter":
      break
    default:
      shiftDown.value = false
  }
}

const sendByEnter = (e: Event) => {
  if (!shiftDown.value) {
    e.preventDefault()
    send(e)
  }
}

const send = async (e: Event) => {
  if (!disabledByQuestionCount.value &&
      usedCharacters.value > 1 &&
      remainingChraracters.value >= 0) {
    disable.value = true
    if (props.chattype === "questions") {
      await store.dispatch('i/pushInteractionMessage', currentText.value)
    } else {
      await store.dispatch('i/pushPublicChatMessage', currentText.value)
    }
    disable.value = false
    currentText.value = ''
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    'chat-area': true,
    'chat-type-chat': (_ctx.chattype === 'chat'),
    'chat-type-questions': true
  })
  }, [
    _createVNode(VideoChatList, {
      chattype: _ctx.chattype,
      msgs: msgs.value,
      onReachedtop: scrollReachedTop
    }, null, 8, ["chattype", "msgs"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (textPlaceholder2.value !== '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("small", null, _toDisplayString(textPlaceholder2.value), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            spellcheck: "true",
            autocomplete: "off",
            disabled: disable.value || disabledByQuestionCount.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentText).value = $event)),
            onKeydown: shiftC,
            onKeyup: _cache[1] || (_cache[1] = ($event: any) => (shiftC(false))),
            onKeypress: _withKeys(sendByEnter, ["enter"]),
            id: `enter-text-${_ctx.chattype}`
          }, null, 40, _hoisted_5), [
            [
              _vModelText,
              currentText.value,
              void 0,
              { trim: true }
            ]
          ]),
          _createElementVNode("label", {
            class: "form-label",
            for: `enter-text-${_ctx.chattype}`
          }, _toDisplayString(textPlaceholder.value), 9, _hoisted_6)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("small", null, _toDisplayString(remainingChraracters.value) + " " + _toDisplayString(_unref(store).getters.i18n({de: 'Zeichen zur Verfügung', en:'characters remaining'})), 1),
          _createElementVNode("button", {
            "data-tracking-name": _ctx.chattype,
            class: "ms-auto btn btn-primary",
            disabled: disable.value || disabledByQuestionCount.value,
            type: "button",
            onClick: _withModifiers(send, ["stop"])
          }, _toDisplayString(_unref(store).getters.i18n({de: 'Senden', en: 'Send'})), 9, _hoisted_8)
        ])
      ])
    ])
  ], 2))
}
}

})