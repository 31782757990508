<template>
  <div class="row mb-4">
    <div class="pointer col-md-1 col-4" @click.prevent="navigate">
      <div class="circular_image circular_image_small">
        <img :src="`/files/members/${props.user.objvalueid}/foto.jpg`"
             onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';"
             alt=""/>
      </div>
    </div>

    <div class="pointer col-md-4 col-8" @click.prevent="navigate">
      <div class="Name-Nachname mb-1 ipad-2">{{ props.user.firstname }} {{ props.user.lastname }}</div>
      <div class="Role copydetailxs mb-2 ipad-2 bold">{{ title(props.user.orgname) }}</div>
    </div>

    <div class="col-md-7 col-12">
      <span class="sm-r col-8">
        <span class="badge badge-pill sm-r">{{ props.user.match }}%</span>
        <span class="bold mb-3 ms-2 pointer" @click="change_matching">
          Matching result
          <span class="ms-2 pb-2">
            <FontAwesomeIcon icon="arrow-down" v-show="!showMatching" />
            <FontAwesomeIcon icon="arrow-up" v-show="showMatching" />
          </span>
        </span>
      </span>


      <span class="sm-l col-4">
        <button class="btn btn-primary m-1" @click.prevent="create">
          <span class="smartphone"><FontAwesomeIcon icon="user-plus" /></span>
          <span class="nsmartphone">Send contact request</span>
        </button>
      </span>



      <div v-if="showMatching" class="sm-mt-4 col-12">
        <div class="mb-2">
          <b>My Expertise</b><br>
          <span v-for="(e, ei) in networking_question1" :key="ei">
            <span
                :title="is_selected1(String(ei)) ? 'Match' : 'No match'"
                :class="{
                'badge badge-no-match': true, 
                'badge-light': is_selected1(String(ei))
              }">
              {{ networkinglist[String(ei)] }}
            </span>
          </span>

        </div>
        <div class="mb-2">
          <b>My interests</b><br>
          <span v-for="(e, ei) in networking_question2" :key="ei">
            <span
                :title="is_selected2(String(ei)) ? 'Match' : 'No match'"
                :class="{
                'badge badge-no-match': true, 
                'badge-light': is_selected2(String(ei))
              }">
              {{ networkinglist[String(ei)] }}
            </span>
          </span>
          
        </div>
        <div class="mb-2">
          <b>My Calendar</b><br>
          <span v-for="(e, ei) in fav_agenda_items" :key="ei">
            <span
                :title="is_selected3(String(ei)) ? 'Match' : 'No match'"
                :class="{
                'badge badge-no-match': true, 
                'badge-light': is_selected3(String(ei))
              }">
              {{ title(agendaEntryTitle(String(ei))) }}
            </span>
          </span>


          <p v-if="fav_agenda_items.length === 0">This participant did not bookmark any program items.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'
import type { AgendaPoint, ContactsNetworking } from '@/store/i/types'

const props = defineProps<{
  user: ContactsNetworking
  contact_type?: string
}>()

const emit = defineEmits<{
  (e: 'navigate', user: ContactsNetworking): void
  (e: 'create', user: ContactsNetworking): void
}>()

const store = useStore()
const showMatching = ref(false)

const change_matching = () => {
  showMatching.value = !showMatching.value
}

const navigate = () => {
  emit('navigate', props.user)
}

const title = (title: string): string => {
  return _.replace(_.replace(title, /&#34;/g, '"'), /&#39;/g, "'")
}

const create = () => {
  emit('create', props.user)
}

const networkinglist = computed(() => store.state.i.me.networkinglist)

const networking_question1 = computed(() => props.user?.networking_question1 || [])
const networking_question2 = computed(() => props.user?.networking_question2 || [])


const fav_agenda_items = computed(() => props.user?.fav_agenda_items || 0)

const agendaEntry = computed(() => {
  return _.filter(
      store.getters['i/orderedAgendaPoints'],
      (ap: AgendaPoint) => ap.dbstate > -1
  )
})

const agendaEntryTitle = (ei: string) => {
  const entry = _.find(
      agendaEntry.value,
      (e: AgendaPoint) => `${e.objvalueid}` === ei
  )
  return entry ? entry.title : ''
}

const is_selected1 = (ei: string) => {
  return _.includes(_.keys(store.state.i.me.networking_question2), ei)
}

const is_selected2 = (ei: string) => {
  return _.includes(_.keys(store.state.i.me.networking_question1), ei)
}

const is_selected3 = (ei: string) => {
  return _.includes(_.keys(store.state.i.me.fav_agenda_items), ei)
}
</script>

<style scoped>
.badge-no-match {
  margin: 2px;
}
.badge-light {
  background-color: var(--bs-primary);
  color: white;
}
</style>