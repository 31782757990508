<template>
  <div class="box">
    <div class="mainform g-3 pb-4">
      <h3>Page in frame</h3>
      <p>
        <a href="https://gmf-event.com/event/" target="_blank">
          Please log in via https://gmf-event.com/event/
        </a>
      </p>
      <p>
        <a class="btn btn-primary m-1" href="https://gmf-event.com/event/" target="_blank">
          To login
        </a>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from 'vue'
import {useGtm} from "@/composables/useGtm";

export default defineComponent({
  name: 'ErrorFrameset',

  setup() {
    const { trackPage } = useGtm()

    onBeforeMount(() => {
      trackPage({
        pageCategory: 'Error',
        PageName: 'ErrorFrameset',
        PageType: 'Webapp',
      })
    })

    onMounted(() => {
      try {
        if (window.top) {
          window.top.location.href = 'https://gmf-event.com/event/'
        }
      } catch (e) {
        console.warn('Auto redirect failed', e)
      }
    })
  }
})
</script>