<template>
  <div v-if="show">
    <div class="modal fade show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">Accessablity</h2>
            <button type="button" aria-label="Close" class="btn-close" @click.prevent="close"></button>
          </div>
          <div class="modal-body">
            <div>
              <h6 class="fw-bold">Hide images and animations</h6>
              <div class="form-check form-switch">
                <input class="form-check-input"
                       type="checkbox"
                       role="switch"
                       id="toggle-contrast"
                       @change="toggleHideImages"
                       :checked="accessibilityOptions.hideImages">
                <label class="form-check-label" for="toggle-contrast">Change mode</label>
              </div>
            </div>
            <div class="mt-2">
              <h6 class="fw-bold">Underline links</h6>
              <div class="form-check form-switch">
                <input class="form-check-input"
                       type="checkbox"
                       role="switch"
                       id="toggle-underline-link"
                       @change="toggleUnderlineLink"
                       :checked="accessibilityOptions.underlineLink">
                <label class="form-check-label" for="toggle-contrast">Change mode</label>
              </div>
            </div>
            <div class="mt-2">
              <h6 class="fw-bold">Font-Size</h6>
              <div>
                <input class="form-custom-range w-100"
                       type="range"
                       min="-1"
                       max="2"
                       step="1"
                       @change="setFontSize"
                       :value="accessibilityOptions.fontSize">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject } from 'vue'
import type { Emitter as MittEmitter } from 'mitt'

type Events = {
  openAccessibilityModal: void
  closeAccessibilityModal: void
}

interface AccessibilityOptions {
  hideImages: boolean
  underlineLink: boolean
  fontSize: number
}

const emitter = inject('emitter') as MittEmitter<Events>
const show = ref(false)
const accessibilityOptions = ref<AccessibilityOptions>({
  hideImages: false,
  underlineLink: false,
  fontSize: 0
})

const getAccessibiltyOptions = (): AccessibilityOptions => {
  const defaultOptions: AccessibilityOptions = {
    hideImages: false,
    underlineLink: false,
    fontSize: 0
  }

  const storageValue = localStorage.getItem('accessibility-options')
  if (!storageValue) {
    return defaultOptions
  }

  const storedOptions = JSON.parse(storageValue)
  return { ...defaultOptions, ...storedOptions }
}

const applyAccessibilityOptions = () => {
  document.body.classList.toggle('hide-images', accessibilityOptions.value.hideImages)
  document.body.classList.toggle('underline-link', accessibilityOptions.value.underlineLink)

  const value = (accessibilityOptions.value.fontSize * 0.1666) + 1
  document.body.style.setProperty('--desired-font-size', `${value}em`)
  document.body.style.setProperty('--font-scale', `${value}`)

  localStorage.setItem('accessibility-options', JSON.stringify(accessibilityOptions.value))
}

const close = () => {
  emitter.emit('closeAccessibilityModal')
}

const toggleHideImages = () => {
  accessibilityOptions.value.hideImages = !accessibilityOptions.value.hideImages
  applyAccessibilityOptions()
}

const toggleUnderlineLink = () => {
  accessibilityOptions.value.underlineLink = !accessibilityOptions.value.underlineLink
  applyAccessibilityOptions()
}

const setFontSize = (ev: Event) => {
  const target = ev.target as HTMLInputElement
  accessibilityOptions.value.fontSize = parseInt(target.value)
  applyAccessibilityOptions()
}

onMounted(() => {
  emitter.on('openAccessibilityModal', () => {
    show.value = true
  })

  emitter.on('closeAccessibilityModal', () => {
    show.value = false
  })

  accessibilityOptions.value = getAccessibiltyOptions()
  applyAccessibilityOptions()
})
</script>