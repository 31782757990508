<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import _ from "lodash"
import type { Speaker, Ticket } from "@/store/landingpage/types"

interface ReasonsToJoin {
  imageurl: string,
  text: string
}

const store = useStore()
const router = useRouter()

const navigateAgenda = (agendaid: number) => {
  router.push({
    name: 'AgendaDetail',
    params: { agendaid: agendaid.toString() }
  })
}

const validTickets = computed((): Ticket[] => {
  const filter = _.filter(
      store.getters["landingpage/tickets"],
      (e: Ticket) => !e.isticketcode && !e.isPress
  )

  return _.orderBy(filter,
      [ticket => ticket.sortPos],
      ["asc"]
  )
})

const isLoginAvailable = computed((): boolean => {
  return store.getters["landingpage/loginAvailable"]
})

const highlightedSpeakers = computed(() => {
  return store.getters["landingpage/highlightedSpeakers"]
})

const reasonsToJoin: ReasonsToJoin[] = [
  {
    imageurl: '/files/landingpage/whyattend_1.png',
    text: 'New Setting: Three iconic locations in the heart of Bonn',
  },
  {
    imageurl: '/files/landingpage/whyattend_2.png',
    text: 'Full access ticket includes dinner cruise on the Rhine and more',
  },
  {
    imageurl: '/files/landingpage/whyattend_3.png',
    text: 'Network with media professionals',
  },
  {
    imageurl: '/files/landingpage/whyattend_4.png',
    text: 'Hands-on workshop'
  }
]

const programHighlights = computed(() => {
  return store.getters["landingpage/programHighlights"]
})

const shopsAvailable = computed(() => {
  return store.getters["landingpage/shopsAvailable"]
})

const getSpeakerName = (speaker: Speaker): string => {
  let prepend = ''
  if (speaker.title) {
    prepend = speaker.title + ' '
  }
  return `${prepend}${speaker.firstname} ${speaker.lastname}`
}
</script>

<template>
  <div>
    <div class="banner Mask" style="background-size: cover; --header-xs-url: url('/files/landingpage/header_xs.png'); --header-xl-url: url('/files/landingpage/header_xl.png')">
      <iframe class="video float-end pr-lg-3 d-none d-lg-block"
              src="https://player.vimeo.com/video/1070977089?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="640"
              height="360"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              title=""
      ></iframe>
    </div>


    <iframe class="w-100 pt-3 pr-lg-3 d-block d-lg-none"
                src="https://player.vimeo.com/video/1070977089?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                width="640"
                height="360"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                title=""
        ></iframe>

        <div class="box-header w-100 g-3 row">


            <div class="col-md-12 col-xl-4 text-center">
              <router-link :to="{name: 'Login'}" class="col-12 btn btn-primary buttonsprimarytextbutton-text d-flex align-items-center justify-content-center"
                   role="button"
                   aria-label="Login"
                   v-if="isLoginAvailable">
                    Login
              </router-link>
                <div v-else>
                    <span class="headline_light">Join the conversation</span>
                    <br>
                    <h2 class="pt-3" style="color: var(--headline-light-color);">#GMF24</h2>
                </div>
            </div>

            <div class="col-md-12 col-xl-4 text-center social">
                <h2 class="headline_light">Share on</h2>
              

                <a href="https://twitter.com/DW_GMF" target="_blank">
                  <i class="fab fa-2x fa-x-twitter"></i>
                    <span class="sr-only">X (Twitter)</span>
                </a>
                <a href="https://www.linkedin.com/showcase/5088224/" target="_blank">
                  <i class="fab fa-2x fa-linkedin-in"></i>
                  <span class="sr-only">LinkedIn</span>
                </a>
                <a href="https://www.facebook.com/dw.gmf" target="_blank">
                  <i class="fab fa-2x fa-facebook-f"></i>
                  <span class="sr-only">Facebook</span>
                </a>
            </div>

            <div class="col-md-12 col-xl-4 text-center">
                <span class="headline_light text-center">Get in touch with</span>
                <div class="row intouch">
                    <div class="col-4 text-center">
                        <b>900+</b><span class="intouch_small text-center">Participants</span>
                    </div>
                    <div class="col-4 text-center">
                        <b>160+</b><span class="intouch_small text-center">Speakers</span>
                    </div>
                    <div class="col-4 text-center">
                        <b>100+</b><span class="intouch_small text-center">Countries</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="box">
            <span class="headline cb-headlinenormalxl g-3" id="speakers">Speakers</span>

            <div class="row g-3 ms-2 mt-2">
                <router-link :to="{ name: 'SpeakerDetail', params: { speakerid: speaker.objvalueid  } }"
                    v-for='(speaker, ei) in highlightedSpeakers' :key="`omember${ei}`"
                     :title="getSpeakerName(speaker)"
                    class="col-xl-4 col-md-6 col-12 mb-5 row"
                >
                    <div class="col-auto no-gutters">
                        <div class="circular_image">
                            <img :src="`/files/members/${ speaker.objvalueid }/foto.jpg`" :alt="`${speaker.firstname} ${speaker.lastname}`">
                        </div>
                    </div>

                    <div class="col-6">
            <span class="Name-Nachname">
              {{ getSpeakerName(speaker) }}
            </span>
                        <br>
                        <span v-if="speaker.orgname === '' && speaker.position" class="Role copydetailxs mb-2 bold">
              {{ speaker.position }}
            </span>
                        <span v-if="speaker.orgname" class="Role copydetailxs">
              {{ speaker.orgname }}
            </span>
                    </div>
                </router-link>
                <span class="Text"><router-link :to="{name: 'SpeakerOverview'}">See all speakers <i class="fal fa-chevron-right"></i></router-link></span>
            </div>

        </div>

        <div class="box2">
            <span class="headline cb-headlinenormalxl g-3">Even more reasons to join</span>
            <div class="row g-3 mt-0">
                <div class="col-md-6 col-lg-3 g-2" v-for="(reason, reasonIndex) in reasonsToJoin" :key="`reason${reasonIndex}`">
                    <img :src="reason.imageurl" class="img-fluid col-12" :alt="reason.text">
                    <h2 class="Lorem-ipsum-dolor-in headlineonbrightxs mt-2">
                        {{ reason.text }}
                    </h2>
                </div>
            </div>
        </div>

        <div class="Rectangle">
            <span class="headline g-3" id="highlights">Program Highlights</span>
            <div class="row g-3">
                <div class="col-md-6 col-lg-6">

                  <div v-for='(agenda, agendaIndex) in programHighlights'
                         class="row agenda_item mb-4"
                         :class="{'highlight': agendaIndex === 0}"
                    >
                        <div class="col-12 white">
                            <router-link :to="{ name: 'AgendaDetail', params: { agendaid: agenda.objvalueid } }">
                                <b v-html="agenda.title"></b>
                                <p v-if="agenda.subtitle" v-html="agenda.subtitle"></p>
                            </router-link>
                        </div>
                    </div>
                </div>   

                <div class="col-md-6 col-lg-6">
                    <img src="/files/landingpage/highlight_xl.png" class="img-fluid" alt="Highlights">
                </div>

                <span class="Text white mt-2">
                <router-link :to="{name: 'AgendaOverview'}">See full program <i class="fal fa-chevron-right"></i></router-link>
        </span>
            </div>
        </div>

        <div class="box" v-if="shopsAvailable">
            <span class="headline cb-headlinenormalxl g-3">Tickets</span>

            <div class="row g-3 mt-0">
                <div v-for="(ticket, ticketIndex) in validTickets" class="col-12 col-lg-6">
                    <h2 class="mb-0">
                        {{ ticket.label }} - <s v-if="ticket.isEarlyBirdPrice">€ {{ Math.round(ticket.priceInCents * 1.19) / 100 }}</s>

                        only € {{ Math.round(ticket.currentPriceInCents * 1.19) / 100 }}
                    </h2>

                    <h5 v-if="ticket.isEarlyBirdPrice">
                      
                        (early bird price extended until {{ (new Date(ticket.earlyBirdUntil)).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    }) }})
                    </h5>
                    <div class="Lorem-ipsum-bis-150 mt-3" v-html="ticket.description"/>
                </div>

                <a class="col-md-12 col-lg-12 btn btn-primary buttonsprimarytextbutton-text d-flex align-items-center justify-content-center"
                   href="/tickets/"
                   role="button"
                >
                    TICKETS
                </a>
            </div>
        </div>

        <div class="box2">
            <span class="headline cb-headlinenormalxl g-3 mt-1 mb-5">Main Partners</span>
            <div class="row g-3 pt-1">
                <img src="/files/landingpage/partners_1.png"
                     class="img-fluid mt-0"
                     alt="Parter Logos of GMF - Federal foreign office and the state government of North Rhine-Westphalia"
                >
            </div>
            <span class="headline cb-headlinenormalxl g-3 mt-3 mb-5">Co-Hosted By</span>
            <div class="row g-3 pt-1">
                <img src="/files/landingpage/partners_2.png"
                     class="img-fluid mt-0"
                     alt="Logo of the Foundation for International Dialogue of the Savongs Bank in Bonn"
                >
            </div>
            <span class="headline cb-headlinenormalxl g-3 mt-3 mb-5">Public Partners</span>
            <div class="row g-3 pt-1">
                <img src="/files/landingpage/partners_3.png"
                     class="img-fluid mt-0"
                     alt="Public partners of GMF - Federal Ministry for Economic Cooperation and Development; Freude Joy Joie Bonn; Federal Government Commissioner for Culture and the Media"
                >
            </div>
            <br>
        </div>

    </div>
</template>
<style scoped lang="scss">
.banner {
  width: auto;
  background-color: #d70b52;

  .video {
    width: 40%;
    position: relative;
    top: 100px;
    right: 20px;
  }
}

.box-header {
  background-color: var(--button-tertiary-default);
  padding-bottom: 30px;
  padding-top: 50px;
}
</style>
