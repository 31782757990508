<template>
  <div class="box">
    <span class="headline cb-headlinenormalxl g-3"  v-if="!SpeakerEntry">Not found</span>
    <div class="row g-3" v-if="SpeakerEntry">

      <div class="col-md-5 col-lg-3 mb-3 sm-center">
        <div class="circular_image_large">
          <img :src="`/files/members/${SpeakerEntry.objvalueid}/foto.jpg?nocache=${(new Date()).getHours()}`" width="250" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
        </div>
      </div>

      <div class="col-md-7 col-lg-9 mb-3 text-left ipadfx">
        <h3 class="pb-3 pt-0"> {{SpeakerEntry.firstname}} {{SpeakerEntry.lastname}}</h3>
        <div v-if="SpeakerEntry.position">Position: {{SpeakerEntry.position}}</div>
        <div v-if="SpeakerEntry.orgname" class="mb-4">Organization: {{SpeakerEntry.orgname}}</div>


        <div class="row">
          <div class="col-12 col-md-5" v-if="SpeakerEntry.xing || SpeakerEntry.linkedin">
            <span class="Role copydetailxs"><br>
                <span class="headline_light text-center">Follow me</span>
              <br>
              <a :href=SpeakerEntry.xing class="buttonshare" target="_blank"><i class="fab fa-xing fa-2x"></i></a>
              <a :href=SpeakerEntry.linkedin class="buttonshare" target="_blank"><i class="fab fa-2x fa-linkedin-in"></i></a>
            </span>
            <br>
          </div>

          <span class="Role copydetailxs col-12 col-md-7">
            <br>
              <span class="headline_light text-center">Share on</span><br>
              <a class="a-link--external share buttonshare" @click="share('twitter','','en_us')" data-label="twitter"><i class="fab fa-2x fa-x-twitter pe-2"></i>
                    <span class="sr-only">X (Twitter)</span></a>
              <a class="a-link--external share buttonshare" @click="share('linkedin','','en_us')" data-label="linkedin"><i class="fab fa-2x fa-linkedin-in pe-2"></i>
                  <span class="sr-only">LinkedIn</span></a>
              <a class="a-link--external share buttonshare" @click="share('facebook','','en_us')" data-label="facebook"><i class="fab fa-2x fa-facebook-f"></i>
                  <span class="sr-only">Facebook</span></a>
          </span>
          <br>

        </div>

      </div>

      <hr>

      <div v-if="SpeakerEntry.cv">
        <h4 class="bold mb-3">Resume</h4>
        <div class="cv" v-html="SpeakerEntry.cv"></div>
        <hr>
      </div>



      <div v-if="Speakers.length > 0">
        <h4 class="bold mt-4 mb-4">My speaker program</h4>
        <AgendaEntry v-for="(ae, aeindex) in Speakers" :key="`agp1_${aeindex}`" :ae="ae" agendatype="entry"/>
        <hr>
      </div>

      <div v-if="Moderators.length > 0">
        <h4 class="bold mt-4 mb-4">My moderator  program</h4>
        <AgendaEntry v-for="(ae, aeindex) in Moderators" :key="`agp2_${aeindex}`" :ae="ae" agendatype="entry"/>
        <hr>
      </div>

      <div v-if="Artists.length > 0">
        <h4 class="bold mt-4 mb-4">My artist program</h4>

        <AgendaEntry v-for="(ae, aeindex) in Artists" :key="`agp3_${aeindex}`" :ae="ae" agendatype="entry"/>
        <hr>
      </div>


    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref,toRaw } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { DateTime } from "luxon"
import _ from "lodash"
import AgendaEntry from "@/components/AgendaEntry.vue"
import { networkErrorText } from "@/crud"
import {useGtm} from "@/composables/useGtm";
import { TranslatedText } from "@/store/types"

export default defineComponent({
  name: 'SpeakerDetail',
  components: { AgendaEntry },

  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const errorstring = ref<TranslatedText>({ de: '', en: '' })
    const speakerData = ref(null)
    
    // Computed Properties
    const SpeakerEntry = computed(() => {
      // Wenn wir bereits Daten im Store haben, nutze diese
      const storeData = _.find(
          store.state.i.speakers,
          (e) => `${e.objvalueid}` === `${route.params.speakerid}`
      )
      
      // Wenn Daten im Store gefunden wurden, verwende sie
      if (storeData) return storeData;
      
      // Andernfalls verwende die separat geladenen Daten
      return speakerData.value;
    })

    const Speakers = computed(() => {
      return _.filter(
          store.getters['i/orderedAgendaPoints'],
          (ap) => ap.dbstate > -1 && _.has(ap.speakers, parseInt(route.params.speakerid as string, 10))
      )
    })

    const Moderators = computed(() => {
      return _.filter(
          store.getters['i/orderedAgendaPoints'],
          (ap) => ap.dbstate > -1 && _.has(ap.moderators, parseInt(route.params.speakerid as string, 10))
      )
    })

    const Artists = computed(() => {
      return _.filter(
          store.getters['i/orderedAgendaPoints'],
          (ap) => ap.dbstate > -1 && _.has(ap.artists, parseInt(route.params.speakerid as string, 10))
      )
    })

    // Methods
    const title = (title: string): string => {
      title = _.replace(title, /&#34;/g, '"')
      return _.replace(title, /&#39;/g, "'")
    }

    const Include = (objvalueid: any) => {
      return _.has(store.state.i.me.fav_agenda_items, objvalueid)
    }

    const Exclude = (objvalueid: any) => {
      return !_.has(store.state.i.me.fav_agenda_items, objvalueid)
    }
    
    const setFav = async (objvalueid: any) => {
      try {
        errorstring.value = { de: '', en: '' }
        const dpr = await store.dispatch("i/setDataFav", { objvalueid })
        if (!dpr.sv) {
          errorstring.value = {
            de: "Feld konnte nicht gesetzt werden.",
            en: "Could not set Field."
          }
        }
      } catch (e) {
        errorstring.value = networkErrorText(e as any)
      }
    }

    const unsetFav = async (objvalueid: any) => {
      try {
        errorstring.value = { de: '', en: '' }
        const dpr = await store.dispatch("i/setDataUnFav", { objvalueid })
        if (!dpr.sv) {
          errorstring.value = {
            de: "Feld konnte nicht gesetzt werden.",
            en: "Could not set Field."
          }
        }
      } catch (e) {
        errorstring.value = networkErrorText(e as any)
      }
    }

    const generateFileICS = (objvalueid: any) => {
      window.location.href = `/projects/dw/gmf/sources/scripts/portal.php?&action=generateFileICS&objvalueid=${objvalueid}`
    }

    const navigateAgenda = (objvalueid: any) => {
      router.push({ name: 'AgendaDetail', params: { agendaid: `${objvalueid}` } })
    }

    const formatedDate = (startdate: string, enddate: string): string => {
      return DateTime.fromISO(startdate, { locale: 'en-us' }).monthLong +
          " " + DateTime.fromISO(startdate).toFormat('d, h:mm a') +
          " - " + DateTime.fromISO(enddate).toFormat('h:mm a')
    }

    const share = (action: string, text: string, language: string) => {
      const link = window.location.href
      const encodedUrl = encodeURIComponent(link + (language === 'en_us' ?
          ((link.indexOf('?') !== -1) ? '&' : '?') + 'hl=en' : ''))

      if (action === "facebook") {
        window.open("https://www.facebook.com/sharer.php?u=" + encodedUrl)
      } else if (action === "twitter") {
        window.open("https://twitter.com/intent/tweet?source=webclient&url=" + encodedUrl)
      } else if (action === "linkedin") {
        window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + encodedUrl)
      }
    }

    // GTM tracking bei Komponenten-Erstellung

    const { trackPage } = useGtm()

    trackPage({
      pageCategory: 'Speaker',
      PageName: 'Detailpage',
      PageType: 'Webapp',
    })

    // Laden der Sprecherdaten, wenn sie nicht im Store sind
    const loadSpeakerDetails = async () => {
      try {
        // Überprüfe, ob wir bereits Daten im Store haben
        const storeData = _.find(
          store.state.i.speakers,
          (e) => `${e.objvalueid}` === `${route.params.speakerid}`
        )
        
        // Wenn nicht, lade sie über die API
        if (!storeData) {
          const response = await store.dispatch('i/fetchSpeakerDetails', route.params.speakerid);
          if (response && response.speaker) {
            speakerData.value = response.speaker;
          }
        }
      } catch (e) {
        console.error('Error loading speaker details', e);
      }
    }
    
    // Lade die Daten beim Erstellen der Komponente
    loadSpeakerDetails();

    return {
      SpeakerEntry,
      Speakers,
      Moderators,
      Artists,
      errorstring,
      title,
      Include,
      Exclude,
      setFav,
      unsetFav,
      generateFileICS,
      navigateAgenda,
      formatedDate,
      share
    }
  }
})
</script>