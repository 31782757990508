<template>
  <div class="box">
    <span class="headline cb-headlinenormalxl g-3" v-if="!meetingEntry">Not found</span>
    <div class="row g-3" v-if="meetingEntry">
      <div class="col">
        <h4>
          <i class="fal fa-calendar-alt"></i>
          {{ formatedDate(meetingEntry.startdate, meetingEntry.enddate) }} CEST
          <span v-if="meetingEntry.status == 1">- Open</span>
          <span v-if="meetingEntry.status == 2">- Accepted</span>
          <span v-if="meetingEntry.status == 3">- Declined</span>
          <span v-if="meetingEntry.status == 4">- Canceled</span>
        </h4>
        <h4 class="mb-3 mt-5 bold">{{ title(meetingEntry.meeting_title) }}</h4>

        <div class="row agenda_item_full">
          <div class="col-12">
            <span class="Sendung_Beschreibung_full"  v-html="meetingEntry.long_summary">
            </span>
          </div>
        </div>
        <div class="row">
          <div class="mb-5 mt-5 col">
            <span class="headline_light text-center"></span><br>
            <button v-if="meetingEntry.status != 2 && meetingEntry.status != 4 && meetingEntry.receiver == store.state.config.me.objvalueid" class="btn btn-primary"  @click.prevent="acceptMeeting" >Accept</button>
            <button v-if="meetingEntry.status != 3 && meetingEntry.status != 4 && meetingEntry.receiver == store.state.config.me.objvalueid" class="btn btn-primary"  @click.prevent="declineMeeting" >Decline</button>
            <button v-if="meetingEntry.status != 4 && meetingEntry.sender == store.state.config.me.objvalueid" class="btn btn-primary"  @click.prevent="removeMeeting" >Cancel</button>
          </div>
          <div class="mb-5 mt-5 col">

          </div>
          <div class="mb-5 mt-5 col">
           <span class="Role copydetailxs">
                <span class="headline_light text-center">ICS DOWNLOAD</span><br>
                  <br><br>
            </span>
          </div>

          <hr>
          
          <div>
            <div>
              <h3>Meeting partner</h3>
              <div class="row">
                <div class="col-md-4 row">
                  <div class="col-5 mb-5 no-gutters">
                    <a @click="navigateMember" class="pointer">
                      <div class="circular_image">
                        <img :src="userimage" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
                      </div>
                    </a>
                  </div>
                  <div class="col-7 mb-5 text-left">
                    <a @click="navigateMember" class="pointer">
                      <span class="Name-Nachname">{{meetingEntry.firstname}} {{meetingEntry.lastname}}</span><br>
                      <span class="Role copydetailxs">{{meetingEntry.orgname}}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'lodash';
import { DateTime } from 'luxon';
import type { Meetings } from '@/store/i/types';
import type { TranslatedText } from '@/store/types';
import { AppNetworkErrorOther, networkErrorText } from '@/crud';
import { useGtm } from '@/composables/useGtm'

const router = useRouter();
const route = useRoute();
const store = useStore();
const gtm = useGtm();
const errorstring = ref<TranslatedText>({ de: '', en: '' });

onMounted(() => {
  const { trackPage } = useGtm()
  
  trackPage({
    pageCategory: 'Networking',
    PageName: 'Meeting',
    PageType: 'Webapp',
  });
});

const meetingEntry = computed(() => {
  return _.find(
      store.state.i.meetings,
      (e: Meetings) => `${e.objvalueid}` === `${route.params.meetingid}`
  );
});

const userimage = computed(() => {
  if (meetingEntry.value) {
    if (meetingEntry.value.receiver == store.state.config.me.objvalueid) {
      return '/files/members/' + meetingEntry.value.sender + '/foto.jpg';
    } else {
      return '/files/members/' + meetingEntry.value.receiver + '/foto.jpg';
    }
  }
  return '/projects/dw/gmf/assets/images/placeholder.jpg';
});

const navigateMember = () => {
  if (meetingEntry.value) {
    if (meetingEntry.value.receiver == store.state.config.me.objvalueid) {
      router.push({ name: 'ContactDetail', params: { contactid: `${meetingEntry.value.sender}` } });
    } else {
      router.push({ name: 'ContactDetail', params: { contactid: `${meetingEntry.value.receiver}` } });
    }
  }
};

const title = (title: string): string => {
  title = _.replace(title, /&#34;/g, '"');
  title = _.replace(title, /&#39;/g, "'");
  return title;
};

const formatedDate = (startdate: string, enddate: string): string => {
  return DateTime.fromISO(startdate, { locale: 'en-us' }).monthLong +
      " " + DateTime.fromISO(startdate).toFormat('d, h:mm a') +
      " - " + DateTime.fromISO(enddate).toFormat('h:mm a');
};

const generateFileICS = (objvalueid: any) => {
  window.location.href = '/projects/dw/gmf/sources/scripts/portal.php?&action=generateFileICS&objvalueid=' + objvalueid;
};

const reset_message = () => {
  errorstring.value = { de: '', en: '' };
};

const removeMeeting = async () => {
  try {
    errorstring.value = { de: '', en: '' };
    const dpr = await store.dispatch("i/removeMeeting", meetingEntry.value);
    setTimeout(reset_message, 2000);
    if (!dpr) {
      errorstring.value = {
        de: "Could not remove Appointment.",
        en: "Could not remove Appointment."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

const acceptMeeting = async () => {
  try {
    errorstring.value = { de: '', en: '' };
    const dpr = await store.dispatch("i/acceptMeeting", meetingEntry.value);
    setTimeout(reset_message, 2000);
    if (!dpr) {
      errorstring.value = {
        de: "Could not accept Appointment.",
        en: "Could not accept Appointment."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};

const declineMeeting = async () => {
  try {
    errorstring.value = { de: '', en: '' };
    const dpr = await store.dispatch("i/declineMeeting", meetingEntry.value);
    setTimeout(reset_message, 2000);
    if (!dpr) {
      errorstring.value = {
        de: "Could not decline Appointment.",
        en: "Could not decline Appointment."
      };
    }
  } catch (e) {
    errorstring.value = networkErrorText(e as AppNetworkErrorOther);
  }
};
</script>
