<template>
  <li :class="{'active': props.selected, 'grey': props.selected, 'lighten-3': props.selected, 'px-3': !props.isSmall, 'py-2': true, 'readed': !unread}">
        <span @click.prevent="pickme" class="d-flex justify-content-between pointer">
            <div class="contact-image-wrapper">
                <div class="circular_image circular_image_small">
                    <img :src="`/files/members/${props.user?.objvalueid}/foto.jpg`"
                         onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';"
                         alt="">
                </div>
                <span v-if="isonline" class="online-indecator"></span>
            </div>
            <div class="chat-dm-name text-small">
                <slot name="user">
                    <b>{{ props.user?.firstname }} {{ props.user?.lastname }}</b><br>
                    {{ title(props.user?.orgname || '') }}<br>
                </slot>
                <p v-if="lastMessage" class="last-message m-0">
                    {{ lastMessage ? lastMessage : store.getters.i18n({de:'Keine Nachricht', en:'No message'}) }}
                </p>
                <p v-if="props.isSmall && lastUserMessage" class="m-0">
                    {{ lastUserMessage ? lastMessageMsgString : ' ' }}
                </p>
            </div>
            <div :class="{'chat-footer': true, 'chat-footer-small': props.isSmall}" v-if="lastUserMessage">
                <slot name="meta"
                      :unread="unread"
                      :is-supported="props.isSupported"
                      :last-user-message="lastUserMessage"
                      :last-message-msg-string="lastMessageMsgString">
                    <p v-if="!props.isSmall" class="mb-0">{{ lastUserMessage ? lastMessageMsgString : ' ' }}</p>
                    <span v-if="unread > 0" class="badge badge-danger float-right">{{ unread }}</span>
                </slot>
            </div>
        </span>
  </li>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { getFireDB } from '@/firebaseConnections'
import type { ChatContact, DMMessage } from '@/store/i/types'

const props = defineProps<{
  user?: ChatContact
  selected?: boolean
  isSmall?: true
  isSupported?: boolean
}>()

const emit = defineEmits<{
  (e: 'pick', fbuid: string): void
}>()

const store = useStore()
const isonline = ref(false)

const title = (title: string): string => {
  let formattedTitle = _.replace(title, /&#34;/g, '"')
  formattedTitle = _.replace(formattedTitle, /&#39;/g, "'")
  return formattedTitle
}

const pickme = () => {
  if (props.user) {
    emit('pick', props.user.fbuid)
  }
}

const userfbuid = computed(() =>
    props.user && props.user.fbuid !== '' ? props.user.fbuid : ''
)

const userMessages = computed((): DMMessage[] => {
  if (props.user) {
    return store.getters['i/dmsbyuser'](props.user.fbuid)
  }
  return []
})

const lastUserMessage = computed(() => _.last(userMessages.value))

const lastMessageMsgString = computed(() => {
  if (lastUserMessage.value?.ts) {
    const setM = DateTime.fromSeconds(lastUserMessage.value.ts)
    return setM
            .setLocale(store.getters.i18n({de:'de',en:'en'}))
            .toLocaleString(DateTime.DATETIME_SHORT) +
        store.getters.i18n({de:' Uhr',en:' '})
  }
  return ''
})

const unread = computed(() => {
  const unreadMessages = _.filter(userMessages.value, (um) =>
      !um.seen && um.receiver === store.state.config.fbuid
  )
  return unreadMessages.length
})

const lastMessage = computed(() =>
    lastUserMessage.value ? lastUserMessage.value.body : null
)

const addUseridEventlistener = async () => {
  if (userfbuid.value) {
    const fbdb = await getFireDB()
    fbdb.ref(`/presence/event-general/${userfbuid.value}`).on("child_added", () => {
      isonline.value = true
    })
    fbdb.ref(`/presence/event-general/${userfbuid.value}`).on("child_removed", () => {
      isonline.value = false
    })
  }
}

const removeUseridEventlistener = async (userid?: string) => {
  const fbdb = await getFireDB()
  const id = userid || userfbuid.value
  fbdb.ref(`/presence/event-general/${id}`).off("child_added")
  fbdb.ref(`/presence/event-general/${id}`).off("child_removed")
}

watch(userfbuid, async (newVal, oldVal) => {
  if (oldVal) {
    await removeUseridEventlistener(oldVal)
  }
  await addUseridEventlistener()
})

onMounted(() => {
  void addUseridEventlistener()
})

onBeforeUnmount(() => {
  void removeUseridEventlistener()
})
</script>